import {Component, OnInit} from "@angular/core";
import {BlogService} from "../../services/blog.service";
import {BlogArticle} from "../../entities/blog/BlogArticle";
import {ModalService} from "../../services/modal.service";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {KeapEmailTemplate} from "../../entities/keapEmailTemplate/KeapEmailTemplate";

@Component({
  selector: 'app-admin-articles',
  templateUrl: './admin-articles.component.html',
  styleUrls: [ './admin-articles.component.scss' ]
})

export class AdminArticlesComponent implements OnInit {
  searchQuery = '';
  count: number;
  page = 1;
  allArticles: BlogArticle[] = [];
  filteredArticles: BlogArticle[] = [];
  private searchSubject: Subject<string> = new Subject();
  constructor(private blogService: BlogService, private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.getAllArticle();

    this.searchSubject.pipe(debounceTime(300)).subscribe(query => {
      this.performSearch(query);
    });
  }

  getAllArticle() {
    this.blogService.getAllArticlesForAdmin().subscribe(response => {
      this.count = response.count;
      this.allArticles = response.items;
      this.applyPagination();
    });
  }

  applyPagination() {
    this.filteredArticles = this.allArticles.slice((this.page - 1) * 10, this.page * 10);
  }

  openModal() {
    this.modalService.openAddArticlePopup().subscribe((() => {
      this.getAllArticle();
      this.searchQuery = '';
    }));
  }

  openUpdateModal(id: string){
    this.modalService.openUpdateArticlePopup(id).subscribe((() => {
      this.getAllArticle();
      this.searchQuery = '';
    }));
  }

  onSearch(query: string) {
    this.searchSubject.next(query);
  }

  performSearch(query: string) {
    this.searchQuery = query.toLowerCase();
    this.page = 1;

    if (this.searchQuery) {
      const filtered = this.allArticles.filter(article =>
          article.title.toLowerCase().includes(this.searchQuery)
      );
      this.filteredArticles = filtered.slice((this.page - 1) * 10, this.page * 10);
      this.count = filtered.length;
    } else {
      this.count = this.allArticles.length;
      this.applyPagination();
    }
  }

  deleteArticle(article: BlogArticle, index: number) {
    this.modalService.openConfirmModal('Delete article?', () => {
      this.blogService.removeArticle(article.id).subscribe(() => {

        this.modalService.showTopNotification(true, `Article '${article.title}' was successfully removed`);
        this.getAllArticle();
        this.searchQuery = '';
      });
    });
  }
}

