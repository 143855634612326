import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  constructor(private http: HttpClient) {}

  getAbout(): Observable<any> {
    return this.http.get<any>(environment.base + '/api/taxonomy/about');
  }

  updateAbout(model) {
    return  this.http.put(environment.base + '/api/taxonomy/about', model).toPromise();
  }

  saveAuthCode(model) {
    return  this.http.put(environment.base + '/api/taxonomy/authcode', model).toPromise();
  }

  getAuthClient():Observable<any> {
    return  this.http.get<any>(environment.base + '/api/taxonomy/clientid');
  }
}
