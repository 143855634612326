import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { SharedService } from 'src/app/services/shared.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.scss']
})
export class AdminEditComponent implements OnInit {

  constructor(private modalService: ModalService,
              public sharedService: SharedService) { }

  addPage() {
    this.modalService.openEdit();
  }

  deletePage(id) {
    this.sharedService.deletePage(id).subscribe((response) => {
      this.sharedService.getList();
    });
  }

  openPage(option) {  // create or edit
    this.modalService.openEdit(option);
  }

  openLink(option){
    window.open(environment.baseUrl + `/${option.slug ? option.slug : option.name}`, "_blank");
  }

  openDialog(id) {
    this.modalService.openConfirmModal('Are you sure to delete the page?', () => {
      this.deletePage(id);
    });
  }

  ngOnInit(): void {
  }

}
