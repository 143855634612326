import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(private http: HttpClient) {
  }

  getPwn(){
    return this.http.get<any>(environment.base + `/api/pwnhealth/products/approved`);
  }

  addProduct(model):Observable<any> {
    return this.http.post<any>(environment.base + `/api/catalog/`, model);
    // return this.CatalogResource.addProduct({}, model).$promise;
  }

  updateProduct(model): Observable<any> {
    return this.http.put<any>(environment.base + `/api/catalog/`, model);
    // return this.CatalogResource.updateProduct({}, model).$promise;
  }

  removeProduct(id) {
    this.http.delete(environment.base + `/api/catalog/${id}`).toPromise();
    // return this.CatalogResource.removeProduct({productId: id}).$promise;
  }

  getProduct(id): Observable<any> {
    return this.http.get<any>(environment.base + `/api/catalog/${id}`);
    // return this.CatalogResource.getProduct({productId: id}).$promise;
  }

  getProducts(params) {
    if (!params) {
      params = {};
    }
    params.productId = null;
    params.$orderby = 'Name'; // temporary sorting
    return this.http.get(environment.base + `/api/catalog/${params && params.id ? params.id : ''}`, params).toPromise();
    // return this.CatalogResource.getProduct(params).$promise;
  }

  searchProduct(model) {
    return this.http.post(environment.base + `/api/catalog/`, model).toPromise();
    // return this.CatalogResource.searchProduct({}, model).$promise;
  }

  addChildren(id, relationType, model) :Observable<any> {
    return this.http.post<any>(environment.base + `/api/catalog/children/${id}/${relationType}`, model);
  }

  addOneChildren(id, relationType, productId):Observable<any> {
    return this.http.post<any>(environment.base + `/api/catalog/${productId}/${id}/${relationType}`, null);
    /*return this.CatalogResource.addOneChildren({
      childrenId: id,
      relationType: relationType,
      productId: productId
    }).$promise;*/
  }

  removeRelation(id, childId):Observable<any> {
    return this.http.delete<any>(environment.base + `/api/catalog/${id}/${childId}`);
    // return this.CatalogResource.removeRelation({productId: id, childId: childId}).$promise;
  }

  removeCategory(id) {
    return this.http.delete(environment.base +   `/api/catalog/category/${id}`).toPromise();
    // return this.CatalogResource.removeCategory({categoryId: id}).$promise;
  }

  search(model) {
    return this.http.post(environment.base + '/api/catalog/search', model);
    // return this.CatalogResource.search(model).$promise;
  }
}
