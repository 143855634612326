import { Component, EventEmitter, Output } from '@angular/core';
import { EligibleIneligibleDirective } from 'src/app/Modals/partialCovid/eligible-ineligible.directive';

@Component({
  selector: 'app-covid-contact',
  templateUrl: './covid-contact.component.html',
  styleUrls: [ './covid-contact.component.scss' ]
})
export class CovidContactComponent extends EligibleIneligibleDirective {
  @Output() covidContactChange = new EventEmitter<any>();

  fourteen($event: any): void {
    this.covidContactChange.emit($event.target.innerText.trim());
    this.eligibleClick(this.eligible + 1);
  }
}
