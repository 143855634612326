<div class="popup forms">

    <div class="cancel" (click)="ok()" style="top: -8px !important; right: -9px !important;"><i class="fa fa-times"></i></div>

    <div class="col-md-12">
        <h1 style="padding-top:0; margin-top:0;">CONGRATULATIONS! YOUR ORDER HAS BEEN SUBMITTED FOR APPROVAL</h1>
    </div>

    <p class="text-center">Please click below to go to your Order History where you can view your order.</p>
  <p class="text-center">
    After
    <a class="href-wrapper" href="https://help.grassrootslabs.com/article/168-how-long-until-my-order-is-approved" target="_blank" rel="noopener noreferrer">approval</a>, you may print out your Lab Requisition form to take with you to the testing center.
  </p>

    <div class="row text-center">
        <button (click)="ok()" type="submit" class="btn btn-default">Go to Order History</button>
    </div>

</div>
