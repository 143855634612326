import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  params = {
    isOpen: false
  };

  openedMenu;

  service = {
    params: this.params,
    openMobileMenu: this.openMobileMenu,
    closeMobileMenu: this.closeMobileMenu,
    toggleMobileMenu: this.toggleMobileMenu
  };

  constructor() { }


  openMobileMenu() {
    this.params.isOpen = true;
  }

  closeMobileMenu() {
    this.params.isOpen = false;
    this.openedMenu = -1;
  }

  toggleMobileMenu() {
    this.params.isOpen = !this.params.isOpen;
  }
}
