<div style="background-color:white; padding-top: 40px; ">
  <div class="container">
    <div class="blog-container">
      <div >
        <div class="trending-container" >
          <!-- Main Blog Post -->
          <div class="main-post" *ngIf="lastArticle">
            <div class="image-wrapper">
              <img (click)="navigatePost('blog/' + lastArticle.slug)" [src]="imageURL + lastArticle.imageId" alt="Main blog post image" class="main-post-image">
            </div>
            <h2 (click)="navigatePost('blog/' + lastArticle.slug)" class="title-posts">{{ lastArticle.title }}</h2>
            <p class="description-posts">{{ lastArticle.shortDescription }}</p>
            <div class="author-data-container">
              <div>By {{ lastArticle.author }} |</div>
              <div>&nbsp;{{ lastArticle.createDate | date:'MMMM d, yyyy' }} | </div>
              <div> &nbsp;{{ lastArticle.estimateLength }} min</div>
            </div>

          </div>
          <!-- Sidebar for Other Posts -->
          <div class="blog-side-wrapper" *ngIf="topArticleMonth && topArticleMonth.length > 0">
            <div class="blog-side-heading-wrapper"
            >

              <div class="blog-side-heading">Trending
                <br>
                on Grassroots Labs</div>
            </div>
            <div class="">
              <div *ngFor="let post of topArticleMonth" class="trending-post">
                <a class="trending-post-image-link"><img  (click)="navigatePost('blog/' + post.slug)" class="trending-post-img" [src]="imageURL + post.imageId" alt="{{ post.title }}" ></a>
                <div class="trending-post-info-wrapper">
                  <a (click)="navigatePost('blog/' + post.slug)" class="trending-post-title">{{ post.title }}</a>

                  <p class="trending-post-meta">{{ post.createDate| date:'MMMM d, yyyy'  }} | {{ post.estimateLength }} min</p>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div *ngIf="topArticleAllTime && topArticleAllTime.length > 0">
    <div>
    <div >
      <div class="container" style="background: linear-gradient(135deg, rgba(122, 106, 248, 0.1), rgba(95, 73, 244, 0.1), rgba(60, 45, 188, 0.1)); padding-top: 40px; padding-bottom: 40px; border-radius: 10px">
        <div class="most-popular-post-text">
          Most popular posts
        </div>
        <div class="most-popular-container">
          <div class="most-popular-post">
            <div class="most-popular-image-wrapper">
              <img (click)="navigatePost('blog/' + topArticleAllTime[0].slug)" [src]="imageURL + topArticleAllTime[0].imageId" alt="Post image" class="most-popular-image">
            </div>

            <div class="most-popular-post-content">
              <h3 (click)="navigatePost('blog/' + topArticleAllTime[0].slug)" class="most-popular-title">{{ topArticleAllTime[0].title }}</h3>
              <p class="most-popular-description">{{ topArticleAllTime[0].shortDescription }}</p>
              <div class="most-popular-meta">
                <span class="most-popular-date">{{ topArticleAllTime[0].createDate| date:'MMMM d, yyyy'  }}</span>
                <span class="most-popular-read-time">{{ topArticleAllTime[0].estimateLength }} min</span>
                <span class="most-popular-author">{{ topArticleAllTime[0].author }}</span>
              </div>
            </div>
          </div>
          <div class="email-subscribe">
            <h1 style="color: white; font-weight: 800 !important;">Never miss another article</h1>
            <h2 style="color: white">Curated content, compelling stories, Grassroots Labs updates, and more.</h2>
            <div class="subscribe-form" *ngIf="isActiveForm">
              <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit()" style="    display: flex; align-items: center;">
              <input type="email" formControlName="email" placeholder="Enter your email">
              <button type="submit">Subscribe</button>
              </form>
            </div>
            <h1 style="color: white; font-weight: 800 !important; border-top: 2px solid white;
    padding-top: 10px;" *ngIf="!isActiveForm">
              Thanks for subscribing to the blog!
            </h1>
          </div>
          <div class="most-popular-post" *ngFor="let post of newTopArticles; let i = index">
            <div class="most-popular-image-wrapper">
              <img (click)="navigatePost('blog/' + post.slug)" [src]="imageURL + post.imageId" alt="Post image" class="most-popular-image">
            </div>

            <div class="most-popular-post-content">
              <h3 (click)="navigatePost('blog/' + post.slug)" class="most-popular-title">{{ post.title }}</h3>
              <p class="most-popular-description">{{ post.shortDescription }}</p>
              <div class="most-popular-meta">
                <span class="most-popular-date">{{ post.createDate| date:'MMMM d, yyyy'  }}</span>
                <span class="most-popular-read-time">{{ post.estimateLength }} min</span>
                <span class="most-popular-author">{{ post.author }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="topArticleNewest && topArticleNewest.length > 0">
      <div style="background-color:white; padding-top: 40px; padding-bottom: 40px; ">
        <div class="container">
          <div style="display: flex; justify-content: space-between; align-items: flex-start;">
            <div class="most-popular-post-text">
              Chronological posts
            </div>
            <button type="button" class="btn btn-default" (click)="navigatePost('blog/explore-more')" >
              All articles
            </button>
          </div>
          <div class="most-popular-container">
            <div class="most-popular-post" *ngFor="let post of topArticleNewest">
              <div class="most-popular-image-wrapper">
                <img (click)="navigatePost('blog/' + post.slug)" [src]="imageURL + post.imageId" alt="Post image" class="most-popular-image">
              </div>

              <div class="most-popular-post-content">
                <h3 (click)="navigatePost('blog/' + post.slug)" class="most-popular-title">{{ post.title }}</h3>
                <p class="most-popular-description">{{ post.shortDescription }}</p>
                <div class="most-popular-meta">
                  <span class="most-popular-date">{{ post.createDate| date:'MMMM d, yyyy'  }}</span>
                  <span class="most-popular-read-time">{{ post.estimateLength }} min</span>
                  <span class="most-popular-author">{{ post.author }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
