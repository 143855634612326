import { Component, EventEmitter, Output } from '@angular/core';
import { EligibleIneligibleDirective } from 'src/app/Modals/partialCovid/eligible-ineligible.directive';

@Component({
  selector: 'app-saliva-second-screen',
  templateUrl: './saliva-second-screen.component.html',
  styleUrls: ['./saliva-second-screen.component.scss']
})
export class SalivaSecondScreenComponent extends EligibleIneligibleDirective {
  ScreeningQuestion2 = [
    {
    id: 'sickContact',
    title: 'Exposed or Sick Contact',
    body: ' I have been in close proximity* to someone who has been diagnosed with or presumed to have COVID-19. *within 6 ft. of the person for a prolonged period of time or being coughed on.',
    Selected: false
  }, {
    id: 'testingRecommended',
    title: 'Testing Recommended',
    body: 'I have been asked to get testing by my employer, public health department, contact investigator, or healthcare provider.',
    Selected: false
  }, {
    id: 'retesting',
    title: 'Retesting',
    body: 'I need to get retested to see if I am still sick with COVID-19.',
    Selected: false
  }, {
    id: 'setting',
    title: 'Congregate Setting',
    body: 'I live or work in a place where people reside, meet, or gather in close proximity.* *This\n' +
      ' includes office buildings, workplaces, schools, health care settings, nursing homes, long-term\n' +
      ' care or assisted living facilities, group homes, homeless shelters, prisons, and detention\n' +
      ' centers.',
    Selected: false
  }, {
    id: 'Unexposed',
    title: 'Unexposed',
    body: 'I have not been exposed.',
    Selected: false
  }
  ];
  @Output() ScreeningQuestion2Change = new EventEmitter<any>();

  screeningQuestion2Click(index, item) {
    this.ScreeningQuestion2[index].Selected = true;
    this.ScreeningQuestion2Change.emit(this.ScreeningQuestion2);
    this.eligibleClick(3);
  }
}
