<div class="nowrap" (click)="navigate()"  ngbTooltip="This requisition is in the que to be approved by our physician oversight.">
  <div *ngIf="!afterEightPm && !afterEightAm">
    Your requisition will be approved in approximately:&nbsp;
    <div class="timer" *ngIf="!ASAP else asap">
      <span class="minutes">{{hours}}</span>
      <span>:</span>
      <span class="seconds">{{minutes}}</span>
      <span>:</span>
      <span class="seconds">{{seconds}}</span>

    </div>
    <ng-template #asap> 0:00 ASAP</ng-template>
  </div>
  <div *ngIf="afterEightPm">Your requisition will be approved at approximately: 8:00 am EST {{dataTimeTomorrow | date}}</div>
  <div *ngIf="afterEightAm">Your requisition will be approved at approximately: 8:00 am EST {{dateCreatedSource | date}}</div>
</div>
