import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, ReplaySubject } from 'rxjs';
import { map, startWith } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-product-tags',
  templateUrl: './product-tags.component.html',
  styleUrls: [ './product-tags.component.scss' ]
})
export class ProductTagsComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  bar = '';
  separatorKeysCodes: number[] = [ ENTER, COMMA ];
  fruitCtrl = new UntypedFormControl();
  filteredFruits: Observable<any>;
  fruits = [];
  @Output() productChange = new EventEmitter<any>();
  @Input()
  isDisabled = false;
  @Input()
  type = 0;
  @Input()
  banks = [];
  @Input()
  selectedChildren = [];
  @Input()
  multiple = true;
  filteredBanksMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  items = [];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  formControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword multi-selection */
  bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();

  constructor(public productService: ProductService) {
  }

  add(event: any): void {
    const value = (event.option.value || '').trim();
    if (value) {
      this.formControl.value.push(event.option.value);
      this.formControl.setValue(this.formControl.value);
    }
  }

  remove(fruit: any): void {
    for (let i = 0; i < this.formControl.value.length; i++) {
      if(this.formControl.value[i].id === fruit.id){
        this.formControl.value.splice(i, 1)
        this.formControl.setValue(this.formControl.value);
        break
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.formControl.value.push(event.option.value);
    this.formControl.setValue(this.formControl.value);
  }

  onChange(value){
    if(typeof value !== 'string'){
      this.items = this.banks
      return
    }
    const filterValue = value.toLowerCase();
    this.items = this.banks.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  ngOnInit(): void {
    this.formControl.valueChanges.subscribe(x => {
      this.productChange.emit(x);
    });
    this.bankMultiFilterCtrl.valueChanges.pipe(startWith(' '))
      .subscribe(() => {
        this.filterBanksMulti();
      });
      let currentChild = [];
      this.selectedChildren.forEach((el)=>{
        let chl = this.banks.filter((child)=> child.id === el.id)[0]
        if(chl){
          currentChild.push(chl)
        }
      })
      this.formControl.setValue(currentChild);
      this.filteredBanksMulti.subscribe((res)=>{
        this.items = res
      })
  }

  filterBanksMulti() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.filteredBanksMulti.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanksMulti.next(
      this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

}
