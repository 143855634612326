<div class="upload btn btn-info" style="cursor: pointer;">
    <!-- <span class="upload__label">
        Select File(s) to Upload
    </span> -->
    <label class="browseLabel">
        <i class="fa fa-folder-open-o"></i>
            Browse
            <input
            #fileInput
            type="file"
            [multiple]="true"
            class="upload__input"
            (change)="uploadFiles( fileInput.files ) ; fileInput.value = null;"
        />
    </label>
</div>
<!-- <h2 >
    Uploads
</h2> -->
<img src="" alt=""> 
<ul class="uploads">
    <li *ngFor="let upload of uploads" class="uploads__item">
        <a [href]="upload" target="_blank" class="uploads__link">
            {{ upload}}
        </a>
        <span class="uploads__size">
            ( Size: {{ upload }} bytes )
        </span>
    </li>
</ul>