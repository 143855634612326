import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EligibleIneligibleDirective } from 'src/app/Modals/partialCovid/eligible-ineligible.directive';

@Component({
  selector: 'app-symptom',
  templateUrl: './symptom.component.html',
  styleUrls: ['./symptom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SymptomComponent extends EligibleIneligibleDirective{
}
