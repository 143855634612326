<div class="cabinetTabs">
  <div class="container-fluid">
    <div class="actionLine">
      <div class="articles-input-wrapper">
        <button (click)="openModal()" class="btn btn-default"><i class="fa fa-plus-circle"></i>
          Add New Article
        </button>
        <div class="row">
          <div class="col-md-12">
            <label >
              <div class="input-group">
                <input class="form-control"
                       type="text"
                       (input)="onSearch($event.target.value)"
                       formControlName="search"
                       class="form-control"
                       id="search"
                       tooltip-placement="left">
                <span class="input-group-addon">
                    <i class="glyphicon glyphicon-search"></i>
                </span>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>


    <div >
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Views</th>
          <th class="col-md-3">Actions</th>
        </tr>
        </thead>
        <tbody>

        <tr *ngFor="let article of filteredArticles ;let $index = index">
          <td>{{article.title}}</td>
          <td>{{ article.isActive ? 'Active' : 'Disabled' }}</td>
          <td>{{article.viewCount}}</td>
          <td>
            <button class="btn btn-danger"
                    (click)="deleteArticle(article, $index)"
            ><i
              class="fa fa-minus-circle"></i> Remove
            </button>
            <button (click)="openUpdateModal(article.id)" class="btn btn-success"><i
              class="fa fa-pencil-square"></i> Edit
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
      <ngb-pagination
              style="display: flex; justify-content: space-evenly;"
              [collectionSize]="count"
              [(page)]="page"
              [maxSize]="5"
              [pageSize]="10"
              (pageChange)="getAllArticle()"
              [rotate]="true"
              [boundaryLinks]="true">
      </ngb-pagination>
  </div>
</div>

