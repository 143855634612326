<form class="autocompleteForm" (submit)="searchF()">
  <mat-form-field
    floatLabel="never"
    [class.mat-elevation-z8]="isActive"
    class="example-full-width custom-mat-form-field"
  >
    <!-- <mat-icon style="margin-right: 10px; width: 35px;" matPrefix><img style="padding-bottom: 10px; width:30px;" src="../../../assets/img/search-button-bg.png"></mat-icon>
     -->
    <mat-icon matPrefix>
      <i class="material-icons md-24">search</i>
    </mat-icon>
    <input
      type="text"
      placeholder="Search Here and Price Check the Lab Tests You Are Looking For!"
      aria-label="Number"
      matInput
      [formControl]="searchFormControl"
      [matAutocomplete]="auto"
      *ngIf="innerWidth > 1201"
      style="animation: none; font-family: inherit; padding-top: 3px; color: #11AFBD; font-size: 14px;"
    >
    <input
      type="text"
      placeholder="Search Your Lab Tests Here!"
      aria-label="Number"
      matInput
      [formControl]="searchFormControl"
      [matAutocomplete]="auto"
      *ngIf="innerWidth < 1200"
      style="animation: none; padding-top: 3px; color: #11AFBD; font-size: 14px;"
    >
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="autocompleteComponent">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="hide-xs col-md-2 col-sm-3 col-lg-2 text-right customStyle">
    <a class="btn btn-primary displayAbove768" (click)="testCentersRedirect()">
      <i class="fa fa-map-marker"></i>
      Test Centers
    </a>
    <a class="btn btn-success displayBelow768" routerLink="cart" style="top: 30px; right: 0px;">
      <i>CART</i>
      (<span>{{cartItemsCount}}</span>)
    </a>
  </div>
</form>
<div class="container cart"
     *ngIf="showCart">
  <app-cart></app-cart>
</div>
