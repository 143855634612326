<div>
  <!--  <button mat-icon-button class="close-button" [mat-dialog-close]="true">-->
  <!--    <mat-icon class="close-icon" color="warn">close</mat-icon>-->
  <!--  </button>-->
  <div class="cancel"  [mat-dialog-close]="true">
    <i class="fa fa-times"></i>
  </div>
  <form id="payment-form">
    <div id="payment-element">
      <!--Stripe.js injects the Payment Element-->
    </div>
<!--    <p class="u-lh u-fs-3xs u-color-textSecondary u-mt-2 p-TermsText TermsText Text&#45;&#45;terms">Guaranteed secure checkout.....</p>-->
    <img class="stripe-img" src="../../assets/img/stripe-badge-transparent.png"
         alt="WP Full Stripe - Trust badge with transparent background"
         title="WP Full Stripe - Trust badge with transparent background">
    <button id="submit" (click)="handleSubmit($event)">
      <div class="spinner hidden" id="spinner"></div>
      <span id="button-text">Pay {{data.totalAmount | currency}}</span>
    </button>
    <div id="payment-message" class="hidden"></div>
  </form>
</div>

