import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PwnHealthService {

  orderStatusList = [
    {
      id: 0,
      label: 'all'
    },
    {
      id: 1,
      label: 'pending'
    },
    {
      id: 2,
      label: 'approved'
    },
    {
      id: 3,
      label: 'rejected'
    },
    {
      id: 4,
      label: 'results'
    }
  ];
  types: [];

  constructor(private http: HttpClient) {
  }


  getTypes(params):Observable<any> {
    return this.http.get<any>(environment.base + `/api/pwnhealth/types${ params }`).pipe(
      tap(types => {
        // console.log('3333',types);
        this.types = types;
      })
    );

    // return this.http.get<any>(environment.base + '/api/pwnhealth/types${params}`, {params});

  }
  getTypesTest(params): Observable<any>{
return this.http.get<any>(environment.base + `/api/pwnhealth/orders/${params.status}`)
  }
  getOrders(params): Observable<any> {
    return this.http.get<any>(environment.base + `/api/pwnhealth/orders/${params.status}`);
  }

  getOrderStatusList() {
    return this.orderStatusList;
  }
  // search(model) {
  //   console.log('search service');
  //   return this.http.post(environment.base + '/api/pwnhealth/search', model);
  //   // return this.CatalogResource.search(model).$promise;
  // }
}
