<div class="container-fluid ">
  <div class="col-md-12 top20 bot20 text-center sort-buttons" *ngIf="filteredTests">
    <button class='btn btn-info' (click)="sortBy('Name')">
      Sort by Name
      <i class="fa fa-sort-alpha-asc" *ngIf="paginator.orderBy == 'Name'"></i>
      <i class="fa fa-sort-alpha-desc" *ngIf="paginator.orderBy == 'Name desc'"></i>
    </button>
    <button class='btn btn-info' (click)="sortBy('CPT')">
      Sort by CPT
      <i class="fa fa-sort-amount-asc" *ngIf="paginator.orderBy == 'CPT'"></i>
      <i class="fa fa-sort-amount-desc" *ngIf="paginator.orderBy == 'CPT desc'"></i>
    </button>
    <button class='btn btn-info' (click)="sortBy('MasterPrice')">
      Sort by Price
      <i class="fa fa-sort-numeric-asc" *ngIf="paginator.orderBy == 'MasterPrice'"></i>
      <i class="fa fa-sort-numeric-desc" *ngIf="paginator.orderBy == 'MasterPrice desc'"></i>
    </button>
  </div>
</div>

<mat-accordion [hideToggle]="true" [displayMode]="'flat'">
  <mat-expansion-panel *ngFor="let test of newTests; let i=index" [expanded]="i === 0" #f (opened)="f.close()"
                       (click)="testNavigate(test)">
    <mat-expansion-panel-header [collapsedHeight]="'fit-content'" [expandedHeight]="'fit-content'" style="height: 100%;">
      <div class="col-md-1 imgcontainer">
        <img src="{{ imageURL + test.imageId }}" class="img-responsive">
      </div>
      <div class="col-md-11 pricelist">
        <h4>{{test.name}}</h4>
        <div>
          <small>Price:</small>
          <span style="font-size: 16px;">{{test.masterPrice | currency}}</span>
        </div>
        <div>
          <small>CPT:</small>
          <span>{{test.cpt || '-'}}</span>
        </div>
      </div>
      <div class="col-md-2 hidden-xs text-right top10">
        <button class="btn btn-primary" (click)="testNavigate(test)">
          <i class="fa fa-plus-square-o"></i> View
        </button>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-accordion>

<ngb-pagination [collectionSize]="testsCount" [(page)]="page" [maxSize]="5" (pageChange)="onPageChange(page)"
                [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
