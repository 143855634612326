<div class="container cabinet">
  <div class="col col-lg-8" >
    <div class="panel panel-main hidden-xs">
      <div class="panel-heading panel-deals cart-container cart-header-container">
        <h2 class="panel-title">Cart</h2>
      </div>
      <div  class="panel-body-container cart-container">
        <form method="post"  *ngIf="items.length > 0" class="cart-width100">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th colspan="2">Product</th>
                  <th>Market Price</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of items; let i = index;">
                  <tr>
                      <td colspan="2" (click)="navigateProduct('product/' + item.product.slug)" style="cursor: pointer;"><img [src]="imageURL+item.product.imageId" alt="Product image" width="50">
                        <span >{{item.product.name}}</span>
                      </td>
                    <td class="decoration">
                      {{ item.product.marketPrice | currency}}
                    </td>
                    <td>{{item.product.masterPrice | currency}}</td>
                    <td><i class="fa fa-trash-o" style="cursor:pointer" (click)="deleteItem(item.id, item.product.name)"></i></td>

                  </tr>
                  <tr class="tests" *ngIf="item.children?.length > 0">
                    <td colspan="5" style="border-top: 0px;">
                      <h3>Panel Includes the Following Tests:</h3>
                      <ng-container>
                        <ul class="testsUl">
                          <li *ngFor="let child of item.children">
                            {{child.product.name}}
                          </li>
                        </ul>

                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="discount > 0" >
                  <tr>
                    <td colspan="5">
                      <div class="panel panel-success">
                        <div class="panel-heading cart-container cart-header-container">
                          <h3 class="panel-title">Discounts</h3>
                        </div>
                        <div class="panel-body-discount cart-container">
                          <h3 class="panel-title cart-width100" >Duplicate Tests Ordered:</h3>
                        </div>
                        <div class="panel-body-discount cart-container duplicate-childs" *ngFor="let duplicateChild of duplicateChilds">
                          <span class="cart-width100">{{duplicateChild[0].product.name}}</span>
                          <span class="cart-widthPrice" >- {{ duplicateChild[0].product.masterPrice | currency }} {{ duplicateChild.length > 1? ' ( * ' + duplicateChild.length + ')': '' }}</span>
                        </div>
                        <div  class="panel-body-discount cart-container">
                          <h3 class="panel-title cart-width100" >Total Order Discounts</h3>
                          <span class="cart-widthPrice">- {{ discount | currency}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                <tr *ngIf="items.length > 0">
                  <td  colspan="2">
                    <a
                      tooltip-append-to-body="true"
                      placement="bottom"
                      ngbTooltip="Click on the link to see Order Fees description"
                      (click)="showProcessingFee()">Physician Oversight Fee:
                      <i class="fa fa-question-circle"></i></a>
                  </td>
                  <td><span class="decoration">$219</span></td>
                  <td  colspan="2">${{ physicianFee }} </td>
                </tr>

                <tr *ngIf="items.length > 0">
                  <td td  colspan="2">
                    <a
                      tooltip-append-to-body="true"
                      placement="bottom"
                      ngbTooltip="Click on the link to see Order Fees description"
                      (click)="showProcessingFee()">Lab Draw Fee:
                      <i class="fa fa-question-circle"></i></a>
                  </td>
                  <td><span class="decoration">$13</span></td>
                  <td colspan="2" >${{labDrawFee}}</td>
                </tr>
              </tbody>
              <tfoot>
                <!-- <tr *ngIf="items.length > 0">
                  <th colspan="3">Cart Total</th>
                  <th colspan="2">
                    {{physicianFee + labDrawFee + getTotalPrice()  - discount | currency}}
                    <span *ngIf="isFree" style="color: green">
                                      Free of charge
                    </span>
                  </th>
                </tr> -->
              </tfoot>
            </table>
            <div *ngIf="showNote" style="margin-bottom:10px; white-space: nowrap;">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              <p style="display: inline-block" class="bg-primary">{{showNote}}</p>
            </div>
          </div>


          <div class="box-footer  hidden-xs">
            <div class="btn-center">
              <button class="btn btn-warning" (disabled)="!items.length" (click)="clearCart()"><i class="fa fa-minus-circle"></i> Clear Cart</button>
            </div>
          </div>
        </form>
        <div  *ngIf="items.length === 0 && isEmpty !== undefined">
          <p>You do not have anything in your cart. Search or browse our tests and panels to add something here.</p>
          <div class="btn-group margin5">
            <a type="button" class="btn btn-default userPart bold" routerLink="/"> Browse tests </a>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-main visible-xs"> <!-- mobile view -->
      <div class="panel-heading panel-deals cart-container cart-header-container">
        <h2 class="panel-title">Cart</h2>
      </div>
      <div  class="cart-container">
        <table class="table">
          <div *ngFor="let item of items; let i = index;" class="mobile-table">
            <tr class="itemsFromLoop gitCells" style="border: 0px;">
              <td>
                <img [src]="imageURL+item.product.imageId" alt="Product image" width="50">
              </td>
              <td style="cursor: pointer; padding-top: 6px;" >
                <h3 (click)="navigateProduct('product/' + item.product.slug)" ><b>{{item.product.name}}</b></h3>
                <div>
                  <h4 style="display: inline-block; font-size: 14px;">Market Price:</h4><span  class="decoration"> {{ item.product.marketPrice | currency}}</span>
                </div>
                <div>
                  <h2 style="display: inline-block;">Price: {{item.product.masterPrice | currency}}</h2>
                </div>
              </td>
              <td>
                <i class="fa fa-trash-o fa-lg" style="margin-left: 19px; margin-bottom: 5px; cursor:pointer" (click)="deleteItem(item.id, item.product.name)"></i>
              </td>
            </tr>
            <tr *ngIf="item.children?.length > 0" class="gitCells">
              <td ></td>
              <td>
                <h3>Panel Includes:</h3>
                <ng-container>
                  <ul class="testsUl">
                    <li *ngFor="let child of item.children">
                      {{child.product.name}}
                    </li>
                  </ul>

                </ng-container>
              </td>
              <td></td>
            </tr>
          </div>
          <ng-container *ngIf="discount > 0" >
            <tr>
              <td colspan="5">
                <div class="panel panel-success">
                  <div class="panel-heading cart-container cart-header-container">
                    <h3 class="panel-title">Discounts</h3>
                  </div>
                  <div class="panel-body-discount cart-container">
                    <h3 class="panel-title cart-width100" >Duplicate Tests Ordered:</h3>
                  </div>
                  <div class="panel-body-discount cart-container duplicate-childs" *ngFor="let duplicateChild of duplicateChilds">
                    <span class="cart-width100">{{duplicateChild[0].product.name}}</span>
                    <span class="cart-widthPrice" >- {{ duplicateChild[0].product.masterPrice | currency }} {{ duplicateChild.length > 1? ' ( * ' + duplicateChild.length + ')': '' }}</span>
                  </div>
                  <div  class="panel-body-discount cart-container">
                    <h3 class="panel-title cart-width100" >Total Order Discounts</h3>
                    <span class="cart-widthPrice">- {{ discount | currency}}</span>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="items.length > 0">
              <div  class="panel-body-discount cart-container fee-div" >
                <a class="cart-width40"
                  tooltip-append-to-body="true"
                  placement="bottom"
                  ngbTooltip="Click on the link to see Order Fees description"
                  (click)="showProcessingFee()">Physician Oversight Fee:
                  <i class="fa fa-question-circle"></i>
                </a>
                <span class="decoration" style="margin: 0px 10px;">$219</span>
                <span class="cart-widthPrice" style="padding-left: 5px;"> ${{ physicianFee }}</span>
              </div>
          </tr>
          <tr *ngIf="items.length > 0">
            <div  class="panel-body-discount cart-container fee-div" >
              <a class="cart-width40"
                tooltip-append-to-body="true"
                placement="bottom"
                ngbTooltip="Click on the link to see Order Fees description"
                (click)="showProcessingFee()">Lab Draw Fee:
                <i class="fa fa-question-circle"></i>
              </a>
              <span class="decoration" style="margin: 0px 10px;">$13</span>
              <span class="cart-widthPrice" style="padding-left: 5px;"> ${{ labDrawFee }}</span>
            </div>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="col col-lg-4">
    <div class="panel panel-main">
      <div class="panel-heading panel-conf cart-container cart-header-container">
        <h2 class="panel-title">Summary</h2>
      </div>
      <div  class="panel-body-container cart-container">
        <h3 class="panel-title cart-width100 content">Subtotal</h3>
        <span class="cart-widthPrice decoration">{{ 219 + 13 + getTotalMarketPrice() || 0  | currency }}</span>
        <span class="cart-widthPrice">{{physicianFee + labDrawFee + getTotalPrice() || 0  | currency}}</span>
      </div>
      <div *ngIf="discount > 0"  class="panel-body-container cart-container">
        <h3 class="panel-title cart-width100" >Discount</h3>
        <span class="cart-widthPrice">- {{ discount | currency}}</span>
      </div>
      <div  class="panel-body-container cart-container">
        <h3 class="panel-title cart-width100" ><b>Total</b></h3>
        <span class="cart-widthPrice"><b>{{physicianFee + labDrawFee + getTotalPrice()  - discount || 0 | currency}}</b></span>
      </div>
      <div #squareWrapper class="panel-body-container cart-container">
        <h3 class="panel-decoration panel-green hidden-xs"><b>Average Order Savings: {{ 219 + 13 + getTotalMarketPrice() - (physicianFee + labDrawFee + getTotalPrice()  - discount) | currency}}</b></h3>
        <h3 class="panel-decoration panel-green visible-xs"><b>Average Order Savings: <span #statNumber ></span></b></h3>
      </div>
      <div class="panel-body-container panel-btns">
        <div class="btn-group margin5">
          <a type="button" class="btn btn-default userPart bold" routerLink="/all"> Continue shopping </a>
        </div>
        <button type="button" class="btn btn-success checkout-btn" (disabled)="!items.length" (click)="proceedCart()">Checkout
          <i class="fa fa-chevron-right"></i>
        </button>

      </div>
    </div>
    <ng-container  *ngIf="isEmpty">
      <div class="info-main-div">
        <h3 style="text-align: center;">Nearest Approved Test Center</h3>
        <table class="info-zipCode-div">
          <thead >
            <tr>
              <th colspan="2">
                {{nearestCenter.name}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Address:</th>
              <th>{{nearestCenter.distance}} Miles</th>
            </tr>
            <tr>
              <td>
                {{nearestCenter.address}} <br> {{nearestCenter.city}}, {{nearestCenter.state}}, {{nearestCenter.zip }}
              </td>
              <td>
                from Zip Code {{zipCode}}
                <div>
                  <a class="btn btn-info" href="https://www.google.com/maps/place/{{nearestCenter.address}},{{nearestCenter.city}},{{nearestCenter.state}},{{nearestCenter.zip}}" target="_blank">See in Maps</a>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                Phone:
              </th>
              <th>
                Hours of Operation:
              </th>
            </tr>
            <tr>
              <td>
                {{nearestCenter.phone}}
              </td>
              <td style="white-space:pre-line">{{nearestCenter.workingHours}}</td>
            </tr>
          </tbody>
        </table>
        <div class="zip-div">
          <a class="btn btn-primary" (click)="testCentersRedirect()">See More Test Centers</a>
        </div>
      </div>
    </ng-container>
    <ng-container  *ngIf="isEmpty === false">
      <form [formGroup]="testCentersForm" #ngForm="ngForm">
        <div class="div-search-title">
          <h3 class="search-title">Nearest Approved Test Center</h3>
        </div>
          <input class="form-control" appNumbersOnly id="zip" name="zipCode" formControlName="zipCode" type="text" placeholder="Zip Code" minlength="5" maxlength="5" />
          <button (click)="search()" class="btn btn-lg btn-primary searchBtn" style="margin-top: 10px;">
            <i class="fa fa-search"></i>  Search</button>
        </form>
      </ng-container>
      <div class="panel cart-mobile">
        <div class="cart-container cart-header-container">
          <h3 class="panel-title panel-decoration">Buy with confidence</h3>
        </div>
        <div class="cart-container cart-mobile">
          <i class='fa fa-calendar-check-o fa-lg icon-margin'></i>
          <div class="panel-body cart-container">
            <span><b>Convenient Testing on Your Schedule.</b> </span>
            <p>
              Have your tests performed locally at one of over 2100+ Quest Diagnostics Testing centers across the US. Note: Testing not available in AZ.
            </p>
            <br>
            <p>
              <a href="https://questhealth.pxf.io/Gm1NL9" target="_blank">
                Click here for testing in NJ, NY, and RI.
              </a>
            </p>
            <br>
            <p>
              Please Note: A small minority of testing centers require appointments to have labs drawn. Please contact the testing center to confirm.
            </p>
            <br>
            <p>
              Need an appointment?
              <a href="https://help.grassrootslabs.com/article/146-scheduling-an-appointment-for-lab-testing-with-quest-diagnostics" target="_blank">
                Click here.
              </a>
            </p>
          </div>
        </div>
        <div class="cart-container cart-mobile">
          <i class='fa fa-support fa-lg icon-margin'></i>
          <div class="panel-body cart-container">
            <span><b>World Class Support.</b></span>
            <p>Our team is unashamedly for our family of customers and works hard to make them happy.<br>
              Available:</p>
            <ul>
              <li>
                Live Chat: Mon-Fri, 9am-5pm EST
              </li>
              <li>
                Phone: Mon-Fri, 7am-12am EST / 4am-9pm PST
              </li>
              <li>
                Email: 9am-5pm EST
              </li>
            </ul>
          </div>
        </div>
        <div class="cart-container cart-mobile">
          <i class="fa fa-usd fa-lg icon-margin"></i>
          <div class="panel-body cart-container">
            <span><b>Affordability = Better Healthcare.</b> </span>
            <p>Save on average between 30-70% off market rates for lab tests.</p>
          </div>
        </div>
      </div>
  </div>
</div>
