<header>
  <!-- Top -->
  <div class="row admin" style="margin-left: 0px; margin-right: 0px;">
    <!-- <div class="col-md-4 col-lg-4 hidden-xs info">
    </div> -->
    <div class="col-md-5 col-lg-4 col-xs-12 text-right"
    style="
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;">
      <button class="btn btn-primary margin5 bold" *ngIf="!logined" (click)="openLoginPopup()">
        <i class="fa fa-sign-in"></i>
        LOGIN
      </button>
      <!-- <button class="btn btn-default" *ngIf="!logined" (click)="openRegisterPopup()">
        <i class="fa fa-pencil"></i>
        REGISTER
      </button> -->

      <!-- Single button using append-to-body -->
      <div *ngIf="logined" class="btn-group margin5">
        <a id="btn-append-to-body" type="button" class="btn btn-primary userPart bold" routerLink="/account">
          My Account
        </a>
      </div>

      <!-- Single button using append-to-body -->
      <div *ngIf="sessionInfoStorageService.isDashboard" class="btn-group margin5">
        <a type="button" class="btn btn-primary userPart bold" routerLink="dashboard"> Admin Dashboard </a>
      </div>

      <div class="btn-group margin5">
        <a type="button" class="btn btn-success userPart bold cart" routerLink="cart"> CART (<span>{{cartItemsCount}}</span>)</a>
      </div>
      <button class="btn btn-danger" *ngIf="logined" (click)="logout()">
        <i class="fa fa-sign-out"></i>
        <b class="hidden-xs">LOGOUT</b>
      </button>
    </div>

  </div>
  <!-- Top -->
</header>
<div class="container cart"
     *ngIf="showCart">
  <app-cart></app-cart>
</div>
