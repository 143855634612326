<div class="popup forms printable">

    <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="row" id="print-target">

        <div style="display: flex; justify-content: center; align-items: center; text-align: center;">
            <h2 style="color: #ccecfc; border-bottom: 1px solid #183b5a; width: 90%; margin-bottom: 10px; padding-bottom: 10px;">Order Fees</h2>
        </div>

        <div class="col-md-12" style="color: #fff">
            Physician Oversight Fee:<br>
            The oversight fee is collected on behalf of PWNHealth and its affiliated professional entities for independent
            <br> physician oversight of lab testing. The fee is non-refundable.
            For more information, see
            <a style="    font-size: 1em;
            margin-left: 0px;" target="_blank" href="https://help.grassrootslabs.com/article/177-who-approves-and-reviews-lab-orders-purchased-with-grassroots-labs">Physician Oversight</a>
           .
            <br><br>Lab Draw Fee:<br>
            The lab draw fee covers the cost of having labs draw at a Quest Diagnostics Testing Center. The fee is non-refundable.
        </div>

    </div>

    <div class="text-center top10 bot20">
        <button print-popup print-popup-element="print-target" (click)="cancel()"
            class="btn btn-default">
        OK</button>
    </div>

</div>
