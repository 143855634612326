<div class="cabinetTabs">
    <div class="container-fluid">
        <table class="table table-hover">
            <thead>
            <tr>    
                <th>Name</th>
                <th>Code</th>
                <th>Same Day</th>
                <th>Required Gender</th>
                <th>Disclaimers Required</th>
                <th>Customer Physician Required</th>
                <th>Fasting Required</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of types">
                <td>{{ item.name}}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.sameDay ? 'YES' : 'NO' }}</td>
                <td>{{ item.requiredGender == 'false' ? 'NO' : item.requiredGender }}</td>
                <td>{{ item.disclaimersRequired ? 'YES' : 'NO' }}</td>
                <td>{{ item.customerPhysicianRequired ? 'YES' : 'NO' }}</td>
                <td>{{ item.fastingRequired ? 'YES' : 'NO' }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>