<div class="popup forms">

    <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="col-md-12">
        <h1 style="padding-top:0; margin-top:0;">Registration Success</h1>
    </div>

    <p> For your security, please check your email for the account activation link we just sent.<br><br>
        Click it to activate your account so you can log in and complete your order.
    </p>

    <div class="row text-center">
        <button (click)="cancel()" type="submit" class="btn btn-default">OK</button>
    </div>

</div>