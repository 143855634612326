import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AddCategoryComponent } from './add-category/add-category.component';
import { FileUploaderComponent } from '../thirdParty/file-uploader/file-uploader.component';
import { AddTestsComponent } from './add-tests/add-tests.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { LoginComponent } from './login/login.component';
import { PrintReceiptComponent } from './print-receipt/print-receipt.component';
import { RegistrationComponent } from './registration/registration.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../shared/material-module';
import { BrowseImageComponent } from './browse-image/browse-image.component';
import { LoginAsModalComponent } from './login-as/login-as-modal.component';
import { AuthInterceptor } from '../interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Covid19EmpComponent } from './covid19-emp/covid19-emp.component';
import { Covid19Component } from './covid19/covid19.component';
import { CovidEmpWithoutThreeStepsComponent } from './covid-emp-without-three-steps/covid-emp-without-three-steps.component';
import { InProgressComponent } from './in-progress/in-progress.component';
import { CheckoutSuccessComponent } from './checkout-success/checkout-success.component';
import { Covid19SalivaComponent } from './covid19-saliva/covid19-saliva.component';
import { SymptomComponent } from './partialCovid/symptom/symptom.component';
import { ModalLogoComponent } from 'src/app/Modals/partialCovid/modal-image/modal-logo.component';
import { PcrTestComponent } from './partialCovid/pcr-test/pcr-test.component';
import { CovidContactComponent } from './partialCovid/covid-contact/covid-contact.component';
import { DemographicInfoComponent } from 'src/app/Modals/partialCovid/demografic-info/demographic-info.component';
import { SalivaSymptomsComponent } from './covid19-saliva/salivaShared/saliva-symptoms/saliva-symptoms.component';
import { SalivaSecondScreenComponent } from './covid19-saliva/salivaShared/saliva-second-screen/saliva-second-screen.component';
import { SalivaThirdScreenComponent } from './covid19-saliva/salivaShared/saliva-third-screen/saliva-third-screen.component';
import { SalivaFourthScreenComponent } from './covid19-saliva/salivaShared/saliva-fourth-screen/saliva-fourth-screen.component';
import { SalivaFifthScreenComponent } from './covid19-saliva/salivaShared/saliva-fifth-screen/saliva-fifth-screen.component';
import { SalivaSixthScreenComponent } from './covid19-saliva/salivaShared/saliva-sixth-screen/saliva-sixth-screen.component';
import { CovidConsentComponent } from './covid19-emp/covid-consent/covid-consent.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatTableModule } from '@angular/material/table';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { AddPageComponent } from './add-page/add-page.component';
import { IdleComponent } from './idle/idle.component';
import { ProcessingFeeComponent } from './processing-fee/processing-fee.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { ConfirmForgotPasswordComponent } from './confirm-forgot-password/confirm-forgot-password.component';
import { ProductTagsComponent } from './add-tests/product-tags/product-tags.component';
import { ImportantNoticeComponent } from './important-notice/important-notice.component';
import { TestNotApprovedComponent } from './test-not-approved/test-not-approved.component';
import { AddCardComponent } from './add-card/add-card.component';
import { AddPromoCodeComponent } from './add-promo-code/add-promo-code.component';
import { PromoCodeConfirmComponent } from './promoCode-confirm/promoCode-confirm.component';
import { ResultsRequisitionComponent } from './results-requisition/results-requisition.component';
import {AddKeapTemplateComponent} from './add-keap-template/add-keap-template.component';
import {AddArticleComponent} from "./add-article/add-article.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSelectModule} from "@angular/material/select";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {NgxSummernoteModule} from "ngx-summernote";


@NgModule({
    declarations: [
        AddCategoryComponent,
        FileUploaderComponent,
        AddTestsComponent,
        AddArticleComponent,
        ConfirmComponent,
        LoginComponent,
        PrintReceiptComponent,
        RegistrationComponent,
        BrowseImageComponent,
        LoginAsModalComponent,
        Covid19EmpComponent,
        Covid19Component,
        CovidEmpWithoutThreeStepsComponent,
        InProgressComponent,
        CheckoutSuccessComponent,
        Covid19SalivaComponent,
        SymptomComponent,
        ModalLogoComponent,
        PcrTestComponent,
        CovidContactComponent,
        DemographicInfoComponent,
        SalivaSymptomsComponent,
        SalivaSecondScreenComponent,
        SalivaThirdScreenComponent,
        SalivaFourthScreenComponent,
        SalivaFifthScreenComponent,
        SalivaSixthScreenComponent,
        ForgotPasswordComponent,
        CovidConsentComponent,
        ChangePasswordComponent,
        ChangeEmailComponent,
        CovidConsentComponent,
        AddPageComponent,
        IdleComponent,
        ProcessingFeeComponent,
        ConfirmEmailComponent,
        RegistrationSuccessComponent,
        ConfirmForgotPasswordComponent,
        ProductTagsComponent,
        ImportantNoticeComponent,
        TestNotApprovedComponent,
        AddCardComponent,
        AddPromoCodeComponent,
        PromoCodeConfirmComponent,
        ResultsRequisitionComponent,
        AddKeapTemplateComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: DatePipe,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    imports: [
        NgxSummernoteModule,
        MatIconModule,
        MatChipsModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgbModule,
        NgScrollbarModule,
        NgSelectModule,
        MatTableModule,
        FileUploadModule,
        ToastModule,
        ButtonModule,
        TabViewModule,
        NgxMatSelectSearchModule,
    ],
    exports: [
        AddCategoryComponent,
        FileUploaderComponent,
        AddTestsComponent,
        ConfirmComponent,
        LoginComponent,
        PrintReceiptComponent,
        RegistrationComponent,
        ProductTagsComponent,
        AddCardComponent,
        AddPromoCodeComponent,
        PromoCodeConfirmComponent,
        ResultsRequisitionComponent,
        AddKeapTemplateComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalModule { }
