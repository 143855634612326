<div class="covid2 panel" [hidden]="(showScreeningQuestion2 || showScreeningQuestion3) && !ineligible2">
  <div *ngIf="HouseholdContact" class="householdContact">
    <span>
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        If you are currently experiencing any of the following
        symptoms, seek medical attention right away.
    </span>
    <ul *ngFor="let item of dontKnow">
      <li>{{item}}</li>
    </ul>
  </div>
  <div class="panel-heading" *ngFor="let item of h3Element">
    <h3 class="panel-title">{{ item }}</h3>
  </div>
  <div class="panel-body">
    <div class="col-sm-12" *ngFor="let item of fieldSet">
      <fieldset class="form-group">
        <div class="row">
          <input
            required
            [(ngModel)]="model[item.model]"
            type="checkbox"
            class="checkbox-inline col-xs-2 col-sm-2"
            [name]="item.name"
            [value]="item.value"
            [id]="item.id"
            (change)="modelCheck()">
          <label [for]="item.labelFor" class="col-xs-10 col-sm-10 control-label">
            <a [href]=item.aHref target="_blank">{{ item.text }}</a>
          </label>
        </div>
      </fieldset>
    </div>
    <div ng-cloak class="form-group col-sm-12 pull-right"
         [hidden]="(showScreeningQuestion2 || showScreeningQuestion3) && !ineligible2">
      <div class="alert alert-danger" role="alert" *ngIf="!checkboxValid">
        Please check to agree to the
        {{!model.privacyPractices ? 'PWN Notice of Privacy Practices' :
        ''}}{{!model.privacyPractices && (!model.termsAndConditions || !model.testConsent) ? ' and
        ': ''}}
        {{!model.termsAndConditions ? 'PWN Terms and Conditions' : ''}}{{!model.termsAndConditions
      && !model.testConsent ? ' and ' : ''}}
        {{!model.testConsent ? 'Privacy Policy and COVID-19 Test Consent' : ''}}
      </div>
    </div>
  </div>
  <button
    color="primary"
    mat-raised-button
    type="submit"
    (click)="submitForm()"
    [disabled]="disableButton">
    Next Screen
  </button>
</div>
