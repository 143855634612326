<div>
  <table class="table table-hover">
    <thead>
    <tr>
        <th>Date</th>
        <th class="col-md-3">Old Email</th>
        <th class="col-md-3">New Email</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let template of emailHistory; let i = index">
        <td>
            {{template.changeDate  | date}}
        </td>
        <td>
          {{template.oldEmail}}
        </td>
        <td>
          {{template.newEmail}}
        </td>
      </tr>
    </tbody>
</table>
</div>
