import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EligibleIneligibleDirective } from 'src/app/Modals/partialCovid/eligible-ineligible.directive';
import { cartRepeater, salivaFifthScreenText } from '../configs';

@Component({
  selector: 'app-saliva-fifth-screen',
  templateUrl: './saliva-fifth-screen.component.html',
  styleUrls: [ './saliva-fifth-screen.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalivaFifthScreenComponent extends EligibleIneligibleDirective {
  @Output() ScreeningQuestion5Change = new EventEmitter<any>();
  text = salivaFifthScreenText;
  cartRepeater = cartRepeater;


  nextScreen(f: NgForm): void {
    const selected = this.formStatus(f);
    this.ScreeningQuestion5Change.emit(selected);
    this.eligibleClick(this.eligible + 1);
  }

  checkboxSelection(item, f, key): void {
    const groupControl = f.form.controls[key];
    if(key != 'feverDate'){
      Object.keys(groupControl.value).forEach(x => {
        if( item.id !== 'feverDate'){
          if (x !== item.id) {
            groupControl.controls[x].patchValue(false);
          }
        }
      });
    }
    
    // if(!f.form.controls['healthcare'].value.healthcareYes){
    //   f.form.controls['feverDate']?.patchValue('')
    // }
    if(!f.form.controls['healthcare'].value.healthcareYes || f.form.controls['feverDate']?.value){
      this.validation(f, 4);

    }else{
      this.validation(f, 4, true);
    }
  }

  formStatus(f: NgForm): object {
    const formStatus = {};
    Object.entries(f.form.controls).forEach(x => {
      formStatus[x[0]] = x[1].value;
    });
    return formStatus;
  }

  // private validation(f): void {
  //   const valid = {};
  //   Object.values(f.form.controls)
  //     .forEach((x: any) => Object.entries(x.value).forEach(y => {
  //       if (y[1] === true) {
  //         valid[y[0]] = y[1];
  //       }
  //     }));
  //   this.validForm = Object.keys(valid).length === 4;
  // }
}
