import { Component, OnInit } from '@angular/core';
import { AboutService } from '../../services/about.service';
import { NewResourcesService } from '../../services/new-resources.service';
import { CONSTANTS } from '../../const';
import { ProfileService } from '../../services/profile.service';
import { SessionInfoStorageService } from '../../services/session-info-storage.service';
import { NavbarService } from '../../services/navbar.service';
import { ModalService } from '../../services/modal.service';
import { CartService } from '../../services/cart.service';
import { AccountService } from '../../services/account.service';
import { SessionStorageService } from 'ngx-webstorage';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, UrlTree } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { environment } from '../../../environments/environment';
import { SharedService } from './../../services/shared.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

    // $scope, $state, $uibModal, $timeout,
    openedMenu = -1;
  isSearchFired = false;
  userName = '';
  userEmail = '';
  cartItemsCount = 0;
  navstatus = false;
  blogURL = environment.blogUrl;
  searchOpen = false;
  minError = false;
  aboutHTML = '';
  logined;
  isDashboard;
  model: any = {
    search: ''
  };
  showCart = false;
  constructor( private aboutService: AboutService,
               public newResourcesService: NewResourcesService,
               private $sessionStorage: SessionStorageService,
               private profileService: ProfileService,
               private sessionInfoStorageService: SessionInfoStorageService,
               private cartService: CartService,
               private accountService: AccountService,
               public activatedRoute: ActivatedRoute,
               private router: Router,
               public notificationService: NotificationService,
               public navbarService: NavbarService,
               public modalService: ModalService,
               public sharedService: SharedService) {
    setTimeout(() => {
      this.cartService.refreshCart();
    }, 0 );
    notificationService.getProfileState().subscribe(data => {
      if (data && data.event === 'profiles:update') {
        this.updateUserName(data.data);
      }
    });
    notificationService.getCartState().subscribe(data => {
      if (data.event === 'cart:update') {
        this.updateItemCount(data.event, data.data);
      }
    });
    this.$sessionStorage.observe('auth').subscribe(() => {
      this.setLogined();
    });

    router.events.subscribe(e => {
      if (e instanceof NavigationEnd){
        this.openMenu(-1);
      }
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  closeCart() {
    this.showCart = false;
    this.notificationService.cartState.next({event: 'cart:close'})
    // $rootScope.$broadcast('cart:close', {});
  }

  openCart() {
    this.showCart = true;
  }

  toggleCart() {
    this.showCart = !this.showCart;
    if (this.showCart) {
      this.notificationService.cartState.next({event: 'cart:onOpen', data: {}});
      // $rootScope.$broadcast('cart:onOpen', {});
    } else {
      this.notificationService.cartState.next({event: 'cart:close', data: {}});
      // $rootScope.$broadcast('cart:close', {});
    }
  }

    updateUserName(profiles) {
      if (!profiles || !profiles.length){
        return;
      }
      const profile = profiles[0];
      this.userName = profile.firstName + ' ' + profile.lastName;
      this.userEmail = profile.email;
    }

    updateItemCount(event, cartData) {
      this.cartItemsCount = cartData.itemsCount;
    }

    openMenu(id) {
      this.navbarService.openedMenu = ( this.navbarService.openedMenu === id ) ? -1 : id;
    }

    isOpen(id) {
      return this.navbarService.openedMenu === id;
    }

    openSearchPopup () {
      this.openPopup('md', '/partials/Modals/search.html', 'SearchController');
    };

    toggleSearch() {
      this.searchOpen = !this.searchOpen;
    };

    search() {
      if (this.model.search.length < CONSTANTS.MIN_SEARCH_LENGTH) {
        this.minError = true;
        return;
      }
      this.minError = false;
      this.searchOpen = false;
      this.router.navigate(['search'], {queryParams: {query: this.model.search}});
      // $state.go('search', {query: $scope.model.search});
    }

    toggleCollapsed() {
      this.navbarService.toggleMobileMenu();
    };

    closeMenu() {
      this.navbarService.closeMobileMenu();
    }
    testCentersNavigate() {
      this.navbarService.closeMobileMenu();
      this.router.navigate(['testcenters']);
    }

    openPopup(size, template, controller) {
      // implement this modal option !!!

      /*$uibModal.open({
        animation: true,
        templateUrl: template,
        controller: controller,
        size: size
      })*/
    }

    setLogined() {
      
      if (this.$sessionStorage.retrieve('auth')) {
        
        this.logined = true;
        this.isDashboard = this.sessionInfoStorageService.isDashboardAllowed();
      } else {
        this.logined = false;
      }
    }


    openLoginPopup() {
      this.modalService.openLogin();
    }

    openRegisterPopup() {
      this.modalService.openRegistration();
    }

    // openCart() {
    //   this.cartService.openCart();
    // }

    logout() {
      this.accountService.logout();
      this.router.navigate(['/']);
      // $state.go('main');
    }


    getCookieAuth() {
      const auth = this.$sessionStorage.retrieve('auth');
      if (auth && auth.blogCookieName && auth.blogCookie) {
        return '?auth_cookie_name=' + auth.blogCookieName + '&auth_cookie=' + auth.blogCookie;
      }

    }

  ngOnInit(): void {
    
    this.profileService.getProfiles((profiles) => {
      this.updateUserName(profiles);
    });

    this.setLogined();
  }
}
