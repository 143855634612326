import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss']
})
export class CheckoutSuccessComponent implements OnInit {

  constructor(
    private router:Router,
    private dialogRef: MatDialogRef<CheckoutSuccessComponent>,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    // this.dialogRef.close();
    this.modalService.dismissAll();
  }

  ok (){
    this.router.navigateByUrl('cabinet');
    this.modalService.dismissAll();
    // this.dialogRef.close();
  };
          
}
