<div class="popup forms">
  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>
  <div class="col-md-12">
    <h1 style="padding-top:0; margin-top:0;">{{editMode ? 'Edit' : 'Add'}} Promo Code</h1>
  </div>
  <div class="row">
    <form class="form-horizontal promo-custom-field" [formGroup]="promoCodeForm" (ngSubmit)="submit()">
      <div class="col-md-12">
        <div mat-dialog-content>
          <div class="col-md-6">
            <div class="form-group">
              <label for="description" class="col-sm-4 control-label">Promo Code Name*</label>
              <div class="col-sm-8">
                <input type="text" formControlName="description" class="form-control"  [readonly]="editMode" />
              </div>
              <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.description?.invalid">
                <div class="alert alert-danger" role="alert" *ngIf="promoCodeForm.controls.description.errors.required">
                  <b>The Promo Code Name Field is required</b>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="code" class="col-sm-4 control-label">Promo Code Text*</label>
              <div class="col-sm-8">
                <input class="form-control" type="text" maxlength="255" formControlName="code" [readonly]="editMode"
                  class="form-control" id="code">
              </div>
              <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.code?.invalid">
                <div class="alert alert-danger" role="alert" *ngIf="promoCodeForm.controls.code.errors.required">
                  <b>The Promo Code Text Field is required</b>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">Discount</label>
              <div class="col-sm-8">
                <input class="form-control" type="number" maxlength="10" formControlName="discount" [readonly]="editMode"
                  class="form-control">
              </div>
              <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.discount?.invalid">
                <div class="alert alert-danger" role="alert" *ngIf="promoCodeForm.controls.discount.errors.required">
                  <b>The Discount Field is required</b>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="discountType" class="col-sm-4 control-label">Promotion Type</label>
              <div class="col-sm-8">
                <mat-form-field appearance="fill" class="mat-form-wrapper">
                  <mat-select class="custom-select-promo" placeholder="Discount Type"
                              formControlName="discountType">
                    <mat-option *ngFor="let type of promotionTypeList" [value]="type.value">
                      {{type.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.discountType?.invalid">
                <div class="alert alert-danger" role="alert" *ngIf="promoCodeForm.controls.discountType.errors.required">
                  <b>The Promotion Type Field is required</b>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="promoType" class="col-sm-4 control-label">Applies to</label>
              <div class="col-sm-8">
                  <mat-radio-group formControlName="promoType" [disabled]="editMode" >
                    <mat-radio-button [value]="0">Order</mat-radio-button>
                    <mat-radio-button [value]="1">Product</mat-radio-button>
                  </mat-radio-group>
                  <div *ngIf="promoCodeForm.controls.promoType.value=='1'">
                    <div style=" margin-bottom: 20px;">
                      <app-product-tags [banks]="data" [selectedChildren]="selectedChildren" [isDisabled]="editMode" [type]="0" [multiple]="false"
                                        (productChange)="onProductChange($event)">
                      </app-product-tags>
                    </div>
                    <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.promoType?.value === 1 && selectedChildren.length === 0">
                      <div class="alert alert-danger" role="alert" >
                        <b>The Products Type Field is required</b>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.promoType?.invalid">
                <div class="alert alert-danger" role="alert" *ngIf="promoCodeForm.controls.promoType.errors.required">
                  <b>The Applies to Field is required</b>
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="editMode" >
              <label for="discountType" class="col-sm-4 control-label">Promo code URL</label>
              <div class="col-sm-8">
                <p class="promoUrl">{{promoUrl}}</p>
                <button class="btn btn-default" type="button"  (click)="coppy(promoUrl)">Copy</button>
              </div>

            </div>

            <div class="form-group" *ngIf="editMode" >
              <label for="discountType" class="col-sm-4 control-label">Promo code URL (product)</label>
              <div class="col-sm-8">
                <p class="promoUrl">{{promoProductUrl}}</p>
                <app-product-tags [banks]="data" [type]="1" [multiple]="false"
                                        (productChange)="generateUrl($event)">
                </app-product-tags>
                <button class="btn btn-default" type="button" (click)="coppy(promoProductUrl)">Copy</button>
              </div>
            </div>
          </div>
          <!-- Second Half -->

          <div class="col-md-6">
            <div class="form-group">
              <label for="totalUseLimit" class="col-sm-4 control-label">Total Use Limit</label>
              <div class="col-sm-8">
                <input class="form-control" type="number" formControlName="totalUseLimit" class="form-control"
                  id="totalUseLimit">
              </div>
              <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.totalUseLimit?.invalid">
                <div class="alert alert-danger" role="alert" *ngIf="promoCodeForm.controls.totalUseLimit.errors.required">
                  <b>The Total Use Limit Field is required</b>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="customerUseLimit" class="col-sm-4 control-label">Customer Use Limit</label>
              <div class="col-sm-8">
                <input class="form-control" type="number" maxlength="10" formControlName="customerUseLimit"
                  id="customerUseLimit">
              </div>
              <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.customerUseLimit?.invalid">
                <div class="alert alert-danger" role="alert" *ngIf="promoCodeForm.controls.customerUseLimit.errors.required">
                  <b>The Customer Use Limit Field is required</b>
                </div>
              </div>
            </div>
            <div class="form-group" ng-controller="DatePickerCtrl">
              <label class="col-sm-4 control-label">Range</label>
              <div class="col-sm-8">
                <ng-container *ngIf="!editMode">
                  <mat-form-field appearance="outline" class="w100" style="color: white; background-color:#1C629E">
                    <mat-date-range-input
                      style="color: white; font-size: 14px"
                      [rangePicker]="campaignOnePicker"
                      [comparisonStart]="campaignTwo.value.start"
                      [comparisonEnd]="campaignTwo.value.end">
                      <input matStartDate placeholder="Start date" formControlName="dateStart" style="color: white; font-size: 14px">
                      <input matEndDate placeholder="End date" formControlName="dateFinish" style="color: white; font-size: 14px">
                    </mat-date-range-input>
                    <mat-hint style="color: white;">MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker" style="color: white;"></mat-datepicker-toggle>
                    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                  </mat-form-field>

                </ng-container>
                <ng-container *ngIf="editMode">
                  <mat-form-field appearance="fill">
                    <mat-label>Start date</mat-label>
                    <input matInput [matDatepicker]="start" formControlName="dateStart" disabled>
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                    <mat-datepicker #start startView="year" [startAt]="dateStart"></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label>Finish date</mat-label>
                    <input matInput [matDatepicker]="finish" formControlName="dateFinish" [min]="promoCodeForm.controls?.dateStart?.value">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="finish"></mat-datepicker-toggle>
                    <mat-datepicker #finish startView="year" [startAt]="dateFinish"></mat-datepicker>
                  </mat-form-field>
                </ng-container>

              </div>

            </div>
            <div class="form-group" >
              <label for="wpof" class="col-sm-4 control-label">Waive Physician Oversight Fee*</label>
              <div class="col-sm-8">
                <mat-form-field appearance="fill" class="mat-form-wrapper">
                  <mat-select [disabled]="editMode"  class="custom-select-promo" formControlName="isPhysicianOversightFeeDiscount" appearance="outline" color="accent">
                    <mat-option *ngFor="let wpofValue of selectedWpof" [value]="wpofValue.value">
                      {{wpofValue.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.isPhysicianOversightFeeDiscount?.value === ''">
                <div class="alert alert-danger" role="alert" >
                  <b>The Waive Physician Oversight Fee Field is required</b>
                </div>
              </div>
            </div>
            <div class="form-group" >
              <label for="wpof" class="col-sm-4 control-label">Waive lab draw Fee*</label>
              <div class="col-sm-8">
                <mat-form-field appearance="fill" class="mat-form-wrapper">
                  <mat-select class="custom-select-promo" [disabled]="editMode" formControlName="isLabDrawFeeDiscount" appearance="outline" color="accent" >
                    <mat-option *ngFor="let wldfValue of selectedWldf" [value]="wldfValue.value">
                      {{wldfValue.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 pull-right" *ngIf="submitted && promoCodeForm.controls?.isLabDrawFeeDiscount?.value === ''">
                <div class="alert alert-danger" role="alert" >
                  <b>The Waive lab draw Fee Field is required</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right" mat-dialog-actions align="end">
          <!-- <div class="checkbox checkbox-success inTests">
            <input id="isHidden" class="styled" type="checkbox" formControlName="isHidden">
            <label for="isHidden">
              Hidden
            </label>
          </div> -->
          <div class="checkbox checkbox-success inTests">
            <input id="isActive" class="styled" type="checkbox" formControlName="isActive">
            <label for="isActive">
              Active
            </label>
          </div>
          <div class="checkbox checkbox-success inTests">
            <input id="isBlured" class="styled" type="checkbox" formControlName="isBlured">
            <label for="isBlured">
              Blured
            </label>
          </div>

          <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Save</button>
          <a class="btn btn-warning" (click)="cancel()"><i class="fa fa-remove"></i> Cancel</a>
        </div>

      </div>
    </form>
  </div>
</div>
