import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {KeapEmailTemplate} from '../../entities/keapEmailTemplate/KeapEmailTemplate';
import {KeapMailTemplateService} from '../../services/keap-mail-template.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-admin-keap-template',
  templateUrl: './admin-keap-template.component.html',
  styleUrls: [ './admin-keap-template.component.scss' ]
})

export class AdminKeapTemplateComponent implements OnInit   {
templates: KeapEmailTemplate[] = [];

  constructor(
    private modalService: ModalService,
    private keapMailTemplateService: KeapMailTemplateService,
  ) { }

openModal() {
  this.modalService.openKeapTemplatePopup().subscribe((() => {
    this.keapMailTemplateService.getAllTemplates().subscribe((response: KeapEmailTemplate[]) => {
      this.templates = response;
    });
  }));
  }

removeTemplate(template: KeapEmailTemplate, templateIndex: number) {
    this.modalService.openConfirmModal('Delete template?', this.removeTemplateTest.bind(this, template.id, templateIndex, template));
  }
removeTemplateTest(id: string, templateIndex: number, template) {
    this.keapMailTemplateService.removeKeapMailTemplate(id).subscribe((
      () => {
        this.templates.splice(templateIndex, 1);
        this.modalService.showTopNotification(true, 'Template \'' + template.name + '\' was successfully removed');
      }
    ));
  }
openModalToUpdate(id){
    this.modalService.updateKeapTemplate(id, true).subscribe((() => {
      this.keapMailTemplateService.getAllTemplates().subscribe((response: KeapEmailTemplate[]) => {
        this.templates = response;
      });
    }));
  }
copyLink(id: string){
  const tempInput = document.createElement('input');
  tempInput.value =  environment.baseUrl + `/api/api/keap/send/${id}`;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
  this.modalService.showTopNotification(true, 'The link was copied to the clipboard.');
}
  ngOnInit(): void {
    this.keapMailTemplateService.getAllTemplates().subscribe((response: KeapEmailTemplate[]) => {
      this.templates = response;
    });
  }
}
