import { Component, Input, Output, EventEmitter } from '@angular/core';
import { consentConfig, fieldSetType } from 'src/app/Modals/covid19-emp/covid-consent/consentConfig';

@Component({
  selector: 'app-covid-consent',
  templateUrl: './covid-consent.component.html',
  styleUrls: [ './covid-consent.component.scss' ]
})
export class CovidConsentComponent {
  showScreeningQuestion2: any;
  showScreeningQuestion3: any;
  HouseholdContact: any;
  ineligible2: boolean;
  model = {
    testConsent: null,
    termsAndConditions: null,
    privacyPractices: null
  };
  dontKnow = consentConfig.dontKnowWhatIsThis;
  fieldSet: Array<fieldSetType> = consentConfig.fieldSet;
  h3Element: Array<string> = consentConfig.h3Element;
  checkboxValid: boolean;
  disableButton = true;


  @Output() consentClick: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:variable-name
  private _validFour: any;

  get validFour(): any {
    return this._validFour;
  }

  @Input()
  set validFour(value: any) {
    this._validFour = value;
    this.disableButton = !value || !this.checkboxValid;
  }

  modelCheck(): void {
    this.checkboxValid = Object.values(this.model).every(Boolean);
    this.disableButton = !this._validFour || !this.checkboxValid;
  }

  submitForm() {
    this.consentClick.emit(22);
  }
}
