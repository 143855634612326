import { Component, OnInit } from "@angular/core";
import { BlogArticle } from "../../entities/blog/BlogArticle";
import { BlogService } from "../../services/blog.service";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'app-blog-all-posts',
  templateUrl: './blog-all-posts.component.html',
  styleUrls: ['./blog-all-posts.component.scss']
})

export class BlogAllPostsComponent implements OnInit {
  imageURL = environment.baseImageUrl;
  allArticles: BlogArticle[] = [];
  filteredArticles: BlogArticle[] = [];
  count: number;
  page = 1;
  searchQuery = '';

  private searchSubject: Subject<string> = new Subject();

  constructor(private blogService: BlogService, private router: Router) {}

  ngOnInit(): void {
    this.getAllArticle();

    this.searchSubject.pipe(debounceTime(300)).subscribe(query => {
      this.performSearch(query);
    });
  }

  getAllArticle() {
    this.blogService.getAllArticles().subscribe(response => {
      this.count = response.count;
      this.allArticles = response.items;
      this.applyPagination();
    });
  }

  applyPagination() {
    this.filteredArticles = this.allArticles.slice((this.page - 1) * 9, this.page * 9);
  }

  onSearch(query: string) {
    this.searchSubject.next(query);
  }

  performSearch(query: string) {
    this.searchQuery = query.toLowerCase();
    this.page = 1;

    if (this.searchQuery) {
      const filtered = this.allArticles.filter(article =>
        article.title.toLowerCase().includes(this.searchQuery)
      );
      this.filteredArticles = filtered.slice((this.page - 1) * 9, this.page * 9);
      this.count = filtered.length;
    } else {
      this.count = this.allArticles.length;
      this.applyPagination();
    }
  }

  onPageChange() {
    if (this.searchQuery) {
      const filtered = this.allArticles.filter(article =>
        article.title.toLowerCase().includes(this.searchQuery)
      );
      this.filteredArticles = filtered.slice((this.page - 1) * 9, this.page * 9);
    } else {
      this.applyPagination();
    }
  }

  navigatePost(href: string) {
    this.router.navigate([href]);
  }
}
