import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export class EligibleIneligibleDirective {
  @Input()
  eligible: number;
  @Input()
  ineligible: boolean;
  validForm: boolean;
  @Output() eligibleChange = new EventEmitter<any>();
  @Output() ineligibleChange = new EventEmitter<any>();

  eligibleClick(which: number): void {
    // which is number, whose represent separate sections in main modal
    this.eligible = which;
    this.eligibleChange.emit(this.eligible);
  }

  protected validation(f, length: number , date?): void {
    
    let valid = 0;
    Object.values(f.form.controls)
      .forEach((x: any) => {
          let shouldPass = true;
          if(x.value instanceof Object){
            Object.entries(x.value).forEach(y => {
              if (y[1] === true && shouldPass) {
                valid++;
                shouldPass = false;
              }
            });
          }    
        }
      );
      if(date){
        this.validForm = false
        
      }else{
        this.validForm = valid === length;
      }
  }
}
