import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  cards = null;

  constructor(public notificationService: NotificationService, public http: HttpClient) {
    // this.notificationService.getAuthenticationState().subscribe((data) => {
      
    //   if (data === 'authentication:login') {
    //     this.getAllCards();
    //   }
    //   if (data === 'authentication:logout') {
    //     this.clearCards();
    //   }
    // });

    // $rootScope.$on('authentication:login', this.getAllCards);
    // $rootScope.$on('authentication:logout', this.clearCards);

  }

  // getAllCards() {
  //   return new Promise((resolve, reject) => {
  //     if (this.cards) {
  //       resolve(this.cards);
  //     } else {
  //       this.checkoutCards().subscribe(
  //         (cards) => {
  //           resolve(cards);
  //         }
  //       );
  //     }
  //   });
  // }

  // checkoutCards():Observable<any> {
  //   const observable = this.http.get<any>(environment.base + '/api/payment/card/list');

  //   observable.subscribe((res) => {
  //     this.cards = res;
  //     this.notificationService.cardState.next('cards:update');
  //     // this.$rootScope.$broadcast('cards:update');
  //   });

  //   return observable;
  // }

  // setAllCards(model) {
  //   this.cards = model;
  // }

  // addCard(model):Observable<any> {
  //   const observable = this.http.post<any>(environment.base + '/api/payment/card/add', model);
  //   // const promise = this.CardResource.add({}, model).$promise;

  //   this.checkoutCards().subscribe(() => {
  //       this.notificationService.cardState.next('cards:update');
  //     }
  //     // this.$rootScope.$broadcast('cards:update')
  //   );
  //   return observable;
  // }

  updateCard(model):Observable<any> {
    return this.http.put<any>(environment.base + '/api/payment/card/update', model);
    // return this.CardResource.update({}, model).$promise;
  }

  activateCard(id):Observable<any> {
    return this.http.put<any>(environment.base + `/api/payment/card/action/${id}/true`, {id});
    // return this.CardResource.activate({id: id}).$promise;
  }

  deactivateCard(id):Observable<any> {
    return this.http.put<any>(environment.base + `/api/payment/card/action/${id}/false`, {id});

    // return this.CardResource.deactivate({id: id}).$promise;
  }

  makeCardDefault(id):Observable<any> {
    return this.http.put<any>(environment.base + `/api/payment/card/default/${id}`, {id});
    // return this.CardResource.makeDefault({id: id}).$promise;
  }

  addPaymentType(model):Observable<any> {
    return this.http.post<any>(environment.base + `/api/payment/add/${model.id}/${model.paymentTypeId}`, model);
    // return this.CardResource.addPayment({id: model.id, paymentTypeId: model.paymentTypeId}).$promise;
  }

  clearCards() {
    this.cards = null;
  }
}
