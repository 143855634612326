import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app-service.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProfileService } from 'src/app/services/profile.service';
import { AccountService } from '../../services/account.service';
import { SessionInfoStorageService } from '../../services/session-info-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ]
})
export class LoginComponent {
  loginForm = new UntypedFormGroup({
    userName: new UntypedFormControl('', Validators.compose([ Validators.email, Validators.required ])),
    password: new UntypedFormControl('', Validators.compose([ Validators.required ])),
    rememberMe: new UntypedFormControl(false),
    code: new UntypedFormControl(''),
  });

  errorReason = '';
  showPassword = false;
  expiredPassword = false;
  admin = false;
  tempUserId = '';

  constructor(public dialogRef: MatDialogRef<LoginComponent>,
              private accountService: AccountService,
              private router: Router,
              private sessionInfoStorageService: SessionInfoStorageService,
              private notificationService: NotificationService,
              private modalService: ModalService,
              private profileService: ProfileService,
              private appService: AppService) {
  }

  ok() {
    const urlWithParams: string = this.router.url;
    if (!urlWithParams.includes('product')) {
      this.router.navigate([ '/' ]);
    }
    this.dialogRef.close();
  }

  cancel() {
    this.router.navigate([ '/' ]);
    this.dialogRef.close();
  }


  openForgotPassword() {
    this.dialogRef.close();
    setTimeout(() => {
      this.modalService.openForgotPassword();
    }, 1);
  }

  openRegisterPopup() {
    this.dialogRef.close();
    setTimeout(() => this.modalService.openRegistration(), 200);
  }

  login(admin = false) {

    if (this.loginForm.invalid) {
      return;
    }
    let send = {
      userName: this.loginForm.get('userName')!.value,
      password: this.loginForm.get('password')!.value,
      rememberMe: this.loginForm.get('rememberMe')!.value,
      grant_type : admin? 'code' : 'password'
    };
    if (admin){
      if (this.tempUserId === ''){
        this.tempUserId = localStorage.getItem("code");
      }
      send["userid"] =  this.tempUserId;
      send["code"] = this.loginForm.get('code')!.value;
    }

    this.accountService.authorize(send).subscribe((res) => {
      if (res.access_token) {
        localStorage.setItem('languagePreference', res.languagePreference);
        setTimeout(() => {
          let languagePreference = Boolean(JSON.parse(localStorage.getItem('languagePreference'))) ? 'es' : 'en';
          window['Weglot'].switchTo(languagePreference);
          // this.accountService.authorize2(res).subscribe()
        },250);
        this.errorReason = '';
        this.appService.setUserLoggedIn(true);
        this.ok();
        this.notificationService.cartState.next({ event: 'cart:close', data: {} });
        this.isAllowedStates(res.profileId);
      }
      else {
        this.tempUserId = res;
        this.admin = true;
      }
      }, (error) => {
      this.expiredPassword = false;
      this.accountService.checkEmail({ email: this.loginForm.get('userName').value }).subscribe(x => {
        let text = 'That password is not right. We can help you ';
        if(error.indexOf('The user name or password is incorrect.') === -1){
          this.expiredPassword = true;
          text = error.replace("<br/>", "");
        }
        this.errorReason = x
          ? text :
          'We could not find an account with that email address. Please try again.';
      });
      });
  }

  isAllowedStates(profile){
    this.profileService.getProfile(profile).subscribe((profile) => {
      if(profile.homeAddress.state === 'AZ'){
        this.modalService.importantNotice(profile.homeAddress.state)
      }
    })
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
