import { Component, OnInit } from '@angular/core';
import { PwnHealthService } from 'src/app/services/pwn-health.service';

@Component({
  selector: 'app-pwn-tests',
  templateUrl: './pwn-tests.component.html',
  styleUrls: ['./pwn-tests.component.scss']
})
export class PwnTestsComponent implements OnInit {
  types = [];
  params = {
      '$orderby': 'Name'
  };

  constructor(
    private pwnHealthService: PwnHealthService
  ) { }

  ngOnInit(): void {
    
  this.activate();  
  }

  activate() {
      this.getPwnHealthTypes();
  }

  getPwnHealthTypes() {
      this.pwnHealthService.getTypes('?$orderby=Name')
          .subscribe((response) => {
              this.types = response;
              // console.log('this.types', this.types)
          });
  }

}

