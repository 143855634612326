import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CONSTANTS } from 'src/app/const';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-important-notice',
  templateUrl: './important-notice.component.html',
  styleUrls: ['./important-notice.component.scss']
})
export class ImportantNoticeComponent implements OnInit {
  link = 'https://questhealth.pxf.io/Gm1NL9';
  forbiddenStates = CONSTANTS.forbiddenStates;
  constructor(
    private modalService: ModalService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  cancel() {
    window.open(this.link, '_blank');
    // this.modalService.dialog.closeAll();
    this.dialogRef.close();
  }

  get hasForbiddenStates() {
      return this.forbiddenStates.includes(this.data.state) || false;
  }
  ngOnInit(): void {
  }


}
