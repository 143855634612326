<div class="container-post">
  <mat-card class="podcast-card">
    <div class="content">
      <h2 class="title">{{article.title}}</h2>
      <p class="description">
        {{article.shortDescription}}
      </p>
      <div class="author-section">
        <div class="author-info">
          <span class="author-name">{{article.author}}</span>
        </div>
      </div>
      <div class="meta-info">
        <span>Last Updated: {{ article.createDate | date: 'MMMM d, y' }} • {{ article.estimateLength }} min</span>
      </div>
    </div>
    <img class="cover-img" src="{{imageURL + article.imageId}}" alt="How to Find, Invite, And Prepare Guests" />
  </mat-card>
</div>
<div class="container">
  <div class="container-wrapper ">
  <div class="blog-content" [innerHTML]="sanitizeHTML(article.text)"></div>
  <div class="subscribe-cta">
    <div class="email-subscribe">
      <h1 style="color: white; font-weight: 800 !important;">Never miss another article</h1>
      <div class="blog-sidebar-divider"></div>
      <h2 class="sub-header-text">Curated content, compelling stories, Grassroots Labs updates, and more.</h2>
      <div class="subscribe-form" *ngIf="isActiveForm">
        <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit()" style="    display: flex; align-items: center;">
          <input type="email" formControlName="email" placeholder="Enter your email">
          <button type="submit">Subscribe</button>
        </form>
      </div>
      <h1 style="color: white; font-weight: 800 !important; border-top: 2px solid white;
    padding-top: 10px;" *ngIf="!isActiveForm">
        Thanks for subscribing to the blog!
      </h1>
    </div>
  </div>
  </div>
</div>
