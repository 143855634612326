import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as LZUTF8 from 'lzutf8';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from 'src/app/types/product';
import { environment } from '../../environments/environment';
import { CONSTANTS } from '../const';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) {
  }

  getProducts(id): Observable<Product> {
    return this.http.get<Product>(environment.base + `/api/product/type/browse/${ id }`);

  }

  getAllProductsPromo(params?, key?): Observable<any> {
    if (!key) {
      key = CONSTANTS.all;
    }
    return this.http.get<any>(environment.base + `/api/product/promo/browse/${ key }`);
  }

  getAllProducts(params?, key?): Observable<any> {
    if (!key) {
      key = CONSTANTS.all;
    }
    return this.http.get<any>(environment.base + `/api/product/type/browse/${ key }?$orderby=SearchPriority desc, Name`);
  }

  getPanelProducts(params): Observable<Array<Product>> {
    return this.getAllProducts(params, CONSTANTS.panels + CONSTANTS.packages);
  }

  getProduct(id: string): Observable<Product> {
    return this.http.get<Product>(environment.base + `/api/product/${ id }`).pipe(map((element) => {
      if (element.description && element.description.includes(`${environment.compressedData}`)){
        element.description = element.description.replace(`${environment.compressedData}`, '');
        element.description = LZUTF8.decompress(element.description, {inputEncoding: 'StorageBinaryString'});
      }
      if (element.instructions && element.instructions.includes(`${environment.compressedData}`)){
        element.instructions = element.instructions.replace(`${environment.compressedData}`, '');
        element.instructions = LZUTF8.decompress(element.instructions, {inputEncoding: 'StorageBinaryString'});
      }
          return element;
      }));
  }

  searchProducts(searchStr): Observable<Product> {
    return this.http.post<Product>(environment.base + '/api/product/search', { search: searchStr });
  }
}
