<div class="cabinetTabs">
    <div class="container-fluid">
        <div class="actionLine">
            <button class="btn btn-default" (click)="addUser()"><i class="fa fa-user-plus"></i> Add New User</button>
        </div>
    <form [formGroup]="userForm" name="form" class="form-horizontal">
        <div class="row" style="margin-bottom: 10px;">
            <div class="col-md-4">
                <div class="input-group">
                    <input class="form-control"
                           type="text"
                           (change)="search()"
                           formControlName="searchByName"
                           class="form-control"
                           id="searchByName"
                           tooltip-placement="left" >
                    <span class="input-group-addon">
                        <i class="glyphicon glyphicon-search"></i>
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <button class="btn btn-primary" (click)="getUsers({$skip:0, $top:10}, true)" type="button"><i class="fa fa-users"></i> Get All Users</button>
            </div>
        </div>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let user of _users; let i = index" hideToggle
                                 [ngStyle]="{ 'border': user.isOpen ? '1px solid #E9E9E9' : '', 'box-shadow': !user.isOpen ? 'none' : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }">
              <mat-expansion-panel-header (click)="togglePanel(user)"
                                          [ngStyle]="{ 'border': !user.isOpen ? '1px solid #E9E9E9' : '0px', 'border-radius': !user.isOpen ? '5px' : '0px'}">
                <div class="info">
                    <div class="pannel-header">
                        <small class="panel-header-label">Name:</small> <br>
                        <span class="panel-header-item">{{user.firstName}} {{user.lastName}}</span>
                    </div>
                    <div  class="pannel-header">
                        <small class="panel-header-label"> Email:</small><br>
                        <span class="panel-header-item">
                            {{user.email}}
                        </span>
                    </div>
                    <div  class="pannel-header mobile-actions">
                        <small class="panel-header-label"> Actions:</small><br>
                        <div class="pannel-header-actions">
                            <div class="tgl-list-item space">
                                <input (click)="toggleActiveStatus(user)"
                                [checked]="user.isActive"

                                class='tgl tgl-flip' id="{{'cba' + i}}"  type='checkbox'>
                                <label class='tgl-btn'
                                data-tg-off='Disabled' data-tg-on='Active' for="{{'cba' + i}}"></label>
                            </div>
                            <div class="form-group bgColor padding-top">
                                <mat-form-field (click)="preventEvent($event)" appearance="fill" class="roles">
                                    <mat-select [placeholder]="user.roleName" formControlName="currentRole">
                                      <mat-option  (click)="setRoleChanged(user)" *ngFor="let role of roles; let i = index" [value]="role">
                                        {{role}}
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="space">
                                <button class="btn btn-primary" [hidden]="isAdmin" [disabled]="!user.isRoleChanged"
                                    (click)="changeRole(user)"><i class="fa fa-user-circle"></i> Assign Role
                                </button>
                            </div>
                          <div class="space">
                            <button *ngIf="!user.emailConfirmed" class="btn btn-primary" [hidden]="isAdmin"
                                    (click)="reverify(user)"><i class="fa fa-user-circle"></i> Reverify
                            </button>
                          </div>
                            <!-- <div class="space">
                                <button class="btn btn-default space" (click)="loginAs(user)"><i class="fa fa-user-secret"></i> Login As</button>
                            </div> -->
                        </div>
                    </div>
                </div>
              </mat-expansion-panel-header>
              <div>
                <div *ngIf="tab">
                  <mat-tab-group mat-align-tabs="start" animationDuration="0ms"  (selectedTabChange)="tabChange($event,user)">
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <i class="fa fa-users"></i>
                        User
                      </ng-template>
                      <ng-container>
                        <fieldset [disabled]="changeMode" >
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="firstNameid" container="body" class="col-sm-3 control-label">First Name *</label>
                              <div class="col-sm-9" >
                                  <input name="firstName" formControlName="firstName" type="text" class="form-control" placeholder="First Name" >
                              </div>
                            </div>
                            <div class="form-group">
                                <label for="mnameid" class="col-sm-3 control-label">Middle Name</label>
                                <div class="col-sm-9">
                                  <input formControlName="middleName" type="text" class="form-control" placeholder="Middle Name">
                                </div>
                            </div>


                            <div class="form-group">
                                <label for="lnameid" class="col-sm-3 control-label" container="body"  >Last Name *</label>
                                <div class="col-sm-9">
                                  <input name="lastName" formControlName="lastName" type="text" class="form-control" placeholder="Last Name">
                                </div>
                            </div>
                            <div class="form-group bgColor padding-top">
                              <label for="title" class="col-sm-3 control-label">Suffix</label>
                              <div class="col-sm-9">
                                <mat-form-field appearance="fill" class="maxWidth">
                                  <mat-select formControlName="title" [disabled]="changeMode">
                                    <mat-option *ngFor="let title of titles; let i = index" [value]="i">
                                      {{title}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="form-group">
                                <label for="inputEmail" class="col-sm-3 control-label">Email *</label>
                                <div class="col-sm-9" >
                                  <mat-form-field
                                    class="matOver moibileWidth maxWidth"
                                    appearance="standard">
                                  <input
                                    matInput
                                    formControlName="email"
                                    type="email"
                                    class="form-control"
                                    placeholder="Email">
                                  </mat-form-field>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="homePhone" class="col-sm-3 control-label">Home Phone</label>
                              <div class="col-sm-9">
                                <mat-form-field
                                  appearance="standard"
                                  class="matOver moibileWidth maxWidth">
                                  <input
                                    style=" padding: 6px 12px;"
                                    matInput
                                    phone-input
                                    name="homePhone"
                                    class="form-control"
                                    formControlName="homePhone"
                                    type="text"
                                    placeholder="Home Phone"
                                    />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="workPhone" class="col-sm-3 control-label">Work Phone</label>
                              <div class="col-sm-9">
                                <mat-form-field
                                  appearance="standard"
                                  class="matOver moibileWidth maxWidth">
                                <input
                                  matInput
                                  phone-input
                                  name="workPhone"
                                        class="form-control"
                                  formControlName="workPhone"
                                  type="text"
                                  placeholder="Work Phone"/>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="mobilePhone" class="col-sm-3 control-label">Cell Phone *</label>
                              <div class="col-sm-9">
                                <mat-form-field
                                  appearance="standard"
                                  class="matOver moibileWidth maxWidth">
                                <input
                                  matInput
                                  phone-input name="mobilePhone"
                                        class="form-control"
                                  formControlName="mobilePhone"
                                  type="text"
                                  placeholder="Mobile Phone" autocomplete="on"/>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div  class="form-group">
                                <label class="col-sm-3 control-label">Gender *</label>
                                <div class="col-sm-9">
                                    <fieldset>
                                        <input type="radio" formControlName="gender" [value]="2">
                                        <label for="html">Female</label>
                                        <input type="radio" formControlName="gender" [value]="1">
                                        <label for="css">Male</label>
                                    </fieldset>
                                </div>
                              </div>
                              <ng-template #footerTemplate>
                                <hr class="my-0">
                                <button class="btn btn-sm btn-danger m-2" float-left (click)="clearDate()">Clear</button>
                                <button class="btn btn-primary btn-sm m-2 float-left" (click)="model = today; d.close()">Today</button>
                                <button class="btn btn-success btn-sm m-2 float-right" (click)="d.close()">Close</button>
                              </ng-template>
                              <div  class="form-group padding-top" ng-controller="DatePickerCtrl">
                                <label for="inputPassword3" class="col-sm-3 control-label">Birthday *</label>
                                <div class="col-sm-9">
                                  <mat-form-field  appearance="fill">
                                      <input formControlName="birthDate" matInput [matDatepicker]="picker">
                                      <mat-datepicker-toggle matSuffix [for]="picker">
                                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                              </div>
                            <div  class="form-group bgColor padding-top" >
                              <label for="languagePreference" class="col-sm-3 control-label">Language Preference *</label>
                              <div class="col-sm-9">
                                <mat-form-field appearance="fill" >
                                  <mat-select
                                    [disabled]="changeMode"
                                    formControlName="languagePreference">
                                    <mat-option *ngFor="let language of languagePreference" [value]="language.id">
                                      {{language.name}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="addressIndicator" >
                              <div class="form-group">
                                  <label for="address" class="col-sm-3 control-label">Address *</label>
                                  <div class="col-sm-9">
                                      <input type="text" formControlName="addressLine" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" type="text"
                                          placeholder="Address" />
                                  </div>
                              </div>
                              <div class="form-group" >
                                  <label for="city" class="col-sm-3 control-label">City *</label>
                                  <div class="col-sm-9">
                                      <input
                                          name="city" class="form-control"
                                          type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                                          placeholder="City" />
                                  </div>
                              </div>
                              <div  class="form-group bgColor" >
                                <label for="state" style="margin-right: 15px;" class="col-sm-3 control-label">State *</label>
                                <mat-form-field appearance="fill">
                                  <mat-label>State</mat-label>
                                  <mat-select formControlName="state" [disabled]="changeMode">
                                    <mat-option *ngFor="let state of stateList$ | async" [value]="state.value">
                                      {{state.value}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="form-group" >
                                <label for="zip" class="col-sm-3 control-label">Zip *</label>
                                <div class="col-sm-9">
                                    <input name="zip" class="form-control"
                                        type="text" formControlName="zip" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.zip.errors }"
                                        minlength="5" maxlength="5" numbers-only
                                        type="text" placeholder="Zip" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div class="btn-handler">
                          <button class="btn bot5 order4 btn-primary" (click)="mfaOnOff()">MFA {{ mfaStatus }}</button>
                          <button class="btn bot5 order4 btn-primary" (click)="nonTransactionalEmailsStatusChangeStatus()">Non Transactional Emails {{ nonTransactionalEmailsStatus }}</button>
                          <a (click)="changeEmail()" class="btn btn-warning bot5 order2"><i class="fa fa-envelope-o"></i> Change
                            Email</a>
                          <button class="btn bot5 order4 btn-warning" (click)="changeMode = !changeMode">{{ changeMode? 'Edit': 'Cancel'}}</button>
                          <button *ngIf="!changeMode" class="btn bot5 order4 btn-primary" (click)="update()">UPDATE</button>
                        </div>
                      </ng-container>
                    </mat-tab>
                    <mat-tab >
                      <ng-template mat-tab-label>
                        <i class="fa fa-medkit"></i>
                        Orders
                      </ng-template>
                      <ng-container *ngIf="showOrders">
                        <app-order-history [clearToken]='true'></app-order-history>
                      </ng-container>
                    </mat-tab>
                    <mat-tab >
                      <ng-template mat-tab-label>
                        <i class="fa fa-envelope"></i>
                        Email History
                      </ng-template>
                      <ng-container *ngIf="showEmailChangeHistory">
                        <app-admin-user-email-change-history [userId] = "user.id"></app-admin-user-email-change-history>
                      </ng-container>

                    </mat-tab>
                  </mat-tab-group>

                </div>
              </div>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel disabled style="margin-bottom: 10px">
              <mat-expansion-panel-header *ngIf="isEmpty" >
                <h3 style="width: 100%; text-align: center; margin: 0px;">No orders created yet.</h3>
              </mat-expansion-panel-header>
            </mat-expansion-panel> -->
          </mat-accordion>
            <ngb-pagination [collectionSize]="totalUsers" [(page)]="page" [maxSize]="5"
            (pageChange)="usersFromSearch ? getUsersFromSearch(page) : getUsers({$skip: (page * 10)- 10, $top: 10},true)"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
    </form>
    </div>
</div>
