import { Component, Input, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { environment } from '../../../environments/environment';
import { OrderByPipe } from 'src/app/pipes/order-by.pipe';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'test-list',
  templateUrl: './test-list.component.html',
  styleUrls: ['./test-list.component.scss']
})
export class TestListComponent implements OnInit {
  page = 1;
  path;
  skip = 0;
  imageURL = environment.baseIconUrl;
  filteredTests;
  productType = '';
  oldTests = [];
  newTests = [];
  @Input() public set tests (value) {
    if(value) {
      this.oldTests = value;
      this.newTests = value.map((country, i) => ({id: i + 1, ...country, isOpen: i === 0}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.newTests = [];
    }
  };
  @Input() public testsCount:any;
  @Input('getTestsFunction') getTestsFunction: Function;
  pageSize: number = 10;
  id: string;
  startInd = 0;
  endInd;

  constructor(
    private cartService: CartService,
    private orderBy:OrderByPipe,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  paginator = {
    itemsPerPage: 10, // TODO need move this to CONSTANTS?
    currentPage: 1,
    maxSize: 5
  } as any;


  togglePanel(id) {

    const newOrders = this.tests;
    const orderToUpdate = newOrders.find((el) => el.id === id);
    orderToUpdate.isOpen = !orderToUpdate.isOpen;
    this.tests = newOrders;
  }

  sortBy(orderBy){
    // if (this.paginator.orderBy === orderBy){
    //   this.paginator.orderBy += ' desc';
    // } else {
    //   this.paginator.orderBy = orderBy;
    // }
    if (this.testsCount()){
      this.getTests();
    } else {
      //this.filterTests();
    }
  }

  addToCart (test, event?) {
    this.cartService.addToCart(test);
  };

  getPaginationOffset() {
    return (this.paginator.currentPage - 1) * this.paginator.itemsPerPage;
  }

  onPageChange(currentPage){
    // this.skip = (10 * currentPage) - 10;
    // const top = 10;
    // this.route.paramMap.subscribe(params => {
    //   this.id = params.get('id');
    // })
    // const path = `&$skip=${this.skip}&$top=${top}`;
    // if(this.getTestsFunction){
    // this.getTestsFunction({path:path,id:this.id});
    // }
    // else{
    //   this.newTests;
    // }
    this.newTests = this.oldTests.slice((currentPage-1)*this.pageSize,currentPage*this.pageSize);
    window.scroll(0,0);
  }

  testNavigate(test){
    this.router.navigateByUrl(`product/${test.slug}`);
  }

  getTests(){
    if (this.getTestsFunction) {
      this.getTestsFunction('&$skip=0&$top=10',undefined);
      // this.getTestsFunction({$top: this.paginator.itemsPerPage, $skip: this.getPaginationOffset(), $orderby: this.paginator.orderBy});
    } 
    // else {
    //   this.filterTests();
    // }
  }

  // filterTests(){
  //   // console.warn(" orderBy    " + scope.paginator.orderBy);
  //   if (!this.paginator.orderBy) {
  //     this.filteredTests = this.tests;
  //   } else {
  //     const oArr = this.paginator.orderBy.split(' '); // 'Name desc'
  //     // const reverse = oArr.length > 1;
  //     // var ordering = oArr[0].charAt(0).toLowerCase() + oArr[0].slice(1);
  //     const ordering = oArr[0].replace(/[A-Z]+/, (letter) => {
  //       return letter.toLowerCase();
  //     });  // lowerCase lead-off Letters
  //     this.filteredTests = this.orderBy.transform(this.tests, ordering);
  //   }
  //   // scope.filteredTests = scope.paginator.orderBy  ? $filter("orderBy")(scope.tests, scope.paginator.orderBy.toLowerCase()) : scope.tests;
  //   // this.filteredTests = $filter("limitTo")(this.filteredTests, this.paginator.itemsPerPage, this.getPaginationOffset());
  //   // this.filteredTests = this.filteredTests.slice(this.getPaginationOffset(), this.paginator.itemsPerPage);
  // }

  ngOnInit(): void {
    this.tests;
    this.testsCount;
  }

}
