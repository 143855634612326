<div class="popup forms printable">

    <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="row" id="print-target" *ngIf="!data.register">

        <div style="display: flex; justify-content: center; align-items: center; text-align: center;">
            <h2 class="title-notice">Important Notice: New Orders from AZ Residents Temporarily Prohibited
            </h2>
        </div>

        <div class="col-md-12" style="color: #fff" id="test">
            We regret to inform you that new orders from residents of AZ are temporarily prohibited because of an ongoing issue with Sonora Quest Laboratory in AZ.
            <br><br>
            If your provider refers to Grassroots Labs for affordable testing, we are working to restore testing with them. Please call, email, or chat with us if you have any questions.
            <br><br>
            <span>
                Az residents may review any previous lab orders and download results, but you will not be able to make new orders through Grassroots Labs. We apologize for the inconvenience and are working hard to find a solution to restore access. <a target="_blank" href="https://help.grassrootslabs.com/article/167-notice-temporary-prohibition-of-new-orders-for-az-residents">Please read our post on our help center here for more information.</a> We have also included links to some alternative lab solutions in this post for your convenience and continued testing while we work on a solution to restore access through Grassroots Labs.
            </span>
        </div>

    </div>

    <div class="row" id="print-target" *ngIf="data.register">
        <ng-container *ngIf="hasForbiddenStates">
            <div style="display: flex; justify-content: center; align-items: center; text-align: center;" >
                <h2 class="title-notice">Important Notice: New Account Registrations from NJ, NY, and RI Residents Prohibited
                </h2>
            </div>

            <div class="col-md-12" style="color: #fff" id="test">
                <span>We regret to inform you that new Account Registrations and orders from residents of NY, NJ, and RI are prohibited because of state laws in those states.</span>
                <br><br>
                <span>Residents from NY, NJ, and RI can access testing via <a class="a-wrapper" href="https://questhealth.pxf.io/Gm1NL9" target="_blank">Quest Health's</a> online market place.
                  For more information and directions about using Quest Health, please visit our help center article
                  <a class="a-wrapper" href="https://help.grassrootslabs.com/article/176-testing-now-available-for-ny-nj-ri-residents" target="_blank">
                    Testing Now Available for NY | NJ | RI Residents.</a></span>
            </div>
        </ng-container>
        <ng-container *ngIf="!hasForbiddenStates">
            <div style="display: flex; justify-content: center; align-items: center; text-align: center;" >
                <h2 class="title-notice">Important Notice: New Account Registrations from AZ Residents Temporarily Prohibited
                </h2>
            </div>

            <div class="col-md-12" style="color: #fff" id="test">
                <span>We regret to inform you that new Account Registrations and orders from residents of AZ are temporarily prohibited because of an ongoing issue with Sonora Quest Laboratory in AZ.</span>
                <br><br>
                <span>We are sorry for the inconvenience. Please reach out to our customer support staff if you have any questions.</span>
                If your provider refers to Grassroots Labs for affordable testing, we are working to restore testing with them. Please call, email, or chat with us if you have any questions.
                <br><br>
                <span>
                    Current Grassroots Labs customers who are Az residents may review any previous lab orders and download results, but you will not be able to make new orders through Grassroots Labs. We apologize for the inconvenience and are working hard to find a solution to restore access. <a target="_blank" href="https://help.grassrootslabs.com/article/167-notice-temporary-prohibition-of-new-orders-for-az-residents">Please read our post on our help center here for more information.</a> We have also included links to some alternative lab solutions in this post for your convenience and continued testing while we work on a solution to restore access through Grassroots Labs.
                </span>
            </div>
        </ng-container>
    </div>

    <div class="text-center top10 bot20">
        <button print-popup print-popup-element="print-target" (click)="cancel()"
            class="btn btn-default">
          Visit Quest Health to Get Labs</button>
    </div>

</div>
