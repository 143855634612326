<div class="popup forms">
  <div><h1>Reset Password</h1></div>
  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>
  <form #resetPasswordForm="ngForm"  novalidate (submit)="resetPasswordForm.valid && submit()">
    <div class="form-group">
      <label for="password">New password *</label>
      <input [(ngModel)]="model.password" [type]="showPassword ? 'text' : 'password'" (ngModelChange)="analyze()"
             autocomplete="new-password"
             class="form-control" id="password" (focus)="showSuggestions = true"
             (blur)="showSuggestions = false" name="password" required  #password="ngModel">
             <button mat-icon-button (click)="showPassword = !showPassword" type="button" id="visibilityButton">
              <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
            </button>
    </div>
    <div class="form-group" *ngIf="password.errors?.required && (password.touched || resetPasswordForm.submitted)">
      <div class="alert alert-danger" role="alert"><b>Error:</b> Please fill password field</div>

    </div>

    <div class="alert alert-warning password-alert" role="alert" *ngIf="showSuggestions" style="font-size: 15px;">
      <h6 style="font-size: 11px;">Password Help:</h6>
      <ul style="list-style-type: none;">
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.hasNumber  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.hasNumber ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 number!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.hasCapitalCase  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.hasCapitalCase ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 in Capital Case!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.hasSmallCase  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.hasSmallCase ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 Letter in Small Case!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.hasSpecialCharacters  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.hasSpecialCharacters ?  'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 Special Character!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.minLength  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.minLength ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must be at least 8 characters!</span>
          </label>
        </li>
      </ul>
    </div>


    <div class="form-group">
      <label for="repeatPassword">Repeat password *</label>
      <input [(ngModel)]="model.confirmPassword" [type]="showPassword ? 'text' : 'password'" (ngModelChange)="analyzeConfirm(this)"
            (focus)="showSuggestions2 = true" (blur)="showSuggestions2 = false" class="form-control"
             id="repeatPassword"  name="confirmPassword" required #confirmPassword="ngModel">

    </div>

    <div class="form-group" *ngIf="confirmPassword.errors?.required && (confirmPassword.touched || resetPasswordForm.submitted)">
      <div class="alert alert-danger" role="alert"><b>Error:</b> Please fill repeat password field</div>
    </div>
    <div class="form-group" *ngIf="model.confirmPassword !== model.password &&  confirmPassword.dirty">
      <div class="alert alert-danger" role="alert" ><b>Error:</b> Your passwords do not match.Please confirmed them and try again.</div>
    </div>

    <div class="alert alert-warning password-alert" role="alert" *ngIf="showSuggestions2" style="font-size: 15px;">
      <h6 style="font-size: 11px;">Password Help:</h6>
      <ul style="list-style-type: none;">
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch2.hasNumber  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch2.hasNumber ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 number!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch2.hasCapitalCase  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch2.hasCapitalCase ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 in Capital Case!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch2.hasSmallCase  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch2.hasSmallCase ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 Letter in Small Case!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch2.hasSpecialCharacters  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch2.hasSpecialCharacters ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 Special Character!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch2.minLength  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch2.minLength ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must be at least 8 characters!</span>
          </label>
        </li>
      </ul>
    </div>
    <div class="row text-center">
      <button type="submit"  class="btn btn-default">SAVE</button>
    </div>
  </form>
</div> 
