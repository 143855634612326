import { logging } from 'protractor';
import { AfterViewInit, Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from 'ngx-webstorage';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { CONSTANTS } from '../../const';
import { AccountService } from '../../services/account.service';
import { ModalService } from '../../services/modal.service';
import { StatesService } from '../../services/states.service';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/datepicker-adapter.service';

const RequireOneValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  if (!fg) return null;
  const homePhone = fg.get('homePhone')?.value;
  const workPhone = fg.get('workPhone')?.value;
  const mobilePhone = fg.get('mobilePhone')?.value;
  return homePhone || workPhone || mobilePhone ? null : { requiredOne: true };
};

const RepeatPasswordValidator: ValidatorFn = (group: UntypedFormGroup) => {
  if (!group) return null;
  const password = group.controls.password?.value;
  const confirmPassword = group.controls.confirmPassword?.value;
  return password === confirmPassword ? null : { passwordsNotEqual: true };
};

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class RegistrationComponent implements OnInit {

  isAdulthood = false;
  daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  today = new Date();
  selectedDate = this.today;
  selectedDay: number;
  selectedMonth: number;
  selectedYear: number;
  days: number[] = [];
  months = [];
  years: number[] = [];
  innerWidth: number;
  paswordInvalid: boolean;
  confirmPasswordInvalid: boolean;
  stateList$;
  showSuggestions: boolean;
  showSuggestions2: boolean;
  form: any = {};
  model: any = {
    email: '',
    password: '',
    confirmPassword: '',
    date: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    termsAndConditions: '',
    privacyPolicy: '',
    state: '',
    city: '',
    zip: '',
    addressType: 1,
    addressLine: '',
    homePhone: '',
    workPhone: '',
    mobilePhone: '',
    campaignid: 0,
    mbsy: ''
  };
  showPassword = false;
  regex = {
    hasNumber: /\d/,
    hasSpecialCharacters: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
    hasCapitalCase: /[A-Z]/,
    hasSmallCase: /[a-z]/,
    minLength: /^.{8,}$/
  };

  colorMatch = {
    hasNumber: false,
    hasCapitalCase: false,
    hasSmallCase: false,
    hasSpecialCharacters: false,
    minLength: false
  };

  confirmColorMatch = {
    hasNumber: false,
    hasCapitalCase: false,
    hasSmallCase: false,
    hasSpecialCharacters: false,
    minLength: false
  };

  suffix = ['None', 'Sr.', 'Jr.', 'I', 'II', 'III'];
  languagePreference = [{ name: 'English', id: 0 }, { name: 'Español', id: 1 }];
  addressesType = CONSTANTS.addressesType;
  datePattern = CONSTANTS.datePattern;
  strongPwd = CONSTANTS.passwordPattern;
  forbiddenStates = CONSTANTS.forbiddenStates;
  duplicateEmail = false;
  minAge = {
    year: this.today.getFullYear() - CONSTANTS.MIN_AGE,
    month: this.today.getMonth() + 1,
    day: this.today.getDate() + 1
  };
  minDate: Date;
  selectedPersonId = 0;
  submitted = false;

  registrationForm = this.formBuilder.group({
    selectedDay: new UntypedFormControl(null, Validators.compose([Validators.required])),
    selectedMonth: new UntypedFormControl(null, Validators.compose([Validators.required])),
    selectedYear: new UntypedFormControl(null, Validators.compose([Validators.required])),
    firstName: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z\s-]+$/)])),
    middleName: new UntypedFormControl(null,Validators.compose([Validators.pattern(/^[a-zA-Z\s-]+$/)])),
    lastName: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z\s-]+$/)])),
    suffix: new UntypedFormControl(null),
    email: new UntypedFormControl(null, Validators.compose([Validators.email, Validators.required])),
    homePhone: new UntypedFormControl(null, Validators.compose([Validators.pattern('^(?!^1.*$).*'), Validators.minLength(10), Validators.maxLength(10)])),
    workPhone: new UntypedFormControl(null, Validators.compose([Validators.pattern('^(?!^1.*$).*'), Validators.minLength(10), Validators.maxLength(10)])),
    mobilePhone: new UntypedFormControl(null, Validators.compose([Validators.pattern('^(?!^1.*$).*'), Validators.minLength(10), Validators.maxLength(10)])),
    password: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(26)])),
    confirmPassword: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(26)])),
    gender: new UntypedFormControl(null, Validators.compose([Validators.required])),
    date: new UntypedFormControl(null),
    addressLine: new UntypedFormControl(null, Validators.compose([Validators.required])),
    city: new UntypedFormControl(null, Validators.compose([Validators.required])),
    state: new UntypedFormControl(null, Validators.compose([Validators.required])),
    languagePreference: new UntypedFormControl("0", Validators.compose([Validators.required])),
    zip: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])),
    privacyPolicy: new UntypedFormControl(null, Validators.compose([Validators.required])),
    termsAndConditions: new UntypedFormControl(null, Validators.compose([Validators.required]))
  }, { validators: Validators.compose([RepeatPasswordValidator, RequireOneValidator]) });

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private $sessionStorage: SessionStorageService,
    private actRoute: ActivatedRoute,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<RegistrationComponent>,
    private formBuilder: UntypedFormBuilder,
    private notifications: NotificationService,
    private router: Router,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private statesService: StatesService,
    public modalService: ModalService
  ) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    this.minDate = new Date(currentYear - 18, currentMonth, currentDay);
    this.stateList$ = this.statesService.states$;
    this.months = CONSTANTS.registrationMonths;

    this.registrationForm.controls.firstName.valueChanges.subscribe(value => {
      if (value) {
        this.registrationForm.get('firstName')?.patchValue(value.charAt(0).toUpperCase() + value.slice(1), { emitEvent: false });
      }
    });
    this.registrationForm.controls.middleName.valueChanges.subscribe(value => {
      if (value) {
        this.registrationForm.get('middleName')?.patchValue(value.charAt(0).toUpperCase() + value.slice(1), { emitEvent: false });
      }
    });
    this.registrationForm.controls.lastName.valueChanges.subscribe(value => {
      if (value) {
        this.registrationForm.get('lastName')?.patchValue(value.charAt(0).toUpperCase() + value.slice(1), { emitEvent: false });
      }
    });
  }

  get hasForbiddenStates() {
    if (this.registrationForm.controls.state.value) {
      return this.forbiddenStates.includes(this.registrationForm.controls.state.value.value);
    }
    return false;
  }

  clearDate() {
    this.registrationForm.controls.date.setValue(null);
  }
  setSuffix(value: string) {
    this.registrationForm.patchValue({
      suffix: value
    });
  }
  setState(value: number) {
    this.registrationForm.get('state')?.patchValue(value);
  }
  setLanguagePreference(value: any) {
    this.registrationForm.patchValue({
      languagePreference: value
    });
    this.selectedPersonId = value;
  }
  analyze(value: string) {
    Object.keys(this.regex).forEach(x => {
      this.colorMatch[x] = value && !!this.regex[x].test(value);
    });
    this.paswordInvalid = Object.values(this.colorMatch).includes(false);
  }

  confirmPasswordAnalyze(value: string) {
    Object.keys(this.regex).forEach(x => {
      this.confirmColorMatch[x] = value && !!this.regex[x].test(value);
    });
    this.confirmPasswordInvalid = Object.values(this.confirmColorMatch).includes(false);
  }


  ok() {
    this.router.navigate(['/']);
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  register() {
    if (this.registrationForm.value.state === 'AZ' || this.forbiddenStates.includes(this.registrationForm.value.state)) {
      this.modalService.importantNotice(this.registrationForm.value.state, true);
      return;
    }
    this.submitted = true;
    if (this.registrationForm.invalid || this.paswordInvalid) {
      return;
    }
    const processedModel: any = this.processModel(this.registrationForm.value);
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const { queryParams } = tree;
    const searchObject: any = this.actRoute.snapshot.queryParamMap; // || this.$sessionStorage.retrieve('ambasador');
    processedModel.maritalStatus = 1;
    processedModel.title = 1;
    Object.keys(searchObject.params).forEach(x => {
      if (['campaignid', 'mbsy'].includes(x.toLowerCase())) {
        processedModel[x.toLowerCase()] = searchObject.params[x];
      }
    });
    if (this.statesService.getState(this.registrationForm.value.zip) !== this.registrationForm.value.state) {
      this.notifications.block({ message: 'Your zip code does not match your state. Please review and update.' }, false);
      return;
    }
    this.accountService.register(processedModel)
      .pipe(catchError((error: any) => {
        if (error.toLowerCase().indexOf(this.registrationForm.controls.email.value.toLowerCase()) > -1) {
          this.duplicateEmail = true;
        }
        return throwError(error);
      })).subscribe(() => {
        this.duplicateEmail = false;
        this.ok();
        this.modalService.openRegistrationSuccess();
      });
  }

  checkDuplicate() {
    if (!this.registrationForm.controls.email.value ||
      this.registrationForm.controls.email.value.length === 0) {
      return;
    } else {
      this.accountService.checkEmail({ email: this.registrationForm.controls.email.value }).subscribe((result: any) => {
        this.duplicateEmail = result;
      });
    }
  }

  processModel(model: any) {
    const processed = { ...model };
    Object.keys(processed).map((key) => {
      if (typeof processed[key] === 'object' && processed[key] && processed[key].value) {
        processed[key] = processed[key].value;
      }
    });
    return processed;
  }

  login(model: any) {
    const loginModel = {
      userName: model.email,
      password: model.password,
      grant_type: 'password'
    };
    this.accountService.authorize(loginModel);
  }



  setDays(isYear: boolean, value: any) {
    if (this.selectedYear !== this.today.getFullYear() - 18) {
      this.isAdulthood = false;
    }
    if (this.selectedYear === this.today.getFullYear() - 18) {
      if (this.selectedMonth && this.selectedMonth < this.today.getMonth() + 1) {
        this.isAdulthood = false;
      }
    }
    if (!isYear) {
      this.selectedMonth = value;
    }
    else {
      this.selectedYear = value;
    }
    const monthIndex = this.selectedMonth - 1;
    const daysCount = this.daysInMonth[monthIndex];
    const isLeapYear = this.isLeapYear(this.selectedYear);
    let newDays = Array.from({ length: daysCount }, (_, i) => i + 1);
    if (monthIndex === 1 && isLeapYear) {
      newDays.push(29);
    }

    if (this.selectedYear === this.today.getFullYear() - 18) {
      if (this.selectedMonth === this.today.getMonth() + 1) {
        newDays = Array.from({ length: this.today.getDate() }, (_, i) => this.today.getDate() - i).reverse();
      }
    }
    if (!isYear) {
      this.selectedDay = null;
    } else {
      if (this.selectedYear === this.today.getFullYear() - 18) {
        this.months = CONSTANTS.registrationMonths.filter(month => month.value <= this.today.getMonth() + 1);
        if (this.selectedMonth > this.today.getMonth() + 1) {
          this.isAdulthood = true;
          this.selectedMonth = null;
          this.selectedDay = null;
        }
      } else {
        this.months = CONSTANTS.registrationMonths;
      }
    }
    this.days = [...newDays];
    this.registrationForm.patchValue({
      date: new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay)
    });
  }

  setValueDay(day) {
    this.selectedDay = day;
  }
  setYears(val: number) {
    this.years = Array.from({ length: val }, (_, i) => this.today.getFullYear() - i - CONSTANTS.MIN_AGE);
  }

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }

  ngOnInit(): void {
    this.setYears(100);
    this.innerWidth = window.innerWidth;
  }
}
