<div class="popup forms printable">

  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="row" id="print-target" mat-dialog-content style="font: 400 14px / 20px Roboto, Helvetica Neue, sans-serif;">

      <div class="top20" style="text-align: center">
        <img src="../../../assets/img/logo.png" style="width: 300px">
        <p style="color: white;">
          www.grassrootslabs.com<br>
          +1 (888) 315-3265
        </p>
      </div>

      <div class="col-md-12 top20 print-text-wrapper" >
        <span><b>Patient:</b> {{firstName}} {{lastName}}</span><br>
        <span><b>Patient DOB:</b> {{birthDate | date:'MMMM d yyyy'}}</span><br><br>

        <span><b>Order ID:</b> {{orderNumber}}</span><br>
        <span><b>Order Date:</b> {{orderDate | date:'MMMM d yyyy'}}</span><br>
        <span><b>Ordering Physician:</b> {{physiciansName}}</span><br>
        <span><b>Ordering Physician NPI:</b> {{physiciansNPI}}</span><br><br>
        <span><b>Grassroots Labs Corp. Tax ID: </b>81-0974656</span><br>
        <span>470 Commerce Dr.</span><br>
        <span>STE 662</span><br>
        <span>Peachtree City, GA 30269</span><br><br>

        <!-- <span><b>Justifying Diagnosis:</b> </span><br><br> -->

        <span><b>Tests Ordered:</b></span><br>
        <!--<ul style="list-style-type:none">-->
        <!--<li ng-repeat="product in vm.productDetails"> {{product.name}} CPT: {{product.cpt}} Price: {{product.price | currency}}</li>-->
        <!--</ul>-->

        <!--<span><b>Processing/Draw CPT: 36415­90</b>  Price: {{vm.processingFee | currency}}</span>-->
        <!--<hr>-->
        <!--<b>Total:</b> {{vm.total | currency}}<br><br>-->

        <table class="table top20 table-borderless">
          <thead></thead>
          <tbody>
          <tr *ngIf="panelTest">
            <td style="padding-right: 10px">{{panelTest.product.name}}</td>
            <td style="padding-right: 10px">CPT: {{panelTest.product.cpt}}</td>
            <td [hidden]="panelTest.product.showPrice">Price: {{panelTest.product.masterPrice | currency}}</td>
          </tr>
          <ng-container *ngFor="let pr of testsInfo">
            <tr>
              <ng-container *ngIf="pr.product.slug!=='Panel_Test'">
                <td [ngClass]="{ 'panelChild': panelTest && !pr.product.name.includes('Panel - Test') }" style="padding-right: 18px">{{pr.product.name}}</td>
                <td style="padding-right: 10px">CPT: {{pr.product.cpt}}</td>
                <td [hidden]="pr.product.showPrice">Price: {{(pr.orderItemInitialPrice || pr.orderItemPrice) | currency}}</td>
              </ng-container>
            </tr>
            <ng-container *ngIf="pr.children.length > 0">
              <tr *ngFor="let child of pr.children">
                <td class="discounts-td-border discounts-td-padding">{{ child.product.name }}</td>
                <td class="discounts-td-border">CPT: {{child.product.cpt}}</td>
              </tr>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="discount > 0" >
            <tr>
              <td colspan="2">
                Discounts
              </td>
              <td>- {{ discount | currency}}</td>
            </tr>
            <tr>
              <td class="discounts-td-border discounts-td-padding">
                Duplicate Tests Ordered:
              </td>
            </tr>
            <tr *ngFor="let duplicateChild of duplicateChilds">
              <td colspan="2" class="discounts-td-border discounts-td-padding">
                {{duplicateChild[0].product.name}}
              </td>
              <td class="discounts-td-border" >
                - {{ duplicateChild[0].orderItemInitialPrice || duplicateChild[0].product.masterPrice | currency }}
                <span *ngIf="duplicateChild.length > 1">{{ ' ( * ' + duplicateChild.length + ')' }}</span>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="promoCodeName">
            <td style="padding-right: 18px">Promo discount ({{promoCodeName}})</td>
            <td style="padding-right: 10px"></td>
            <td>-{{promoCodeDiscount | currency}}</td>
          </tr>
          <tr>
            <td style="padding-right: 18px">Subtotal</td>
            <td style="padding-right: 10px"></td>
            <td style="width: 20%;">&emsp;&emsp;&ensp;&nbsp;{{subTotal | currency}}</td>
          </tr>
          <tr *ngIf="processingFee !== 0" >
            <td>Processing/Draw</td>
            <td>CPT: 36415­90</td>
            <td>Price: {{processingFee | currency}}</td>
          </tr>
          <ng-container *ngIf="processingFee === 0" >
            <tr>
              <td>Physician Oversight Fee</td>
              <td>CPT:</td>
              <td>Price: {{physicianFee | currency}}</td>
            </tr>
            <tr>
              <td>Lab Draw Fee</td>
              <td>CPT: 36415</td>
              <td>Price: {{labDrawFee | currency}}</td>
            </tr>
          </ng-container>
          <tr>
            <td colspan="3">
              <hr>
            </td>
          </tr>
          <tr>
            <td colspan="3" style="text-align: right; padding-right: 20px;">
              <p><b>Total:</b> <span class="price">{{total | currency}}</span></p>
              <p><b>Customer Paid {{ cardType }}  :</b> <span class="price">{{total | currency}}</span></p>
              <!-- ({{}} - {{}}) -->
              <p><b>Balance:</b> <span class="price">$0.00</span></p>
<!--            <td colspan="2"></td>-->
<!--            <td><b>Total:</b> {{total | currency}}</td>-->
          </tr>
          </tbody>
        </table>
        <br>
        <br>
        <b>ATTENTION:</b> <p>This is a receipt for purchase of lab test and not a lab order. Please print out your lab order from your Order History on the Grassroots Labs website.</p> <br> <br>
        <b>PSC Staff:</b> <p>To view the lab order for this customer, search for requisition ID: {{requistion}} in your system. Call Grassroots Labs at 1-888-315-3265 with any questions. </p><br>
        <br>
        <b *ngIf="lab">Fulfilling Lab:</b> {{lab}}<br>
        <b>Site:</b> Patient Service Center
      </div>

    </div >

    <div class="text-center top10 bot20" mat-dialog-actions  align="center">
      <button
              class="btn btn-default" (click)="print()">
        <i class="fa fa-print"></i> Print
      </button>
    </div>
</div>

