<div class="cancel" mat-dialog-close><i class="fa fa-times"></i></div>

<app-modal-logo></app-modal-logo>
<mat-dialog-content class="mat-typography" style=" padding:20px">
  <app-saliva-symptoms
    class="row"
    *ngIf="!ineligible && eligible==1"
    [(eligible)]="eligible"
    (ScreeningQuestion1Change)="this.statementApplied = $event"
  ></app-saliva-symptoms>

  <div id="buttonsContainer">
    <app-saliva-second-screen
      *ngIf="eligible===2"
      [(eligible)]="eligible"
      (ScreeningQuestion2Change)="this.ScreeningQuestion2 = $event"
      class="row showScreeningQuestion2"
    ></app-saliva-second-screen>
  </div>

    <app-saliva-third-screen
      *ngIf="eligible===3"
      [(eligible)]="eligible"
      (ScreeningQuestion3Change)="this.ScreeningQuestion3 = $event"
      class="row showScreeningQuestion3"
    ></app-saliva-third-screen>

    <app-saliva-fourth-screen
      *ngIf="eligible===4"
      [(eligible)]="eligible"
      (ScreeningQuestion4Change)="this.ScreeningQuestion4 = $event"
    ></app-saliva-fourth-screen>

    <app-saliva-fifth-screen
      *ngIf="eligible===5"
      [(eligible)]="eligible"
      (ScreeningQuestion5Change)="this.ScreeningQuestion5 = $event"
      class="row showScreeningQuestion5" style="text-align: center"
    ></app-saliva-fifth-screen>

    <app-saliva-sixth-screen
      *ngIf="eligible===6"
      [(eligible)]="eligible"
      (ScreeningQuestion6Change)="this.ScreeningQuestion6 = $event"
      class="row showScreeningQuestion4" style="text-align: center"
    ></app-saliva-sixth-screen>

  <section *ngIf="eligible==999">
    <h1></h1>
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-danger">
          <div class="panel-heading">
            <h3 class="panel-title">
              Ineligible for TESTING
            </h3>
          </div>
          <div class="panel-body text-danger">
            Based on your response, you are ineligible for testing and we recommend you seek immediate
            medicare care. For more information on COVID-19 Testing, please visit out COVID-19 FAQs (link:
            <a href="https://help.grassrootslabs.com/collection/55-covid-19-testing">
              https://help.grassrootslabs.com/collection/55-covid-19-testing)</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>
