import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { SessionInfoStorageService } from './session-info-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  profiles = null;
  addresses = null;
  test$ = of([] );

  constructor(private http: HttpClient,
              private notificationService: NotificationService,
              private sessionInfoStorageService: SessionInfoStorageService) {

    this.notificationService.authenticationState.subscribe((data) => {
      if (data === 'authentication:login') {
        this.test$ = this.http.get<any>(environment.base + '/api/profile/list').pipe(shareReplay());
        this.getProfilesList();

      }
      if (data === 'authentication:logout') {
        this.clearProfiles();
      }
    });
    // $rootScope.$on('authentication:login', this.checkoutProfiles);
    // $rootScope.$on('authentication:logout', this.clearProfiles);
  }

  getProfile(id): Observable<any> {
    return this.http.get<any>(environment.base + `/api/profile/get/${ id }`);
    // return this.ProfileResource.getProfile({profileId: id}).$promise;
  }

  updateProfile(data): Observable<any> {
    const promise = this.http.put<any>(environment.base + `/api/profile/update`, data);
    // const promise = this.ProfileResource.updateProfile(data).$promise;
    promise.subscribe(() => {
      this.getProfilesList().subscribe(() => {
        this.broadcastUpdate();
      });
    });
    return promise;
  }

  updateChildProfile(data): Observable<any> {
    const promise = this.http.put<any>(environment.base + '/api/profile/child/update', data);
    promise.subscribe(() => {
      this.getProfilesList().subscribe(() => {
        this.broadcastUpdate();
      });
    });
    return promise;
  }

  // createChildProfile(data):Observable<any>{
  //   debugger;
  //   return this.http.post<any>(environment.base + 'profile/child/create', data)
  // }


  createProfile(data): Observable<any> {
    const observable = this.http.post(environment.base + '/api/profile/create', data);

    // const promise = this.ProfileResource.createProfile(data).$promise;
    observable.subscribe(() => {
      this.addProfile(data);
      this.broadcastUpdate();
    });
    return observable;
  }

  createChildProfile(data): Observable<any> {
    const promise = this.http.post<any>(environment.base + '/api/profile/child/create', data);

    // const promise = this.ProfileResource.createProfile(data).$promise;
    promise.subscribe(() => {
      this.addProfile(data);
      this.broadcastUpdate();
    });
    return promise;
  }

  getAddresses() {
    return new Promise((resolve) => {
      if (this.addresses) {
        resolve(this.addresses);
      } else {
        this.http.get(environment.base + '/api/address/list').toPromise()
          // this.ProfileResource.getAddresses().$promise
          .then((res) => {
            this.addresses = res;
            resolve(this.addresses);
          });
      }
    });
  }

  getProfiles(callback) {
    if (this.sessionInfoStorageService.getToken()) {
      this.getProfilesList().subscribe((res) => {
        callback(res);
      }, function () {
        callback([]);
      });
    } else {
      callback([]);
    }
  }

  broadcastUpdate() {
    this.notificationService.profileState.next({ event: 'profiles:update', data: this.profiles });
    // this.$rootScope.$broadcast('profiles:update', this.profiles);
  }

  addProfile(profile) {
    if (this.profiles) {
      this.profiles.push(profile);
      this.broadcastUpdate();
    } else {
      this.getProfiles(function () {
        this.broadcastUpdate();
      });
    }
  }

  getProfilesList(): Observable<any> {
    return this.test$;
  }

  clearProfiles() {
    this.test$ = of([]);
    this.profiles = null;
  }

  processProfilesForInput(profiles) {
    return [ ...profiles ].map(function (profile) {
      return {
        name: profile.firstName + ' ' + profile.lastName,
        value: profile.id
      };
    });
  }
}
