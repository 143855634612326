<div class="popup forms">

    <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="col-md-12">
        <h1 style="padding-top:0; margin-top:0;">Forgot Password</h1>
    </div>

    <form class="form-horizontal" novalidate [formGroup]="forgotForm">

        <!-- Current Password -->
        <div class="form-group">
            <label for="email" class="col-sm-3 control-label">Email</label>
            <div class="col-sm-9">
                <input name="password" required formControlName="email" type="email" class="form-control" id="email"
                       placeholder="Email">
            </div>
        </div>
        <!-- <div class="form-group col-md-12 pull-right" ng-cloak ng-show="email.$error && ($submitted || email.$touched)">
            <div class="alert alert-danger" role="alert" *ngIf="email.$error.required"><b>Error:</b> Please add an email address.</div>
            <div class="alert alert-danger" role="alert" *ngIf="email.$error.email"><b>Error:</b> Email is invalid.</div>
        </div> -->


        <!-- Buttons-->
        <div class="form-group">
            <div class="col-sm-12 text-right">
                <div class="col-sm-12 text-right">
                    <button (click)="submit()" type="submit" class="btn btn-primary">SUBMIT</button>
                    <a (click)="cancel()" class="btn btn-default">CANCEL</a>
                </div>
            </div>
        </div>
    </form>
</div>
