import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as LZUTF8 from 'lzutf8';
import { Observable } from 'rxjs/index';
import { ProductTagsComponent } from 'src/app/Modals/add-tests/product-tags/product-tags.component';
import { CatalogService } from 'src/app/services/catalog.service';
import { CategoryService } from 'src/app/services/category.service';
import { ImageService } from 'src/app/services/image.service';
import { ModalService } from 'src/app/services/modal.service';
import { UploadService } from 'src/app/services/upload.service';
import { environment } from 'src/environments/environment';
import {MatChipInputEvent} from "@angular/material/chips";

@Component({
  selector: 'app-add-tests',
  templateUrl: './add-tests.component.html',
  styleUrls: [ './add-tests.component.scss' ]
})
export class AddTestsComponent implements OnInit {
  seasons = [ {name: 'Simple', id: 1}, { name: 'Panel', id: 8}, {name: 'Physician Consult', id: 16}, {name: 'In-Home test', id: 2} ];
  chilDataId = null;
  selectedChildren = [];
  @ViewChild(ProductTagsComponent) productTagsComponent: ProductTagsComponent;
  editMode;
  categories = {};
  model;
  imageURL = environment.baseIconUrl;
  uploadFile = null;
  selectedLabCode = [
    { name: 'Quest Diagnostics', id: 1 },
    { name: 'Lab Corp', id: 2 },
    { name: 'Ayumetrix', id: 4 },
    { name: 'Accurate Diagnostic Labs', id: 8 }
  ];
  testForm: UntypedFormGroup;
  data = { testId: null };
  selectedCode: any;
  selectedGroup: any;
  selectedCategory$: Observable<any>;
  previewImage = null;
  proceed = true;
  private products: any;
  addOnBlur = true;
  readonly separatorKeysCodes = [COMMA, ENTER] as const;
  tags = [];

  constructor(
    public dialogRef: MatDialogRef<AddTestsComponent>,
    private modalService: ModalService,
    public formBuilder: UntypedFormBuilder,
    private categoryService: CategoryService,
    private catalogService: CatalogService,
    private imageService: ImageService,
    private uploadService: UploadService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public inputdata: any
  ) {
  }

  get f() {
    return this.testForm.controls;
  }

  ngOnInit() {
    this.testForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      cpt: new UntypedFormControl(''),
      marketPrice: new UntypedFormControl(''),
      masterPrice: new UntypedFormControl(''),
      customerPrice: new UntypedFormControl(''),
      patientPrice: new UntypedFormControl(''),
      imageId: new UntypedFormControl(''),
      labCode: new UntypedFormControl('', Validators.required),
      questCode: new UntypedFormControl(''),
      slug: new UntypedFormControl(''),
      tagsInput: new UntypedFormControl(''),
      categoryId: new UntypedFormControl('', Validators.required),
      productType: new UntypedFormControl(''),
      panel: new UntypedFormControl(''),
      simple: new UntypedFormControl(''),
      physicianConsult: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      instructions: new UntypedFormControl(''),
      isActive: new UntypedFormControl(''),
      children: new UntypedFormControl([]),
      visibility: new UntypedFormControl(1),
      hasExtraCharge: new UntypedFormControl(true),
      isHidden: new UntypedFormControl(false),
      pwnCode: new UntypedFormControl('test'),
      metaDescription: new UntypedFormControl(''),
      metaRobots: new UntypedFormControl(''),
      pageTitle: new UntypedFormControl(''),
      searchPriority: new UntypedFormControl(0),
    });
    this.chilDataId = this.inputdata.items;
    if(this.inputdata.children){
        this.selectedChildren = this.inputdata.children;
      }
    if (this.inputdata.isEdit) {
      this.previewImage = `${ this.imageURL }${ this.inputdata.imageId }`;

      if (this.inputdata.description.includes(`${environment.compressedData}`)){
        this.inputdata.description = this.inputdata.description.replace(`${environment.compressedData}`, '');
        this.inputdata.description = LZUTF8.decompress(this.inputdata.description, {inputEncoding: 'StorageBinaryString'});
      }
      if(this.inputdata.instructions.includes(`${environment.compressedData}`)){
        this.inputdata.instructions = this.inputdata.instructions.replace(`${environment.compressedData}`, '');
        this.inputdata.instructions = LZUTF8.decompress(this.inputdata.instructions, {inputEncoding: 'StorageBinaryString'});
      }

      this.editMode = true;
      this.tags = this.inputdata.tags ? this.inputdata.tags.split(',') : [];
      this.testForm.patchValue(this.inputdata);
      setTimeout(() => {
        const labcodeId = this.inputdata['labCode'];
        const categor = this.inputdata['categoryId'];
        this.selectedCode = this.selectedLabCode.find((el) => el.id == labcodeId).id;
        this.selectedCategory$.subscribe((res) => {
          this.selectedGroup = res.items.find((obj) => obj.id === categor).id;
        });
      }, 0);
    }
    this.getCategories();
    this.model = { productType: 1, selectedCategory: null };
    console.log('SelectedLabCode:', this.selectedLabCode);
    console.log('Initial form value:', this.testForm.value);
  }

  InHomeSelected() {
    // this.selectedLabCode[3].disabled = true;
    this.model['questCode'] = '';
    this.testForm.patchValue({ labCode: this.selectedLabCode[2] });
    // this.testForm['labCode'].$render();
  };

  uploadFileImage() {
    this.imageService.uploadImage(this.uploadFile).then(
      (response) => {
        this.modalService.showTopNotification(true, 'Upload successful');
        this.model['imageId'] = response['id'];
      }, () => {
        this.modalService.showTopNotification(false, 'Upload failed');
      });
  };

  submit(model) {
    const formProduct = model.form.getRawValue();
    // formProduct.tags = formProduct.tags.split(' ');
    const prodTypeNumber = {
      Simple: 1,
      Panel: 8,
      Physician: 16,
      In: 2
    };
    formProduct.customerPrice = parseFloat(formProduct.customerPrice) || null;
    formProduct.patientPrice = parseFloat(formProduct.patientPrice) || null;
    formProduct.marketPrice = parseFloat(formProduct.marketPrice) || null;
    formProduct.masterPrice = parseFloat(formProduct.masterPrice) || null;

    formProduct.description = LZUTF8.compress(formProduct.description, {outputEncoding: "StorageBinaryString"})
    formProduct.description += `${environment.compressedData}`
    formProduct.instructions = LZUTF8.compress(formProduct.instructions, {outputEncoding: "StorageBinaryString"})
    formProduct.instructions += `${environment.compressedData}`

    // formProduct.productType = prodTypeNumber[formProduct.productType.split(' ')[0].split('-')] || null;

    if (this.editMode) {
      let oldChildrenId = this.selectedChildren.map((el)=>el.id)
      let newChildrenId = formProduct.children.map((el)=>el.childrenId)
      let forRemove = this.selectedChildren.filter((child) => newChildrenId.indexOf(child.id) == -1 ? child : false);
      let forAdd = formProduct.children.filter((child) => oldChildrenId.indexOf(child.childrenId) == -1 ? child : false);
      formProduct.id = this.inputdata.id
      formProduct.tags = this.tags.join(',');
      this.catalogService.updateProduct(formProduct).subscribe(() => {
        if(forRemove){
          forRemove.map(x => {
            this.catalogService.removeRelation(formProduct.id, x.id).subscribe();
          });
        }
        if(forAdd){
          forAdd.map(x => {
                this.catalogService.addOneChildren(x.childrenId, 8, formProduct.id).subscribe();
              });
        }
        this.modalService.showTopNotification(true, 'Test \'' + formProduct['name'] + '\' has been updated.');
      });
    } else {
      this.catalogService.addProduct(formProduct).subscribe((response) => {
        // this.data.testId = response.id;
        // const isActiveItem = this.productTagsComponent.allFruits.find(x => x.name === this.productTagsComponent?.fruits[0]);
        if(formProduct.children){
          formProduct.children.map(x=>{
            this.catalogService.addOneChildren(x.childrenId, 8, response.id).subscribe();
          })
        }
        this.cancel()
        this.modalService.showTopNotification(true, 'Test \'' + formProduct['name'] + '\' has been created.');
      });
    }
  };

  cancel() {
    this.dialogRef.close('cancel');
  };

  activate() {

    this.getCategories();
  }

  getCategories() {
    this.selectedCategory$ = this.categoryService.getAllCategories({ flags: 255 });
    this.categoryService.getAllCategories({ flags: 255 })
      .subscribe((response) => {
        console.log(response)
        this.categories['items'] = response.items;
        this.model['children'] = this.model['children'] || [];
        this.categories['count'] = response.count;
        this.model.selectedCategory = response.items[0];
      });
  }

  getProduct() {
    this.catalogService.getProduct(this.data.testId)
      .subscribe(function (response) {
        this.model = response;

        if (this.model.categoryId) {
          this.model.selectedCategory = this.categories.items.find((val) => {
            return val.id === this.model.categoryId;
          });
        }
      });
  }

  uploadFiles(files: File) {
    let formData = new FormData();

    formData.append('file', files[0], files[0]['name']);

    this.uploadService.uploadFiles(formData).subscribe((res: any) => {
      // this.previewImage = res.id;
      this.previewImage = `${ this.imageURL }${ res.id }`;
      this.testForm.patchValue({ imageId: res.id });
    });
  }

  openGallery() {
    this.modalService.openBrowseImage().afterClosed().subscribe((result) => {
      this.previewImage = result.preview;
      this.testForm.patchValue({ imageId: result.image });
    });
  }

  ok(message) {
    this.modalService.showTopNotification(true, message);
    this.dialogRef.close('ok');
  }

  processModel(model) {
    const processed = { ...model, children: this.productTagsComponent.fruits };
    Object.keys(processed).map((key) => {
      if (typeof processed[key] === 'object' && processed[key] && processed[key].value) {
        processed[key] = processed[key].value;
      }
    });
    return processed;
  }

  onProductChange(product: []) {
    const pr = product.map((x: any) => {
      return { childrenId: x.id, name: x.name, relationType: x.productType };
    });
    this.testForm.controls.children.setValue(pr);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.tags.push(value);
    }

    event.chipInput?.clear();

    this.testForm.controls.tagsInput.patchValue('');
  }

  remove(tag): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  goToTest(){
    this.router.navigate(['/product/' + this.testForm.controls.slug.value]);
    this.dialogRef.close('cancel');
  }

}
