import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestCentersService {

  constructor(private http: HttpClient) {
  }

  getTestCenters(params) {
    // if (!params) {
    //    params = {};
    //    params.$orderby = 'LabZip';
    //    params.$top = 10;
    //    params.$skip = 0;
    // }
    return this.http.get(environment.base + '/api/labsite/list', {params}).toPromise();
    // return this.TestCentersResource.getTestCenters(params).$promise;
  }

  findTestCenters(zip) {
    return this.http.get(environment.base + `/api/labsite/pwnhealth/${zip}`).toPromise();
    // return this.TestCentersResource.findTestCenters({'zip': zip}).$promise;
  }
}
