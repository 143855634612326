import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { interval } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: [ './count-down.component.scss' ]
})
export class CountDownComponent {
  ASAP: boolean;
  hours: any;
  minutes: any;
  dataTimeTomorrow: any;
  afterEightPm: boolean;
  afterEightAm: boolean;
  showNothing: boolean;
  dateCreatedSource: any;
  seconds: string;

  @Input()
  set dateCreated(value: any) {
    this.dataTimeTomorrow = new Date().setDate(new Date().getDate() + 1);
    const dateNowSource = formatDate(new Date(), 'short', 'en').split(/[, ]+/);
    const dateNow = dateNowSource[0].split(/[/]+/);
    const end = moment().utc().valueOf();
    const purchase = moment(value).valueOf() + moment().utcOffset() * 1000 * 60;
    const purchaseHours = parseInt(moment(value).subtract( 4, 'hours').format('H'));
    // const purchaseHours = parseInt(momentTz(value).tz( 'America/Indiana/Indianapolis').format('H'), 10);
    // const purchaseHours = parseInt(moment(value).format('H'), 10);

    const currnet = momentTz().tz( 'America/Indiana/Indianapolis').subtract( 4, 'hours').utc().valueOf();
    const transactionDate = momentTz(value).tz( 'America/Indiana/Indianapolis').subtract( 8, 'hours').utc().valueOf();

    const test0800 = momentTz(value).tz( 'America/Indiana/Indianapolis')
      .set('hour', 8).set('minute', 0).set('second', 0).set('millisecond', 0).subtract( 4, 'hours').utc().valueOf();
    const result0800 = (test0800 - transactionDate) / 1000;
    const test1030 = momentTz(value).tz( 'America/Indiana/Indianapolis')
      .set('hour', 10).set('minute', 30).set('second', 0).set('millisecond', 0).subtract( 4, 'hours').utc().valueOf();
    const result1030 = (test1030 - transactionDate) / 1000;
    const test1300 = momentTz(value).tz( 'America/Indiana/Indianapolis')
      .set('hour', 13).set('minute', 0).set('second', 0).set('millisecond', 0).subtract( 4, 'hours').utc().valueOf();
    const result1300 = (test1300 - transactionDate) / 1000;
    const test1530 = momentTz(value).tz( 'America/Indiana/Indianapolis')
      .set('hour', 15).set('minute', 30).set('second', 0).set('millisecond', 0).subtract( 4, 'hours').utc().valueOf();
    const result1530 = (test1530 - transactionDate) / 1000;
    const test1800 = momentTz(value).tz( 'America/Indiana/Indianapolis')
      .set('hour', 18).set('minute', 0).set('second', 0).set('millisecond', 0).subtract( 4, 'hours').utc().valueOf();
    const result1800 = (test1800 - transactionDate) / 1000;
    const test2000 = momentTz(value).tz( 'America/Indiana/Indianapolis')
      .set('hour', 20).set('minute', 0).set('second', 0).set('millisecond', 0).subtract( 4, 'hours').utc().valueOf();
    const result2000 = (test2000 - transactionDate) / 1000;
    const test2359 = momentTz(value).tz( 'America/Indiana/Indianapolis')
      .set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 0).subtract( 4, 'hours').utc().valueOf();
    const result2359 = (test2359 - transactionDate) / 1000;
    let diff = 0;

    // console.log('currnet',momentTz().tz( 'America/Indiana/Indianapolis').subtract( 4, 'hours').utc().format())
    // console.log('transactionDate',momentTz(value).tz( 'America/Indiana/Indianapolis').subtract( 8, 'hours').utc().format())
    // console.log('result0800',result0800)
    // console.log('result1030',result1030)
    // console.log('result1300',result1300)
    // console.log('result1530',result1530)
    // console.log('result1800',result1800)
    // console.log('result2000',result2000)
    // console.log('result2359',result2359)

    let isPast = false;

    if (result0800 > 0 ){
      this.afterEightAm = true;
      isPast = currnet > test0800;

    }else if (result1030 > 0){
      diff = (test1030 - currnet) / 1000;
      isPast = currnet > test1030;

    }else if (result1300 > 0){
      diff = (test1300 - currnet) / 1000;
      isPast = currnet > test1300;

    }else if (result1530 > 0){
      diff = (test1530 - currnet) / 1000;
      isPast = currnet > test1530;

    }else if (result1800 > 0){
      diff = (test1800 - currnet) / 1000;
      isPast = currnet > test1800;

    }else if (result2000 > 0){
      diff = (test2000 - currnet) / 1000;
      isPast = currnet > test2000;

    }else if (result2359 > 0){
      this.afterEightPm = true;
      if (currnet > momentTz().tz( 'America/Indiana/Indianapolis').set('hour', 8).set('minute', 0).set('second', 0).set('millisecond', 0).subtract( 4, 'hours').utc().valueOf()){
        isPast = currnet > test2359;
      }
    }else{
      isPast = true;
    }


    if (isPast){
      this.afterEightAm = false;
      this.afterEightPm = false;
      this.ASAP = true;
    }

    const diffFinal = Math.floor(diff);

    const testOut = this.timeConvert(diffFinal);
    this.hours = testOut[0];
    this.minutes = testOut[1];
    this.seconds = testOut[2];

    this.dateCreatedSource = new Date(value);
    // if (diffFinal <= 0) {
    //   this.ASAP = true;
    //   return;
    // }

    // if (purchaseHours >= 20) {
    //   this.afterEightPm = true;
    // } else if (purchaseHours <= 8) {
    //   this.afterEightAm = true;
    // }

    interval(1000)
      .pipe(
        take(diffFinal),
        finalize(() => this.ASAP = true)
      )
      .subscribe(seconds => {
        const test = this.timeConvert(diffFinal - seconds);
        this.hours = test[0];
        this.minutes = test[1];
        this.seconds = test[2];
        if (diffFinal - seconds <= 0) {
          this.ASAP = true;
          return;
        }
      });
  }

  timeConvert(n) {
    const convertor = moment.utc(moment.duration(n, 'seconds').asMilliseconds()).format('HH:mm:ss');
    return convertor.split(':');
  }

  navigate() {
    window.open(
      'https://help.grassrootslabs.com/article/168-how-long-until-my-order-is-approved',
      '_blank'
    );
  }
}
