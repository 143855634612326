import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { PromoCodeService } from 'src/app/services/promo-code.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { ModalService } from '../../services/modal.service';
import {AccountService} from '../../services/account.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: [ './main.component.scss' ]
})
export class MainComponent implements OnInit, OnDestroy {

  metaDescription = 'Discover transparent and affordable diagnostic lab tests with Grassroots Labs. Join the revolution for convenient healthcare and start saving on lab costs today for a healthier, stress-free life.';
  defaultTitle = 'Grassroots Labs - Blood Tests are Expensive. We fix that.';
  title = 'Affordable Lab Testing Marketplace | Grassroots Labs';
  youtubeURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/OWjaw-TflMU?rel=0&showinfo=0&autoplay=0');
  isYoutubeOn = false;
  private url: any;
  private orderInfo = this.sessionInfoStorage.getOrderInfo();
  constructor(
    public modalService: ModalService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private promoCodeService: PromoCodeService,
    private notificationService: NotificationService,
    private sessionInfoStorage: SessionInfoStorageService,
    public accountService: AccountService,
    private metaService: Meta,
    private titleService: Title
  ) {
  }

  playYoutube() {
    this.youtubeURL = this.sanitizer
      .bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/OWjaw-TflMU?rel=0&showinfo=0&autoplay=1');
    this.isYoutubeOn = true;
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=\'description\'');
    this.titleService.setTitle(this.defaultTitle);
  }

  ngOnInit(): void {
   this.metaService.addTag({ name: 'description', content: this.metaDescription });
    this.titleService.setTitle(this.title);
    this.route.queryParams
      .subscribe(params => {
        if (params.userId && params.SecurityCode) {
          this.modalService.openConfirmForgotPassword(params);
        }
        if (params.disableNonTransactionalEmails){
          this.modalService.openUnsubscribeEmail(params);
        }
      });
    const promoCodeId = this.route.snapshot.params.code;
    if (promoCodeId){
      this.promoCodeService.getPromoListById(promoCodeId).subscribe(response => {
        if (response) {
          this.modalService.openConfirmPromoCode({id: promoCodeId, isBlured: response.isBlured, name: response.code});
          this.sessionInfoStorage.setPromoCode(response.code);
      }
      });
    }
  }
}
