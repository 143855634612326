import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {AdminEmailInfoResponse} from '../entities/admin-email/AdminEmailInfoResponse';


@Injectable({
  providedIn: 'root'
})
export class AdminEmailService {
  constructor(private http: HttpClient) { }
  getAllEmails(skip: number, pageSize: number): Observable<AdminEmailInfoResponse>{
    return this.http.get<AdminEmailInfoResponse>(environment.base +  `/api/admin-mail/get?$skip=${skip}&$top=${pageSize}`);
  }
  getAllEmailsWithDate(path: string): Observable<AdminEmailInfoResponse>{
    return this.http.get<AdminEmailInfoResponse>(environment.base +  `/api/admin-mail/get${path}`);
  }
}
