import { HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { NotificationType } from 'src/app/thirdParty/ngx-notification-bar';
import { NotificationBarService } from 'src/app/thirdParty/ngx-notification-bar/notification-bar.service';
import { environment } from 'src/environments/environment';

declare var Stripe;

function showMessage(messageText) {
  const messageContainer = document.querySelector('#payment-message');

  messageContainer.classList.remove('hidden');
  messageContainer.textContent = messageText;

  setTimeout(() => {
    messageContainer.classList.add('hidden');
    messageText.textContent = '';
  }, 4000);
}

// Show a spinner on payment submission
function setLoading(isLoading) {
  if (isLoading) {
    // Disable the button and show a spinner
    // @ts-ignore
    document.querySelector('#submit').disabled = true;
    document.querySelector('#spinner').classList.remove('hidden');
    document.querySelector('#button-text').classList.add('hidden');
  } else {
    // @ts-ignore
    document.querySelector('#submit').disabled = false;
    document.querySelector('#spinner').classList.add('hidden');
    document.querySelector('#button-text').classList.remove('hidden');
  }
}

@Component({
  selector: 'app-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrls: [ './stripe-checkout.component.scss' ]
})
export class StripeCheckoutComponent implements OnInit {
  readonly stripe = Stripe(environment.stripeTest);
  itemsId = this.sessionInfoStorageService.getOrderInfo().orderId;
  elements;
  paymentStatus = {
    succeeded: 1,
    faild: 2,
    processing: 5
  };

  constructor(
    public sessionInfoStorageService: SessionInfoStorageService,
    public orderService: OrderService,
    public cartService: CartService,
    private notifications: NotificationBarService,
    public dialogRef: MatDialogRef<StripeCheckoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { totalAmount: string },
  ) {
  }

  ngOnInit(): void {
    this.initialize();
    this.checkStatus();
  }

  initialize() {
    setLoading(true);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    const appearance = {
      theme: 'stripe',
    };

    this.orderService.stripeIntent(this.itemsId).subscribe((res) => {
      const appearance = {
        theme: 'stripe',
      };
      this.elements = this.stripe.elements({ appearance, clientSecret: res.clientSecret });
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#payment-element');
      paymentElement.on('ready', function(event) {
        setLoading(false);
      });
    });

  }

  async handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    await this.stripe.confirmPayment({
      elements: this.elements,
      redirect: 'if_required',
    }).then((res) => {
      if (res.error) {
        if(res.error.type != 'validation_error'){
          this.dialogRef.close(res.paymentIntent);
          this.notifications.create({ message: `A payment error occurred: ${res.error.message}`, type: NotificationType.Error}, false)
        }
        setLoading(false);
        
      } else {
        this.orderService.stripeConfirm({
          orderId: this.itemsId,
          paymentStatus: this.paymentStatus[res.paymentIntent.status],
          paymentMethodId: res.paymentIntent.payment_method
        }).subscribe((test) => {
          this.dialogRef.close(res.paymentIntent);
        }, (err) => {
          res.paymentIntent.status = 'error';
          this.dialogRef.close(res.paymentIntent);
        });
      }
      // this.orderService.deleteOrder(this.itemsId).subscribe()


      // let paymentData = {
      //   orderId: this.itemsId,
      //   paymentTypeId: ???,
      //   savePaymentType: true,
      //   repeatOrderCount: 0,
      //   repeatOrderSettings: 0
      // };

      //   this.orderService.submitCart(paymentData).subscribe((response) => { //Todo: http://grl-api-dev.softprise.net/swagger/ui/index#!/Order/Order_ProceedToCheckout
      //         console.log('response',response);
      //     this.dialogRef.close({
      //       stripe:res.paymentIntent,
      //       submit:response
      //     }

      //     )
      //   }, (errorData) => {
      //        console.log(errorData)
      //       })

      // setLoading(false);
      return res.paymentIntent;
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case 'succeeded':
        showMessage('Payment succeeded!');
        break;
      case 'processing':
        showMessage('Your payment is processing.');
        break;
      case 'requires_payment_method':
        showMessage('Your payment was not successful, please try again.');
        break;
      default:
        showMessage('Something went wrong.');
        break;
    }
  }
}
