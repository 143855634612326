import { Component, OnInit, ViewChild } from '@angular/core';
import { PwnHealthService } from 'src/app/services/pwn-health.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';



export interface PWNData {
  number: string;
  pwnOrderId: string;
  PWNStatus: string;
  pwnTimestamp: string;
  errorMessage: string;
}
@Component({
  selector: 'app-pwn-orders',
  templateUrl: './pwn-orders.component.html',
  styleUrls: ['./pwn-orders.component.scss']
})
export class PwnOrdersComponent implements OnInit {

  orders;
  allOrders;
  totalOrders = 0;
  page = 1;
  filteredOrders;
  params = {
    status: 0
  };

  date = {
    dateStart: "",
    dateEnd: ""
  };
  dateEndOpen = true;
  dateStartOpen = true;
  pwnOrdersForm: UntypedFormGroup
  // paginator = {};
  statusList = [];
  selectedStatus = [];

  displayedColumns: string[] = ['number', 'pwnOrderId', 'PWNStatus', 'pwnTimestamp', 'errorMessage'];

  searchTerm: string;
  dataSource: MatTableDataSource<PWNData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private pwnHealthService: PwnHealthService
  ) { }

  ngOnInit(): void {
    this.getOrders({ status: 0 });
    this.pwnOrdersForm = new UntypedFormGroup({
      status: new UntypedFormControl(''),
      dateStart: new UntypedFormControl(''),
      dateEnd: new UntypedFormControl(''),
    })
    this.getTest();
  }

  activate() {
    this.getPwnHealthOrdersStatus();
  }

  getPwnHealthOrdersStatus() {
    this.statusList = this.pwnHealthService.getOrderStatusList();
  }

  getTest() {
    this.pwnHealthService.getTypesTest(this.params).subscribe((response) => {
      this.dataSource = new MatTableDataSource(response);
      // this.dataSource.paginator = this.paginator;
    });
  }

  getOrders(params) {
    this.pwnHealthService.getOrders(params)
      .subscribe((response) => {
        this.orders = response.slice((this.page - 1) * 10, this.page * 10);
        this.allOrders = response;
        this.filteredOrders = response;
        this.totalOrders = this.allOrders.length;
        // this.pwnOrdersForm.patchValue({ searchModel: '' })
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterTransaction(search) {
    this.page = 1;
    let linkedOrder = false;
    this.filteredOrders = this.allOrders.filter((res) => {
      if(res.linkedOrder){
        linkedOrder = res.linkedOrder.number.toString().indexOf(search) !== -1;
      }
      return res.pwnOrderId.toString().indexOf(search) !== -1 || linkedOrder;
    });
    this.orders = this.filteredOrders.slice((this.page - 1) * 10, this.page * 10);
    this.totalOrders = this.filteredOrders.length;
  }

  getUsersFromSearch(page, orders) {
    const newItems = this.filteredOrders.slice((page - 1) * 10, page * 10);
    this.orders = newItems;
  }

}