import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  done = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmComponent>,
    private accountService:AccountService
    ) {
     }

  ngOnInit() {
  }

  no() {
    // $state.go('main');
    this.dialogRef.close()
  };

  yes = function(){
    this.data.result()
    if(!this.data.user || !this.params.security)
        return;

    let model = {
         userId: this.params.user,
         securityCode: this.params.security
    };
    this.accountService.confirm(model).then(function () {
        this.done = true;
    });
  }

}
