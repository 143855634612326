<div class="container">

  <div class="row breadcrumbs">
    <div class="col-md-12">
      <div class="btn-group btn-breadcrumb">
        <a ui-sref="main" title="Grassroot Labs Home" class="btn btn-default"><i class="glyphicon glyphicon-home"></i></a>
        <a href="" title="Test Centers" class="btn btn-default">Test Centers</a>
        <a href="" title="Search" class="btn btn-default">Search</a>
      </div>
    </div>
  </div>

  <div class="innerSearch">
    <form class="form-inline searchResult" [formGroup]="testCentersForm" #ngForm="ngForm">
      <!--<div class="row input-group">-->
      <div class="col-lg-12 text-center testCenters">
        <h2>Find Your Closest Test Center >> Search By Zip Code Below</h2>
        <!--<div class="col-md-10 col-xs-8 no-padding">-->
        <!--<label for="zip">Find Your Test Center by Search By Zip Code Below </label>-->
        <input class="form-control" appNumbersOnly id="zip" name="zipCode" formControlName="zipCode" type="text" placeholder="Zip Code" minlength="5" maxlength="5" style="    display: inline-block;"/>
        <button (click)="search()" class="btn btn-lg btn-primary searchBtn" style="margin-left: 15px">
          <i class="fa fa-search"></i>  Search</button>
        <span class="searchResults" *ngIf="isValidFormSubmitted && isEmpty"> Nothing found. Try another Zip.</span>
        <!--</div>-->
        <div class="form-group col-sm-12 pull-right"
             *ngIf="zipCode.errors" style="margin-top: 20px; display: flex; justify-content: center;">
          <div class="alert alert-danger errorClass" role="alert" *ngIf="zipCode.errors && ngForm.submitted" style="width: 38%;"><b>Error:</b>
            Zip must be 5 digits.
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="container-fluid" *ngIf="nearestCenters.length > 0" style="margin-top: 15px; margin-bottom: 50px;">
  <div class="row hidden-xs">
    <div class="col-md-7">
      <table class="table">
        <thead>
        <tr>
          <th>Test Center</th>
          <th>Address</th>
          <th>Range</th>
          <th>Phone</th>
          <th>Operation Hours</th>
          <th>GMap</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let center of nearestCenters;trackBy: trackByFn">
          <td>{{center.name}}</td>
          <td>{{center.address}} <br> {{center.city}}, {{center.state}}, {{center.zip }}</td>
          <!--<td>{{center.address}} <br> {{center.city}}, {{center.state}}, {{center.zip | limitTo:5}}</td>-->
          <td>{{center.distance | number:'1.0-0'}}m</td>
          <td style="white-space: nowrap;">{{center.phone}}</td>
          <td><pre class="pre-hours">{{center.workingHours}}</pre></td>
          <td><a class="btn btn-info" href="https://www.google.com/maps/place/{{center.address}},{{center.city}},{{center.state}},{{center.zip}}" target="_blank">Map</a></td>
        </tr>
        </tbody>
      </table>
    </div>

    <div style="position: relative !important" class="col-md-5 col-sm-10  top10 map-wrapper" >
      <div class="row">
        <div class="col">
          <div ngbDropdown class="d-inline-block">
            <button  style="top: 0px;left: 16px;position: absolute; z-index: 4;" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Map</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <a  type="checkbox" ngbDropdownItem (click) ="this.viewType='roadmap'">Terrain</a>
            </div>
          </div>
        </div>
        <div class="col ">
          <div ngbDropdown  class="d-inline-block">
            <button style="top: 0px;left:103px;position: absolute; z-index: 4;" class="btn btn-outline-primary" id="dropdownBasic2" ngbDropdownToggle>Satellite</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
              <a type="checkbox" ngbDropdownItem (click) ="this.viewType='satellite'">Labels</a>
            </div>
          </div>
        </div>
      </div>
      <google-map
        height="500px"
        width="100%"
        [center]="{ lat: nearestCenters.length > 0 ? nearestCenters[0].latitude : 0, lng: nearestCenters.length > 0 ? nearestCenters[0].longtitude : 0 }"
        [zoom]="9"
      >
        <map-marker
          *ngFor="let center of nearestCenters"
          [position]="{ lat: center.latitude, lng: center.longtitude }"
          [title]="center.name"
        ></map-marker>
      </google-map>
    </div>
  </div>
  <div class="row hidden-md hidden-lg">
    <div class="col-xs-12">
      <mat-card class="cards hidden-md hidden-lg" *ngFor="let center of nearestCenters; trackBy: trackByFn">
        <mat-card-header>
          <mat-card-title>{{center.name === 'Quest Diagnostics' ? center.name + ' Test Center' : center.name}}</mat-card-title>
          <!-- <mat-card-subtitle>{{center.city}}, {{center.state}}, {{center.zip }}</mat-card-subtitle> -->
        </mat-card-header>
        <mat-card-content>
          <div style="font-weight: bold;">Address:</div>
          <div>{{center.address}} <br> {{center.city}}, {{center.state}}, {{center.zip }}</div>
          <br>
          <div><span style="font-weight: bold">{{center.distance | number:'1.0-0'}} Miles</span> from Zip Code {{zipCode.value}}.</div>
          <div>
            <a class="btn btn-info" href="https://www.google.com/maps/place/{{center.address}},{{center.city}},{{center.state}},{{center.zip}}" target="_blank">See in Maps</a>
          </div>
          <br>
          <div style="font-weight: bold;">Phone:</div>
          <div>{{center.phone}}</div>
          <br>
          <div style="font-weight: bold;">Hours of Operation:</div>
          <div style="white-space: pre-wrap;">{{center.workingHours}}</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
