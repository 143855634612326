import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-confirm-forgot-password',
  templateUrl: './confirm-forgot-password.component.html',
  styleUrls: [ './confirm-forgot-password.component.scss' ]
})
export class ConfirmForgotPasswordComponent {
  showPassword = false;
  regex = {
    hasNumber: /\d/,
    hasSpecialCharacters: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
    hasCapitalCase: /[A-Z]/,
    hasSmallCase: /[a-z]/,
    minLength: /^.{8,}$/
  };
  colorMatch = {
    hasNumber: false,
    hasCapitalCase: false,
    hasSmallCase: false,
    hasSpecialCharacters: false,
    minLength: false
  };
  colorMatch2 = {
    hasNumber: false,
    hasCapitalCase: false,
    hasSmallCase: false,
    hasSpecialCharacters: false,
    minLength: false
  };
  model = {
    userId: this.data.userId,
    securityCode: this.data.SecurityCode,
    password: '',
    confirmPassword: ''
  };
  showSuggestions: boolean;
  showSuggestions2: boolean;
  private paswordInvalid: boolean;

  constructor(
    public router: Router,
    private modalService: ModalService,
    public accountService: AccountService,
    public dialogRef: MatDialogRef<ConfirmForgotPasswordComponent>,
    private notifications: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  cancel() {
    this.dialogRef.close('cancel');
  };

  analyze() {
    Object.keys(this.regex).forEach((x) => {
      this.colorMatch[x] = this.model.password && !!this.regex[x].test(this.model.password);
    });
    this.paswordInvalid = Object.values(this.colorMatch).includes(false);
  };

  analyzeConfirm(k) {
    Object.keys(this.regex).forEach((x) => {
      this.colorMatch2[x] = this.model.confirmPassword && !!this.regex[x].test(this.model.confirmPassword);
    });
    this.paswordInvalid = Object.values(this.colorMatch2).includes(false);
  };

  submit() {
    this.accountService.confirmForgotPassword(this.model)
      .subscribe(
        (x) => {
          this.modalService.showTopNotification(true, 'Your password successfully changed <br> Please click the Login button in the top right of your screen to login to your account.');
          this.router.navigate([ '/' ]);
          // $state.go('main', { userId: '', SecurityCode: '', ResetPassword: '' });
          this.dialogRef.close('ok');
        }
      );
  };
}
