<mat-form-field *ngIf="type === 0" class="example-chip-list" style="background-color: #1c629e;">
  <mat-label>Add product</mat-label>

  <mat-select (selectionChange)="onChange($event)" [formControl]="formControl"  placeholder="Banks" [multiple]="true" #multiSelect [disabled]="isDisabled">
      <mat-select-trigger>
        <mat-chip-list [multiple]="true" #chipList aria-label="Product selection" [formControl]="formControl" >
        <mat-chip
          [disabled]="isDisabled"
          *ngFor="let product of formControl.value"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(product)">
          {{product.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        </mat-chip-list>
      </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [noEntriesFoundLabel]="'No entries found'" [placeholderLabel]="'Please select product'" [formControl]="bankMultiFilterCtrl"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let bank of filteredBanksMulti | async" [value]="bank">
      {{bank.name}}
    </mat-option>
  </mat-select>
</mat-form-field>


<mat-form-field *ngIf="type === 1" class="example-chip-list" appearance="fill">
  <mat-label>Add product</mat-label>

  <mat-select (selectionChange)="onChange($event)" [formControl]="formControl"  placeholder="Banks" [multiple]="true" #multiSelect [disabled]="isDisabled">
    <mat-select-trigger>
      <mat-chip-list [multiple]="true" #chipList aria-label="Product selection" [formControl]="formControl" >
        <mat-chip
          [disabled]="isDisabled"
          *ngFor="let product of formControl.value"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(product)">
          {{product.name}}
          <span>&nbsp;&nbsp;${{product.masterPrice}}</span>
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [noEntriesFoundLabel]="'No entries found'" [placeholderLabel]="'Please select product'" [formControl]="bankMultiFilterCtrl"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let bank of filteredBanksMulti | async" [value]="bank">
    {{bank.name}}
      <span>&nbsp;&nbsp;${{bank.masterPrice}}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
