<div class="popup forms">

  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

  <div class="logo">
    <img src="../../../assets/img/logo_white.png" alt="">
  </div>
  <div class="alert alert-danger" *ngIf="errorReason" style="display: flex; align-items: center;" role="alert">
    <button mat-mini-fab disabled >
      <mat-icon>close</mat-icon>
    </button>
    <div  style="margin-bottom: 0; margin-left: 12px"><span [innerHTML]="errorReason"></span><span *ngIf="errorReason.includes('password') && !expiredPassword" style="text-decoration: underline;" (click)="openForgotPassword()">reset your password.</span></div>
  </div>
  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input formControlName="userName" type="email" class="form-control" id="exampleInputEmail1" placeholder="Email"
              autofocus>
    </div>
    <div class="form-group passwordContainer">
      <label for="exampleInputPassword1">Password</label>
      <input formControlName="password" [type]="showPassword ? 'text' : 'password'" class="form-control" id="exampleInputPassword1"
              placeholder="Password">
      <button mat-icon-button (click)="togglePassword()" type="button" id="visibilityButton">
        <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
      </button>
    </div>
    <div class="checkbox">
      <!-- <input id="remember" class="styled" type="checkbox" formControlName="rememberMe">
      <label for="remember">
        Remember me
      </label> -->
      <a (click)="openForgotPassword()" href="#" class="forgotpassword"><i class="fa fa-question-circle"></i> Forgot
        Password</a>
    </div>
    <button class="btn btn-default loginbtn" style="width: 100%;" [disabled]="tempUserId !== ''">
      <i class="fa fa-sign-in"></i> LOGIN
    </button>
    <div *ngIf="admin">
      <div class="form-group">
        <label for="codeEmail">Code from Email</label>
        <input pattern="[0-9]*" formControlName="code" type="text" class="form-control" id="codeEmail" placeholder="Code"
                autofocus>
      </div>
      <button  class="btn btn-default loginbtn" type="button" style="width: 100%;" (click)="login(true)"><i class="fa fa-sign-in"></i> MFA Login</button>
    </div>

  </form>
  <div class="noaccount">
    <div>Don't have an account?</div>
    <button class="btn btn-default" style="width: 100%;" (click)="openRegisterPopup()">REGISTER NOW!</button>
  </div>
</div>
