import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginAsGuard  {
  fakeInfo = [];

  constructor(public activatedRoute: ActivatedRoute,
    public router: Router,
    public sessionInfoStorageService:SessionInfoStorageService){}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      this.sessionInfoStorageService.setFakeAuthInfo(route.queryParams);
      this.sessionInfoStorageService.isAdmin();
    
      return this.router.navigate(['/']);
      // return this.router.createUrlTree(['/']);
  }

  
}
