import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionInfoStorageService } from '../../services/session-info-storage.service';
import { CartService } from '../../services/cart.service';
import { ProfileService } from '../../services/profile.service';
import { ModalService } from '../../services/modal.service';
import { NotificationService } from '../../services/notification.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { TestCentersService } from 'src/app/services/test-centers.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrderService } from 'src/app/services/order.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { PromoCodeService } from 'src/app/services/promo-code.service';
import { StatesService } from 'src/app/services/states.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  imageURL = environment.baseIconUrl;
  items = [];
  profileList = null;
  processingFee = environment.processingFee;
  physicianFee = environment.physicianFee;
  labDrawFee = environment.labDrawFee;
  isFree = null;
  selectedProfiles = [];
  isShown = false ;
  exposed = [];
  currentId = '';
  itemTests = [];
  showNote: string;
  nearestCenters = [];
  nearestCenter = {};
  zipCode;
  isEmpty = undefined;
  testCentersForm: UntypedFormGroup;
  state: String;
  discount = 0;
  duplicateChilds = [];
  promoCodeName = '';
  promoCodeSession = this.sessionInfoStorageService.getPromoCode();
  orderInfo = this.sessionInfoStorageService.getOrderInfo();

  @ViewChild('squareWrapper') squareWrapper: any;
  @ViewChild('statNumber') statNumber: any;

  constructor(private sessionInfoStorageService: SessionInfoStorageService,
              public sharedService: SharedService,
              private cartService: CartService,
              private profileService: ProfileService,
              public modalService: ModalService,
              public notificationService: NotificationService,
              private testCentersService: TestCentersService,
              private orderService: OrderService,
              private catalogService: CatalogService,
              private promoCodeService: PromoCodeService,
              private statesService: StatesService,
              public router: Router) {
    this.refreshProfileList();
    notificationService.getCartState().subscribe(data => {
      if (data.event === 'cart:onOpen') {
        this.onOpenCart();
      }
      if (data.event === 'cart:update') {
        this.updateItems(data.event, data.data);
      }
    });
    notificationService.getProfileState().subscribe(data => {
      if (data && data.event === 'profiles:update') {
        this.refreshProfileList();
      }
    });
    notificationService.getAuthenticationState().subscribe(data => {
      if (data === 'authentication:login') {
        this.refreshProfileList();
      }
      if (data === 'authentication:logout') {
        this.clearProfileList();
      }
    });
    // const orderInfo = this.sessionInfoStorageService.getOrderInfo();
    // if(Object.keys(orderInfo).length > 0){
    //   this.cartService.checkoutOrder(orderInfo.orderId).then((x: any) => {
    //   this.cartService.updateCartData(x.items);
    // });
    // }
    this.testCentersForm = new UntypedFormBuilder().group({
      zipCode: [null, [Validators.minLength(5), Validators.maxLength(5)]]
    });
  }
  testCentersRedirect(){
    this.router.navigateByUrl('testcenters');
    this.toggleCart();
  }

  toggleShow(id) {
    this.currentId = id;
    const index = this.exposed.indexOf(this.currentId);
    if (index == -1){
      this.exposed.push(this.currentId);
    }else{
      this.exposed.splice(index, 1);
    }

    // this.isShown = !this.isShown;
  }
  updateNote(cartData, event?) {
    this.showNote = cartData.some((item) => {
      return environment.covidIdList.indexOf(item.product.id) > -1 || environment.covidIdListEmp.indexOf(item.product.id) > -1;
    }) ? 'Please note that every order with a COVID-19 IgG test includes a $10 fee, collected on behalf of PWNHealth for Physician Oversight Services.' : '';

  }
  updateItems(event, cartData) {
    const token = this.sessionInfoStorageService.getToken();
    // this.processingFee = cartData.processingFee;
    this.items = cartData.items.map((item) => {
      if (!item.profile && token) {
        item.profile = this.profileList.length > 0 ? this.profileList[0] : null;
      }
      return item;
    });
  }

  refreshProfileList() {
    this.profileService.getProfiles((profiles) => {
      this.profileList = profiles;
    });
  }

  clearProfileList() {
    this.profileList = null;
  }

  processItems(input) {
    return input.map((item) => {
      const eachObject: any = {id: item.id};
      if (item.profile) {
        eachObject.ProfileId = item.profile.id;
      } else if (this.profileList && this.profileList.length) {
        eachObject.ProfileId = this.profileList[0].id;
      }
      return eachObject;
    });
  }

  onOpenCart() {
    // this.isFree = this.sessionInfoStorageService.asQuasiAdmin();
  }

  refreshCart() {
    this.showNote = '';
    let products = [];
    this.duplicateChilds = [];
    this.cartService.refreshCart()
    .then(res => {
      if (res[0]){
        this.items = res[0].items;
        let allItems = [];
        this.promoCodeName = res[0].promoCode;
        res[0].items.forEach((el) => {
          if(el.promoCode){
            this.promoCodeName = el.promoCode;
          }
          allItems = el.children;
          allItems = [...allItems, el];
          let allAreActive = true;
          let inActiveName = '';
          allItems.forEach((child, index) => {
            this.catalogService.getProduct(child.product.id).subscribe((res) => {
              if (!res.isActive) {
                allAreActive = false;
                inActiveName = child.product.name;
                }
              if (allItems && index === allItems.length - 1){
                if (!allAreActive){
                  this.cartService.deleteFromCart(el.id).then((ress) => {
                    this.refreshCart();
                    this.notificationService.block({message: `We are sorry, but a test or panel that you had in your cart is no longer approved for ordering. Please contact customer support for help. <br><br>Non-approved test: ${inActiveName}`}, false);
                  });
                }
              }
            });
          });
        });

        if(typeof this.promoCodeSession !== 'object' && this.promoCodeName !== this.promoCodeSession && this.orderInfo){
          this.promoCodeService.checkPromoCode(this.orderInfo.orderId,this.promoCodeSession).subscribe((res)=>{},(err)=>{
            this.sessionInfoStorageService.cleanPromoCode()
            this.promoCodeSession = this.sessionInfoStorageService.getPromoCode();
          })
        }

        this.updateNote(res[0].items);
        res[0].items.forEach((el, i) => {
          this.selectedProfiles.push(this.profileList.find(profile => profile.id === el.profile.id));
          if (el.children.length > 0){
            products = products.concat(el.children);
          }else{
            products.push(el);
          }
          this.duplicateChilds = products.reduce((a, e) => {
            if (a[e.product.id]){
              a[e.product.id].push(e);
            }else{
              a[e.product.id] = [];
            }
            return a;
          }, []);
          this.duplicateChilds = Object.values(this.duplicateChilds).filter(e => e.length);
          this.discount = 0;
          this.duplicateChilds.forEach((current) => {
              this.discount += (current[0].product.masterPrice * current.length);
            });

        });
      }

      const observer = new IntersectionObserver(entries => {
        const startNr = 0;
        const endNr = (219 + 13 + this.getTotalMarketPrice()) - (this.physicianFee + this.labDrawFee + this.getTotalPrice()  - this.discount) | 0;
        const countUp = (count) => {
          this.statNumber.nativeElement.innerHTML = `$${Math.round(count)}`;
          if (count < endNr) {
            setTimeout(() => countUp(count + (endNr / 100) * 0.5), 0);
          }else{
            this.statNumber.nativeElement.innerHTML = `$${endNr}`;
          }
        };
        countUp(startNr);
      });
      observer.observe(this.squareWrapper.nativeElement);
    });
  }
  navigateProduct(href){
    this.router.navigate([href]);
  }
  updateCart() {
    const processedItems = this.processItems(this.items);
    this.cartService.updateOrder(processedItems);
  }

  deleteItem(id, productName) {
    this.modalService.openConfirmModal('Are you sure to remove' + '\n' + productName + ' ?', () => {
      this.cartService.deleteFromCart(id).then((res) => this.refreshCart());

    });
  }

  clearCart() {
    this.modalService.openConfirmModal('Are you sure you want to remove all tests?', () => {
      this.cartService.clearCart().then((res) => {
        // this.sessionInfoStorageService.cleanOrderInfo()
        this.refreshCart();
        this.discount = 0;
      });
    });
  }

  getTotalPrice() {
    if (this.items && this.items.length) {
      return this.items.reduce((prev, current) => {
        return prev + current.product.masterPrice;
      }, 0);
    }
  }

  getTotalMarketPrice() {
    if (this.items && this.items.length) {
      return this.items.reduce((prev, current) => {
        return prev + current.product.marketPrice;
      }, 0);
    }
  }

  proceedCart() {
    // this.router.navigate(['/checkout']);

    if (this.state === 'AZ'){
      document.getElementsByClassName('shoppingCart')[0].scrollTo(0, 0);
      window.scrollTo(0, 0);
      this.modalService.importantNotice(this.state);
      return;
    }
    if (this.sessionInfoStorageService.getToken()) {
      const items = this.processItems(this.items);
      this.cartService.updateOrder(items).then(() => {
        this.toggleCart();
        this.router.navigate(['/checkout'], { queryParamsHandling: 'merge'});
      });
    } else {
      this.modalService.openLogin();
    }
  }

  showProcessingFee() {
    if (this.items.length > 0) {
      this.modalService.openProcessingFee();
    }
  }

  toggleCart() {
    this.notificationService.cartState.next({event: 'cart:close', data: {}});
  }

  getProfile(i) {
    return this.selectedProfiles[i];
  }
  setProfile(item, event) {
    item.profile = event.value;
  }

  search(){
    if (this.testCentersForm.get('zipCode').value.length != 5) {
      return;
    }

    this.findTestCentersByZipCode(this.testCentersForm.get('zipCode').value);
  }

  findTestCentersByZipCode(zipCode){
    this.testCentersService.findTestCenters(zipCode).then(
      (result: any) => {
        this.nearestCenters = result;
        this.nearestCenters.forEach((center) => {
          center.workingHours = center.workingHours.replace(/; |\|/g, '\n');   // replace '; '  or '|' with new line
          // 7:30 am-5:00 pm  convert to 7:30am - 5:00pm
          center.workingHours = center.workingHours.replace(/ ([ap])m/g, '$1m');
          center.workingHours = center.workingHours.replace(/([ap])m-/g, '$1m-');
          center.workingHours = center.workingHours.replace(/m-/g, 'm ~ ');
          const newCenterArray = center.workingHours.split('-');
          const finalArray = newCenterArray.map(element => {
            const matches = element.match(/[a-zA-Z] \d/g);
            if (matches) {
              const finalMatches = matches.map(match => match.split(' ').join(': '));
              let finalElement = element;
              finalMatches.forEach((el, index) => {
                finalElement = finalElement.replace(matches[index], el);
              });
              return finalElement;
            }
            return element;
          });
          center.workingHours = finalArray.join('-').replace(/m ~ /g, 'm - ');
          });
        this.nearestCenter = this.nearestCenters[0];
        this.isEmpty = this.nearestCenters[0] ? true : false;
      }, reason => {
        this.isEmpty = false;
      }
    );
  }
  ngOnInit(): void {

    this.refreshCart();
    if (this.sessionInfoStorageService.getToken()) {          // someone logged in
      this.profileService.getProfile(this.sessionInfoStorageService.getProfileId()).subscribe(
        (mainProfile: any) => {
          if(this.statesService.getState(mainProfile.homeAddress.zip) !== mainProfile.homeAddress.state){
            this.notificationService.block({ message: 'Your zip code does not match your state. Please contact GRL support to have your address updated to proceed with ordering tests.' },false)
          }
          this.state = mainProfile.homeAddress.state;
          if (mainProfile.homeAddress) {
            this.zipCode = mainProfile.homeAddress.zip;
          } else if (mainProfile.billingAddress) {
            this.zipCode = mainProfile.billingAddress.zip;
          } else if (mainProfile.govAddress) {
            this.zipCode = mainProfile.govAddress.zip;
          }
          this.findTestCentersByZipCode(this.zipCode);
        }
      );
    }else{
      this.isEmpty = false;
    }

  }

}
