<div class="popup forms covid">

  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>
  <app-modal-logo></app-modal-logo>
  <mat-dialog-content class="mat-typography" style=" padding:20px">
    <app-symptom
      *ngIf="!ineligible && !eligible"
      [(eligible)]="eligible"
    ></app-symptom>

    <app-pcr-test
      *ngIf="eligible === 1"
      [(eligible)]="eligible"
      (optionChange)="this.psrTest = $event"
    ></app-pcr-test>

    <app-covid-contact
      *ngIf="eligible === 2"
      [(eligible)]="eligible"
      (covidContactChange)="this.covidContact = $event"
    ></app-covid-contact>

    <app-saliva-fourth-screen
      *ngIf="eligible===3"
      [(eligible)]="eligible"
      (ScreeningQuestion4Change)="this.ScreeningQuestion4 = $event"
    ></app-saliva-fourth-screen>
    <!--<section style="position: relative; " *ngIf="eligible === 3 && !ineligible" (click)="eligible === 3">
      <h1>Please fill out the following demographic information.</h1>
      <div class="row">
        <div class="col-md-12" style="text-align:center; height: 400px;">
          <form name="form" class="form-horizontal" novalidate>
            <div class="panel panel-primary">
              <div class="panel-heading">
                <h3 class="panel-title">
                  What is your ethnicity?
                </h3>
              </div>

              <div class="panel-body text-info">

                <div class="form-group" *ngFor="let item of firstGroup">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">
                        <input type="checkbox"
                               style="margin-top: 7px"
                               (change)="onCheckBoxSelected(item.id,true)"
                               value="{{item.id}}"
                               id="firstGroup{{item.id}}"
                               name="selectedEthnicity"
                               [(ngModel)]="item.Selected"
                               class="checkbox-inline col-xs-2 col-sm-2"/>
                        <label for="firstGroup{{item.id}}" class="col-xs-10 col-sm-10 control-label"
                               style="text-align: left">{{item.Name}}</label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div ng-cloak class="form-group col-sm-12 pull-right"
                     [hidden]="IsValidFirstGroup === null || IsValidFirstGroup">
                  <div class="alert alert-danger" role="alert">
                    Select at least one checkbox!
                  </div>
                </div>
              </div>
            </div>


            <div class="panel panel-info">
              <div class="panel-heading">
                <h3 class="panel-title">
                  What is your race?
                </h3>
              </div>

              <div class="panel-body text-info">

                <div class="form-group" *ngFor="let item of secondGroup">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">
                        <input type="checkbox"
                               (change)="onCheckBoxSelected(item, true)"
                               style="margin-top: 7px"
                               value="{{item.id}}"
                               id="secondGroup{{item.id}, true}"
                               name="selectedRace"
                               [(ngModel)]="item.Selected"
                               class="checkbox-inline col-xs-2 col-sm-2"/>
                        <label for="secondGroup{{item.id}}" class="col-xs-10 col-sm-10 control-label"
                               style="text-align: left">{{item.Name}}</label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div ng-cloak class="form-group col-sm-12 pull-right"
                     [hidden]="IsValidSecondGroup === null || IsValidSecondGroup">
                  <div class="alert alert-danger" role="alert">
                    Select at least one checkbox!
                  </div>
                </div>
              </div>
            </div>


            <div class="panel panel-warning">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Do any of the following statements apply to you?
                </h3>
              </div>

              <div class="panel-body text-info">

                <div class="form-group" *ngFor="let item of thirdGroup">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">
                        <input type="checkbox"
                               (change)="onCheckBoxSelected(item.id,true)"
                               style="margin-top: 7px"
                               value="{{item.id}}"
                               id="thirdGroup{{item.id}}"
                               name="selectedStatements"
                               [(ngModel)]="item.Selected"
                               class="checkbox-inline col-xs-2 col-sm-2"/>
                        <label for="thirdGroup{{item.id}}" class="col-xs-10 col-sm-10 control-label"
                               style="text-align: left">{{item.Name}}</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>

            </div>
            <div class="covid2 panel">
              <div class="panel-heading">
                <h3 class="panel-title" style="text-align: center;color: white;">
                  PWNHealth provides physician oversight, ordering, and follow-up consults for COVID-19 testing. Please
                  review and consent to each item below.
                </h3>
                <h3 class="panel-title" style="text-align: center;color: white;">
                  By checking, I consent to the PWNHealth Notice of Privacy Practices, Terms and Conditions, and
                  Consent.
                </h3>
              </div>
              <div class="panel-body" style="padding: 0 30px;">
                <div class="col-sm-12">
                  <fieldset class="form-group">
                    <div class="row" style="display: block;text-align: left;">
                      <input required name="privacyPractices" [(ngModel)]="model.privacyPractices" type="checkbox"
                             class="checkbox-inline col-xs-2 col-sm-2" name="privacyPractices" value="1"
                             id="privacyPractices">
                      <label for="privacyPractices"
                             style="text-align: left"
                             class="col-xs-10 col-sm-10 control-label"> <a
                        style="font-size:16px;text-align: left;margin: 0"
                        href="https://www.pwnhealth.com/privacy-policy-covid-19/"
                        target="_blank">PWN Notice of Privacy Practices </a> </label>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row" style="display: block;text-align: left;">
                      <input required name="termsAndConditions" [(ngModel)]="model.termsAndConditions" type="checkbox"
                             class="checkbox-inline col-xs-2 col-sm-2" name="termsAndConditions" value="2"
                             id="termsAndConditions">
                      <label for="termsAndConditions"
                             style="text-align: left"
                             class="col-xs-10 col-sm-10 control-label"> <a
                        style="font-size:16px;text-align: left;margin: 0"
                        href="http://www.pwnhealth.com/self-pay-terms-of-use/" target="_blank">PWN
                        Terms and Conditions </a> </label>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row" style="display: block;text-align: left;">
                      <input required name="testConsent" [(ngModel)]="model.testConsent" type="checkbox"
                             class="checkbox-inline col-xs-2 col-sm-2" name="testConsent" value="3"
                             id="testConsent">
                      <label for="testConsent"
                             style="text-align: left"
                             class="col-xs-10 col-sm-10 control-label"> <a
                        style="font-size:16px;text-align: left;margin: 0"
                        href="https://www.pwnhealth.com/covid-19/" target="_blank">PWN COVID-19 Test Consent</a>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12" style="text-align:center">
                <button class="btn btn-info" type="submit" (click)="Submit()">
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </section>-->
    <section *ngIf="ineligible">
      <h1></h1>
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-danger">
            <div class="panel-heading">
              <h3 class="panel-title">
                Ineligible for TESTING
              </h3>
            </div>
            <div class="panel-body text-danger">
              Based on your response, you may still have an active COVID-19 infection and this test
              may not be right for you at this time. COVID-19 Immune Response testing is specifically
              used to check for an immune response to the virus which can take time after an active
              infection.
            </div>
          </div>
        </div>
      </div>
    </section>
  </mat-dialog-content>
</div>
