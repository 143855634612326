<div class="row top20 bot20 mainvideo container mainContainer"
     style="margin-left: auto; margin-right: auto; padding: 20px; margin-top: 20px !important;">
  <div class="container row-eq-height" style="width: 100%; padding: 0px;">
    <div class="col-xs-12 col-md-offset-1 vcenter" style="padding: 0px; margin: 0px;">
      <h1 class="text-center" style="
      font-weight: revert;
      font-size: 36px;
      margin-top: 20px;
      margin-bottom: 10px;
      line-height: 1.1;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      letter-spacing: 0.8px !important;">Blood Tests are Expensive. <br> We fix that.</h1>
    </div>

  </div>
</div>

<div class="container seeoursystem" style="padding: 20px;">
  <h3 class="text-center top20" style="text-transform: uppercase; color: #11AFBD; padding: 20px; font-size: 2em;">3
    Steps to Get Your Lab Testing</h3>
  <div class="row row-eq-height top20 bot20">

    <div class="col-sm-4 col-xs-12">
      <div class="well arrowright">
        <div class="row text-center">
          <img src="../../../assets/img/m.png" alt="Find your tests" class="img-step">
        </div>
        <h4 style="margin-top: 16px;"><b style="font-size: 18px;">Find the Tests You Need</b></h4>
        <p>Search for your tests add them to your cart to price check your labs and see your savings. Then checkout, pay
          for your tests, and download your lab order from a licensed physician in your state.</p>
      </div>
    </div>
    <div class="col-sm-4 col-xs-12">
      <div class="well arrowright">
        <div class="row text-center">
          <img src="../../../assets/img/s.png" alt="Visit Your Testing Center" class="img-step">
        </div>
        <h4 style="margin-top: 16px;"><b style="font-size: 18px;">Visit Your Testing Center</b></h4>
        <p>Use our <a href="testCenters">Test Centers</a> search tool to find the closest approved testing center near
          you. Print out and take your lab order and visit the test center at your convenience to have your tests
          performed.</p>
      </div>
    </div>
    <div class="col-sm-4 col-xs-12">
      <div class="well ">
        <div class="row text-center">
          <img src="../../../assets/img/p.png" alt="Get your FResults" class="img-step">
        </div>
        <h4 style="margin-top: 16px;"><b style="font-size: 18px;">Get Your Results Fast</b></h4>
        <p>Most test results are available within 3-5 business days and stored securely in your account. Download them
          to take with you when you visit your doctor. Get Started!</p>
      </div>
    </div>
  </div>

</div>
<!-- CONTACT PART -->
<!-- <div class="contact">
  <div class="container">
    <div class="col-md-5 col-sm-4 col-xs-12 text-right">
      <p><i class="fa fa-phone-square"></i>(888) 315-3265</p>
    </div>
    <div class="col-md-7 col-sm-8 col-xs-12 text-left">
      <p><i class="fa fa-envelope"></i>SUPPORT@GRASSROOTSLABS.IO</p>
    </div>
  </div>
</div> -->
<!-- CONTACT PART Ends -->

<!-- <ui-view></ui-view> -->

<div>

  <div class="container-fluid" style="padding: 20px; max-width: 1170px;">
    <div style="background-color: #21AFBD; padding: 0 40px 20px 40px">
      <h1
        style="text-align: center; font-size: 2em; color: white; text-transform: uppercase; margin: 0; padding-top: 35px; padding-bottom: 15px">
        What People Are Saying About Grassroots Labs
      </h1>

      <div style="padding: 10px 0;" class="elfsight-app-cf860c82-62cd-44a8-893f-585f63aac219"></div>
    </div>
  </div>

  <div class="container-fluid" style="padding: 20px; max-width: 1170px;">
    <app-saved-counter></app-saved-counter>
  </div>

  <br>


  <!-- CONTACT PART -->
  <!-- <div class="contact">
    <div class="container">
        <div class="col-md-5 col-sm-4 col-xs-12 text-right">
            <p><i class="fa fa-phone-square"></i>(888) 315-3265</p>
        </div>
        <div class="col-md-7 col-sm-8 col-xs-12 text-left">
            <p><i class="fa fa-envelope"></i>SUPPORT@GRASSROOTSLABS.COM</p>
        </div>
    </div>
</div> -->
  <!-- CONTACT PART Ends -->
  <!-- <ui-view></ui-view> -->
