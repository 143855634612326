import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'app-covid19-emp',
  templateUrl: './covid19-emp.component.html',
  styleUrls: [ './covid19-emp.component.scss' ]
})
export class Covid19EmpComponent {
  ineligible: any = false;
  ineligible2: any = false;
  eligible: any = 0;
  form: UntypedFormGroup;
  testConsent;
  ScreeningQuestion4: any;
  ScreeningQuestion6: any;
  ScreeningQuestion5: any;

  constructor(public dialogRef: MatDialogRef<Covid19EmpComponent>) {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onConsentClick() {
    const race1 = this.ScreeningQuestion4.ScreeningQuestion4.race;
    const race = {};
    Object.keys(race1).forEach(x => race[x] = race1[x] || false);
    const demographic1 = this.ScreeningQuestion4.ScreeningQuestion4.ethnicity;
    const demographic = {};
    Object.keys(demographic1).forEach(x => demographic[x] = demographic1[x] || false);
    const statements1 = this.ScreeningQuestion4.ScreeningQuestion4.thirdGroup;
    const statements = {};
    Object.keys(statements1).forEach(x => statements[x] = statements1[x] || false);


    const fresh = {
      ...this.ScreeningQuestion5,
      ...this.ScreeningQuestion6,
      race, demographic, statements,
      terms: {
        testConsent: true,
        termsAndConditions: true,
        privacyPractices: true
      },
      option: 'I have never been tested for COVID-19.',
      feverDate: this.ScreeningQuestion5.feverDate? moment(this.ScreeningQuestion5.feverDate).format('DD/MM/YYYY') : false,
      fourteenDays: 'NO'
    };

    this.dialogRef.close(fresh);
  }
}
