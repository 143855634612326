<form [formGroup]="pwnOrdersForm">
<div class="cabinetTabs">
    <div class="container-fluid">
        <div class="row transactions-filter">
            <div class="col-md-4">
                <div class="input-group">
                  <input class="form-control" type="text" #search (keyup)="filterTransaction(search.value)" #input>
                  <span class="input-group-addon">
                    <i class="glyphicon glyphicon-search"></i>
                  </span>
                </div>
              </div>
            <!-- <div class="col-xs-3">
                <p class="input-group">
                    <input formControlName="dateStart"  class="form-control"
                                 placeholder="Date start">
                    <span class="input-group-btn">
                <button type="button" class="btn btn-default" (click)="dateStartOpen = !dateStartOpen">
                    <i class="glyphicon glyphicon-calendar"></i>
                </button>
            </span>
                </p>
            </div>
            <div class="col-xs-3">
                <p class="input-group">
                    <input formControlName="dateEnd"  class="form-control" 
                                placeholder="Date end" >
                    <span class="input-group-btn">
                <button type="button" class="btn btn-default" (click)="dateEndOpen = !dateEndOpen">
                    <i class="glyphicon glyphicon-calendar"></i>
                </button>
            </span>
                </p>
            </div> -->
        </div>

        <table class="table table-hover">
            <thead>
            <tr>
                <th>GRL Order ID</th>
                <th>PWN Order ID</th>
                <th>PWN Status</th>
                <th>PWN Timestamp</th>
                <th>Error Message</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of orders">
                <td>{{ item.linkedOrder? item.linkedOrder.number :  ''}}</td>
                <td>{{ item.pwnOrderId }}</td>
                <td>{{ item.pwnStatus }}</td>
                <td>{{ item.pwnTimestamp | date: 'MMMM dd, yyyy'}}</td>
                <td>{{ item.errorMessage }}</td>
            </tr>
            </tbody>
        </table>
        <ngb-pagination [collectionSize]="totalOrders" [(page)]="page" [maxSize]="5"
            (pageChange)="getUsersFromSearch(page, orders)"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
    </div>
</div>
</form>