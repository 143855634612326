import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PromoCodeService } from 'src/app/services/promo-code.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

export interface PromoList {
  Id: string;
  code: string;
  isActive: boolean;
  totalUseLimit: number;
  totalUsedLimit: number;
  dateFinish: string;
}


@Component({
  selector: 'app-admin-promo-code',
  templateUrl: './admin-promo-code.component.html',
  styleUrls: ['./admin-promo-code.component.scss']
})
export class AdminPromoCodeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<PromoList>;

  searchInput = new UntypedFormControl('');
  allPromoCodes = [];
  filteredCodes;

  totalPromo = 0;
  page = 1;

  id: any;
  constructor(
    private modalService: ModalService,
    private promoCodeService: PromoCodeService
  ) { }

  ngOnInit(): void {
    this.searchInput.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(val => val);

    this.getPromos();
  }

  getPromos() {
    this.promoCodeService.getPromoList()
      .subscribe((response) => {
        this.allPromoCodes = response.items;
        this.filteredCodes = response.items;
        this.totalPromo = response.items.length;
        this.dataSource = new MatTableDataSource<PromoList>(
          response.items
            .sort((a, b) => b.totalUsedLimit - a.totalUsedLimit)
            .slice((this.page - 1) * 10, this.page * 10));
        this.dataSource.sort = this.sort;
      });
  }

  openModal() {
    this.modalService.openPromoCodPopup();
  }

  promoCodeEdit: any;
  openPromoCode(id){
    this.modalService.updatePromoCode(id, true);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
  }

  getUsersFromSearch(page) {
    this.dataSource = new MatTableDataSource(
      this.allPromoCodes
        .sort((a, b) => b.totalUsedLimit - a.totalUsedLimit)
        .slice((page - 1) * 10, page * 10));
    this.dataSource.sort = this.sort;
  }

  filterPromoCode(search) {
    this.page = 1;
    let linkedOrder = false;
    this.filteredCodes = this.allPromoCodes.filter((res) => {
      if (res){
        linkedOrder = res.code.toString().indexOf(search) !== -1;
      }
      return res.code.toString().indexOf(search) !== -1 || linkedOrder;
    });
    this.dataSource = new MatTableDataSource(
      this.filteredCodes
        .sort((a, b) => b.totalUsedLimit - a.totalUsedLimit)
        .slice((this.page - 1) * 10, this.page * 10));
    this.dataSource.sort = this.sort;
    this.totalPromo = this.filteredCodes.length;
  }
}
