<div class="popup forms">
  <div class="cancel cancelWrapper" (click)="cancel()"><i class="fa fa-times"></i></div>
  <form [formGroup]="shareResultForm" (ngSubmit)="shareModalSend()">
    <div *ngIf="showContent === 1">
      <div class="col-md-12">
        <h1 style="padding-top: 0; padding-left: 30px; padding-right: 30px; margin-top: 0">
          Send Your {{ data.type === 1 ? "Requisition / lab order" : "Results" }} via
          Encrypted Email
        </h1>
      </div>
      <div class="form-horizontal">
        <div class="text-info-form">
          Grassroots Labs uses
          <a href="https://www.paubox.com/" target="_blank" class="pauboxLink"
            >Paubox</a
          >
          to send your {{ data.type === 1 ? "requisition / lab order" : "results" }} via
          encrypted email. Enter the email address to which you want to send your
          {{ data.type === 1 ? "requisition / lab order" : "results" }}.
        </div>
        <!-- <mat-label>Enter email</mat-label> -->
        <div class="divEmail">
          <input
            name="email"
            formControlName="email"
            type="email"
            class="form-control emailForm"
            id="emailId"
            placeholder="Enter email"
          />
          <button
            (click)="showConfirmation()"
            class="btn btn-primary btnEmail"
            [disabled]="!shareResultForm.valid"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="showContent === 2">
      <div>
        <h1 style="padding-top: 0; padding-left: 30px; padding-right: 30px; margin-top: 0">
          Confirm THE Email Address to Send the
          {{ data.type === 1 ? "Requisition / lab order" : "Results" }} for Order
          {{ data.info.order.number }}
        </h1>
      </div>
      <div class="cancel cancelWrapper" (click)="cancel()"><i class="fa fa-times"></i></div>
      <div class="divEmailSend">
        <p>
          I, <b><u>{{userInfo.firstName}} {{userInfo.lastName}}</u></b>,
          confirm I want to send my lab
          {{ data.type === 1 ? "Requisition / lab order" : "Results" }}
          for Order <b><u>{{data.info.order.number}}</u></b>
          to the recipient indicated below.
        </p>
        <span>{{ shareResultForm.controls.email.value }}</span>
      </div>
      <div class="btns">
        <button (click)="showConfirmation()" class="btn btn-success">
          Confirm
        </button>
        <button (click)="showConfirmation(false)" class="btn btn-primary">
          Back
        </button>
      </div>
    </div>
    <div *ngIf="showContent === 3">
      <div>
        <h1 style="padding-top: 0; padding-left: 30px; padding-right: 30px; margin-top: 0">
          Send the {{ data.type === 1 ? "Requisition / lab order" : "Results" }} for Order
          {{ data.info.order.number }}
        </h1>
      </div>
      <div class="cancel cancelWrapper" (click)="cancel()"><i class="fa fa-times"></i></div>
      <div class="divEmailSend">
        <p>
          By clicking ‘Send’ below, I indicate:
          <br />
          <br />
          My request to send my {{ data.type === 1 ? "requisition / lab order" : "results" }} for Order <b><u>{{data.info.order.number}}</u></b> to
          <b><u>{{ shareResultForm.controls.email.value }}</u></b> via encrypted email. I understand that
          Grassroots Labs is not responsible for the privacy or
          security of this information once transmitted to the recipient.
          <br />
          <br />
          Permission to share my email address <b><u>{{data.userEmail| lowercase }}</u></b> and phone number <b><u>{{userInfo.mobilePhone}}</u></b> with the noted recipient at <b><u>{{ shareResultForm.controls.email.value }}</u></b>.
          <br />
          <br />
          I understand this action cannot be undone once initiated and that these activities are subject to the Grassroots Labs Terms and Conditions and Privacy Policy.
          <br />
          <br />
          See the
          <a
            target="_blank"
            href="https://zachgrassrootslabs-my.sharepoint.com/:w:/g/personal/zach_grassrootslabs_com/ETbg2OiFnIdaM4D1U93MhWUBbJ0fTMYbrWsUy0gwRP5WjQ?rtime=_sP87KtB20g"
            class="pauboxLink"
            >Grassroots Labs Terms and Conditions</a
          >
          and
          <a
          target="_blank"
          href="https://zachgrassrootslabs-my.sharepoint.com/:w:/g/personal/zach_grassrootslabs_com/EYaUQbv42i9fVngyyCL1FAoBSrBvt1dCJZPXiIBC8SjauA?rtime=Ad9zxP5a20g"
          class="pauboxLink"
          > Privacy Policy
          </a>

          for more information.
        </p>
      </div>
      <div class="btns">
        <button type="submit" class="btn btn-success">Send</button>
        <button (click)="showConfirmation(false)" class="btn btn-primary">
          Back
        </button>
      </div>
    </div>
  </form>
</div>
