import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-processing-fee',
  templateUrl: './processing-fee.component.html',
  styleUrls: ['./processing-fee.component.scss']
})
export class ProcessingFeeComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  cancel () {
    this.modalService.dialog.closeAll();
  };

  ngOnInit(): void {
  }

}
