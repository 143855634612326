import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'navigation'
})
export class NavigationPipe implements PipeTransform {

    transform(data: any, option: any): any {
        const navList: any = [];
        const navComboBox: any = [];
        if(!data) return null;
        data.forEach((item) => {
            item.parent = item.parent || option;
            if(!item.isActive) return;
            if(item.parent === option) {
                if(item.combobox) {
                    let index = navComboBox.findIndex((el) => el.name === item.combobox);
                    if(index === -1) {
                        navComboBox.push({
                            name: item.combobox,
                            subitems: [item]
                        });
                    } else {
                        navComboBox[index].subitems.push(item);
                    }
                } else {
                    navList.push(item);
                }
            }
        });
        navList.sort((a,b) => a.position - b.position);
        navComboBox.forEach((el) => el.subitems.sort((a,b) => a.position - b.position));
        return navList.concat(navComboBox);
    }
}
