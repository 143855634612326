import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CatalogService } from '../../services/catalog.service';
import { ModalService } from '../../services/modal.service';


@Component({
  selector: 'app-admin-products',
  templateUrl: './admin-products.component.html',
  styleUrls: [ './admin-products.component.scss' ]
})
export class AdminProductsComponent implements OnInit {
  tests = [];
  totalTests = 0;
  searchInput = new UntypedFormControl('');
  imageURL = environment.baseIconUrl;

  constructor(private catalogService: CatalogService, private modalService: ModalService) {
  }

  ngOnInit() {
    this.searchInput.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(val => this.search(val));
  }

  openModal() {
    this.modalService.openTestPopup();
  }

  removeTest(test, testIndex) {
    this.modalService.openConfirmModal('Delete test?', removeTest.bind({}, test.id, testIndex));

    function removeTest(id, testIndex) {
      this.catalogService.removeProduct(id).then(
        () => {
          this.tests.splice(testIndex, 1);
          this.modalService.showTopNotification(true, 'Test \'' + test.name + '\' was successfully removed');
        }
      );
    }
  };

  getTests(params, flag) {
    if (this.totalTests > 0 || flag) {
      this.catalogService.getProducts(params).then(
        (result) => {
          this.tests = result['items'].filter(x => x.productType !== 2 && x.productType !== 3);

          this.totalTests = this.tests['count'];
        }
      );
    }
  };

  openTestsPopup(id) {
    const dialog = this.modalService.updateTestPopup(id);
    // dialog.closed.then(function(){
    //     $scope.paginatorFunctions.update();
    // });
  };

  search(search) {
    if (search.length < 2) {
      this.getTests(undefined, true);
      return;
    }

    this.catalogService.search({ search }).subscribe((result: any) => {
      this.tests = result.items;
      this.totalTests = result.count;
    });
  };
}
