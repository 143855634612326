<div class="popup forms">

  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>
  <div class="col-md-12">
    <h1 style="padding-top:0; margin-top:0;">{{editMode ? 'Edit' : 'Add'}} Article</h1>
  </div>

  <div class="row">
    <form class="form-horizontal"
          (ngSubmit)="submit(articleForm)"
          [formGroup]="articleForm">
      <div class="col-md-12">
        <div mat-dialog-content>
          <div class="col-md-12">
            <div class="form-group">
              <label for="title" class="col-sm-2 control-label">Title*</label>
              <div class="col-sm-8">
                <input class="form-control" type="text" maxlength="255" formControlName="title"
                       id="title">
                <div class="form-group col-sm-12 pull-left" *ngIf="submitted && articleForm.controls?.title?.invalid">
                  <div class="alert alert-danger" role="alert" *ngIf="articleForm.controls.title.errors.required">
                    <b>The Text Field is required</b>
                  </div>
                </div>
              </div>


            </div>
            <div class="form-group">
              <label for="shortDescription" class="col-sm-2 control-label">Short Description*</label>
              <div class="col-sm-8">
                <input class="form-control" type="text" maxlength="255" formControlName="shortDescription"
                       id="shortDescription">
                <div class="form-group col-sm-12 pull-left" *ngIf="submitted && articleForm.controls?.shortDescription?.invalid">
                  <div class="alert alert-danger" role="alert" *ngIf="articleForm.controls.shortDescription.errors.required">
                    <b>The Short Description Field is required</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label  class="col-sm-2 control-label">Author*</label>
              <div class="col-sm-8">
                <!-- Dropdown for saved authors and add new author option -->
                <select class="form-control" formControlName="authorId" (change)="onAuthorChange($event)">
                  <option value="">Add New Author</option>
                  <option *ngFor="let author of savedAuthors" [value]="author.id">{{ author.name }}</option>
                </select>

                <!-- Input field to enter new author, shown only if 'new' is selected -->
                <input
                  style="margin-top: 12px"
                  *ngIf="addingNewAuthor"
                  class="form-control mt-2"
                  type="text"
                  maxlength="255"
                  formControlName="newAuthorName"
                  placeholder="Enter new author name"
                />

                <div class="form-group col-sm-12 pull-left" *ngIf="submitted && !isAuthorValid">
                  <div class="alert alert-danger" role="alert" *ngIf="!isAuthorValid">
                    <b>The Author Field is required</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="estimateLength" class="col-sm-2 control-label">Estimate Length*</label>
              <div class="col-sm-8">
                <input class="form-control" type="number" maxlength="255" formControlName="estimateLength"
                       id="estimateLength">
                <div class="form-group col-sm-12 pull-left" *ngIf="submitted && articleForm.controls?.estimateLength?.invalid">
                  <div class="alert alert-danger" role="alert" *ngIf="articleForm.controls.estimateLength.errors.required">
                    <b>The Estimate Length Field is required</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="slug" class="col-sm-2 control-label">Slug*</label>
              <div class="col-sm-8">
                <input class="form-control" type="text" maxlength="255" formControlName="slug"
                       id="slug">
                <div class="form-group col-sm-12 pull-left" *ngIf="submitted && articleForm.controls?.slug?.invalid">
                  <div class="alert alert-danger" role="alert" *ngIf="articleForm.controls.slug.errors.required">
                    <b>The Estimate Length Field is required</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="metaDescription" class="col-sm-2 control-label">Meta Description*</label>
              <div class="col-sm-8">
                <input class="form-control" type="text" maxlength="255" formControlName="metaDescription"
                       id="metaDescription">
                <div class="form-group col-sm-12 pull-left" *ngIf="submitted && articleForm.controls?.metaDescription?.invalid">
                  <div class="alert alert-danger" role="alert" *ngIf="articleForm.controls.metaDescription.errors.required">
                    <b>The Meta Description Field is required</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="pageTitle" class="col-sm-2 control-label">Page Title*</label>
              <div class="col-sm-8">
                <input class="form-control" type="text" maxlength="255" formControlName="pageTitle"
                       id="pageTitle">
                <div class="form-group col-sm-12 pull-left" *ngIf="submitted && articleForm.controls?.pageTitle?.invalid">
                  <div class="alert alert-danger" role="alert" *ngIf="articleForm.controls.pageTitle.errors.required">
                    <b>The Page Title Field is required</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="metaRobots" class="col-sm-2 control-label">Meta robots*</label>
              <div class="col-sm-8">
                <input class="form-control" type="text" maxlength="255" formControlName="metaRobots"
                       id="metaRobots">
                <div class="form-group col-sm-12 pull-left" *ngIf="submitted && articleForm.controls?.metaRobots?.invalid">
                  <div class="alert alert-danger" role="alert" *ngIf="articleForm.controls.metaRobots.errors.required">
                    <b>The Meta Robots Field is required</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="isActive" class="col-sm-2 control-label">Active Article</label>
              <div class="col-sm-8">
                <input type="checkbox" formControlName="isActive" id="isActive">
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">Image</label>
              <div class="col-sm-4">
                <div class="upload btn btn-info" style="cursor: pointer;">

                  <label class="browseLabel">
                    <i class="fa fa-folder-open-o"></i>
                    Browse
                    <input
                      #fileInput
                      type="file"
                      [multiple]="true"
                      class="upload__input"
                      (change)="uploadFiles( $event.target.files )"
                    />
                  </label>
                </div>
              </div>
              <div class="col-sm-4">
                <button type="button"
                        class="btn btn-info"
                        [disabled]="false"
                        browse-images
                        (click)="openGallery()"
                        browse-images-selected="model.image1Id"
                        browse-images-preview="previewImage"
                        browse-images-on-selected="uploadFile = {}">
                  <i class="fa fa-file-image-o"></i> Gallery
                </button>
              </div>
              <br>
              <br>
              <div class="col-sm-8 col-sm-offset-4" *ngIf="previewImage">
                <input type="image" [src]="previewImage" width="100" formControlName="imageId">

              </div>
            </div>
            <div class="form-group">
              <div class="editor-container" style="background-color: white !important;">
                <div formControlName="text" [ngxSummernote]="config" (mediaDelete)="onDelete($event)"></div>
              </div>
              <div class="form-group col-sm-12 pull-left" *ngIf="submitted && articleForm.controls?.text?.invalid">
                <div class="alert alert-danger" role="alert" *ngIf="articleForm.controls.text.errors.required">
                  <b>The Text Field is required</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right" mat-dialog-actions align="end">
          <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Save</button>
          <a class="btn btn-warning" (click)="cancel()"><i class="fa fa-remove"></i> Cancel</a>
        </div>
      </div>
    </form>
  </div>
</div>
