import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CONSTANTS } from 'src/app/const';
import { Validators, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, FormControl } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
import { StatesService } from 'src/app/services/states.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { AccountService } from 'src/app/services/account.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { formatDate } from '@angular/common';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { fromEventPattern } from 'rxjs';

const RequireOneValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  const homePhone = fg.get('homePhone').value;
  const workPhone = fg.get('workPhone').value;
  const cellPhone = fg.get('mobilePhone').value;
  return homePhone || workPhone || cellPhone ? null : {reqiredOne: true};
};

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChildrenComponent implements OnInit {

  _orders = [];
  get orders() {
    return this._orders;
  }
  set orders(newOrders) {
    this._orders = newOrders;
  }
  titles = ['Sr.', 'Jr.', 'I', 'II', 'III'];
  addNewChild = false;
  profileList;
  newChildForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    middleName: ['', Validators.required],
    lastName: ['', Validators.required],
    title: '',
    gender: ['', Validators.required],
    birthDate: ['', Validators.required],
    consentStatus: 0,
    homeAddress: this.formBuilder.group({
      addressLine: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required]
    })
  });
  stateList$;
  selectedChild;

  constructor(
    private profileService:ProfileService,
    private accountService:AccountService,
    private formBuilder: UntypedFormBuilder,
    private router:Router,
    private sessionInfoStorage:SessionInfoStorageService,
    private statesService: StatesService,
    private modalService: ModalService) {

    }

  addChild(flag) {
    this.newChildForm.reset();
    this.addNewChild = flag;
    this.selectedChild = null;
  }
  trackByFn(index, item) {
    return index; // or item.id
  }

  selectChild(profile) {
    this.profileService.getProfile(profile.id).subscribe(result => {
      this.selectedChild = result;
      Object.keys(result).forEach(key => {
        if(this.newChildForm.controls[key] && key !== 'homeAddress') {
          this.newChildForm.controls[key].setValue(result[key])
        }
      });
      Object.keys(result.homeAddress).forEach(key => {
        if(this.newChildForm.controls.homeAddress['controls'][key]) {
          this.newChildForm.controls.homeAddress['controls'][key].setValue(result.homeAddress[key])
        }
      });
      this.newChildForm.controls['consentStatus'].setValue(+result.consentStatus);
      this.newChildForm.controls['gender'].setValue(''+result.gender);
      this.newChildForm.controls['title'].setValue(this.titles[result.title]);
    })

  }

  createNewChild() {
    const dataToSend = this.newChildForm.getRawValue();
    dataToSend.consentStatus = +dataToSend.consentStatus;
    dataToSend.gender = +dataToSend.gender;
    dataToSend.homeAddress.state = dataToSend.homeAddress.state.value;
    dataToSend.title = this.titles.findIndex(el => el === dataToSend.title);
    this.profileService.createChildProfile(dataToSend);
  }

  toggleConsent() {
    this.newChildForm.controls['consentStatus'].setValue(+(!this.newChildForm.value.consentStatus))
  }

  updateChild() {
    const dataToSend = {...this.selectedChild, ...this.newChildForm.getRawValue()};
    dataToSend.consentStatus = +dataToSend.consentStatus;
    dataToSend.gender = +dataToSend.gender;
    if(dataToSend.homeAddress.state.value) {
      dataToSend.homeAddress.state = dataToSend.homeAddress.state.value;
    }
    dataToSend.title = this.titles.findIndex(el => el === dataToSend.title);
    this.profileService.updateChildProfile(dataToSend);
  }

  refreshProfileList() {
    this.profileService.getProfiles((profiles) => {
      this.profileList = profiles.filter((el) => el.isChild);
    });
  }
  ngOnInit() {
    this.stateList$ = this.statesService.states$;
    this.refreshProfileList();
  }
}

