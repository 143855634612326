import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesStorageService } from '../../services/categories-storage.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  tests = [];
  categoryName = '';
  categories;
  testsCount;
  id;
  getTestsFunction = this.getCategoryTests.bind(this);
  constructor(private readonly categoryService: CategoryService, private route: ActivatedRoute,
              private router: Router, private categoriesStorage: CategoriesStorageService) {
  }

  getCategoryTests(params) {
    if(params.path === undefined){
      params.path = '&$skip=0&$top=10';
    }
    this.categoryService.getCategoryTests(params.path,params.id)
      .subscribe((tests: any) => {
        this.tests = tests.items;
        this.testsCount = tests.count;
        this.categoryName = tests.items[0].categoryName;
      });
  }

  check(category){
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.categoriesStorage.getName(this.id)
        .subscribe((name: any) => {
          this.categoryName = name;
        });
      this.categoriesStorage.getAllZero().subscribe(result => {
        this.categories = result;
        const currentCategory = result.items.map(category => {
          if(category.id === this.id){
            this.categories = category;
          } 
        })
        this.getCategoryTests({path:undefined,id:this.categories.id})
      });
    });


  }

}
