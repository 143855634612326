import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';

@Component({
  selector: 'app-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.scss']
})
export class LoginAsComponent implements OnInit {
  info;
  isDashboard;
  constructor(public sessionInfoStorageService:SessionInfoStorageService,
    public accountService:AccountService,
    public router: Router,
    public activatedRoute:ActivatedRoute,
    public notificationService: NotificationService) { }

  ngOnInit(): void {
    
    this.activatedRoute.queryParams.subscribe((params) => {
  
      this.sessionInfoStorageService.setFakeAuthInfo(params);
      sessionStorage.setItem('fakeInfo', JSON.stringify(params));
      // console.log('sesiq',sessionStorage)
      this.router.navigateByUrl('')
    })
    this.info = this.sessionInfoStorageService.getAuthInfo();
    this.sessionInfoStorageService.setFakeAuthInfo(this.info);
    this.sessionInfoStorageService.isDashboardAllowed();
  }

}
