<div class="cabinetTabs">
  <div class="container-fluid">
    <div class="actionLine">
      <button class="btn btn-default" (click)="openModal()"><i class="fa fa-plus-circle"></i>
        Add New Test
      </button>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="input-group">
          <input
            class="form-control"
            type="text"
            [formControl]="searchInput"
            id="search">
          <span class="input-group-addon">
                    <i class="glyphicon glyphicon-search"></i>
                </span>
        </div>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th>Name</th>
        <th>CPT</th>
        <!--                    <th>Product Type</th>-->
        <th class="col-md-3">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let test of tests">
        <td>
          <img src="{{ imageURL + test.imageId }}" width="50">
          {{ test.name }}
        </td>
        <td>{{test.cpt}}</td>
        <!--                    <td ng-bind="test.productType | productType"></td>-->
        <td>
          <button class="btn btn-success" (click)="openTestsPopup(test.id)">
            <i class="fa fa-pencil-square"></i>
            Edit
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

