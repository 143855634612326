<form [formGroup]="emailInfoForm" class="emailInfoForm">
<div class="cabinetTabs">
  <div class="row justify-content-end">
    <div class="col-xs-3">
    <input class="input-group" style="height: 42px;" type="text"
           placeholder="Search Email Log"
           #search
           formControlName="searchModel"
           (change)="filterEmails(search.value)"
          >
    </div>
  <div class="col-xs-3">
    <p class="input-group">
      <mat-form-field appearance="fill" class="datePicker">
        <mat-label>Date start</mat-label>
        <input formControlName="dateStart" matInput [matDatepicker]="dateStart"
               (dateChange)="dateChange()">
        <mat-datepicker-toggle matSuffix [for]="dateStart">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #dateStart></mat-datepicker>
      </mat-form-field>
    </p>
  </div>
  <div class="col-xs-3">
    <p class="input-group">
      <mat-form-field appearance="fill" class="datePicker">
        <mat-label>Date end</mat-label>
        <input formControlName="dateEnd" matInput [matDatepicker]="dateEnd"
               (dateChange)="dateChange()">
        <mat-datepicker-toggle matSuffix [for]="dateEnd">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #dateEnd></mat-datepicker>
      </mat-form-field>
    </p>
  </div>
  </div>
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <ng-container matColumnDef="from" >
    <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
    <td mat-cell *matCellDef="let emailInfo" matTooltip="{{ emailInfo.from }}">{{ emailInfo.from }}</td>
  </ng-container>
  <ng-container matColumnDef="to">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
    <td mat-cell *matCellDef="let emailInfo" matTooltip="{{ emailInfo.to }}">{{ emailInfo.to }}</td>
  </ng-container>
  <ng-container matColumnDef="subject">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
    <td mat-cell *matCellDef="let emailInfo" matTooltip="{{ emailInfo.subject }}">{{ emailInfo.subject }}</td>
  </ng-container>
    <ng-container matColumnDef="sendBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-xs-1">Send By</th>
      <td mat-cell *matCellDef="let emailInfo" matTooltip="{{ emailInfo.sendBy }}">{{ emailInfo.sendBy }}</td>
    </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-xs-1">Status</th>
    <td mat-cell *matCellDef="let emailInfo" matTooltip="{{ (emailInfo.status || 'Unknown').charAt(0).toUpperCase() + (emailInfo.status || 'Unknown').slice(1) }}">
      {{ (emailInfo.status || 'Unknown').charAt(0).toUpperCase() + (emailInfo.status || 'Unknown').slice(1) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-xs-3">Date</th>
    <td mat-cell *matCellDef="let emailInfo" matTooltip="{{ formatDate(emailInfo.date)}}">{{formatDate(emailInfo.date) }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="['from', 'to', 'subject', 'sendBy', 'status', 'date']"></tr>
  <tr mat-row *matRowDef="let emailInfo; columns: ['from', 'to', 'subject', 'sendBy', 'status', 'date']"></tr>
</table>
  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5"
                  (pageChange)="getEmails(page, $event)" [rotate]="true" [boundaryLinks]="true">
  </ngb-pagination>
</div>
</form>
