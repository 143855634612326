<div class="popup forms">

    <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="col-md-12">
        <h1 style="padding-top:0; margin-top:0;">Your order is being processed</h1>
    </div>

    <div  style="text-align:center;">
        <img src="../../../assets/img/spinner64.gif" alt="In progress">
    </div>
    <!--<div class="row text-center">-->
        <!--<button ng-click="cancel()" type="submit" class="btn btn-default">OK</button>-->
    <!--</div>-->
        
</div>