<form 
[formGroup]="categoriesForm"
submit="submit(model)">
<div class="cabinetTabs">
    <div class="container-fluid">
        <div class="actionLine">
            <!-- <button class="btn btn-default" (click)="openCategoryPopup()"><i class="fa fa-plus-circle"></i> Create New
                Category
            </button> -->
        </div>

        <div class="row">
            <div class="col-md-4">
                <label >
                <div class="input-group">
                    <input class="form-control"
                           type="text"
                           (change)="search()"
                           formControlName="search" 
                           class="form-control" 
                           id="search" 
                           tooltip-placement="left">
                    <span class="input-group-addon">
                    <i class="glyphicon glyphicon-search"></i>
                </span>
                </div>
            </label>
            </div>
        </div>

        <!-- <pagination-wrapper get-data-function="getAllCategories" total-count="count"
                            output-functions="paginatorFunctions"> -->
            <div >
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>Name</th>
                    <th class="col-md-3">Actions</th>
                </tr>
                </thead>
                <tbody>

                <tr *ngFor="let category of categories ;let $index = index">
                    <td>{{category.name}}</td>
                    <td>
                        <button class="btn btn-danger" (click)="deleteCategory(category, $index)"><i
                                class="fa fa-minus-circle"></i> Remove
                        </button>
                        <button class="btn btn-success" (click)="openCategoryPopup(category.id)"><i
                                class="fa fa-pencil-square"></i> Edit
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <ngb-pagination [collectionSize]="count" [(page)]="page" [maxSize]="5" (pageChange)="getAllCategories({$skip: (page * 10) - 10, $top:10})"[rotate]="true" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</form>