<div class="popup forms">

    <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="col-md-12">
        <h1 style="padding-top:0; margin-top:0;">{{editMode ? 'Edit' : 'Add'}} Category</h1>
    </div>

    <form class="form-horizontal" [formGroup]="categoryForm"  (ngSubmit)="save()">
        <div class="form-group">
            <label for="name" class="col-sm-3 control-label">Name *</label>
            <div class="col-sm-8">
                <input name="name" required type="text" class="form-control" id="name" minlength="6" maxlength="255"
                       placeholder="Category Name" formControlName="name">
            </div>
        </div>
            <!-- <label>
            First Name:
            <input type="text" formControlName="name">
            </label> -->
        <!-- <div class="form-group col-sm-12 pull-right" ng-cloak
             ng-show="categoryForm.name.$touched && categoryForm.name.$invalid">
            <div class="alert alert-danger" role="alert" ng-show="categoryForm.name.$error.required"><b>Error:</b> Enter
                Category name
            </div>
            <div class="alert alert-danger" role="alert" ng-show="categoryForm.name.$error.minlength"><b>Error:</b>
                Minimum length 6 symbols
            </div>
        </div> -->
        <div class="form-group">
            <label for="desc" class="col-sm-3 control-label">Description</label>
            <div class="col-sm-8">
                <textarea id="desc" class="form-control" rows="3" maxlength="4000"
                          formControlName="description"></textarea>
            </div>
        </div>

        <div class="form-group">
            <label class="col-sm-3 control-label">Image 1</label>
            <div class="col-sm-8"  >
                <!-- <file-field accept="image/*" class="btn btn-info" ng-model="uploadFile"
                            ng-class="{'btn-success':uploadFile.name}" preview="previewImage"><i
                        class="fa fa-folder-open-o"></i> Browse
                </file-field> -->
                <app-file-uploader preview="previewImage" class="{btn-success:}" ></app-file-uploader>
                <button type="button"
                        class="btn btn-info"
                        [disabled]="false"
                        browse-images
                        (click)="openGallery()"
                        browse-images-selected="model.image1Id"
                        browse-images-preview="previewImage"
                        browse-images-on-selected="uploadFile = {}">
                    <i class="fa fa-file-image-o"></i> Gallery
                </button>
                <br>
                <br>
                <!-- <div [hidden]="uploadFile.name || model.image1Id">
                    <img src="{{previewImage ? previewImage : imageURL + model.image1Id}}" width="100">
                    <div [hidden]="uploadFile.name">
                        <h6 [innerHtml]="uploadFile.name" style="color: white;"></h6>
                        <button type="button" class="btn btn-default" (click)="uploadFileImage('image1Id', uploadFile)"><i
                                class="fa fa-upload"></i> <span class="hidden-xs">Upload</span></button>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="form-group">
            <label class="col-sm-3 control-label">Image 2</label>
            <div class="col-sm-8" >
                <app-file-uploader></app-file-uploader>
                <button type="button"
                        class="btn btn-info"
                        [disabled]="false"
                        (click)="openGallery()"
                        browse-images
                        browse-images-selected="model.image2Id"
                        browse-images-preview="previewImage2"
                        browse-images-on-selected="uploadFile2 = {}">
                    <i class="fa fa-file-image-o"></i> Gallery
                </button>
                <br>
                <br>
            </div>
        </div>

        <div class="form-group">
            <label class="col-sm-3 control-label"></label>
            <div class="col-sm-8">
                <div class="checkbox">
                    <input id="remember" class="styled" type="checkbox" formControlName="type" ng-true-value="1"
                           ng-false-value="0">
                    <label for="remember">
                        Show in carousel at homepage
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="col-sm-3 control-label">&nbsp;</label>
            <div class="col-sm-8">
                <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Save</button>
                <a class="btn btn-warning" (click)="cancel()"><i class="fa fa-remove"></i> Cancel</a>
            </div>
        </div>

    </form>

</div>
