export enum NotificationType { Info, Success, Error, Warning, Block }

export interface Notification {
    message: string;
    type?: NotificationType;
    typeValue?: string;
    contact?: boolean;
    autoHide?: boolean;
    hideDelay?: number;
    isHtml?: boolean;
    allowClose?: boolean;
    hideOnHover?: boolean;
}
