<!-- User Information -->
<div class="row cabinetTabs">
  <form [formGroup]="accountForm" name="form" class="form-horizontal" (ngSubmit)="update()">
    <fieldset [disabled]="changeMode">
      <!-- first column of registration -->
      <div class="col-sm-6">
        <!-- First Name -->
        <!-- <div class="form-group">
                  <label for="firstName" class="col-sm-3 control-label">First Name *</label>
                  <div class="col-sm-9">
                      <input formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                          id="firstName" placeholder="First Name">
                  </div>
              </div >
              <div class="form-group col-sm-12 pull-right" [hidden]="submitted">
                  <div class="alert alert-danger" role="alert" *ngIf="f.firstName.errors?.required && f.firstName.dirty || f.firstName.touched"><b>Error:</b>
                      Please add a first name.</div>
              </div> -->
        <div class="form-group">
          <label for="firstNameid" container="body" class="col-sm-3 control-label">First Name *</label>
          <div class="col-sm-9" (click)="toggleWithGreeting(t1)" (mouseover)="t1.show()" (mouseout)="t1.hide()"
            matTooltipPosition="below" #t1="matTooltip" matTooltipTouchGestures="on"
            [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' ">
            <input name="firstName" formControlName="firstName" type="text" class="form-control" id="firstNameid"
              placeholder="First Name">
          </div>
          <div class="form-group col-sm-12 pull-right"
            *ngIf="accountForm.controls.firstName.touched && accountForm.controls.firstName.invalid">
            <div class="alert alert-danger">
              <b>Error:</b> Please add a first name.
            </div>
          </div>


        </div>

        <!-- Middle Name -->
        <div class="form-group">
          <label for="mnameid" class="col-sm-3 control-label">Middle Name</label>
          <div class="col-sm-9" (click)="toggleWithGreeting(t2)" (mouseover)="t2.show()" (mouseout)="t2.hide()"
            matTooltipPosition="below" #t2="matTooltip" matTooltipTouchGestures="on"
            [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' ">
            <input formControlName="middleName" type="text" class="form-control" id="mnameid" placeholder="Middle Name">
          </div>
        </div>


        <div class="form-group">
          <label for="lnameid" class="col-sm-3 control-label" container="body">Last Name *</label>
          <div class="col-sm-9" (click)="toggleWithGreeting(t3)" (mouseover)="t3.show()" (mouseout)="t3.hide()"
            matTooltipPosition="below" #t3="matTooltip" matTooltipTouchGestures="on"
            [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' ">
            <input name="lastName" formControlName="lastName" type="text" class="form-control" id="lnameid"
              placeholder="Last Name">
          </div>
        </div>

        <div class="form-group col-sm-12 pull-right"
          *ngIf="accountForm.controls.lastName.touched && accountForm.controls.lastName.invalid">
          <div class="alert alert-danger">
            <b>Error:</b> Please add a last name.
          </div>
        </div>


        <div disabled="true" class="form-group bgColor padding-top">
          <label for="title" class="col-sm-3 control-label">Suffix</label>
          <div class="col-sm-9">
            <mat-form-field appearance="fill" class="maxWidth">
              <mat-select formControlName="suffix" [disabled]="changeMode">
                <mat-option *ngFor="let title of titles; let i = index" [value]="title">
                  {{title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <label for="inputEmail" class="col-sm-3 control-label">Email *</label>
          <div class="col-sm-9" (click)="toggleWithGreeting(tt5)">
            <mat-form-field class="matOver moibileWidth maxWidth" appearance="standard" (mouseover)="tt5.show()"
              (mouseout)="tt5.hide()">
              <input matInput (mouseover)="tt5.show()" (mouseout)="tt5.hide()" matTooltipPosition="below"
                matTooltipTouchGestures="on" #tt5="matTooltip"
                [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' " formControlName="email"
                type="email" class="form-control" id="inputEmail" placeholder="Email">
            </mat-form-field>
          </div>
        </div>
        <div class="form-group col-sm-12 pull-right"
          *ngIf="accountForm.controls.email.touched && accountForm.controls.email.invalid">
          <div class="alert alert-danger" *ngIf="accountForm.controls.email.errors.required">
            <b>Error:</b> Email is required
          </div>
          <div class="alert alert-danger" *ngIf="accountForm.controls.email.errors.email">
            <b>Error:</b> Please provide a valid email address
          </div>
        </div>
        <!-- Home Phone -->
        <div class="form-group">
          <label for="homePhone" class="col-sm-3 control-label">Home Phone</label>
          <div class="col-sm-9">
            <mat-form-field appearance="standard" class="matOver moibileWidth maxWidth" (mouseover)="tt4.show()"
              (mouseout)="tt4.hide()">
              <input matInput matTooltipPosition="below" matTooltipTouchGestures="on" #tt4="matTooltip"
                [matTooltip]="changeMode? homePhoneMsg : '' " phone-input name="homePhone" class="form-control"
                formControlName="homePhone" type="text" placeholder="Home Phone" id="homePhone" />
            </mat-form-field>
          </div>
        </div>

        <!-- Work Phone -->
        <div class="form-group">
          <label for="workPhone" class="col-sm-3 control-label">Work Phone</label>
          <div class="col-sm-9">
            <mat-form-field appearance="standard" class="matOver moibileWidth maxWidth" (mouseover)="tt6.show()"
              (mouseout)="tt6.hide()">
              <input matInput (mouseover)="tt6.show()" (mouseout)="tt6.hide()" matTooltipPosition="below"
                matTooltipTouchGestures="on" #tt6="matTooltip" [matTooltip]="changeMode? shareMsg : '' " phone-input
                name="workPhone" class="form-control" formControlName="workPhone" type="text" id="workPhone"
                placeholder="Work Phone" />
            </mat-form-field>
          </div>
        </div>

        <!-- Mobile / Cell Phone -->
        <div class="form-group">
          <label for="mobilePhone" class="col-sm-3 control-label">Cell Phone *</label>
          <div class="col-sm-9">
            <mat-form-field appearance="standard" class="matOver moibileWidth maxWidth" (mouseover)="tt7.show()"
              (mouseout)="tt7.hide()">
              <input matInput (mouseover)="tt7.show()" (mouseout)="tt7.hide()" matTooltipPosition="below"
                matTooltipTouchGestures="on" #tt7="matTooltip" [matTooltip]="changeMode? shareMsg : '' " phone-input
                name="mobilePhone" class="form-control" formControlName="mobilePhone" type="text" id="mobilePhone"
                placeholder="Mobile Phone" autocomplete="on" />
            </mat-form-field>
          </div>
        </div>

        <div class="form-group col-sm-12 pull-right"
          *ngIf="!accountForm.controls.workPhone.value && !accountForm.controls.mobilePhone.value && !accountForm.controls.homePhone.value">
          <div class="alert alert-danger" role="alert">
            <b>Error:</b> Please add a mobile number.
          </div>
        </div>

        <!-- Social Security Number -->
        <!-- <div class="form-group">
                  <label for="ssN" class="col-sm-3 control-label">SSN</label>
                  <div class="col-sm-9">
                      <input name="ssN" type="text" formControlName="ssN" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.ssN.errors }" id="ssN"
                      placeholder="Social Security Number" />
                  </div>
              </div> -->
      </div>

      <!-- second half of the column of registration -->
      <div class="col-sm-6">
        <!-- Gender -->
        <div class="form-group">
          <label class="col-sm-3 control-label">Gender *</label>
          <div class="col-sm-9" matTooltipPosition="below" (mouseover)="t5.show()" (mouseout)="t5.hide()">
            <fieldset>
              <div class="radio radio-info radio-inline" matTooltipTouchGestures="on" #t5="matTooltip"
                (click)="toggleWithGreeting(t5)"
                [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' ">
                <input formControlName="gender" type="radio" name="gender" [value]="2" id="female">
                <label for="female"> Female </label>
              </div>

              <div class="radio radio-info radio-inline">
                <input formControlName="gender" type="radio" name="gender" [value]="1" id="male">
                <label for="male"> Male </label>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="form-group col-sm-12 pull-right" *ngIf="accountForm.touched && accountForm.controls.gender.invalid">
          <div class="alert alert-danger">
            <b>Error:</b> Please choose a gender.
          </div>
        </div>


        <ng-template #footerTemplate>
          <hr class="my-0">
          <button class="btn btn-sm btn-danger m-2" float-left (click)="clearDate()">Clear</button>
          <button class="btn btn-primary btn-sm m-2 float-left" (click)="model = today; d.close()">Today</button>
          <button class="btn btn-success btn-sm m-2 float-right" (click)="d.close()">Close</button>
        </ng-template>

        <div class="form-group padding-top" ng-controller="DatePickerCtrl">
          <label for="inputPassword3" class="col-sm-3 control-label">Birthday *</label>
          <div class="col-sm-9" (mouseover)="t6.show()" (mouseout)="t6.hide()" (click)="toggleWithGreeting(t6)">
            <mat-form-field appearance="fill">
              <input [disabled]="changeMode || (!isAdmin && !asQuasiAdmin)" formControlName="birthDate" matInput
                [matDatepicker]="picker" matTooltipPosition="below" matTooltipTouchGestures="on" #t6="matTooltip"
                [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' ">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-12 pull-right"
            *ngIf="accountForm.controls.birthDate.touched && accountForm.controls.birthDate.invalid">
            <div class="alert alert-danger" style="margin-left: 30px;">
              <b>Error:</b> Please choose a birthday.
            </div>
          </div>
        </div>

        <div class="form-group bgColor padding-top">
          <label for="languagePreference" class="col-sm-3 control-label">Language Preference *</label>
          <div class="col-sm-9" (mouseover)="t9.show()" (mouseout)="t9.hide()" (click)="toggleWithGreeting(t9)">
            <mat-form-field appearance="fill">
              <mat-select formControlName="languagePreference" [disabled]="changeMode" matTooltipPosition="below"
                matTooltipTouchGestures="on" #t9="matTooltip" [matTooltip]="changeMode? shareMsg : '' ">
                <mat-option *ngFor="let language of languagePreference" [value]="language.id">
                  {{language.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
        <!-- <div class="form-group col-sm-12 pull-right" >
                  <div class="alert alert-danger" role="alert" *ngIf="form.date.$error.required"><b>Error:</b>
                      Please add a birthday(MM/DD/YYYY). </div>
                  <div class="alert alert-danger" role="alert" *ngIf="form.date.$invalid"><b>Error:</b> Please
                      enter the date in the MM/DD/YYYY format. </div>
                  <div class="alert alert-danger" role="alert" *ngIf="form.date.$error.eighteenYears"><b>Error:</b>
                      You must be at least 18 years old. </div>
              </div> -->

        <div class="addressIndicator">

          <!-- Address -->
          <div class="form-group">
            <label for="address" class="col-sm-3 control-label">Address *</label>
            <div class="col-sm-9" (mouseover)="t7.show()" (mouseout)="t7.hide()" (click)="toggleWithGreeting(t7)">
              <input matTooltipPosition="below" matTooltipTouchGestures="on" #t7="matTooltip"
                [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' "
                type="text" formControlName="addressLine" class="form-control" id="address"
                [ngClass]="{ 'is-invalid': submitted && f.address.errors }" [disabled]="homeCopy" type="text"
                placeholder="Address" />
            </div>
          </div>

          <div class="col-sm-12 pull-right"
            *ngIf="accountForm.controls.addressLine.touched && accountForm.controls.addressLine.invalid"
            style="padding-right: 0px;">
            <div class="alert alert-danger" style="margin-left: 30px;">
              <b>Error:</b> Please choose an address.
            </div>
          </div>
          <!-- <div class="form-group">-->
          <!--<div class="col-sm-9 col-sm-offset-3">-->
          <!--<input ng-model="uc.model.addressLine2" type="text" class="form-control" placeholder="Address Line 2"/>-->
          <!--</div>-->
          <!--</div> -->

          <!-- City -->
          <div class="form-group">
            <label for="city" class="col-sm-3 control-label">City *</label>
            <div class="col-sm-9" (mouseover)="t8.show()" (mouseout)="t8.hide()" (click)="toggleWithGreeting(t8)">
              <input matTooltipPosition="below" matTooltipTouchGestures="on" #t8="matTooltip"
                [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' "
                name="city" class="form-control" type="text" formControlName="city" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.city.errors }" placeholder="City" />
            </div>
          </div>

          <div class="col-sm-12 pull-right"
            *ngIf="accountForm.controls.city.touched && accountForm.controls.city.invalid" style="padding-right: 0px;">
            <div class="alert alert-danger" style="margin-left: 30px;">
              <b>Error:</b> Please choose a city.
            </div>
          </div>

          <!-- <div class="form-group col-md-12 pull-right" >
                      <div class="alert alert-danger" role="alert" *ngIf="f.city.errors?.required && f.city.touched && f.city.dirty"><b>Error:</b>
                          Please fill at least one city field
                      </div>
                  </div> -->

          <div class="form-group bgColor">
            <label for="state" style="margin-right: 15px;" class="col-sm-3 control-label">State *</label>
            <mat-form-field appearance="fill" (mouseover)="t9.show()" (mouseout)="t9.hide()"
              (click)="toggleWithGreeting(t9)">
              <mat-label>State</mat-label>
              <mat-select formControlName="state" [disabled]="changeMode || (!isAdmin && !asQuasiAdmin)"
                matTooltipPosition="below" matTooltipTouchGestures="on" #t9="matTooltip"
                [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' ">
                <mat-option *ngFor="let state of stateList$ | async" [value]="state.value">
                  {{state.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>



          <div class="col-sm-12 pull-right"
            *ngIf="accountForm.controls.state.touched && accountForm.controls.state.invalid"
            style="padding-right: 0px;">
            <div class="alert alert-danger" style="margin-left: 30px;">
              <b>Error:</b> Please choose a state.
            </div>
          </div>
          <!-- Zip -->
          <div class="form-group">
            <label for="zip" class="col-sm-3 control-label">Zip *</label>
            <div class="col-sm-9" (mouseover)="t10.show()" (mouseout)="t10.hide()" (click)="toggleWithGreeting(t10)">
              <input matTooltipPosition="below" matTooltipTouchGestures="on" #t10="matTooltip"
                [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' "
                name="zip" [disabled]="homeCopy" class="form-control" type="text" formControlName="zip"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" minlength="5" maxlength="5"
                numbers-only type="text" id="zip" placeholder="Zip" />
            </div>
            <div class="col-sm-12 pull-right"
              *ngIf="accountForm.controls.zip.touched && accountForm.controls.zip.invalid"
              style="padding-right: 0px; margin-top: 15px;">
              <div class="alert alert-danger" style="margin-left: 45px; margin-right: 15px;">
                <b>Error:</b> Please choose a zip code.
              </div>
            </div>
          </div>

          <!-- <div class="form-group col-md-12 pull-right" >
                      <div class="alert alert-danger" role="alert" *ngIf="form.zip.$error.required"><b>Error:</b>
                          Please add your zip code.</div>
                      <div class="alert alert-danger" role="alert" *ngIf="form.zip.$error.minlength"><b>Error:</b>
                          Zip must be 5 digits.</div>
                  </div> -->
        </div>
      </div>
    </fieldset>

    <div class="form-group">
      <div class="col-sm-12 text-right">
        <div class="col-sm-12 text-right mobileFlex"  >
          <div style="display: inline-block;" (mouseover)="t20.show()" (mouseout)="t20.hide()" (click)="toggleWithGreeting(t20)">
            <a (click)="changeEmail()" class="btn btn-warning bot5 order2" matTooltipPosition="below" matTooltipTouchGestures="on" #t20="matTooltip"
            [matTooltip]="changeMode || (!isAdmin && !asQuasiAdmin)? 'To make changes to this information, please send a request to support@grassrootslabs.com.' : '' "
          >
          <i class="fa fa-envelope-o"></i> Change Email
          </a>
          </div>
          <a (click)="changePw()" class="btn btn-warning bot5 order3"><i class="fa fa-key"></i> Change Password</a>
          <button [hidden]="changeMode" *ngIf="!changeMode" type="submit"
            class="btn btn-primary bot5 order4">UPDATE</button> <!-- ng-disabled="form.$invalid"  -->
          <button (click)="enterToChangeMode()" [hidden]="!changeMode" *ngIf="changeMode"
            class="btn btn-primary bot5 order1">Edit Account Information</button>
          <a (click)="leaveChangeMode()" class="btn btn-default bot5 order5">CANCEL</a>
        </div>
      </div>
    </div>
  </form>
</div>
