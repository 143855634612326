<!-- End Google Tag Manager (noscript) -->
<!-- Header Part -->
<mat-progress-bar mode="indeterminate" *ngIf="loading" style="height: 20px; position: absolute; z-index: 1000;"></mat-progress-bar>
<div class="container containerHeader">
  <app-header></app-header>
</div>
<!-- Header Part -->
<div style="color: darkmagenta; font-size: 12px; font-weight: bolder;position: fixed;bottom:0;right:0;z-index: 1">{{version}}</div>
<!-- admin bar  desktop-->
<div class="visible-xs">
  <!-- <app-admin></app-admin> -->
</div>
<!-- admin bar -->

<!-- Menu bar -->
<div class="container containerNavigation">
  <app-navigation></app-navigation>
</div>
<!-- Menu bar -->

<!-- admin bar xs-->
<!-- <div class="hidden-xs">
  <div class="container">
    <app-admin></app-admin>
  </div>
</div> -->
<!-- admin bar -->


<router-outlet></router-outlet>

<!-- Footer -->
<app-footer></app-footer>
<!-- Footer Ends -->

<app-notification-bar class="notifications" closeIcon="fa fa-times-circle"></app-notification-bar>


