import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationBarService } from '../thirdParty/ngx-notification-bar/notification-bar.service';
import { NotificationType } from '../thirdParty/ngx-notification-bar/index';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  constructor(private notifications: NotificationBarService){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<any> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.status === 404) {
            errorMessage = 'Not Found';
          }
          // console.log('asdasdasd',error)
          if (error.error.message) {
            errorMessage += error.error.message;
          }
          if (error.error.error_description) {
            errorMessage += error.error.error_description;
          }
          if (error.error.ErrorInfo) {
            errorMessage += error.error.ErrorInfo + '  ';
          }
          if (error.error.errorMessage &&  error.error.errorMessage.indexOf('_') === -1 && request.body) {
            errorMessage += request.body.confirmPassword && request.body.newPassword? 'Error.' : error.error.errorMessage;
          }
          if (error.error.errorDescription && request.body) {
            errorMessage += '<br/>';
            errorMessage += request.body.confirmPassword && request.body.newPassword? 'This password has been used previously. Please create a new password.' : error.error.errorDescription;
          }
          if (error.error.validationErrors) {
              errorMessage ='';
            Object.values(error.error.validationErrors).forEach((message:string) => {
              errorMessage += message;
            });
          }
          if (error.error.modelState) {
            errorMessage += '<code>';
            let counter = 0;
            error.error.modelState.forEach((message) => {
              if (counter > 0) {
                errorMessage += '<br/>';
              }
              errorMessage += message;
              counter ++;
            });
            errorMessage += '</code>';
          }
          !request.url.includes('Token') && this.notifications.create({ message: errorMessage, type: NotificationType.Error});
          if(typeof error.error === 'string'){
            localStorage.setItem('code', error.error);
          }else{
            localStorage.removeItem('code');
          }
          return throwError(errorMessage);
        })
      );
  }
}
