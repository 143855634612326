import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-idle',
  templateUrl: './idle.component.html',
  styleUrls: [ './idle.component.scss' ]
})
export class IdleComponent {
  totalSeconds: number | string;

  constructor(private accountService: AccountService,
    private titleService: Title,
    public router: Router,
    public dialogRef: MatDialogRef<any>,) {
  }

  timer(): string {
    this.totalSeconds = 1800 - 1;
    let minutes: any = Math.floor(this.totalSeconds / 60);
    let seconds: any = this.totalSeconds % 60;


    minutes = String(minutes).padStart(2, '0');
    seconds = String(seconds).padStart(2, '0');
    // this.totalSeconds = `${minutes}:${seconds}`;
    this.totalSeconds = `${ minutes }`;
    return this.titleService.getTitle();
  }

  cancel() {
    this.dialogRef.close();
  }
  logout(): void {
    this.router.navigateByUrl('');
    this.accountService.logout();
  }
}

