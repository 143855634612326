import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {KeapEmailTemplate} from '../entities/keapEmailTemplate/KeapEmailTemplate';

@Injectable({
  providedIn: 'root'
})
export class KeapMailTemplateService {
  constructor(private http: HttpClient) { }
  getAllTemplates(): Observable<KeapEmailTemplate[]>{
    return this.http.get<KeapEmailTemplate[]>(environment.base +  `/api/keap/template/get/all`);
  }
  getTemplateById(id: string): Observable<KeapEmailTemplate[]>{
    return this.http.get<KeapEmailTemplate[]>(environment.base +  `/api/keap/template/${id}`);
  }
  addKeapMailTemplate(model: KeapEmailTemplate): Observable<string>{
    return this.http.post<string>(environment.base + `/api/keap/template/add`, model);
  }
  updateKeapMailTemplate(model: KeapEmailTemplate): Observable<string>{
    return this.http.put<string>(environment.base + `/api/keap/template/update`, model);
  }
  removeKeapMailTemplate(id: string): Observable<string> {
    return this.http.delete<string>(environment.base + `/api/keap/template/delete/${id}`);
  }
  subscribeBlog(email: string): Observable<string> {
    return this.http.get<string>(environment.base + `/api/keap/subscribe?email=${email}`);
  }

}
