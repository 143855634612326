import {Injectable} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {CookiesStorageService} from './cookies-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionInfoStorageService {
  isDashboard = null;
  authInfo = null;
  fakeInfo = null;

  constructor(
    public $sessionStorage: SessionStorageService,
    public $localStorage: LocalStorageService,
    public cookiesStorageService: CookiesStorageService) {
  }

  loadAuthInfo() {
    if (this.$sessionStorage.retrieve('auth')) {
      this.authInfo = this.$sessionStorage.retrieve('auth');
    } else {
      this.authInfo = this.cookiesStorageService.getAuth();
      this.setAuthInfo(this.authInfo);
    }
    if(!this.authInfo.languagePreference){
      localStorage.removeItem('languagePreference');
    }
  }

  getToken() {
    if (this.fakeInfo) {
      return this.fakeInfo.access_token;
    }

    if (!this.authInfo) {
      this.loadAuthInfo();
    }
    return this.authInfo ? this.authInfo.access_token : null;
  }

  isDashboardAllowed() {
    this.isDashboard = this.isAdmin() || this.isQuasiAdmin();
    return this.isDashboard
  }

  getAuthInfo() {
    if (this.fakeInfo) {
      return this.fakeInfo;
    }

    if (!this.authInfo) {
      this.loadAuthInfo();
    }
    return this.authInfo;
  }

  clearToken() {
    this.authInfo = null;
    this.fakeInfo = null;
  }

  getProfileId() {
    if (this.fakeInfo) {
      return this.fakeInfo.profileId;
    }

    if (!this.authInfo) {
      this.loadAuthInfo();
    }
    return this.authInfo.profileId;
  }

  getUserId() {
    if (!this.authInfo) {
      this.loadAuthInfo();
    }
    return this.authInfo.userId;
  }

  setAuthInfo(info) {
    this.$sessionStorage.store('auth', info);
  }

  setFakeAuthInfo(info) {

    this.fakeInfo = info;
  }

  cleanAuthInfo() {
    this.$sessionStorage.clear('auth');
    this.fakeInfo = null;
  }

  setOrderInfo(model) {
    this.$localStorage.store('orderInfo', model);
  }

  getOrderInfo() {
    if(this.fakeInfo){
      return this.fakeInfo
    }
    return this.$localStorage.retrieve('orderInfo') || {};
  }

  setPromoCode(model) {
    this.$localStorage.store('promoCode', model);
  }

  getPromoCode() {
    return this.$localStorage.retrieve('promoCode') || {};
  }

  cleanPromoCode() {
    this.$localStorage.clear('promoCode');
  }

  cleanOrderInfo() {
    this.$localStorage.clear('orderInfo');
  }

  isAdmin() {

    if (this.fakeInfo) {
      return String(this.fakeInfo.isAdmin).toLowerCase() === 'true';
    }
    if (!this.authInfo) {
      this.loadAuthInfo();
    }
    return String(this.authInfo.isAdmin).toLowerCase() === 'true';
  }

  isQuasiAdmin() {
    if (this.fakeInfo) {
      return String(this.fakeInfo.isQuasiAdmin).toLowerCase() === 'true';
    }
    if (!this.authInfo) {
      this.loadAuthInfo();
    }
    return String(this.authInfo.isQuasiAdmin).toLowerCase() === 'true';
  }

  asQuasiAdmin() {
    if (this.fakeInfo) {
      return String(this.fakeInfo.asQuasiAdmin).toLowerCase() === 'true';
    }
    return false;
  }
}
