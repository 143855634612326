import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.scss']
})
export class RegistrationSuccessComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LoginComponent>,
              private router: Router) { }

  ngOnInit(): void {
  }
  cancel() {
    this.router.navigate([ '/' ]);
    this.dialogRef.close();
  }

}
