<h1>Do any of the following statements apply to you?</h1>
<div>
  <div class="col-md-7 panel panel-primary btn btn-primary" style="width: 90%;margin-left: 25px; margin-bottom: 20px"
       (click)="screeningQuestion3Click('option1')">
    <div class="panel-heading" style="white-space: pre-line; ">Option 1</div>
    <div class="panel-body" style="white-space: pre-line; ">
      <ul style="text-align: left; ; padding-inline-start:10px">
        <li *ngFor="let item of thirdGroup"> {{item.Name}}</li>
      </ul>
    </div>
  </div>
  <div class="col-md-3 panel panel-primary btn btn-primary" style="width: 90%;margin-left: 25px; margin-bottom: 20px"
       (click)="screeningQuestion3Click('option2')">
    <div class="panel-heading" style="white-space: pre-line">Option 2</div>
    <div class="panel-body"
         style="white-space: pre-line; font-size: 18px; height: 94%; display: flex; align-items: center;">
      None of these apply to me.
    </div>
  </div>
</div>
