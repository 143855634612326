import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImageService } from 'src/app/services/image.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-browse-image',
  templateUrl: './browse-image.component.html',
  styleUrls: ['./browse-image.component.scss']
})
export class BrowseImageComponent implements OnInit {
  imageURL = environment.baseIconUrl;
  isLoadMore = true;
  images = {
    isLoadMore: false
};
  params = {
      $top: 9,
      $skip: 0
  };
  count: any;

  constructor(
    private imageService: ImageService,
    public dialogRef: MatDialogRef<BrowseImageComponent>,
  ) { }

  ngOnInit(): void {
    this.getImages()
  }
  

  activate() {
      this.getImages();
  }

  // getImages() {

  //   if(this.count){
  //   this.params.$skip += this.params.$top;
  //   if (this.params.$skip < this.count) {
  //     this.isLoadMore = true;
  //   } else {
  //       this.isLoadMore = false;
  //   }
  // }
  //     this.imageService.getImages(this.params).subscribe(
  //         (response) => {
  //           if(response.items === 0){
  //             return this.images;
  //           }
  //           this.images = response.items;
  //           this.count = response.count;

            

  //           if (!this.images) {
  //               this.images = response.items;
  //               return;
  //           }

  //         }
  //     );
  // }

  getImages() {
    this.imageService.getImages(this.params).subscribe(
        (response)=> {
            this.images['count'] = response.count;
            
            this.params.$skip += this.params.$top;

            if (this.params.$skip < this.images['count']) {
                this.images.isLoadMore = true;
            } else {
                this.images.isLoadMore = false;
            }

            if (!this.images['items']) {
                this.images['items'] = response.items;
                return;
            }

            this.images['items'] = this.images['items'].concat(response.items);
        }
    );
}

  add(image) {
      this.dialogRef.close({
          image: image.id,
          preview: this.imageURL + image.id
      });
  }

  loadMore() {
      this.getImages();
  }

  cancel() {
  this.dialogRef.close('cancel');
  }
}
