<h1>Please select the statement that best describes your symptoms.</h1>
<div class="row">
  <div class="col-md-12 panel-body">
    <button class="btn btn-primary covid" (click)="eligibleClick(999)">
      <img src="../../../assets/img/tempereture.png"/>
      <span>
        I have had a new or continuous fever of greater than 100.5°F within the last 72 hours.
      </span>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-md-12 panel-body">
    <button class="btn btn-primary covid" (click)="eligibleClick(999)">
      <img src="../../../assets/img/worse.png"/>
      <span>
        I have started to have new or worsening symptoms of COVID-19 within the last 10 days (cough, trouble breathing, loss of smell, vomiting or diarrhea, fatigue, lightheadedness or dizziness, etc.).
      </span>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-md-12 panel-body">
    <button class="btn btn-primary covid" (click)="eligibleClick(1)">
      <img src="../../../assets/img/happy.png">
      <span>
        I have not had any symptoms of COVID-19 OR I had symptoms, but they have been improving over the last 10 days.
      </span>
    </button>
  </div>
</div>
