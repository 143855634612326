<div class="popup forms" fxFlex="1 1 auto" fxLayout="column">

    <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="logo col-md-12">
      <div class="col-md-3">
        <img src="../../../assets/img/logo_white.png" alt="grassroots logo" class="img-responsive"/>
      </div>
      <div class="col-md-7">
        <h1>Add new Page</h1>
      </div>
    </div>
    <div fxFlex="1 1 100%" flexLayout="column" style="clear: left; height: 900px;">
      <ng-scrollbar class="registered-theme"
                    thumbClass="blue-scrollbars"
                    track="vertical">
        <form name="form" class="form-horizontal" (submit)="data.name ? updatePage() : addNewPage()" [formGroup]="newPageForm" novalidate="novalidate" autocomplete="off">
          <div>
          <div class="col-sm-12">

            <!-- Name -->
            <div class="form-group">
              <label for="name" container="body" class="col-sm-2 control-label">Name</label>
              <div class="col-sm-10">
                <input name="name" formControlName="name" type="text" class="form-control" id="name" placeholder="Name">
              </div>
            </div>

            <!-- Parent -->
            <div class="form-group">
                <label for="parent" class="col-sm-2 control-label">Parent</label>
                <div class="col-sm-10">
                  <ng-select [items]="parents" class="ui-select form-control"
                            id="parent"
                            placeholder="Parent"
                            formControlName="parent"
                            [clearable]="false">
                  </ng-select>
                </div>
            </div>

            <!-- isLink -->
            <div class="form-group">
              <label for="isLink" class="col-sm-2 control-label">Is Link</label>
              <div class="col-sm-10">
                <input formControlName="isLink" type="checkbox" name="isLink" value="1" id="isLink" style="width: 25px; height: 25px;">
              </div>
            </div>

            <!-- Combobox -->
            <div class="form-group">
                <!-- <label for="combobox" class="col-sm-2 control-label">ComboBox</label>
                <div class="col-sm-10">
                  <input name="combobox" formControlName="combobox" type="text" class="form-control" id="combobox" placeholder="Combobox">
                </div> -->
                <label for="combobox" class="col-sm-2 control-label">ComboBox</label>
                <div class="col-sm-10">
                  <ng-select [items]="comboboxes" class="ui-select form-control"
                            id="combobox"
                            placeholder="Combobox"
                            formControlName="combobox"
                            [clearable]="false">
                  </ng-select>
                </div>
              </div>

            <!-- Content -->
            <div class="form-group">
                <label for="content" class="col-sm-2 control-label">Content</label>
                <div class="col-sm-10" style="height: 20vh">
                  <!-- <input name="content" formControlName="content" type="text" class="form-control" id="content" placeholder="Content" style="height: 100%;"> -->
                  <textarea name="content" formControlName="content" type="text" class="form-control" id="content" placeholder="Content" style="height: 100%;"></textarea>
                </div>
            </div>

            <!-- Description -->
            <div class="form-group">
                <label for="description" class="col-sm-2 control-label">Description</label>
                <div class="col-sm-10">
                  <input name="description" formControlName="description" type="text" class="form-control" id="description" placeholder="Description">
                </div>
            </div>
            <div class="form-group">
              <label for="description" class="col-sm-2 control-label">Slug</label>
              <div class="col-sm-10">
                <input name="slug" formControlName="slug" type="text" class="form-control" id="slug">
              </div>
            </div>
            <div class="form-group">
              <label for="metaDescription" class="col-sm-2 control-label">Meta description</label>
              <div class="col-sm-10">
                <textarea
                  class="form-control"
                  type="text"
                  maxlength="300"
                  formControlName="metaDescription"
                  class="form-control"
                  id="metaDescription"></textarea>
              </div>
            </div>

            <div class="form-group">
              <label for="pageTitle" class="col-sm-2 control-label">Page title</label>
              <div class="col-sm-10">
                <input
                  class="form-control"
                  type="text"
                  maxlength="300"
                  formControlName="pageTitle"
                  class="form-control"
                  id="pageTitle">
              </div>
            </div>

            <div class="form-group">
              <label for="metaRobots" class="col-sm-2 control-label">Meta robots</label>
              <div class="col-sm-10">
                <input
                  class="form-control"
                  type="text"
                  maxlength="300"
                  formControlName="metaRobots"
                  class="form-control"
                  id="metaRobots">
              </div>
            </div>
            <!-- Is Active -->
            <div class="form-group">
              <label for="isActive" class="col-sm-2 control-label">Is Active</label>
              <div class="col-sm-10">
                <input formControlName="isActive" type="checkbox" name="isActive" value="1" id="isActive" style="width: 25px; height: 25px;">
              </div>
            </div>

            <!-- Icon Name -->
            <div class="form-group">
                <label for="iconName" class="col-sm-2 control-label">Icon Name</label>
                <div class="col-sm-10">
                  <input name="iconName" formControlName="iconName" type="text" class="form-control" id="iconName" placeholder="Icon Name">
                </div>
            </div>


            <!-- Position -->
            <div class="form-group">
                <label for="position" class="col-sm-2 control-label">Position</label>
                <div class="col-sm-10">
                  <input name="position" formControlName="position" type="text" class="form-control" id="position" placeholder="Position">
                </div>
            </div>
          </div>

            <div class="col-sm-12">
                <div class="form-group">
                <div class="col-sm-12 text-right">
                    <button type="submit" class="btn btn-default" [disabled]="newPageForm.invalid">{{data.name ? 'Update' : 'Create'}}</button>
                </div>
                </div>
            </div>
          </div>
        </form>
      </ng-scrollbar>
    </div>
    <div class="clearfix"></div>
  </div>

