import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {BlogService} from "../../services/blog.service";
import {BlogArticle} from "../../entities/blog/BlogArticle";
import {DomSanitizer, Meta, SafeHtml, Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import * as LZUTF8 from "lzutf8";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ModalService} from "../../services/modal.service";
import {KeapMailTemplateService} from "../../services/keap-mail-template.service";

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})

export class BlogPostComponent implements OnInit {
  isActiveForm: boolean = true;
  subscribeForm: UntypedFormGroup;
  _slug;
  defaultTitle = 'Grassroots Labs - Blood Tests are Expensive. We fix that.';
  imageURL = environment.baseImageUrl;
  article: BlogArticle;
  constructor(  private route: ActivatedRoute,
                private blogService: BlogService,
                private sanitizer: DomSanitizer,
                private metaService: Meta,
                private titleService: Title,
                private modalService: ModalService,
                private keapMailTemplateService: KeapMailTemplateService) {
  }

  ngOnInit(): void {
    this._slug =  this.route.snapshot.params.slug;
    this.blogService.getArticleBySlug(this._slug).subscribe(data => {
      this.article = this.decompressArticleContent(data);
    })

    this.subscribeForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
  }

  onSubmit() {
    if (this.subscribeForm.valid) {
      const email = this.subscribeForm.get('email').value;
      this.keapMailTemplateService.subscribeBlog(email).subscribe((data)=>{
        this.isActiveForm = false;
      });
    } else {
      this.modalService.showTopNotification(false, "Please enter a valid email.");
    }
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(this.defaultTitle);
    this.metaService.removeTag('name=\'description\'');
    if (environment.production) {
      this.metaService.removeTag('name=\'robots\'');
    }
    else {
      this.metaService.updateTag({ name: 'robots', content: 'nofollow, noindex' });
    }
  }

  decompressArticleContent(article: BlogArticle) {
    if (article.text && article.text.includes(`${environment.compressedData}`)) {
      article.text = article.text.replace(`${environment.compressedData}`, '');
      article.text = LZUTF8.decompress(article.text, { inputEncoding: 'StorageBinaryString' });
    }

    if (article.pageTitle && article.pageTitle.trim() !== ''){
      this.titleService.setTitle(article.pageTitle);
    }
    else{
      this.titleService.setTitle(this.defaultTitle);
    }

    if ( article.metaDescription && article.metaDescription.trim() !== '')
    {
      this.metaService.addTag({ name: 'description', content: article.metaDescription });
    }
    else{
      this.metaService.removeTag('name=\'description\'');
    }
    if ( article.metaRobots && article.metaRobots.trim() !== '')
    {
      if (environment.production) {
        this.metaService.addTag({ name: 'robots', content: article.metaRobots });
      }
      else {
        this.metaService.updateTag({ name: 'robots', content: article.metaRobots });
      }
    }
    else {
      if (environment.production) {
        this.metaService.removeTag('name=\'robots\'');
      }
      else {
        this.metaService.updateTag({ name: 'robots', content: 'nofollow, noindex' });
      }
    }
    return article;
  }

  sanitizeHTML(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}

