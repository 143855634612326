import {Component, OnDestroy, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogService } from 'src/app/services/catalog.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PwnHealthService } from 'src/app/services/pwn-health.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { Product } from 'src/app/types/product';
import { environment } from 'src/environments/environment';
import { CartService } from '../../services/cart.service';
import { ProductService } from '../../services/product.service';
import { Meta } from '@angular/platform-browser';
import { PromoCodeService } from 'src/app/services/promo-code.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: [ './product.component.scss' ]
})
export class ProductComponent implements OnInit, OnDestroy{
  imageURL = environment.baseImageUrl;
  product: Product;
  pwnItems = [];

  constructor(
    private productService: ProductService,
    private pwnHealthService: PwnHealthService,
    private route: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    private titleService: Title,
    private catalogService: CatalogService,
    private notificationService: NotificationService,
    public modalService: ModalService,
    private sessionInfoStorage: SessionInfoStorageService,
    private metaService: Meta,
    private promoCodeService: PromoCodeService,
  ) {
  }

  addToCart($event, product): void {
    let allProducts = product.children;
    allProducts = [...allProducts, product];
    // product.children.push(product)
    let allAreActive = true;
    let inActiveName = '';
    const pwnNotApproved = [];
    if (product.children.length){
      product.children.map((el, itemIndex) => {
        if (!this.pwnItems.find((item) => item.id === el.id)){
          pwnNotApproved.push(el.name + ';');
        }
      });
      // if(pwnNotApproved.length){
      //   this.notificationService.block({message: `We are sorry. Something didn't work. </br></br>Please contact GRL customer support and let them know that there was the following error: </br>Error: 101 </br>Test(s) affected: </br>${pwnNotApproved.join('</br>')}`},false);
      //   return;
      // }
    }
    // else{
    //   if(!this.pwnItems.find((item)=>item.id === product.id)){
    //     this.notificationService.block({message: `We are sorry. Something didn't work. </br></br>Please contact GRL customer support and let them know that there was the following error: </br>Error: 101 </br>Test(s) affected: </br>${product.name};`},false);
    //     return;
    //   }
    // }
    allProducts.map((result, index) => {
      this.catalogService.getProduct(result.id).subscribe((res) => {
        if (!res.isActive) {
          allAreActive = false;
          inActiveName = res.name;
          }
        if (allProducts && index === allProducts.length - 1){
          if (allAreActive){
            this.cartService.addToCart(product);
          }else{
            this.notificationService.block({message: `We are sorry, but a test or panel that you are trying to add to your cart is no longer approved for ordering. Please contact customer support for help. <br><br>Non-approved test: ${inActiveName}`}, false);
          }
        }
      });
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.productService.getProduct(id).subscribe((res: Product) => {
        if (this.isGuid(id)){
          this.router.navigateByUrl(`/product/${res.slug}`, { replaceUrl: true });
        }
        if ( res.pageTitle && res.pageTitle.trim() !== '')
        {
          this.titleService.setTitle(res.pageTitle);
        }
        if ( res.metaDescription && res.metaDescription.trim() !== '')
        {
          this.metaService.addTag({ name: 'description', content: res.metaDescription });
        }

        if ( res.metaRobots && res.metaRobots.trim() !== '')
        {
          if (environment.production) {
            this.metaService.addTag({ name: 'robots', content: res.metaRobots });
          }
          else {
            this.metaService.updateTag({ name: 'robots', content: res.metaRobots });
          }
        }
        res.processingFee = res.processingFee.replace(' $21', ' $28');
        this.product = res;
      });
    });
    this.catalogService.getPwn().subscribe((res) => {
      this.pwnItems = res;
     });

     const promoCodeId = this.route.snapshot.params.code;
    if (promoCodeId){
      this.promoCodeService.getPromoListById(promoCodeId).subscribe(response => {
        if (response) {
          this.modalService.openConfirmPromoCode({id: promoCodeId, isBlured: response.isBlured, name: response.code});
          this.sessionInfoStorage.setPromoCode(response.code);
      }
      });
    }
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=\'description\'');
    if (environment.production) {
      this.metaService.removeTag('name=\'robots\'');
    }
    else {
      this.metaService.updateTag({ name: 'robots', content: 'nofollow, noindex' });
    }
    this.titleService.setTitle('Grassroots Labs - Blood Tests are Expensive. We fix that.');
  }
  trackByFn(index, item: Product): string {
    return item.id;
  }
   isGuid(value: string): boolean  {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(value);
  }

}
