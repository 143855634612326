import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
// import { map, catchError, tap } from 'rxjs/operators';
// import { HttpErrorResponse } from '@angular/common/http';
// import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  test;
  innerWidth;
  constructor(public router: ActivatedRoute,
    public accountService: AccountService,
    public routerr: Router ) {

    const {user, security} = this.router.snapshot.params;
    const model = {
      userId: user,
      securityCode: security
    };
    this.accountService.confirm(model).subscribe(

      res => {
        this.test = res.status < 400;
        if(!this.test) {
          this.routerr.navigateByUrl('/')
        }
      }


    )


  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }



  // setConfirmation(){
  // }
}
