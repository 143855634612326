import { Component, OnInit } from '@angular/core';
import {BlogService} from "../../services/blog.service";
import {BlogArticle} from "../../entities/blog/BlogArticle";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ModalService} from "../../services/modal.service";
import {KeapMailTemplateService} from "../../services/keap-mail-template.service";

@Component({
  selector: 'app-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.scss']
})

export class BlogPostsComponent implements OnInit {
  isActiveForm: boolean = true;
  subscribeForm: UntypedFormGroup;
  imageURL = environment.baseImageUrl;
  lastArticle: BlogArticle = null;
  topArticleMonth:BlogArticle[] = [];
  topArticleAllTime:BlogArticle[] = [];
  newTopArticles: BlogArticle[] = [];
  topArticleNewest: BlogArticle[] = [];

  constructor(
    private blogService: BlogService,
    private modalService: ModalService,
    private keapMailTemplateService: KeapMailTemplateService,
    private router: Router) {}

  ngOnInit(): void {
    this.subscribeForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    this.blogService.getLastArticle().subscribe(data => {
      this.lastArticle = data;
    });
    this.blogService.getTopArticleOnMonth().subscribe(data => {
      this.topArticleMonth = data;
    });
    this.blogService.getTopArticlesOnAllTime().subscribe(data => {
      this.topArticleAllTime = data;
      this.newTopArticles = [...this.topArticleAllTime.slice(1, -1)];
    });
    this.blogService.getTopNewest().subscribe(data => {
      this.topArticleNewest = data;
    });
  }

  navigatePost(href: string){
    this.router.navigate([href]);
  }
  onSubmit() {
    if (this.subscribeForm.valid) {
      const email = this.subscribeForm.get('email').value;
      this.keapMailTemplateService.subscribeBlog(email).subscribe((data)=>{
     this.isActiveForm = false;
      });
    } else {
      this.modalService.showTopNotification(false, "Please enter a valid email.");
    }
  }
}
