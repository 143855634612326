import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ethnicity, race } from 'src/app/Modals/covid19-saliva/salivaShared/configs';

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: [ './covid19.component.scss' ]
})
export class Covid19Component implements OnInit {
  form: UntypedFormGroup;
  model = {
    testConsent: null,
    termsAndConditions: null,
    privacyPractices: null
  };

  ineligible: any = false;
  eligible: any = false;
  option = '';

  IsValidFirstGroup = null;
  firstGroup = ethnicity;
  IsValidSecondGroup = null;
  secondGroup = race;

  thirdGroup = [ {
    id: 'Age',
    Name: 'I am 65 years of age or older.',
    Selected: false
  }, {
    id: 'OverweightOrObese',
    Name: 'I have been told by my doctor that I am very overweight or obese.',
    Selected: false
  }, {
    id: 'ChronicCondition',
    Name: 'I have a chronic condition (e.g. diabetes, high blood pressure, kidney disease or on dialysis, liver disease, lung disease, or asthma, etc.)',
    Selected: false
  }, {
    id: 'HeartCondition',
    Name: 'I have a heart condition (e.g. previous heart attacks, heart failure, etc.)',
    Selected: false
  }, {
    id: 'NeurologicCondition',
    Name: 'I have a neurologic condition that affects my ability to cough (e.g., had a stroke)',
    Selected: false
  }, {
    id: 'Pregnant',
    Name: 'I am or may be pregnant.',
    Selected: false
  }, {
    id: 'Smoker',
    Name: 'I regularly use tobacco or nicotine products (e.g. cigarettes, e-cigarettes, vapes, hookah, etc.)',
    Selected: false
  }, {
    id: 'ImmuneSystemInfections',
    Name: 'I have a condition that weakens my immune system or makes it harder to fight infections (e.g. AIDS, cancer, lupus, rheumatoid arthritis, solid organ or bone marrow transplant, etc.)',
    Selected: false
  }, {
    id: 'ImmuneSystemMedication',
    Name: 'I am taking medication that weakens my immune system (e.g. steroids, chemotherapy, immunologics, etc.)',
    Selected: false
  } ];
  psrTest: any;
  covidContact: any;
  ScreeningQuestion4: any;

  constructor(
    public dialogRef: MatDialogRef<Covid19Component>,
  ) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({});
  }

  Submit = () => {
    // this.onCheckBoxSelected();
    // if (!this.form.valid || !this.IsValidFirstGroup || !this.IsValidSecondGroup) return;
    // var demographic = {};
    // this.firstGroup.forEach((item) => {
    //   demographic[item.id] = item.Selected;
    // });
    // var race = {};
    // this.secondGroup.forEach((item) => {
    //   race[item.id] = item.Selected;
    // });
    // var statements = {};
    // this.thirdGroup.forEach((item) => {
    //   statements[item.id] = item.Selected;
    // });
    //
    // this.ok({ demographic: demographic, race: race, statements: statements, terms: this.model, option: this.option });

  };

  // onCheckBoxSelected(item?, bool?) {
  //   this.IsValidFirstGroup = false;
  //   this.IsValidSecondGroup = false;
  //   for (let i = 0; i < this.firstGroup.length; i++) {
  //     if (this.firstGroup[i].Selected) {
  //       this.IsValidFirstGroup = true;
  //       break;
  //     }
  //   }
  //   this.IsValidSecondGroup = false;
  //   for (let i = 0; i < this.secondGroup.length; i++) {
  //     if (this.secondGroup[i].Selected) {
  //       this.IsValidSecondGroup = true;
  //       break;
  //     }
  //   }
  // };

  ok(data) {
    // $state.go('main');
    this.dialogRef.close(data);
  };

  cancel() {
    this.dialogRef.close('cancel');
  };
}
