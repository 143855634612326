import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import * as LZUTF8 from 'lzutf8';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    navigationList;
    currentTest = {};

    constructor(private http: HttpClient) {
        this.getList();
    }

    getTest(id){
        if (!this.currentTest[id]){
            this.currentTest[id] = this.http.get<any>(environment.base + `/api/product/${id}`).pipe(map((element) => {
            if (element.description && element.description.includes(`${environment.compressedData}`)){
              element.description = element.description.replace(`${environment.compressedData}`, '');
              element.description = LZUTF8.decompress(element.description, {inputEncoding: 'StorageBinaryString'});
            }
            if (element.instructions && element.instructions.includes(`${environment.compressedData}`)){
              element.instructions = element.instructions.replace(`${environment.compressedData}`, '');
              element.instructions = LZUTF8.decompress(element.instructions, {inputEncoding: 'StorageBinaryString'});
            }
              return element;
            }), shareReplay());
        }
        return this.currentTest[id];
    }
    getList() {
        this.navigationList = this.http.get<any>(environment.base + '/api/content/list').pipe(map((element) => {
            element.map((el) => {
                if (el.content && el.content.includes(`${environment.compressedData}`)){
                    el.content = el.content.replace(`${environment.compressedData}`, '');
                    el.content = LZUTF8.decompress(el.content, {inputEncoding: 'StorageBinaryString'});
                }
            });
            return element;
        }), shareReplay());
        return this.navigationList;
    }

    createPage(data) {
        return this.http.post(environment.base + '/api/content/add', data);
    }

    updatePage(data) {
        return this.http.put(environment.base + '/api/content/update', data);
    }

    deletePage(id) {
        return this.http.delete(environment.base + `/api/content/delete/${id}`);
    }
}
