import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from './services/account.service';
import { AppService } from './services/app-service.service';
import { LoadingService } from './services/loading.service';
import { ModalService } from './services/modal.service';
import { SessionInfoStorageService } from './services/session-info-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit {
  title = 'grassrootlabs-latest';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  appVersion;
  idleModal;
  loading = false;
  version = environment.version;
  constructor(
    private localStorage: LocalStorageService,
    private actRoute: ActivatedRoute,
    private titleService: Title,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private accountService: AccountService,
    private modalService: ModalService,
    @Inject(DOCUMENT) private _document: Document,
    public changeDetect: ChangeDetectorRef,
    private _loading: LoadingService,
    private appService: AppService,
    public $sessionStorage: SessionStorageService,
    public sessionInfoStorageService: SessionInfoStorageService,
    private renderer2: Renderer2,) {
  }



  ngOnInit(): void {
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    } else {
      if (window.location.protocol === 'http:') {
        window.location.href = window.location.href.replace('http', 'https');
      }
    }
    this.router.events.subscribe((ev) => {
        const campaignid = this.actRoute.snapshot.queryParamMap.get('campaignid');
        const mbsy = this.actRoute.snapshot.queryParamMap.get('mbsy');
        const mbsySource = this.actRoute.snapshot.queryParamMap.get('mbsy_source');
        // console.log(campaignid, mbsy , mbsySource)
        if (campaignid && mbsy) {
              this.$sessionStorage.store('ambasador', {campaignid, mbsy , mbsySource});
            }
      });
    // this.actRoute.queryParams.subscribe(x=>{
    //   const campaignid = this.actRoute.snapshot.queryParamMap.get('campaignid');
    //   const mbsy = this.actRoute.snapshot.queryParamMap.get('mbsy');
    //   console.log('this.actRoute.snapshot.queryParamMap2: ',campaignid)
    //   if (campaignid && mbsy) {
    //     this.$sessionStorage.store('ambasador', {campaignid, mbsy});
    //   }
    // })

    this.listenToLoading();
    if(this.sessionInfoStorageService.getAuthInfo()) {
      this.startIdle();
    } else {
      this.appService.getUserLoggedIn().subscribe(loggedIn => {
        if(loggedIn) {
          this.startIdle();
        }
      })
    }
  }

  ngDoCheck(): void {
    if(!this.sessionInfoStorageService.getAuthInfo() && this.idleState !== 'Not started.') {
      this.idleState = 'Not started.';
      this.timedOut = true;
      this.reload();
    }
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }


  reload(){
    this._document.defaultView.location.reload();
    sessionStorage.clear();
  }

  // tslint:disable-next-line:typedef
  public setTitle(newTitle: string) {
    this.titleService.setTitle(`${newTitle}`);
  }

  // tslint:disable-next-line:typedef
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;

  }

  startIdle() {
  this.appVersion = this.router.url.includes('staging');
   // sets an idle timeout of 5 seconds, for testing purposes.
   this.idle.setIdle(300);
   // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
   this.idle.setTimeout(300);
   // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
   this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

   this.idle.onIdleEnd.subscribe(() => {
     this.idleState = 'No longer idle.'
    //  this._document.defaultView.location.reload();
   });

   this.idle.onTimeout.subscribe(() => {

    const storage = sessionStorage.getItem('ngx-webstorage|auth');

     if(storage.length > 2){
      this.router.navigate(['/']);
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.accountService.logout();
      this.modalService.dismissAll();
    //  this.reload()
     }
     else{
       return;
     }
   });

   this.idle.onIdleStart.subscribe(() => {
       this.idleState = 'You\'ve gone idle!'
       this.idleModal = this.modalService.openIdle();
   });

   this.idle.onTimeoutWarning.subscribe((countdown) => {
    let totalSeconds = countdown
  let minutes: any = Math.floor(totalSeconds / 60);
  let seconds: any = totalSeconds % 60;


  minutes = String(minutes).padStart(2, "0");
  seconds = String(seconds).padStart(2, "0");
    // countdown = `${minutes}:${seconds}`;
    countdown = minutes
     this.setTitle((countdown.toString()));
     this.idleState = 'You will time out in ' + countdown + ' seconds!'
   });

   this.keepalive.interval(15);

   this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

   this.reset();
  }
}
