import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-covid19-saliva',
  templateUrl: './covid19-saliva.component.html',
  styleUrls: ['./covid19-saliva.component.scss']
})
export class Covid19SalivaComponent {
  model = {
    testConsent: null,
    termsAndConditions: null,
    privacyPractices: null
  };

  ineligible = false;
  eligible = 1;
  statementApplied: any;
  ScreeningQuestion2: any;
  ScreeningQuestion3: any;
  thirdGroup: any;
  ScreeningQuestion4: any;
  ScreeningQuestion5: any;
  ScreeningQuestion6: any;

  constructor(public dialogRef: MatDialogRef<Covid19SalivaComponent>) {
  }

  Submit() {
    // this.onCheckBoxSelected();
    // if (!this.IsValidEthnicity || !this.IsValidRace || !this.IsValidThirdGroup) {
    //   return;
    // }
    // const quesitonScreeningQuestion2 = {title: '', Selected: '', body: ''};
    // this.ScreeningQuestion2.forEach(x => {
    //   if (x.Selected) {
    //     quesitonScreeningQuestion2.title = x.title;
    //     quesitonScreeningQuestion2.Selected = 'Yes';
    //     quesitonScreeningQuestion2.body = x.body;
    //   }
    // });
  this.ok({
      // risk: this.ScreeningQuestion2,
      // optionQuestions: optionQuestions,
      // demographic: demographic,
      // race: this.submitParamsNew('race'),
      // statements: statements,
      // firstCOVID: this.submitParamsNew('firstCOVID')[0],
      // healthcare: this.submitParamsNew('healthcare')[0],
      // covidSymptoms: this.submitParamsNew('covidSymptoms')[0],
      // hospital: this.submitParamsNew('hospital')[0],
      // ICU: this.submitParamsNew('ICU')[0],
      // liveWork: this.submitParamsNew('liveWork')[0],
      // pregnant: this.submitParamsNew('pregnant')[0],
      // feverDate: this.showfeverDate && this.feverDate,
      // symptoms: $scope.symptoms
    });

    // this.ok({
    //   // [quesitonScreeningQuestion2.title]: `${quesitonScreeningQuestion2.Selected}, ${quesitonScreeningQuestion2.body}`,
    //   ethnicity: this.submitParamsNew('ethnicity'),
    //   custom_atributes: {
    //     race: this.submitParamsNew('race'),
    //     'first covid test?': this.submitParamsNew('firstCOVID')[0],
    //     'employed_healthcare Settings?': this.submitParamsNew('healthcare')[0],
    //     COVID_symptoms: this.submitParamsNew('covidSymptoms')[0],
    //     symproms_start_date: this.feverDate,
    //     'currently_hospitalized?': this.submitParamsNew('hospital')[0],
    //     current_ICU: this.submitParamsNew('ICU')[0],
    //     'congragate_settings?': this.submitParamsNew('liveWork')[0],
    //     'Are you currently pregnant?': this.submitParamsNew('pregnant')[0],
    //     comorbidities: this.submitParamsNew('thirdGroup'),
    //     'stement aplied': this.statementApplied.symptoms1 ? 'option 1' : 'option 2'
    //   },
    // });
  };

  submitParamsNew(it) {
    const proxy = {};
    proxy[it] = [];

    this[it].forEach((item, index) => {
      if (this[it][index].Selected) {
        proxy[it].push(this[it][index].Name);
      }
    });
    return proxy[it];
  }

  ok(data) {
    this.dialogRef.close(data);
  };

  cancel() {
    this.dialogRef.close('cancel');
  };
}
