import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
  emailForm: UntypedFormGroup
 
  constructor(public fb: UntypedFormBuilder,
    public router: Router,
    private accountService: AccountService,
    private notifications: NotificationService,
    public dialogRef: MatDialogRef<ChangeEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      oldEmail: new UntypedFormControl('', Validators.compose([Validators.required])),
      newEmail: new UntypedFormControl('', Validators.compose([Validators.required])),
      confirmEmail: new UntypedFormControl('', Validators.compose([Validators.required])),
    })
  }

    cancel () {
      // this.router.navigate(['/']);
      this.dialogRef.close();
    };


    change(): void {
      if(this.data.actionType === 'updateEmail'){
        const model = {
          actionType: this.data.actionType,
          newEmail: this.emailForm.getRawValue().newEmail
        }
        this.data.action(model)
        this.cancel();
      }else{
        this.accountService.changeEmail({"email":this.emailForm.getRawValue().newEmail})
        .subscribe(() => {
          this.notifications.showSuccess({ message: 'Your Email has been changed' });
          this.dialogRef.close();
        });
      }
    }


}
