export const CONSTANTS = {
    all: 255,
    panels: 4,
    packages: 8,

    cardsTypes: [
        {name: 'Visa', value: 1, class: 'fa-cc-visa'},
        {name: 'MasterCard', value: 2, class: 'fa-cc-mastercard'},
        {name: 'American Express', value: 3, class: 'fa-cc-amex'},
        {name: 'JCB', value: 4, class: 'fa-cc-jcb'},
        {name: 'Discover', value: 5, class: 'fa-cc-discover'},
        {name: 'DinersClub', value: 6, class: 'fa-cc-diners-club'},
        {name: 'Other', value: 255, class: 'fa-credit-card-alt'}
    ],

    monthsNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],

    currentYear: (new Date()).getFullYear(),
    currentMonth: (new Date()).getMonth() + 1,

    expireYears: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(function (item) {
        var currentYear = (new Date()).getFullYear();
        return currentYear + item;
    }),

    homeAddressCode: 0,
    billingAddressCode: 1,
    govAddressCode: 2,

    addressesType: [
        {name: "Home", value: 0},
        {name: "Billing", value: 1},
        {name: "Government", value: 2}
    ],

    orderStatuses: {
        1: "Cart",
        2: "Submitted",
        4: "In Progress",
        8: "Completed",
        16: "Rejected",
        32: "Declined",
        64: "Canceled",
        128: "ExternalApiError",
        256: "NewRepeatOrder"
    },

    forbiddenStates: ["NY", "NJ", "RI"],


    // datePattern: '^.+$',
    datePattern: '^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/(19|20)\\d{2}$',   //  MM/dd/yyyy

    passwordPattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*~]).{8,16}$',

    MIN_SEARCH_LENGTH: 2,
    MIN_AGE: 18,

    months: [
        {value: "01", name: "Jan (01)"},
        {value: "02", name: "Feb (02)"},
        {value: "03", name: "Mar (03)"},
        {value: "04", name: "Apr (04)"},
        {value: "05", name: "May (05)"},
        {value: "06", name: "June (06)"},
        {value: "07", name: "July (07)"},
        {value: "08", name: "Aug (08)"},
        {value: "09", name: "Sep (09)"},
        {value: "10", name: "Oct (10)"},
        {value: "11", name: "Nov (11)"},
        {value: "12", name: "Dec (12)"}
    ],

    registrationMonths: [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
  ],

    years: [
        {value: "13", name: 2013},
        {value: "14", name: 2014},
        {value: "15", name: 2015},
        {value: "16", name: 2016},
        {value: "17", name: 2017},
        {value: "18", name: 2018},
        {value: "19", name: 2019},
        {value: "20", name: 2020},
        {value: "21", name: 2021},
        {value: "22", name: 2022},
        {value: "23", name: 2023},
        {value: "24", name: 2024},
        {value: "25", name: 2025},
        {value: "26", name: 2026},
        {value: "27", name: 2027},
        {value: "28", name: 2028},
        {value: "29", name: 2029},
        {value: "30", name: 2030}
    ]
}

//curl 'http://grl-api-dev.softprise.net/api/profile/get/9dc9835e-d345-46be-bfb6-a6330161b7ec' -H 'Pragma: no-cache' -H 'Origin: http://localhost:8000' -H 'Accept-Encoding: gzip, deflate, sdch' -H 'Accept-Language: en-US,en;q=0.8,uk;q=0.6,ru;q=0.4' -H 'Authorization: Bearer p7fRVxTnhghlznjkoGC66nTrpZlm-T3E42_gdatVQ8B14BB6HIWVDOohWPCSZV8WIP1Rszhz4Zw7-UCF5fDOq5O2A2bxrFSFOc84wsAZ8JrQ1DpBu9TEidNVyvsPIon4t3228ufzlpLHXUenkqVWqr0YjhheQ8Uv5xvQtejPtjpqspK1mT487DmwXRGM_REjGz61VjVmEgCzfa767ttLS8DUjH_0lxNIhYz3HuQOP9ptTEhE3pi3tSSkTbDY2JjmL4ZWNXzTZ3M5jMg7tsv0Ra4nyh7pi1PJmGEz7uqm_jAkwsTtZ19wWNXefV2yN_2otYzByhTpvDA03DnhzR4G6-3gAgOd78r8XvIvAc05tvXDvYmyuPB-eJVb9N-dDws1K--Ms0dArKOpJPsgEpBnguDT16lkDIxPhzftZsjxMKv0DrWwyTDFLXm1HHLvv122NIVwXTItUsN7eC4uYY93r25a6378TT3ymUdo3lXYrq5MfYSX4CW_smZnpWqPDXvUCVMdba5IOCRqB_nWOcLis5yobO77jsin93nmjMJP6KqK4N4IM6g8KaEG2ZRqCCQc' -H 'Accept: application/json, text/plain, */*' -H 'Cache-Control: no-cache' -H 'User-Agent: Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/50.0.2661.102 Safari/537.36' -H 'Connection: keep-alive' -H 'Referer: http://localhost:8000/' -H 'DNT: 1' --compressed
// curl 'http://grl-api-dev.softprise.net/api/profile/update' -X PUT -H 'Pragma: no-cache' -H 'Origin: http://localhost:8000' -H 'Accept-Encoding: gzip, deflate, sdch' -H 'Accept-Language: en-US,en;q=0.8,uk;q=0.6,ru;q=0.4' -H 'User-Agent: Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/50.0.2661.102 Safari/537.36' -H 'Content-Type: application/json;charset=UTF-8' -H 'Accept: application/json, text/plain, */*' -H 'Cache-Control: no-cache' -H 'Authorization: Bearer p7fRVxTnhghlznjkoGC66nTrpZlm-T3E42_gdatVQ8B14BB6HIWVDOohWPCSZV8WIP1Rszhz4Zw7-UCF5fDOq5O2A2bxrFSFOc84wsAZ8JrQ1DpBu9TEidNVyvsPIon4t3228ufzlpLHXUenkqVWqr0YjhheQ8Uv5xvQtejPtjpqspK1mT487DmwXRGM_REjGz61VjVmEgCzfa767ttLS8DUjH_0lxNIhYz3HuQOP9ptTEhE3pi3tSSkTbDY2JjmL4ZWNXzTZ3M5jMg7tsv0Ra4nyh7pi1PJmGEz7uqm_jAkwsTtZ19wWNXefV2yN_2otYzByhTpvDA03DnhzR4G6-3gAgOd78r8XvIvAc05tvXDvYmyuPB-eJVb9N-dDws1K--Ms0dArKOpJPsgEpBnguDT16lkDIxPhzftZsjxMKv0DrWwyTDFLXm1HHLvv122NIVwXTItUsN7eC4uYY93r25a6378TT3ymUdo3lXYrq5MfYSX4CW_smZnpWqPDXvUCVMdba5IOCRqB_nWOcLis5yobO77jsin93nmjMJP6KqK4N4IM6g8KaEG2ZRqCCQc' -H 'Connection: keep-alive' -H 'Referer: http://localhost:8000/' -H 'DNT: 1' --data-binary '{"model":{"title":{"name":"Mr","value":1},"maritalStatus":{"name":"Single","value":1},"ssn":"427-15-5947","workPhone":"662-631-0954","homeAddress":{"id":"2e18a5fa-7de6-4b14-922e-a66b01336cbe","state":"NY","addressType":1,"addressLine":"Some street 12","city":"NY","zip":"12112"},"id":"1e5c6097-5ad7-4513-8517-d2297f2a752e","firstName":"Gary","middleName":"Jones","lastName":"Jones","email":"gary@softprise.com","birthDate":"1980-08-23T18:28:24.000Z","gender":1}}' --compressed
