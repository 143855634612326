import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { EligibleIneligibleDirective } from 'src/app/Modals/partialCovid/eligible-ineligible.directive';

@Component({
  selector: ' app-pcr-test',
  templateUrl: './pcr-test.component.html',
  styleUrls: [ './pcr-test.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PcrTestComponent extends EligibleIneligibleDirective {
  @Output() optionChange = new EventEmitter<any>();

  testAnswer(testAnswer: string): void {
    this.optionChange.emit(testAnswer);
    this.eligibleClick(this.eligible + 1);
  }
}
