import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';
import { ProfileService } from 'src/app/services/profile.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';

@Component({
  selector: 'app-login-as-modal',
  templateUrl: './login-as-modal.component.html',
  styleUrls: ['./login-as-modal.component.scss']
})
export class LoginAsModalComponent implements OnInit {
  authInfo = this.sessionInfoStorageService.authInfo;
  loginAsForm:UntypedFormGroup;
  user
  showPassword = false;
  constructor(public fb:UntypedFormBuilder,
    public dialogRef: MatDialogRef<LoginAsModalComponent>,
    public sessionInfoStorageService:SessionInfoStorageService,
    public accountService:AccountService,
    public router: Router,
    public notificationService: NotificationService,
    private orderService: OrderService,
    private modalService: ModalService,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.loginAsForm = this.fb.group({
      password: new UntypedFormControl({value:null},Validators.compose([Validators.required]))
    })
    this.loginAsForm.patchValue({
      password: ''
    })
  }

  cancel() {
    this.dialogRef.close();
  }
  openPage(authInfo) {
    
    let url = `/auth?access_token=${authInfo.access_token}&isAdmin=${authInfo.isAdmin}&profileId=${authInfo.profileId}&userName=${authInfo.userName}&asQuasiAdmin=true&orderId=${authInfo.orderId}`;
    window.open( 
    url, "_blank");
  }


  loginAs(){
    if( !this.authInfo.access_token ||
          !this.authInfo.isAdmin ||
          !this.authInfo.profileId ||
          !this.authInfo.userName
      ) {
          return;
      }
      const model = {
        password: this.loginAsForm.get('password').value,
        rememberMe: false,
        userName: this.data.info.email || this.data.info.userEmail,
        adminName: this.authInfo.userName,
      }
      
      if(this.data.info.update){
        this.data.action(model)
        this.cancel();
      }else{
        this.accountService.loginAs(model).subscribe(result => {
          this.openPage(result);
          this.cancel();
        })
      }

  }
}
