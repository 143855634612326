<div class="categories container">

    <!-- BreadCrumbs -->
    <div class="row breadcrumbs">
        <div class="col-md-12">
            <div class="btn-group btn-breadcrumb">
                <a ui-sref="main" title="Grassroot Labs Home" class="btn btn-default"><i class="glyphicon glyphicon-home"></i></a>
                <a ui-sref="all" title="Tests" class="btn btn-default">Tests</a>
            </div>
        </div>
    </div>

    <!-- <test-list [tests]="tests" [testsCount]="testsCount" [getTestsFunction]="getTestsFunction"></test-list> -->

    <test-list 
    [testsCount]="testsCount"  
    [getTestsFunction]="getTestsFunction" 
    [tests]="tests"></test-list>

</div>
