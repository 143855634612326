import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StripeCheckoutComponent } from 'src/app/MainPage/stripe-checkout/stripe-checkout.component';
import { MainComponent } from './MainPage/main/main.component';
import { TestCentersComponent } from './MainPage/navigation/test-centers/test-centers.component';
import { AllTestsComponent } from './Tests/all-tests/all-tests.component';
import { AdminDashboardComponent } from './Admin/admin-dashboard/admin-dashboard.component';
import { AdminTransactionComponent } from './Admin/admin-transaction/admin-transaction.component';
import { ProductComponent } from './Tests/product/product.component';
import { CategoryComponent } from './Tests/category/category.component';
import { AdminUsersComponent } from './Admin/admin-users/admin-users.component';
import { TestListComponent } from './Tests/test-list/test-list.component';
import { AdminProductsComponent } from './Admin/admin-products/admin-products.component';
import { AdminCategoriesComponent } from './Admin/admin-categories/admin-categories.component';
import { AdminAboutComponent } from './Admin/admin-about/admin-about.component';
import { UserCabinetComponent } from './UserCabinet/user-cabinet/user-cabinet.component';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { SearchResultsComponent } from './Tests/search-results/search-results.component';
import { CheckoutComponent } from './MainPage/checkout/checkout.component';
import { DynamicContentComponent } from './MainPage/navigation/dynamic-content/dynamic-content.component';
import { LoginAsModalComponent } from './Modals/login-as/login-as-modal.component';
import { LoginAsComponent } from './Admin/login-as/login-as.component';
import { LoginAsGuard } from './Admin/login-as/login-as.guard';
import { ConfirmEmailComponent } from './Modals/confirm-email/confirm-email.component';
import { ConfirmForgotPasswordComponent } from './Modals/confirm-forgot-password/confirm-forgot-password.component';
import { AccountProfileComponent } from './UserCabinet/account-profile/account-profile.component';
import { CartComponent } from './MainPage/cart/cart.component';
import {BlogPostsComponent} from "./Blog/blog-posts/blog-posts.component";
import {BlogPostComponent} from "./Blog/blog-post/blog-post.component";
import {BlogAllPostsComponent} from "./Blog/blog-all-posts/blog-all-posts.component";
import {AdminInfusionsoftComponent} from "./Admin/admin-infusionsoft/admin-infusionsoft.component";


const routes: Routes = [
  {
    path:'blog',
    component: BlogPostsComponent
  },
  {
    path: 'blog/explore-more',
    component:BlogAllPostsComponent
  },
  {
    path:'blog/:slug',
    component: BlogPostComponent
  },
  {
    path: '',
    component: MainComponent
  }, {
    path: 'all/:panels',
    component: AllTestsComponent
  },{
    path: 'category/:id',
    component: CategoryComponent
  }, {
    path: 'dashboard',
    component: AdminDashboardComponent
  }, {
    path: 'dashboard/infusionsoft',
    component: AdminInfusionsoftComponent
  }, {
    path: 'product/:id',
    component: ProductComponent
  }, {
    path: 'product/:id/promocode/:code',
    component: ProductComponent
  }, {
    path: 'testcenters',
    component: TestCentersComponent
  }, {
    path: 'ResetPassword',
    component: MainComponent
  }, {
    path: 'cart',
    component: CartComponent
  },
  // {
  //   path: 'dashboard/users',
  //   component: AdminUsersComponent
  // },
  {
    path: 'all',
    component: AllTestsComponent
  }, {
    path: '',
    canActivate: [LoginAsGuard],
    component: LoginAsComponent
  }, {
    path: 'auth',
    canActivate: [LoginAsGuard],
    component: LoginAsComponent
  }, {
    path: 'account',
    component: UserCabinetComponent
  }, {
    path: 'cabinet',
    redirectTo: 'account', pathMatch: 'full'
  }, {
    path: 'search/:text',
    component: SearchResultsComponent
  }, {
    path: 'checkout',
    component: CheckoutComponent
  }, {
    path: 'confirm/:user/:security',
    component: ConfirmEmailComponent
  }, {
    path: 'promocode/:code',
    component: MainComponent
  }, {
    path: 'disableNonTransactionalEmails',
    component: MainComponent
  }, {
    path: '**',
    component: DynamicContentComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
