import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CONSTANTS } from 'src/app/const';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: [ './change-password.component.scss' ]
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  duplicatePassword = false;
  strongPwd = CONSTANTS.passwordPattern;
  showPassword = false;
  showNewPassword = false;
  paswordInvalid;
  confirmPasswordInvalid;
  showSuggestions;
  showSuggestions2;
  regex = {
    hasNumber: /\d/,
    hasSpecialCharacters: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
    hasCapitalCase: /[A-Z]/,
    hasSmallCase: /[a-z]/,
    minLength: /^.{8,}$/
  };

  colorMatch = {
    hasNumber: false,
    hasCapitalCase: false,
    hasSmallCase: false,
    hasSpecialCharacters: false,
    minLength: false
  };

  confirmColorMatch = {
    hasNumber: false,
    hasCapitalCase: false,
    hasSmallCase: false,
    hasSpecialCharacters: false,
    minLength: false
  };

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private accountService: AccountService,
    private notifications: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ChangePasswordComponent>) {
  }

  analyze(value) {
    Object.keys(this.regex).forEach(x => {
      this.colorMatch[x] = value &&
        !!this.regex[x].test(value);
    });
    this.paswordInvalid = Object.values(this.colorMatch).includes(false);
  }

  confirmPasswordAnalyze(value) {
    Object.keys(this.regex).forEach(x => {
      this.confirmColorMatch[x] = value &&
        !!this.regex[x].test(value);
    });
    this.confirmPasswordInvalid = Object.values(this.confirmColorMatch).includes(false);
  }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      oldPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]),
      confirmPassword: new UntypedFormControl('', Validators.required)
    });
  }

  cancel(): void {
    // this.router.navigate([ '/' ]);
    this.dialogRef.close();
  };


  change(): void {
    this.accountService.changePassword(this.changePasswordForm.getRawValue())
      .subscribe(() => {
        this.notifications.showSuccess({ message: 'Your password has been changed' });
        this.dialogRef.close();
      });
  }

  checkDuplicate() {
    if (!this.changePasswordForm.getRawValue().newPassword ||
    this.changePasswordForm.getRawValue().newPassword.length === 0) {
      return;
    } else {
      this.accountService.checkPassword({password:this.changePasswordForm.getRawValue().newPassword, userId: this.data}).subscribe((result: any) => {
        this.duplicatePassword = result;
      });
    }
  }
}
