<div class="categories container">

  <!-- BreadCrumbs -->
  <div class="row breadcrumbs">
    <div class="col-md-12">
      <div class="btn-group btn-breadcrumb">
        <a ui-sref="main" title="Grassroot Labs Home" class="btn btn-default"><i class="glyphicon glyphicon-home"></i></a>
        <a routerLink="all" title="All Tests" class="btn btn-default">Tests</a>
        <a [title]="categoryName" (click)="check(id)" routerLink="/category/{{id}}" class="btn btn-default">{{categoryName}}</a>
      </div>
    </div>
  </div>

  <test-list [testsCount]="testsCount"  [getTestsFunction]="getTestsFunction" [tests]="tests"></test-list>


</div>
