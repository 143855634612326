import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TestCentersService } from '../../../services/test-centers.service';
import { SessionInfoStorageService } from '../../../services/session-info-storage.service';
import { ProfileService } from '../../../services/profile.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-test-centers',
  templateUrl: './test-centers.component.html',
  styleUrls: ['./test-centers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestCentersComponent implements OnInit, OnDestroy {
  title = 'Local Lab Test Centers | Find a Grassroots Labs Location Near You';
  defaultTitle = 'Grassroots Labs - Blood Tests are Expensive. We fix that.';
  metaDescription = 'Locate your nearest Grassroots Labs test center and enjoy hassle-free access to affordable lab testing. Our nationwide locations are ready to serve you with transparent pricing and reliable service.';
  isEmpty = false;
  viewType = 'roadmap';
  nearestCenters = [];
  public isCollapsed = false;
  centers = {
    items: [],
    count: 0
  };
  isValidFormSubmitted = false;
  testCentersForm: UntypedFormGroup;

  get zipCode() {
    return this.testCentersForm.get('zipCode');
  }

  constructor(private testCentersService: TestCentersService,
              private sessionInfoStorage: SessionInfoStorageService,
              private modalService: ModalService,
              private metaService: Meta,
              private titleService: Title,
              private profileService: ProfileService,
              private fb: UntypedFormBuilder) {

    this.testCentersForm = this.fb.group({
      zipCode: [null, [Validators.minLength(5), Validators.maxLength(5), Validators.required]]
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTag({ name: 'description', content: this.metaDescription });

    if (this.sessionInfoStorage.getToken()) {  // User is logged in
      this.profileService.getProfile(this.sessionInfoStorage.getProfileId()).subscribe(
        (mainProfile: any) => {
          if (mainProfile.homeAddress) {
            this.testCentersForm.setValue({ zipCode: mainProfile.homeAddress.zip });
          } else if (mainProfile.billingAddress) {
            this.testCentersForm.setValue({ zipCode: mainProfile.billingAddress.zip });
          } else if (mainProfile.govAddress) {
            this.testCentersForm.setValue({ zipCode: mainProfile.govAddress.zip });
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(this.defaultTitle);
    this.metaService.removeTag('name="description"');
  }

  search(): void {
    if (this.testCentersForm.invalid) {
      return;
    }

    const zipCode = this.zipCode.value;
    this.testCentersService.findTestCenters(zipCode).then((result: any) => {
      this.isValidFormSubmitted = true;
      this.nearestCenters = result;
      this.nearestCenters.forEach(center => {
        center.workingHours = this.formatWorkingHours(center.workingHours);
      });

      this.isEmpty = !this.nearestCenters.length;
      if (this.isEmpty) {
        this.modalService.showTopNotification(true, 'Nothing found. Try another Zip.');
      }
    });
  }

  private formatWorkingHours(workingHours: string): string {
    workingHours = workingHours.replace(/; |\|/g, '\n');   // Replace '; ' or '|' with newline
    workingHours = workingHours.replace(/ ([ap])m/g, '$1m');
    workingHours = workingHours.replace(/([ap])m-/g, '$1m-');
    workingHours = workingHours.replace(/m-/g, 'm ~ ');

    const newCenterArray = workingHours.split('-');
    return newCenterArray.map(element => {
      const matches = element.match(/[a-zA-Z] \d/g);
      if (matches) {
        const finalMatches = matches.map(match => match.split(' ').join(": "));
        let finalElement = element;
        finalMatches.forEach((el, index) => {
          finalElement = finalElement.replace(matches[index], el);
        });
        return finalElement;
      }
      return element;
    }).join('-').replace(/m ~ /g, 'm - ');
  }

  trackByFn(index, item): number {
    return index; // Use item.id if available
  }

  initMap(): void {
    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false
    });

    this.nearestCenters.forEach(center => {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: center.latitude, lng: center.longtitude },
        map: map,
        title: center.name
      });
    });
  }
}
