export const ethnicity = [
  {
    id: 'HispanicOrLatino',
    Name: 'Hispanic or Latino',
    selected: false
  }, {
    id: 'NotHispanicOrLatino',
    Name: 'Not Hispanic or Latino',
    selected: false
  }, {
    id: 'Other',
    Name: 'Other',
    Label: 'Other',
    selected: false
  }, {
    id: 'PreferNotToSay',
    Name: 'Prefer Not to Say',
    Label: 'Prefer Not to Say',
    selected: false
  }
];

export const race = [
  {
    id: 'AmericanIndianAndAlaskaNative',
    Name: 'American Indian and Alaska Native',
    selected: false
  }, {
    id: 'Asian',
    Name: 'Asian',
    selected: false
  }, {
    id: 'BlackOrAfricanAmerican',
    Name: 'Black or African American',
    selected: false
  }, {
    id: 'NativeHawaiianAndOtherPacificIslander',
    Name: 'Native Hawaiian and other Pacific Islander',
    selected: false
  }, {
    id: 'TwoOrMoreRaces',
    Name: 'Two or more races',
    selected: false
  }, {
    id: 'White',
    Name: 'White',
    selected: false
  }, {
    id: 'Other',
    Name: 'Other',
    Label: 'Other',
    selected: false
  }, {
    id: 'PreferNotToSay',
    Name: 'Prefer Not to Say',
    Label: 'Prefer Not to Say',
    selected: false
  }
];

export const thirdGroup = [
  {
    id: 'Age',
    Name: 'I am 65 years of age or older.',
    selected: false
  }, {
    id: 'OverweightOrObese',
    Name: 'I have been told by my doctor that I am very overweight or obese.',
    selected: false
  }, {
    id: 'ChronicCondition',
    Name: 'I have a chronic condition (e.g. diabetes, high blood pressure, kidney disease or on dialysis, liver disease, lung disease, or asthma, etc.)',
    selected: false
  }, {
    id: 'HeartCondition',
    Name: 'I have a heart condition (e.g. previous heart attacks, heart failure, etc.)',
    selected: false
  }, {
    id: 'NeurologicCondition',
    Name: 'I have a neurologic condition that affects my ability to cough (e.g., had a stroke)',
    selected: false
  }, {
    id: 'Pregnant',
    Name: 'I am or may be pregnant.',
    selected: false
  }, {
    id: 'Smoker',
    Name: 'I regularly use tobacco or nicotine products (e.g. cigarettes, e-cigarettes, vapes, hookah, etc.)',
    selected: false
  }, {
    id: 'ImmuneSystemInfections',
    Name: 'I have a condition that weakens my immune system or makes it harder to fight infections (e.g. AIDS, cancer, lupus, rheumatoid arthritis, solid organ or bone marrow transplant, etc.)',
    selected: false
  }, {
    id: 'ImmuneSystemMedication',
    Name: 'I am taking medication that weakens my immune system (e.g. steroids, chemotherapy, immunologics, etc.)',
    selected: false
  }, {
    id: 'NoneOfTheAbove',
    Name: 'None of the above apply to me',
    selected: false
  }
];

export const firstCOVID = [
  {
    id: 'firstCOVIDYes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'firstCOVIDNo',
    Name: 'No',
    Selected: false
  }, {
    id: 'firstCOVIDUnknown',
    Name: 'Unknown',
    Selected: false
  }
];

export const healthcare = [
  {
    id: 'healthcareYes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'healthcareNo',
    Name: 'No',
    Selected: false
  }, {
    id: 'healthcareUnknown',
    Name: 'Unknown',
    Selected: false
  }
];
export const covidSymptoms = [
  {
    id: 'covidSymptomsYes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'covidSymptomsNo',
    Name: 'No',
    Selected: false
  }, {
    id: 'covidSymptomsUnknown',
    Name: 'Unknown',
    Selected: false
  }
];
export const hospital = [
  {
    id: 'Yes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'No',
    Name: 'No',
    Selected: false
  }, {
    id: 'Unknown',
    Name: 'Unknown',
    Selected: false
  }
];

export const cartRepeater = { firstCOVID, 'feverDate':false , healthcare, covidSymptoms, hospital };

export const salivaFifthScreenText = [
  'Is this your first COVID-19 test (PCR or antibody)?',
  '',
  'Are you employed in a healthcare setting?',
  'Do you have COVID-19 symptoms (fever, cough, shortness of breath, etc.)?',
  'Are you currently in the hospital?'
];

// will delete when the covid form is finished
const expectedRequest = {
  demographic: {
    HispanicOrLatino: false,
    NotHispanicOrLatino: false,
    Other: true,
    PreferNotToSay: false
  },
  race: {
    AmericanIndianAndAlaskaNative: false,
    Asian: false,
    BlackOrAfricanAmerican: false,
    NativeHawaiianAndOtherPacificIslander: false,
    TwoOrMoreRaces: true,
    White: false,
    Other: false,
    PreferNotToSay: false
  },
  statements: {
    Age: false,
    OverweightOrObese: false,
    ChronicCondition: false,
    HeartCondition: false,
    NeurologicCondition: false,
    Pregnant: false,
    Smoker: false,
    ImmuneSystemInfections: false,
    ImmuneSystemMedication: true,
    NoneOfTheAbove: false
  },
  terms: {
    testConsent: true,
    termsAndConditions: true,
    privacyPractices: true
  },
  option: 'I have never been tested for COVID-19.',
  firstCOVID: {
    firstCOVIDYes: false,
    firstCOVIDNo: true,
    firstCOVIDUnknown: false
  },
  healthcare: {
    healthcareYes: true,
    healthcareNo: false,
    healthcareUnknown: false
  },
  covidSymptoms: {
    covidSymptomsYes: false,
    covidSymptomsNo: false,
    covidSymptomsUnknown: true
  },
  hospital: {
    Yes: false,
    No: true,
    Unknown: false
  },
  ICU: {
    Yes: false,
    No: true,
    Unknown: false
  },
  liveWork: {
    Yes: false,
    No: true,
    Unknown: false
  },
  pregnant: {
    Yes: false,
    No: true,
    Unknown: false
  },
  feverDate: false,
  fourteenDays: 'NO'
};

export const ICU = [
  {
    id: 'Yes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'No',
    Name: 'No',
    Selected: false
  }, {
    id: 'Unknown',
    Name: 'Unknown',
    Selected: false
  }
];

export const liveWork = [
  {
    id: 'Yes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'No',
    Name: 'No',
    Selected: false
  }, {
    id: 'Unknown',
    Name: 'Unknown',
    Selected: false
  }
];

export const pregnant = [
  {
    id: 'Yes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'No',
    Name: 'No',
    Selected: false
  }, {
    id: 'Unknown',
    Name: 'Unknown',
    Selected: false
  }
];

export const salivaSixthScreenCartRepeater = { ICU, liveWork, pregnant };
export const salivaSixthScreenText = [
  'Are you currently in the ICU?',
  `Do you live or work in a congregate
      setting (including nursing homes, residential care for people with intellectual and developmental
      disabilities, psychiatric treatment facilities, group homes, board and care homes, homeless shelter, foster
      care or other setting)?`,
  'Are you pregnant?',
  'Are you currently in the hospital?'
];
