<div class="container" *ngIf="product">

  <!-- BreadCrumbs -->
  <div class="row breadcrumbs">
    <div class="col-md-12">
      <div class="btn-group btn-breadcrumb">
        <a routerLink="/" title="Grassroot Labs Home" class="btn btn-default"><i class="glyphicon glyphicon-home"></i></a>
        <a routerLink="all" title="Tests" class="btn btn-default">Tests</a>
        <a routerLink="/category/{{product.categoryId}}" [title]="product.categoryName" class="btn btn-default">{{product.categoryName}}</a>
        <a routerLink="/product/{{product.id}}" [title]="product.name" class="btn btn-default">{{product.name}}</a>
      </div>
    </div>
  </div>

  <!-- Product Row -->
  <div class="row">

    <!--Product Gallery-->
    <div class="col-lg-4 col-md-4 col-sm-12 product-gallery" style="position: relative;">
      <!--<img class="img-responsive" src="../../img/test_blood.jpg" alt="blood test" >-->
      <img class="img-responsive hidden-xs" src="{{imageURL}}{{product.imageId}}" alt="Product image">
      <!-- Social Media -->
      <div class="row standartInfo hidden-xs">
        <div class="col-md-12 social">
          <h4>Share on Social Media</h4>
          <p>
            <a href="https://www.facebook.com/GrassrootsLabs/" target="_blank">
              <i class="fa fa-facebook-square"></i>
            </a>
            <a href="https://twitter.com/GrassrootsLabs" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-twitter-x" viewBox="0 -2 18 18">
                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
              </svg>
            </a>
            <!--                        <a href="https://plus.google.com/u/0/b/101847991060883428283/?pageId=101847991060883428283"-->
            <!--                           target="_blank">-->
            <!--                            <i class="fa fa-google-plus-square"></i>-->
            <!--                        </a>-->
          </p>
        </div>
      </div>
    </div>

    <!--Product Description-->
    <div class="col-lg-8 col-md-8 col-sm-12">
      <!-- Test Name -->
      <div style="display: flex;">
        <img class="img-responsive visible-xs" src="{{imageURL}}{{product.imageId}}" alt="Product image" style="width: 120px; height: 25%; margin-right: 5px;">
        <div>
          <h1>{{product.name}}</h1>
          <b>CPT:</b>  {{product.cpt}}  <br><br>
        </div>
      </div>
      <!--            <b>Product Type:</b> {{product.productType | productType}} <br><br>-->
      <!-- Prices -->
      <div class="row prices">
        <div class="old-price col-sm-3">Market Price: <span>${{product.marketPrice}}</span></div>
        <div class="price col-sm-3">Price: <span>${{product.masterPrice}}</span></div>
        <div class="savings-price col-sm-3">Savings: <span>${{(product.marketPrice - product.masterPrice).toFixed(2)}}</span></div>
        <div class="col-sm-3 product-functions">
          <!-- Product Functions -->
          <button class="btn btn-primary" (click)="addToCart($event, product)" >
            <i class="fa fa-plus-square-o"></i> Add to Cart
          </button>
        </div>
      </div>

      <div class="productDescription">
        <div *ngIf="product.children?.length > 0">
          <h4 *ngIf="product.productType == 2">This test includes the possible following reflex test:</h4>
          <h4 *ngIf="product.productType >= 4">Tests included in this panel:</h4>
          <ul *ngIf="product.children?.length">
            <li *ngFor="let child of product.children; trackBy: trackByFn"> <a href="product/{{child.slug}}">{{child.name}}</a></li>
          </ul>
        </div>
      </div>

      <!-- Product Description -->
      <div class="row standartInfo">
        <h4>DESCRIPTION</h4>
        <p [innerHTML]=product.description></p>
      </div>

      <div class="row standartInfo">
        <!--<div class="col-md-12">-->
        <h4>INSTRUCTIONS</h4>
        <p [innerHTML]=product.instructions></p>
        <!--</div>-->
      </div>

      <!-- Standard information -->
      <!-- <div class="row standartInfo">
        <p [innerHTML]=product.processingFee></p>
      </div> -->

      <div class="searchResults standartInfo">
        <div class="row top20">
          <div class="col-md-4 col-sm-4"><img src="../../../assets/img/m.png" alt="no tests found" class="img-responsive"/></div>
          <div class="col-md-8 col-sm-8">
            <h2 class="text-center">Need Help Finding Your Tests?</h2>
            <p class="top10">We are always adding new tests to Grassroots Labs. If you do not see the test or tests that you are looking for, reach out to our support team and we will work to see if we can add it for you.

              <br><br>Start a live chat session with us by clicking the purple chat button in the bottom left corner of this web page or, if it is after hours, click the same button and leave us a message detailing what tests you are looking for and we will get back with you ASAP!
              <!--<button class="btn btn-default btn-lg">  &lt;!&ndash; ng-click="addToCart(product, $event)" &ndash;&gt;-->
              <!--<i class="fa fa-volume-control-phone"></i> Get your test now-->
              <!--</button>-->
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>

<!-- REFERRAL PART -->
<!--<div class="referral">-->
<!--<div class="container text-center">-->
<!--<i class="fa fa-users"></i>-->
<!--<h5>Join our referral program</h5>-->
<!--<p>Share Grassroots Labs with your friends and family and get rewarded!</p>-->
<!--<a href="" class="btn btn-primary">Click to signup</a>-->
<!--</div>-->
<!--</div>-->
<!-- REFERRAL PART Ends -->

<!-- CONTACT PART -->
<!-- <div class="contact">
  <div class="container">
    <div class="col-md-5 text-right">
      <p><i class="fa fa-phone-square"></i>(888) 315-3265</p>
    </div>
    <div class="col-md-7 text-left">
      <p><i class="fa fa-envelope"></i>SUPPORT@GRASSROOTSLABS.COM</p>
    </div>
  </div>
</div> -->
<!-- CONTACT PART Ends -->

