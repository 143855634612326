<!-- User Information -->
<div class="row cabinetTabs children-component">
  <form [formGroup]="newChildForm" class="form-horizontal">

    <div class="col-md-12 bot10" *ngIf="!addNewChild">
        <a href="javascript: void(0)" (click)="addChild(true)" class="btn btn-success" >
            <i class="fa fa-user"></i>
            Add Child / Minor
        </a>
    </div>

    <div class="acordionMincho" *ngIf="profileList">
        <div class="container">
            <div class="col-md-11 top10">
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let profile of profileList | orderBy: 'createdDate';trackBy: trackByFn">
                        <mat-expansion-panel-header (click)="selectChild(profile)">
                            <div>
                                <small>Name:</small> {{profile.firstName + ' ' + profile.lastName}}
                                <small ng-if="profile.createdDate">&nbsp;&nbsp; Date Added:</small> {{profile.createdDate| date:'MMMM d yyyy'}}
                                <small>&nbsp;&nbsp; Status:</small> {{profile.consentStatus}}
                            </div>
                        </mat-expansion-panel-header>
                        <div>
                            <div class="col-sm-6">
                                
                    
                                    <!-- First Name -->
                                    <div class="form-group">
                                        <label for="firstName" class="col-sm-3 control-label">First Name *</label>
                                        <div class="col-sm-9">
                                            <input formControlName="firstName" type="text" class="form-control"
                                                id="firstName" placeholder="First Name">
                                        </div>
                                    </div>
                    
                                    <div class="form-group col-sm-12 pull-right" *ngIf="newChildForm.controls.firstName.touched && newChildForm.controls.firstName.invalid">
                                        <div class="alert alert-danger">
                                            <b>Error:</b> Please add a first name.
                                        </div>
                                    </div>
                    
                                    <!-- Middle Name -->
                                    <div class="form-group">
                                        <label for="middleName" class="col-sm-3 control-label">Middle Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" id="middleName" formControlName="middleName"
                                                placeholder="Middle Name">
                                        </div>
                                    </div>
                    
                                    <div class="form-group col-sm-12 pull-right" *ngIf="newChildForm.controls.middleName.touched && newChildForm.controls.middleName.invalid">
                                        <div class="alert alert-danger">
                                            <b>Error:</b> Please add a middle name.
                                        </div>
                                    </div>
                    
                                    <!-- Last Name -->
                                    <div class="form-group">
                                        <label for="lastName" class="col-sm-3 control-label">Last Name *</label>
                                        <div class="col-sm-9">
                                            <input formControlName="lastName" type="text" class="form-control"
                                                id="lastName" placeholder="Last Name">
                                        </div>
                                    </div>
                    
                                    <div class="form-group col-sm-12 pull-right" *ngIf="newChildForm.controls.lastName.touched && newChildForm.controls.lastName.invalid">
                                        <div class="alert alert-danger">
                                            <b>Error:</b> Please add a last name.
                                        </div>
                                    </div>
                    
                                    <!-- suffix -->
                                    <div class="form-group">
                                        <label for="title" class="col-sm-3 control-label">Suffix</label>
                                        <div class="col-sm-5">
                                            <ng-select [items]="titles"
                                                        id="title"
                                                        placeholder="Suffix"
                                                        formControlName="title"
                                                        [clearable]="false">
                                            </ng-select>
                                        </div>
                                    </div>
                    
                    
                            </div>
                    
                            <!-- second half of the column of registration -->
                    
                            <div class="col-sm-6">
                                    <!-- Gender -->
                                    <div class="form-group">
                                        <label class="col-sm-3 control-label">Gender *</label>
                                        <div class="col-sm-9">
                    
                                            <fieldset>
                                                <div class="radio radio-info radio-inline">
                                                    <input formControlName="gender" type="radio" value="2"
                                                        id="female">
                                                    <label for="female"> Female </label>
                                                </div>
                    
                                                <div class="radio radio-info radio-inline">
                                                    <input formControlName="gender" type="radio" value="1"
                                                        id="male">
                                                    <label for="male"> Male </label>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                    
                                    <div class="form-group col-sm-12 pull-right" *ngIf="newChildForm.touched && newChildForm.controls.gender.invalid">
                                        <div class="alert alert-danger">
                                            <b>Error:</b> Please choose a gender.
                                        </div>
                                    </div>
                    
                                    <!-- birthday -->
                                    <div class="form-group" ng-controller="DatePickerCtrl">
                                        <label for="inputPassword3" class="col-sm-3 control-label">Birthday *</label>
                                        <div class="col-sm-9">
                                                <mat-form-field  appearance="fill">
                                                    <mat-label>Choose a date</mat-label>
                                                    <input  formControlName="birthDate" matInput [matDatepicker]="picker">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                            
                                        </div>  
                                        <!-- <div class="form-group col-sm-12 pull-right" ng-cloak
                                            ng-show="pc.forms.profileForm.$submitted || pc.forms.profileForm.date.$touched">
                                            <div class="alert alert-danger" role="alert"
                                                ng-show="pc.forms.profileForm.date.$error.required || pc.forms.profileForm.date.$invalid">
                                                <b>Error: </b> Please add a birthday (MM/DD/YYYY).</div>
                                            <div class="alert alert-danger" role="alert" ng-show="pc.underAgeValidate(pc.model.birthDate)">
                                                <b>Error: </b>Birthday entered is not for a child/minor (under 18). If registering for an
                                                adult, have the individual register for their own account.</div>
                                            <div class="alert alert-danger" role="alert" ng-show="pc.forms.profileForm.date.$invalid"><b>Error:</b> Please enter the date in the MM/DD/YYYY format.</div>
                                            <div class="alert alert-danger" role="alert" ng-show="form.date.$error.eighteenYears">
                                                <b>Error:</b> You must be at least 18 years old.</div>
                                        </div> -->
                                        <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.birthDate.touched && newChildForm.controls.birthDate.invalid">
                                            <div class="alert alert-danger" style="margin-left: 30px;">
                                                <b>Error:</b> Please choose a birthday.
                                            </div>
                                        </div>
                                    </div>
                    
                    
                    
                    
                                    <div class="addressIndicator" formGroupName="homeAddress">
                    
                                        <!-- Address -->
                                        <div class="form-group">
                                            <label class="col-sm-3 control-label">Address *</label>
                                            <div class="col-sm-9">
                                                <input
                                                    formControlName="addressLine" type="text"
                                                    class="form-control" placeholder="Address" />
                                            </div>
                                        </div>
                    
                                        <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.homeAddress.controls.addressLine.touched && newChildForm.controls.homeAddress.controls.addressLine.invalid" style="padding-right: 0px;">
                                            <div class="alert alert-danger" style="margin-left: 30px;">
                                                <b>Error:</b> Please choose an address.
                                            </div>
                                        </div>
                    
                    
                                        <!-- City -->
                                        <div class="form-group">
                                            <label for="city" class="col-sm-3 control-label">City *</label>
                                            <div class="col-sm-9">
                                                <input
                                                    formControlName="city" class="form-control"
                                                    type="text" id="cityH" placeholder="City" />
                                            </div>
                                        </div>
                    
                                        <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.homeAddress.controls.city.touched && newChildForm.controls.homeAddress.controls.city.invalid" style="padding-right: 0px;">
                                            <div class="alert alert-danger" style="margin-left: 30px;">
                                                <b>Error:</b> Please choose a city.
                                            </div>
                                        </div>
                    
                                        <!-- State -->
                                        <div class="form-group">
                                            <label for="state" class="col-sm-3 control-label">State *</label>
                                            <div class="col-sm-3">
                                            <ng-select [items]="stateList$ | async"
                                                    id="state"
                                                    placeholder="State"
                                                    formControlName="state"
                                                    [clearable]="false">
                                            </ng-select>
                                            </div>
                                        </div>
                    
                                        <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.homeAddress.controls.state.touched && newChildForm.controls.homeAddress.controls.state.invalid" style="padding-right: 0px;">
                                            <div class="alert alert-danger" style="margin-left: 30px;">
                                                <b>Error:</b> Please choose a state.
                                            </div>
                                        </div>
                    
                                        <div class="form-group">
                                            <label for="zip" class="col-sm-3 control-label">Zip *</label>
                                            <div class="col-sm-9">
                                                <input formControlName="zip" class="form-control" 
                                                    type="text" id="zip" placeholder="Zip" />
                                            </div>
                                            <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.homeAddress.controls.zip.touched && newChildForm.controls.homeAddress.controls.zip.invalid" style="padding-right: 0px; margin-top: 15px;">
                                                <div class="alert alert-danger" style="margin-left: 45px; margin-right: 15px;">
                                                    <b>Error:</b> Please choose a zip code.
                                                </div>
                                            </div>
                                        </div>
                                        
                    
                                    </div>
                    
                    
                    
                            </div>
                    
                    
                            <div class="col-sm-12">
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                    <div style="display: flex; justify-content: space-between; align-items: center;">
                                        <button class="btn btn-success" tooltip-placement="bottom" (click)="toggleConsent()">
                                            Parental / Guardian Consent
                                        </button>
                                        <input type="checkbox" formControlName="consentStatus"
                                            style="width: 25px; height: 25px; margin-left: 10px; margin-top: 0px;">
                                    </div>
                                    <div>
                                        <button class="btn btn-primary" (click)="updateChild()" type="submit" [disabled]="newChildForm.invalid">Update
                                        </button>
                                        <a href="javascript: void(0)" (click)="selectChild(profile)" class="btn btn-default">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>


    <!-- first column of registration -->
    <div *ngIf="addNewChild" style="margin-top: 20px;">
        <div class="col-sm-6">
            

                <!-- First Name -->
                <div class="form-group">
                    <label for="firstName" class="col-sm-3 control-label">First Name *</label>
                    <div class="col-sm-9">
                        <input formControlName="firstName" type="text" class="form-control"
                            id="firstName" placeholder="First Name">
                    </div>
                </div>

                <div class="form-group col-sm-12 pull-right" *ngIf="newChildForm.controls.firstName.touched && newChildForm.controls.firstName.invalid">
                    <div class="alert alert-danger">
                        <b>Error:</b> Please add a first name.
                    </div>
                </div>

                <!-- Middle Name -->
                <div class="form-group">
                    <label for="middleName" class="col-sm-3 control-label">Middle Name</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" id="middleName" formControlName="middleName"
                            placeholder="Middle Name">
                    </div>
                </div>

                <div class="form-group col-sm-12 pull-right" *ngIf="newChildForm.controls.middleName.touched && newChildForm.controls.middleName.invalid">
                    <div class="alert alert-danger">
                        <b>Error:</b> Please add a middle name.
                    </div>
                </div>

                <!-- Last Name -->
                <div class="form-group">
                    <label for="lastName" class="col-sm-3 control-label">Last Name *</label>
                    <div class="col-sm-9">
                        <input formControlName="lastName" type="text" class="form-control"
                            id="lastName" placeholder="Last Name">
                    </div>
                </div>

                <div class="form-group col-sm-12 pull-right" *ngIf="newChildForm.controls.lastName.touched && newChildForm.controls.lastName.invalid">
                    <div class="alert alert-danger">
                        <b>Error:</b> Please add a last name.
                    </div>
                </div>

                <!-- suffix -->
                <div class="form-group">
                    <label for="title" class="col-sm-3 control-label">Suffix</label>
                    <div class="col-sm-5">
                        <ng-select [items]="titles"
                                    id="title"
                                    placeholder="Suffix"
                                    formControlName="title"
                                    [clearable]="false">
                        </ng-select>
                    </div>
                </div>


        </div>

        <!-- second half of the column of registration -->

        <div class="col-sm-6">
                <!-- Gender -->
                <div class="form-group">
                    <label class="col-sm-3 control-label">Gender *</label>
                    <div class="col-sm-9">

                        <fieldset>
                            <div class="radio radio-info radio-inline">
                                <input formControlName="gender" type="radio" value="2"
                                    id="female">
                                <label for="female"> Female </label>
                            </div>

                            <div class="radio radio-info radio-inline">
                                <input formControlName="gender" type="radio" value="1"
                                    id="male">
                                <label for="male"> Male </label>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div class="form-group col-sm-12 pull-right" *ngIf="newChildForm.touched && newChildForm.controls.gender.invalid">
                    <div class="alert alert-danger">
                        <b>Error:</b> Please choose a gender.
                    </div>
                </div>

                <!-- birthday -->
                <div class="form-group" ng-controller="DatePickerCtrl">
                    <label for="inputPassword3" class="col-sm-3 control-label">Birthday *</label>
                    <div class="col-sm-9">
                            <mat-form-field  appearance="fill">
                                <mat-label>Choose a date</mat-label>
                                <input  formControlName="birthDate" matInput [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                        
                    </div>  
                    <!-- <div class="form-group col-sm-12 pull-right" ng-cloak
                        ng-show="pc.forms.profileForm.$submitted || pc.forms.profileForm.date.$touched">
                        <div class="alert alert-danger" role="alert"
                            ng-show="pc.forms.profileForm.date.$error.required || pc.forms.profileForm.date.$invalid">
                            <b>Error: </b> Please add a birthday (MM/DD/YYYY).</div>
                        <div class="alert alert-danger" role="alert" ng-show="pc.underAgeValidate(pc.model.birthDate)">
                            <b>Error: </b>Birthday entered is not for a child/minor (under 18). If registering for an
                            adult, have the individual register for their own account.</div>
                        <div class="alert alert-danger" role="alert" ng-show="pc.forms.profileForm.date.$invalid"><b>Error:</b> Please enter the date in the MM/DD/YYYY format.</div>
                        <div class="alert alert-danger" role="alert" ng-show="form.date.$error.eighteenYears">
                            <b>Error:</b> You must be at least 18 years old.</div>
                    </div> -->
                    <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.birthDate.touched && newChildForm.controls.birthDate.invalid">
                        <div class="alert alert-danger" style="margin-left: 30px;">
                            <b>Error:</b> Please choose a birthday.
                        </div>
                    </div>
                </div>




                <div class="addressIndicator" formGroupName="homeAddress">

                    <!-- Address -->
                    <div class="form-group">
                        <label class="col-sm-3 control-label">Address *</label>
                        <div class="col-sm-9">
                            <input
                                formControlName="addressLine" type="text"
                                class="form-control" placeholder="Address" />
                        </div>
                    </div>

                    <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.homeAddress.controls.addressLine.touched && newChildForm.controls.homeAddress.controls.addressLine.invalid" style="padding-right: 0px;">
                        <div class="alert alert-danger" style="margin-left: 30px;">
                            <b>Error:</b> Please choose an address.
                        </div>
                    </div>


                    <!-- City -->
                    <div class="form-group">
                        <label for="city" class="col-sm-3 control-label">City *</label>
                        <div class="col-sm-9">
                            <input
                                formControlName="city" class="form-control"
                                type="text" id="cityH" placeholder="City" />
                        </div>
                    </div>

                    <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.homeAddress.controls.city.touched && newChildForm.controls.homeAddress.controls.city.invalid" style="padding-right: 0px;">
                        <div class="alert alert-danger" style="margin-left: 30px;">
                            <b>Error:</b> Please choose a city.
                        </div>
                    </div>

                    <!-- State -->
                    <div class="form-group">
                        <label for="state" class="col-sm-3 control-label">State *</label>
                        <div class="col-sm-3">
                        <ng-select [items]="stateList | async"
                                id="state"
                                placeholder="State"
                                formControlName="state"
                                [clearable]="false">
                        </ng-select>
                        </div>
                    </div>

                    <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.homeAddress.controls.state.touched && newChildForm.controls.homeAddress.controls.state.invalid" style="padding-right: 0px;">
                        <div class="alert alert-danger" style="margin-left: 30px;">
                            <b>Error:</b> Please choose a state.
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="zip" class="col-sm-3 control-label">Zip *</label>
                        <div class="col-sm-9">
                            <input formControlName="zip" class="form-control" 
                                type="text" id="zip" placeholder="Zip" />
                        </div>
                        <div class="col-sm-12 pull-right" *ngIf="newChildForm.controls.homeAddress.controls.zip.touched && newChildForm.controls.homeAddress.controls.zip.invalid" style="padding-right: 0px; margin-top: 15px;">
                            <div class="alert alert-danger" style="margin-left: 45px; margin-right: 15px;">
                                <b>Error:</b> Please choose a zip code.
                            </div>
                        </div>
                    </div>
                    

                </div>



        </div>


        <div class="col-sm-12 text-right" style="margin-bottom: 20px;">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <button class="btn btn-success" tooltip-placement="bottom" (click)="toggleConsent()">
                        Parental / Guardian Consent
                    </button>
                    <input type="checkbox" formControlName="consentStatus"
                        style="width: 25px; height: 25px; margin-left: 10px; margin-top: 0px;">
                </div>
                <div>
                    <button class="btn btn-primary" (click)="createNewChild()" type="submit" [disabled]="newChildForm.invalid">SAVE
                    </button>
                    <a href="javascript: void(0)" (click)="addChild(false)" class="btn btn-default">CANCEL</a>
                </div>
            </div>
            
        </div>
        <!-- <div style="display: flex; justify-content: space-between; align-items: center;">
            
        </div> -->
    </div>

</form>
</div>