import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { AccountService } from 'src/app/services/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-user-cabinet',
  templateUrl: './user-cabinet.component.html',
  styleUrls: ['./user-cabinet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserCabinetComponent implements OnInit {

  profile = {};
  active = 1;
  isAdmin;
  asQuasiAdmin;

  tab = 0;
  innerWidth;

  constructor(private profileService: ProfileService,
              private accountService: AccountService,
              public notifications: NotificationService,
              private sessionInfoStorage: SessionInfoStorageService,
              public sharedService: SharedService) {
    this.isAdmin = sessionInfoStorage.isAdmin();

    this.asQuasiAdmin = sessionInfoStorage.asQuasiAdmin();
  }


  updateProfile() {
    this.profileService.getProfiles((profiles) => {
      if (profiles && profiles.length) {
        this.profile = profiles[0];
      }
    });
  }


  ngOnInit() {
    this.innerWidth = !!(window.innerWidth > 916);
    this.updateProfile();
    this.notifications.getProfileState().subscribe(() => {
      this.updateProfile();
    })
  }

}
