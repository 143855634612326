import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminEmailService} from '../../services/admin-email.service';
import {AdminEmailInfo} from '../../entities/admin-email/AdminEmailInfo';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import moment from 'moment/moment';

@Component({
  selector: 'app-admin-email',
  templateUrl: './admin-email.component.html',
  styleUrls: [ './admin-email.component.scss' ]
})

export class AdminEmailComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  emailsInfo: AdminEmailInfo[] = [];
  collectionSize;
  page = 1;
  dataSource: MatTableDataSource<AdminEmailInfo>;
  pageSize = 10;
  emailInfoForm: UntypedFormGroup;
  dateStart;
  dateEnd;
  searchWord = '';

  constructor( private adminEmailService: AdminEmailService) {
  }
  ngOnInit(): void {
    this.adminEmailService.getAllEmails(0, this.pageSize).subscribe((response) => {
      this.emailsInfo = response.items;
      this.collectionSize = response.count;
      this.dataSource = new MatTableDataSource(this.emailsInfo);
      this.dataSource.sort = this.sort;
    });
    this.emailInfoForm = new UntypedFormGroup({
      dateStart: new UntypedFormControl(''),
      dateEnd: new UntypedFormControl(''),
    });
  }

  filterEmails(searchWord: string){
    this.searchWord = searchWord;
    const searchWordPath = `&searchWord=${searchWord}`;
    const startDatePath = this.dateStart ? `&dateStart=${ this.dateStart }` : '';
    const endDatePath = this.dateEnd ? `&dateEnd=${ this.dateEnd }` : '';
    const path = `?$orderby=CreatedDate+desc&$skip=0&$top=${ this.pageSize }${ startDatePath }${ endDatePath }${this.searchWord ? searchWordPath : ''}`;
    this.adminEmailService
      .getAllEmailsWithDate(path)
      .subscribe((response) => {
        this.emailsInfo = response.items;
        this.collectionSize = response.count;
        this.dataSource = new MatTableDataSource(this.emailsInfo);
        this.dataSource.sort = this.sort;
      });
  }
formatDate(date){
  return moment(date).format('MM/DD/YYYY hh:mm:ss A');
}
  getEmails(currentPage: number, event) {
    const searchWordPath = `&searchWord=${this.searchWord}`;
    this.dateStart = this.emailInfoForm.value.dateStart &&
      moment(this.emailInfoForm.value.dateStart).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    this.dateEnd = this.emailInfoForm.value.dateEnd &&
      moment(this.emailInfoForm.value.dateEnd).format('YYYY-MM-DD') + 'T23:59:00.000Z';
    const startDatePath = this.dateStart ? `&dateStart=${ this.dateStart }` : '';
    const endDatePath = this.dateEnd ? `&dateEnd=${ this.dateEnd }` : '';
    const skip = ((currentPage ? (currentPage - 1) : (event.target.innerHTML.split(' ')[1] - 1)) * 10);
    const path = `?$orderby=CreatedDate+desc&$skip=${skip}&$top=${ this.pageSize }${ startDatePath }${ endDatePath }${this.searchWord ? searchWordPath : ''}`;
    this.adminEmailService
      .getAllEmailsWithDate(path)
      .subscribe((response) => {
      this.emailsInfo = response.items;
      this.collectionSize = response.count;
      this.page = currentPage;
      this.dataSource = new MatTableDataSource(this.emailsInfo);
      this.dataSource.sort = this.sort;
    });
  }

  dateChange(){
     const searchWordPath = `&searchWord=${this.searchWord}`;
     this.dateStart = this.emailInfoForm.value.dateStart &&
       moment(this.emailInfoForm.value.dateStart).format('YYYY-MM-DD') + 'T00:00:00.000Z';
     this.dateEnd = this.emailInfoForm.value.dateEnd &&
       moment(this.emailInfoForm.value.dateEnd).format('YYYY-MM-DD') + 'T23:59:00.000Z';
     const startDatePath = this.dateStart ? `&dateStart=${ this.dateStart }` : '';
     const endDatePath = this.dateEnd ? `&dateEnd=${ this.dateEnd }` : '';
     const path = `?$orderby=CreatedDate+desc&$skip=0&$top=${ this.pageSize }${ startDatePath }${ endDatePath }${this.searchWord ? searchWordPath : ''}`;
     this.adminEmailService
      .getAllEmailsWithDate(path)
      .subscribe((response) => {
        this.emailsInfo = response.items;
        this.collectionSize = response.count;
        this.dataSource = new MatTableDataSource(this.emailsInfo);
        this.dataSource.sort = this.sort;
      });
  }


}
