import { Component, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ethnicity, race, thirdGroup } from 'src/app/Modals/covid19-saliva/salivaShared/configs';
import { EligibleIneligibleDirective } from 'src/app/Modals/partialCovid/eligible-ineligible.directive';

@Component({
  selector: 'app-saliva-fourth-screen',
  templateUrl: './saliva-fourth-screen.component.html',
  styleUrls: [ './saliva-fourth-screen.component.scss' ]
})
export class SalivaFourthScreenComponent extends EligibleIneligibleDirective {
  text = [ 'What is your ethnicity?', 'What is your race?', 'Do any of the following statements apply to you?' ];
  cartRepeater = { ethnicity, race, thirdGroup };

  @Output() ScreeningQuestion4Change = new EventEmitter<any>();

  onCheckBoxSelected(f, item, list): void {
    item.id === list.value[list.value.length - 1].id && this.unselectCheckboxes(f, item, list);
    this.validation(f, 3);
    const ScreeningQuestion4 = f.form.getRawValue();
    this.ScreeningQuestion4Change.emit({ ScreeningQuestion4, valid: this.validForm });
  }

  nextScreen(f: NgForm): void {
    const ScreeningQuestion4 = { ethnicity, race, thirdGroup };
    this.eligibleClick(this.eligible + 1);
  }

  private unselectCheckboxes(f, item, list): void {
    
    const formGroup = f.controls[list.key].controls;
    Object.keys(formGroup).forEach((x, index, array) => {
      if ((f.controls[list.key].controls.PreferNotToSay?.value || f.controls[list.key].controls.NoneOfTheAbove?.value) && array.length - 1 !== index) {
        formGroup[x].patchValue(false);
        formGroup[x].disable();
      } else {
        formGroup[x].enable();
      }
    });
  }
}
