import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { CONSTANTS } from 'src/app/const';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app-service.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProfileService } from 'src/app/services/profile.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { StatesService } from 'src/app/services/states.service';

const RequireOneValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  const homePhone = fg.get('homePhone').value;
  const workPhone = fg.get('workPhone').value;
  const mobilePhone = fg.get('mobilePhone').value;
  return homePhone || workPhone || mobilePhone ? null : { reqiredOne: true };
};

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: [ './account-profile.component.scss' ]
})
export class AccountProfileComponent implements OnInit {
  shareMsg = 'Click/Tap on the "Edit Account Information" button below.';
  languageMsg = 'Click/Tap on the "Edit Account Information" button below.';
  EmailMsg = 'Click/Tap on the "Change Email" button below';
  homePhoneMsg = 'Click/Tap on the "Edit Account Information" button below';
  changeMode = true;
  accountForm: UntypedFormGroup;
  stateList$;
  languagePreference = [ { name: 'English', id: 0 }, { name: 'Spanish', id: 1 } ];
  isAdmin = this.sessionInfoStorage.isAdmin();
  asQuasiAdmin = this.sessionInfoStorage.asQuasiAdmin();
  userId = this.sessionInfoStorage.getUserId();
  addressType = CONSTANTS.homeAddressCode;
  addressesType = CONSTANTS.addressesType;
  datePattern = CONSTANTS.datePattern;
  years = CONSTANTS.years;
  months = CONSTANTS.months;
  addresses = null;
  radioValue = { male: '1', female: '2' };
  homeCopy = false;
  billingCopy = false;
  govCopy = false;
  profileId = null;
  model = null;
  homeAddressSaved = null;
  billingAddressSaved = null;
  govAddressSaved = null;
  forbiddenStates = CONSTANTS.forbiddenStates;
  choosenProfile = null;
  submitted = false;
  $on;
  id;
  titles = [ 'None', 'Sr.', 'Jr.', 'I', 'II', 'III' ];
  today = new Date();
  newDate: NgbDateStruct;
  time: string;
  minAge = {
    year: this.today.getFullYear() - CONSTANTS.MIN_AGE,
    month: this.today.getMonth() + 1,
    day: this.today.getDate() + 1
  };
  message = 'To make changes to this information, please send a request to support@grassrootslabs.com.';

  constructor(
    private profileService: ProfileService,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private sessionInfoStorage: SessionInfoStorageService,
    private statesService: StatesService,
    private modalService: ModalService) {

  }

  get f() {
    return this.accountForm.controls;
  }

  ngOnInit() {
    this.stateList$ = this.statesService.states$;
    this.id = this.sessionInfoStorage.getProfileId();
    this.accountForm = this.formBuilder.group({
      firstName: new UntypedFormControl({
        value: null,
        disabled: !this.isAdmin && !this.asQuasiAdmin
      }, Validators.compose([ Validators.required ])),
      middleName: new UntypedFormControl({ value: null, disabled: !this.isAdmin && !this.asQuasiAdmin }),
      lastName: new UntypedFormControl({
        value: null,
        disabled: !this.isAdmin && !this.asQuasiAdmin
      }, Validators.compose([ Validators.required ])),
      suffix: new UntypedFormControl(null),
      email: new UntypedFormControl({
        value: null,
        disabled: !this.isAdmin && !this.asQuasiAdmin
      }, Validators.compose([ Validators.email, Validators.required ])),
      homePhone: new UntypedFormControl({ value: null, disabled: true }),
      workPhone: new UntypedFormControl({ value: null, disabled: true }),
      mobilePhone: new UntypedFormControl({ value: null, disabled: true }),
      // ssN: new FormControl(null),
      gender: new UntypedFormControl({
        value: null,
        disabled: !this.isAdmin && !this.asQuasiAdmin
      }, Validators.compose([ Validators.required ])),
      birthDate: new UntypedFormControl({
        value: null,
        disabled: !this.isAdmin && !this.asQuasiAdmin
      }, Validators.compose([ Validators.required ])),
      addressLine: new UntypedFormControl({
        value: null,
        disabled: !this.isAdmin && !this.asQuasiAdmin
      }, Validators.compose([ Validators.required ])),
      city: new UntypedFormControl({
        value: null,
        disabled: !this.isAdmin && !this.asQuasiAdmin
      }, Validators.compose([ Validators.required ])),
      state: new UntypedFormControl({
        value: null,
        disabled: !this.isAdmin && !this.asQuasiAdmin
      }, Validators.compose([ Validators.required ])),
      languagePreference: new UntypedFormControl({
        value: null,
        disabled: true
      }, Validators.compose([ Validators.required ])),
      zip: new UntypedFormControl({
        value: null,
        disabled: !this.isAdmin && !this.asQuasiAdmin
      }, Validators.compose([ Validators.required ])),
    });

    this.accountForm.controls['firstName'].valueChanges.subscribe(value => {
      this.accountForm.get( 'firstName' ).patchValue(value.charAt(0).toUpperCase() + value.slice(1),{emitEvent: false})
    });
    this.accountForm.controls['middleName'].valueChanges.subscribe(value => {
      this.accountForm.get( 'middleName' ).patchValue(value.charAt(0).toUpperCase() + value.slice(1),{emitEvent: false})
    });
    this.accountForm.controls['lastName'].valueChanges.subscribe(value => {
      this.accountForm.get( 'lastName' ).patchValue(value.charAt(0).toUpperCase() + value.slice(1),{emitEvent: false})
    });

    this.loadProfile(this.id);
// if (!SessionInfoStorage.getToken()) {
//   // location.go('main');
//   return;
// }
  }

  toggleWithGreeting(tooltip) {

    if (tooltip._tooltipInstance?._closeOnInteraction) {
      tooltip.hide();
    } else {
      tooltip.show();
    }

  }

  clearDate() {
    // this.accountForm.controls.date.setValue(null);
  }

  getAddressType(code) {
    switch (code) {
      case CONSTANTS.homeAddressCode:
        return 'homeAddress';
      case CONSTANTS.billingAddressCode:
        return 'billingAddress';
      case CONSTANTS.govAddressCode:
        return 'govAddress';
    }
  }

  enterToChangeMode() {

    this.changeMode = false;
    this.accountForm.controls['homePhone'].enable();
    this.accountForm.controls['workPhone'].enable();
    this.accountForm.controls['mobilePhone'].enable();
  };


  leaveChangeMode() {
    this.changeMode = true;
    this.accountForm.controls['homePhone'].disable();
    this.accountForm.controls['workPhone'].disable();
    this.accountForm.controls['mobilePhone'].disable();
  };

  setAddressType(code) {
    this.addressType = code;
    if (code == CONSTANTS.homeAddressCode && this.homeCopy) this.setAddressFromCopy();
    if (code == CONSTANTS.billingAddressCode && this.billingCopy) this.setAddressFromCopy();
    if (code == CONSTANTS.govAddressCode && this.govCopy) this.setAddressFromCopy();

  };

  isChecked(id) {
    return this.model.some(item => item.id === id);
  }

  clearHome() {
    this.model.homeAddress = this.choosenProfile.homeAddress;
  }

  clearBilling() {
    this.model.billingAddress = this.choosenProfile.billingAddress;
  }

  clearGov() {
    this.model.govAddress = this.choosenProfile.govAddress;
  }

  toggleHomeCopy() {
    if (this.homeCopy) this.setAddressFromCopy();
    else this.clearHome();
  };

  toggleGovCopy() {
    if (this.govCopy) this.setAddressFromCopy();
    else this.clearGov();
  };

  toggleBillingCopy() {
    if (this.billingCopy) this.setAddressFromCopy();
    else this.clearBilling();
  };

  refreshProfileList() {
    this.profileService.getProfiles((profiles) => {
      var allProfiles = this.profileService.processProfilesForInput(profiles);
      if (allProfiles && allProfiles.length) {
        this.profileId = allProfiles[CONSTANTS.homeAddressCode].value;
        this.loadProfile(this.profileId);
      }
    });
  }

  selectAddress($item) {
    if (this.addressType == CONSTANTS.homeAddressCode) {
      this.homeAddressSaved = $item;
      if (this.homeCopy)
        this.setAddressFromCopy();
    }
    if (this.addressType == CONSTANTS.billingAddressCode) {
      this.billingAddressSaved = $item;
      if (this.billingCopy)
        this.setAddressFromCopy();
    }
    if (this.addressType == CONSTANTS.govAddressCode) {
      this.govAddressSaved = $item;
      if (this.govCopy)
        this.setAddressFromCopy();
    }

  };

  clearAll() {
    this.profileId = null;
    this.addresses = null;
    this.model = null;
    this.choosenProfile = null;
    // $state.go('main');
  }

  findInConstByValue(variable, value) {
    var output = null;
    value.forEach(CONSTANTS[variable], (item) => {
      if (item.value == value)
        output = item;
    });

    return output;
  }

  displayProfile(id) {

  }

  loadProfile(id) {
    this.profileService.getProfile(id).subscribe((profile) => {
      this.model = profile;
      if (profile['addressesType'])
        this.model.addressesType = this.findInConstByValue('addressesTypes', profile['addressesType']);
      // if (profile.billingAddress['state'])
      //     this.model.billingAddress.state = {name: '', value: ''};
      // if (profile.birthDate){
      //   this.model.birthDate = new Date(profile.birthDate);
      // }
      this.choosenProfile = { ...this.model };
      const birthDate = new Date(this.model.birthDate);
      const year = birthDate.getFullYear();
      const month = birthDate.getMonth();
      const day = birthDate.getDay();
      const date: NgbDateStruct = { year: year, month: month, day: day };
      this.newDate = { year: year, month: month, day: day };
       this.accountForm.patchValue({
        email: profile.email,
        firstName: profile.firstName,
        middleName: profile.middleName,
        lastName: profile.lastName,
        gender: profile.gender,
        suffix: profile.suffix,
        homePhone: profile.homePhone,
        workPhone: profile.workPhone,
        mobilePhone: profile.mobilePhone,
        ssN: profile.ssn,
        birthDate: profile.birthDate,
        addressLine: profile.homeAddress?.addressLine,
        city: profile.homeAddress?.city,
        zip: profile.homeAddress?.zip,
        state: profile.homeAddress?.state,
        languagePreference: localStorage.getItem('languagePreference') === 'es' ? 1 : 0
      });

      if(profile.homeAddress?.state === 'AZ'){
        this.modalService.importantNotice(profile.homeAddress.state)
      }

    });
  }

  setAddressFromCopy() {
    var item = null;
    if (this.addressType == CONSTANTS.homeAddressCode) {
      item = this.homeAddressSaved;
    }
    if (this.addressType == CONSTANTS.billingAddressCode) {
      item = this.billingAddressSaved;
    }
    if (this.addressType == CONSTANTS.govAddressCode) {
      item = this.govAddressSaved;
    }
    this.model[this.getAddressType(this.addressType)] = item;
  }

  refreshAddressList() {
    this.profileService.getAddresses().then((addresses) => {
      this.addresses = addresses;
      if (this.addresses.length) {
        this.homeAddressSaved = this.addresses[0];
        this.govAddressSaved = this.addresses[0];
        this.billingAddressSaved = this.addresses[0];

      }
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.accountForm.invalid) {
      return;
    }

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.accountForm.value, null, 4));
  }

  prepareModel(model) {
    this.accountForm.patchValue({
      email: this.accountForm.value.email,
      firstName: this.accountForm.value.firstName,
      middleName: this.accountForm.value.middleName,
      lastName: this.accountForm.value.lastName,
      gender: this.accountForm.value.gender,
      homePhone: this.accountForm.value.homePhone,
      workPhone: this.accountForm.value.workPhone,
      mobilePhone: this.accountForm.value.mobilePhone,
      govAddress: this.accountForm.value.billingAddress,
      homeAddress: this.accountForm.value.billingAddress,
    });

    this.model = this.accountForm.value;
    var modelToSave = { ...this.model };
    if (this.homeCopy) {
      delete modelToSave.homeAddress;
      modelToSave.homeAddressId = this.homeAddressSaved.id;
    }
    if (this.billingCopy) {
      delete modelToSave.billingAddress;
      modelToSave.billingAddressId = this.billingAddressSaved.id;
    }
    if (this.govCopy) {
      delete modelToSave.govAddress;
      modelToSave.govAddressId = this.govAddressSaved.id;
    }
    return this.model;
  }

  changeEmail() {
    if (this.isAdmin || this.asQuasiAdmin) {
    this.modalService.openChangeEmail();
    }
  }

  update() {
    const now = moment(this.accountForm.getRawValue().birthDate);
    const formData = {
      birthDate: now.format('YYYY-MM-DD') + 'T00:00:00.000Z',
      email: this.accountForm.getRawValue().email,
      firstName: this.accountForm.getRawValue().firstName,
      gender: this.accountForm.getRawValue().gender,
      suffix: this.accountForm.getRawValue().suffix,
      homePhone: this.accountForm.getRawValue().homePhone,
      lastName: this.accountForm.getRawValue().lastName,
      middleName: this.accountForm.getRawValue().middleName,
      mobilePhone: this.accountForm.getRawValue().mobilePhone,
      workPhone: this.accountForm.getRawValue().workPhone,
      languagePreference: this.accountForm.getRawValue().languagePreference
    };

    const formAddress = {
      state: this.accountForm.getRawValue().state,
      addressLine: this.accountForm.getRawValue().addressLine,
      city: this.accountForm.getRawValue().city,
      zip: this.accountForm.getRawValue().zip
    };

    const dataToSend = { ...this.model, ...formData, homeAddress: { ...this.model.homeAddress, ...formAddress } };


    if (!this.accountForm.valid) return;
    // this.accountForm.patchValue({
    //   birthDate:this.model.birthDate,
    // });
    // dataToSend.birthDate.setHours(23);

    this.profileService.updateProfile(dataToSend)
      .subscribe(() => {
          localStorage.setItem("languagePreference",dataToSend.languagePreference? 'es' : 'en');
          let languagePreference = localStorage.getItem('languagePreference')
           window['Weglot'].switchTo(languagePreference)
          this.modalService.showTopNotification(true, 'Update success');
          this.accountForm.markAsPristine();
        }, () => {
          this.modalService.showTopNotification(false, 'Update failed');
        }
      );

    this.leaveChangeMode();
  };

// //Change Password Popup Modal Window
  changePw() {
    this.modalService.openChangePassword(this.userId);
  };

  // $on('authentication:login', function () {
  //     this.refreshProfileList();
  //     this.refreshAddressList();
  //   });

// this.$on('authentication:logout', this.clearAll);

//Change Email Popup Modal Window

// getStates(){
// this.statesService.getStates().subscribe((states:[]) => {
//     states = [];
//     this.stateList$ = states.map((state) => {
//         return state['name'];
//     });
//   });
// }


  openPopup(size, template, controller) {
    // this.modalService.openPopup({
    //     animation: true,
    //     templateUrl: template,
    //     controller: controller,
    //     size: size
    // })
  }


  updateProfile() {
    const modelToSave = { ...this.model };
    this.profileService.updateProfile(modelToSave)
      .subscribe(() => {
          // this.form.$setPristine();
          this.modalService.showTopNotification(true, 'Update success');
        }, () => {
          this.modalService.showTopNotification(false, 'Update failed');
        }
      );

    this.leaveChangeMode();
  };
}

