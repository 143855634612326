<h1>Please fill out the following demographic information.</h1>

<form (ngSubmit)="nextScreen(f)" #f="ngForm">
  <ng-container *ngFor="let it of cartRepeater  | keyvalue; let i = index" >
    <mat-card class="result" *ngIf="it.key !== 'feverDate'">
      <mat-card-header>
        <mat-card-subtitle>{{ text[i] }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <section [ngModelGroup]="it.key">
          <mat-checkbox
            [color]="'primary'"
            *ngFor="let item of it.value"
            required
            ngModel
            name="{{ item.id }}"
            (change)="checkboxSelection(item, f, it.key)">
            {{ item.Name }}
          </mat-checkbox>
        </section>
  
        
        <div *ngIf="it.key == 'healthcare' && f.value.healthcare?.healthcareYes ==  true">
          <mat-card-header>
            <mat-card-subtitle>WHAT WAS THE DATE THAT YOUR SYMPTOMS STARTED?</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <mat-form-field  appearance="fill" >
              <mat-label style="color: rgba(0, 0, 0, 0.87);">Choose a date</mat-label>
              <input  [ngModel]="feverDate" name="feverDate" matInput [matDatepicker]="picker" (dateChange)="checkboxSelection({id:'feverDate'}, f, 'healthcare')">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </mat-card-content>
        </div>
        
      </mat-card-content>
    </mat-card>
  </ng-container>
  

  <button color="primary" mat-raised-button type="submit" [disabled]="!validForm"> Next Screen</button>
</form>
