import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {KeapMailTemplateService} from '../../services/keap-mail-template.service';
import {ModalService} from '../../services/modal.service';
import {KeapEmailTemplateType} from '../../entities/keapEmailTemplate/KeapEmailTemplateType';

@Component({
  selector: 'app-add-keap-template',
  templateUrl: './add-keap-template.component.html',
  styleUrls: ['./add-keap-template.component.scss']
})

export class AddKeapTemplateComponent implements OnInit {
  editorContent = '';
  keapTemplateForm: UntypedFormGroup;
  editMode = false;
  templatesType = Object.values(KeapEmailTemplateType).filter(value => typeof value === 'number');
  constructor(
    public dialogRef: MatDialogRef<AddKeapTemplateComponent>,
    private formBuilder: UntypedFormBuilder,
    public modalService: ModalService,
    private keapMailTemplateService: KeapMailTemplateService,
    @Inject(MAT_DIALOG_DATA) public inputdata: any
  ) {}
  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.keapTemplateForm = this.formBuilder.group({
      id:  new UntypedFormControl(''),
      name: new UntypedFormControl('', Validators.compose([Validators.required])),
      body: new UntypedFormControl('', Validators.compose([Validators.required])),
      type: new UntypedFormControl(KeapEmailTemplateType.Transactional)
    });
    if (this.inputdata.isEdit) {
      this.editMode = true;
      this.keapTemplateForm.patchValue(this.inputdata.template);
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
  submit() {
    if (this.editMode){
      this.keapTemplateForm.controls['id'].setValue(this.inputdata.template.id);
      this.keapMailTemplateService.updateKeapMailTemplate(this.keapTemplateForm.value).subscribe((res) => {
        this.modalService.showTopNotification(true, 'Keap Template \'' + this.inputdata.template['name'] + '\' has been updated.');
        this.dialogRef.close('cancel');
      });
    }
    else{
      this.keapMailTemplateService.addKeapMailTemplate(this.keapTemplateForm.value)
        .subscribe((pr) => {
          this.modalService.showTopNotification(true, 'Keap Mail Template\'' + this.keapTemplateForm.get('name').value + '\' has been created.');
          this.dialogRef.close('cancel');
        });
    }
  }
}
