export enum AdminPages {
  Transactions = 0,
  EmailLog = 1,
  Users = 2,
  Products = 3,
  KeapTemplate = 4,
  BlogArticles = 5,
  Edit = 6,
  PromoCode = 7,
  PwnTests = 8,
  PwnOrders = 9,
  Infusionsoft = 10,
  Categories = 11
}

