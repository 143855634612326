<div class="cabinetTabs">
    <div class="container-fluid">
        <div class="actionLine">
            <button class="btn btn-default" (click)="addPage()">Add New Page</button>
        </div>

        <!-- <form [formGroup]="userForm" name="form" class="form-horizontal"> -->
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Parent</th>
                        <th>Combobox</th>
                        <th>Description</th>
                        <th>URL</th>
                        <th>isActive</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let option of sharedService.navigationList | async">
                        <td>{{option.name}}</td>
                        <td>{{option.parent}}</td>
                        <td>{{option.combobox}}</td>
                        <td>{{option.description}}</td>
                        <td>
                          <button class="btn btn-success" (click)="openLink(option)">
                            <i class="fa fa-pencil-square"></i>
                            URL
                          </button>
                        </td>
                        <td>{{option.isActive}}</td>
                        <td>
                          <div class="button-edit-wrapper">
                            <button class="btn btn-success button-edit-page" (click)="openPage(option)"><i
                                    class="fa fa-pencil-square"></i> Update
                            </button>
                            <button class="btn btn-danger" (click)="openDialog(option.id)"><i
                                    class="fa fa-minus-circle"></i> Remove
                            </button>
                          </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <!-- </form> -->
    </div>
</div>
