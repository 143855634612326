import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private http: HttpClient) {
  }

  uploadImage(file) {
    const fd = new FormData();
    fd.append('file', file);
    return this.http.post(environment.baseImageUrl, {fd}, {
      // headers: {
      //   'Content-type': undefined
      // },
    }).toPromise();

    // return this.ImageResource.addImage(fd).$promise;
  }

  getImage(imageId) {
    return this.http.get(environment.baseImageUrl + imageId).toPromise();
    // return this.ImageResource.getImage(imageId).$promise
  }

  getSmallImage(imageId) {
    return this.http.get(environment.baseIconUrl + imageId).toPromise();
    // return this.ImageResource.getSmallImage(imageId).$promise
  }

  getImages(params):Observable<any> {
    return this.http.get<any>(environment.baseImageUrl + `?$skip=${params.$skip}&$top=${params.$top}`);
    // return this.ImageResource.getImage(params).$promise;
  }

  deleteImage(imageId) {
    return this.http.delete(environment.baseImageUrl + imageId).toPromise();
    // return this.ImageResource.deleteImage(imageId).$promise
  }
}
