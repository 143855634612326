<div class="popup forms">

  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

  <div class="col-md-12">
    <h1 style="padding-top:0; margin-top:0;">Change Password</h1>
  </div>

  <form class="form-horizontal" [formGroup]="changePasswordForm">

    <!-- Current Password -->
    <div class="form-group">
      <label for="currentPw" class="col-sm-3 control-label">Current Password *</label>
      <div class="col-sm-9">
        <input name="oldPassword" formControlName="oldPassword" [type]="showNewPassword ? 'text' : 'password'" class="form-control" id="currentPw"
               placeholder="Current Password">
               <button mat-icon-button (click)="showNewPassword = !showNewPassword" type="button" id="visibilityButton">
                <mat-icon>{{ showNewPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
              </button>
      </div>
    </div>
    <!-- <div class="form-group col-md-12 pull-right" ng-cloak ng-show="form.$submitted || form.password.$touched">
        <div class="alert alert-danger" role="alert" ng-show="form.password.$error.required"><b>Error:</b> Please fill password field</div>
    </div> -->


    <!-- Password -->
    <div class="form-group">
      <label for="newPw" class="col-sm-3 control-label">New Password *</label>
      <div class="col-sm-9">
        <input name="newPassword" formControlName="newPassword" (blur)="checkDuplicate()" [type]="showPassword ? 'text' : 'password'" class="form-control" id="newPw"
        (input)="analyze($event.target.value)"
        (focus)="showSuggestions = true"
        (blur)="showSuggestions = false"
        placeholder="Password">
        <button mat-icon-button (click)="showPassword = !showPassword" type="button" id="visibilityButton">
          <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>
        <div class="form-group col-sm-12" *ngIf="duplicatePassword">
          <div class="alert alert-danger" role="alert"><b>Error:</b> Password can not have been previously used.</div>
        </div>
      </div>
    </div>
    <!-- <div class="form-group col-md-12 pull-right" ng-cloak ng-show="form.$submitted || form.password.$touched">
        <div class="alert alert-danger" role="alert" ng-show="form.password.$error.required"><b>Error:</b> Please fill password field</div>
        <div class="alert alert-danger" role="alert" ng-show="form.password.$error.minlength"><b>Error:</b> Your password must be at least 8 character</div>
        <div class="alert alert-danger" role="alert" ng-show="form.password.$invalid"><b>Error:</b> Your password must include at least one uppercase letter, one lowercase letter,
            one number digit and one special character (@,!,#,$,%,^,&,*).</div>
    </div> -->


    <!-- Confirm Password -->
    <div class="form-group">
      <label for="confirmPw" class="col-sm-3 control-label">Confirm Password *</label>
      <div class="col-sm-9">
        <input name="confirmPassword" formControlName="confirmPassword" [type]="showPassword ? 'text' : 'password'"
               class="form-control" id="confirmPw"
               (input)="confirmPasswordAnalyze($event.target.value)"
               (focus)="showSuggestions2 = true"
               (blur)="showSuggestions2 = false"
               placeholder="Confirm Password">
      </div>
    </div>
    <!-- <div class="form-group col-sm-12 pull-right"  *ngIf="form.$submitted || form.confirm.$touched">
        <div class="alert alert-danger" role="alert" *ngIf="form.confirm.$error.compareTo"><b>Error:</b> Your passwords do not match.Please confirmed them and try again.</div>
    </div> -->

    <!-- Buttons-->
    <div class="form-group">
      <div class="col-sm-12 text-right">
        <div class="col-sm-12 text-right">
          <button
            (click)="change()"
            [disabled]="changePasswordForm.invalid || (changePasswordForm.controls.confirmPassword.value !== changePasswordForm.controls.newPassword.value) || duplicatePassword"
            type="submit"
            class="btn btn-primary"
          >
            CHANGE PASSWORD
          </button>
          <a (click)="cancel()" class="btn btn-default">CANCEL</a>
        </div>
      </div>
    </div>
    <div class="alert alert-warning password-alert" role="alert" *ngIf="showSuggestions" style="font-size: 15px;">
      <h6 style="font-size: 11px;">Password Help:</h6>
      <ul style="list-style-type: none;">
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.hasNumber  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.hasNumber ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 number!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.hasCapitalCase  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.hasCapitalCase ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 in Capital Case!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.hasSmallCase  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.hasSmallCase ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 Letter in Small Case!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.hasSpecialCharacters  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.hasSpecialCharacters ?  'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 Special Character!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="colorMatch.minLength  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ colorMatch.minLength ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must be at least 8 characters!</span>
          </label>
        </li>
      </ul>
    </div>

    <div class="alert alert-warning password-alert" role="alert" *ngIf="showSuggestions2" style="font-size: 15px;">
      <h6 style="font-size: 11px;">Password Help:</h6>
      <ul style="list-style-type: none;">
        <li>
          <label class="col password-alert-display" [ngClass]="confirmColorMatch.hasNumber  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ confirmColorMatch.hasNumber ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 number!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="confirmColorMatch.hasCapitalCase  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ confirmColorMatch.hasCapitalCase ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 in Capital Case!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="confirmColorMatch.hasSmallCase  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ confirmColorMatch.hasSmallCase ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 Letter in Small Case!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="confirmColorMatch.hasSpecialCharacters  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ confirmColorMatch.hasSpecialCharacters ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must contain at least 1 Special Character!</span>
          </label>
        </li>
        <li>
          <label class="col password-alert-display" [ngClass]="confirmColorMatch.minLength  ? 'text-success': 'text-danger'">
            <i class="material-icons"> {{ confirmColorMatch.minLength ? 'check_circle' : 'cancel' }} </i>
            <span class="password-alert-padding">Must be at least 8 characters!</span>
          </label>
        </li>
      </ul>
    </div>
  </form>
</div>
