<div class="popup forms">

  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

  <div class="col-md-12">
    <h1 style="padding-top:0; margin-top:0;">{{editMode ? 'Edit' : 'Add'}} Test</h1>
  </div>
  <div class="row">
    <form class="form-horizontal"
          #form="ngForm"
          (ngSubmit)="submit(form)"
          [formGroup]="testForm">
      <div class="col-md-12">
        <div mat-dialog-content>
          <div class="col-md-6">
            <div class="form-group">
              <label for="name" class="col-sm-4 control-label">Name *</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  formControlName="name"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': f.name.errors }"/>

              </div>
            </div>

            <div class="form-group">
              <label for="cpt" class="col-sm-4 control-label">CPT</label>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  type="text"
                  maxlength="255"
                  formControlName="cpt"
                  class="form-control"
                  id="cpt">
              </div>
            </div>

            <div class="form-group">
              <label for="marketPrice" class="col-sm-4 control-label">Market Price</label>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  type="text"
                  maxlength="10"
                  formControlName="marketPrice"
                  class="form-control"
                  id="marketPrice">

              </div>
            </div>

            <div class="form-group">
              <label for="masterPrice" class="col-sm-4 control-label">Website Price</label>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  type="text"
                  maxlength="255"
                  formControlName="masterPrice"
                  class="form-control"
                  id="masterPrice">
              </div>
            </div>

            <div class="form-group">
              <label for="grl" class="col-sm-4 control-label">GRL cost</label>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  type="text"
                  formControlName="customerPrice"
                  class="form-control"
                  id="grl">
              </div>
            </div>

            <div class="form-group">
              <label for="vendor" class="col-sm-4 control-label">Vendor direct</label>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  type="text"
                  maxlength="10"
                  formControlName="patientPrice"
                  class="form-control"
                  id="vendor">
              </div>
            </div>
            <div class="form-group">
              <label for="searchPriority" class="col-sm-4 control-label">Search Priority</label>
              <div class="col-sm-8">
                  <select class="form-control" formControlName="searchPriority" id="searchPriority">
                      <option value="0" selected>0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </select>
              </div>
          </div>
            <div class="form-group">
              <label for="pageTitle" class="col-sm-4 control-label">Page title</label>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  type="text"
                  maxlength="300"
                  formControlName="pageTitle"
                  class="form-control"
                  id="pageTitle">
              </div>
            </div>

            <div class="form-group">
              <label for="metaRobots" class="col-sm-4 control-label">Meta robots</label>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  type="text"
                  maxlength="300"
                  formControlName="metaRobots"
                  class="form-control"
                  id="metaRobots">
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">Image</label>
              <div class="col-sm-4">
                <div class="upload btn btn-info" style="cursor: pointer;">

                  <label class="browseLabel">
                    <i class="fa fa-folder-open-o"></i>
                    Browse
                    <input
                      #fileInput
                      type="file"
                      [multiple]="true"
                      class="upload__input"
                      (change)="uploadFiles( $event.target.files )"
                    />
                  </label>
                </div>
              </div>
              <div class="col-sm-4">
                <button type="button"
                        class="btn btn-info"
                        [disabled]="false"
                        browse-images
                        (click)="openGallery()"
                        browse-images-selected="model.image1Id"
                        browse-images-preview="previewImage"
                        browse-images-on-selected="uploadFile = {}">
                  <i class="fa fa-file-image-o"></i> Gallery
                </button>
              </div>
              <br>
              <br>
              <div class="col-sm-8 col-sm-offset-4" *ngIf="previewImage">
                <input type="image" [src]="previewImage" width="100" formControlName="imageId">

              </div>
            </div>
          </div>

          <!-- Second Half -->

          <div class="col-md-6">

            <div class="form-group">
              <label for="labCode" class="col-sm-4 control-label">LAB*</label>
              <div class="col-sm-8">
                  <mat-form-field appearance="fill"  class="mat-form-wrapper">
                    <mat-select class="custom-select-test" placeholder="Select lab code" formControlName="labCode">
                      <mat-option *ngFor="let type of selectedLabCode" [value]="type.id">
                        {{ type.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
            </div>

            <div class="form-group">
              <label for="labCorp" class="col-sm-4 control-label">Lab test Code</label>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  type="text"
                  maxlength="5"
                  formControlName="questCode"
                  class="form-control"
                  id="labCorp">
              </div>
            </div>

            <div class="form-group">
              <label for="slug" class="col-sm-4 control-label">Slug</label>
              <div class="col-sm-8">
                <input
                  class="form-control"
                  type="text"
                  maxlength="100"
                  formControlName="slug"
                  class="form-control"
                  id="slug">
              </div>
            </div>
            <div class="form-group">
              <label for="tags" class="col-sm-4 control-label">Tags</label>
              <div class="col-sm-8">
                <mat-form-field class="example-chip-list" style="    background-color: #1C629E;
    border-radius: 3px;">
                  <mat-chip-grid #chipGrid aria-label="Enter tags">
                    @for (tag of tags; track tag) {
                      <mat-chip-row
                        (removed)="remove(tag)"
                        [editable]="true"
                       [aria-description]="'press enter to edit ' + tag"
                      >
                        {{tag}}
                        <button matChipRemove [attr.aria-label]="'remove ' + tag">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                    }
                    <input
                      style="color: white"
                      placeholder="New tag..."
                      [matChipInputFor]="chipGrid"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="add($event)"
                    />
                  </mat-chip-grid>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group">
              <label for="name" class="col-sm-4 control-label">Category *</label>
              <div class="col-sm-8">
                <mat-form-field appearance="fill" class="mat-form-wrapper">
                  <mat-select
                    class="custom-select-test"
                    placeholder="Select category"
                    formControlName="categoryId">
                    <mat-option
                      *ngFor="let item of (selectedCategory$ | async)?.items"
                      [value]="item.id">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="col-md-12 alert-padding" *ngIf="!selectedGroup && !proceed">
                  <div class="alert alert-danger" role="alert"><b>Error:</b> Please select category.</div>
                </div>
              </div>

            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">Product Type *</label>
              <div class="col-sm-8">

                <mat-radio-group [color]="'primary'" formControlName="productType">
                  <mat-radio-button *ngFor="let season of seasons"
                                    [value]="season.id">{{ season.name }}</mat-radio-button>
                </mat-radio-group>

              </div>

            </div>
            <div class="form-group" *ngIf="testForm.controls.productType.value=='8'">
              <div style="height: 155px">
                <app-product-tags [banks]="chilDataId" [selectedChildren]="selectedChildren"
                                  (productChange)="onProductChange($event)">
                </app-product-tags>
              </div>
            </div>

          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="description" class="col-sm-2 control-label">Description</label>
              <div class="col-sm-10">
                  <textarea
                    class="form-control"
                    type="text"
                    rows="3"
                    maxlength="32000"
                    formControlName="description"
                    class="form-control"
                    id="description"></textarea>
              </div>
            </div>

            <div class="form-group">
              <label for="metaDescription" class="col-sm-2 control-label">Meta description</label>
              <div class="col-sm-10">
                <textarea
                  class="form-control"
                  type="text"
                  maxlength="300"
                  formControlName="metaDescription"
                  class="form-control"
                  id="metaDescription"></textarea>
              </div>
            </div>

            <div class="form-group">
              <label for="instructions" class="col-sm-2 control-label">Instructions</label>
              <div class="col-sm-10">
                  <textarea
                    class="form-control"
                    type="text"
                    rows="3"
                    maxlength="16000"
                    formControlName="instructions"
                    class="form-control"
                    id="instructions"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right" mat-dialog-actions align="end">
          <div class="custom-left">
            <a class="btn btn-warning" (click)="goToTest()">Go to test</a>
          </div>
          <div class="checkbox checkbox-success inTests">
            <input id="isHidden" class="styled" type="checkbox" formControlName="isHidden">
            <label for="isHidden">
              Hidden
            </label>
          </div>
          <div class="checkbox checkbox-success inTests">
            <input id="isActive" class="styled" type="checkbox" formControlName="isActive">
            <label for="isActive">
              Active
            </label>
          </div>

          <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Save</button>
          <a class="btn btn-warning" (click)="cancel()"><i class="fa fa-remove"></i> Cancel</a>
        </div>

      </div>

    </form>
  </div>
</div>
