import { Component, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  salivaSixthScreenCartRepeater,
  salivaSixthScreenText
} from 'src/app/Modals/covid19-saliva/salivaShared/configs';
import { EligibleIneligibleDirective } from 'src/app/Modals/partialCovid/eligible-ineligible.directive';

@Component({
  selector: 'app-saliva-sixth-screen',
  templateUrl: './saliva-sixth-screen.component.html',
  styleUrls: [ './saliva-sixth-screen.component.scss' ]
})
export class SalivaSixthScreenComponent extends EligibleIneligibleDirective {
  @Output() ScreeningQuestion6Change = new EventEmitter<any>();
  ICU = [ {
    id: 'Yes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'No',
    Name: 'No',
    Selected: false
  }, {
    id: 'Unknown',
    Name: 'Unknown',
    Selected: false
  } ];

  liveWork = [ {
    id: 'Yes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'No',
    Name: 'No',
    Selected: false
  }, {
    id: 'Unknown',
    Name: 'Unknown',
    Selected: false
  } ];

  pregnant = [ {
    id: 'Yes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'No',
    Name: 'No',
    Selected: false
  }, {
    id: 'Unknown',
    Name: 'Unknown',
    Selected: false
  } ];
  text = salivaSixthScreenText;
  cartRepeater = salivaSixthScreenCartRepeater;

  SubmitTrigger(): void {
    const sixthData = {
      ICU: this.ICU,
      liveWork: this.liveWork,
      pregnant: this.pregnant
    };
    this.ScreeningQuestion6Change.emit(sixthData);
    this.eligibleClick(this.eligible + 1);
  }

  formStatus(f: NgForm): object {
    const formStatus = {};
    Object.entries(f.form.controls).forEach(x => {
      formStatus[x[0]] = x[1].value;
    });
    return formStatus;
  }

  nextScreen(f: NgForm): void {
    const selected = this.formStatus(f);
    this.ScreeningQuestion6Change.emit(selected);
    this.eligibleClick(this.eligible + 1);
  }

  checkboxSelection(item, f, key): void {
    const groupControl = f.form.controls[key];
    Object.keys(groupControl.value).forEach(x => {
      if (x !== item.id) {
        groupControl.controls[x].patchValue(false);
      }
    });
    this.validation(f, 3);
  }

  // private validation(f): void {
  //   const valid = {};
  //   Object.values(f.form.controls)
  //     .forEach((x: any) => Object.entries(x.value).forEach(y => {
  //       if (y[1] === true) {
  //         valid[y[0]] = y[1];
  //       }
  //     }));
  //   this.validForm = Object.keys(valid).length === 4;
  // }
}
