<div class="main-navigation">
  <nav class="navbar no-border-radius xs-height75"
       id="main_navbar"
       role="navigation">

    <div class="container-fluid">
      <div (click)="toggleCollapsed()" *ngIf="!navbarService.params.isOpen"
           class="navbar-header"
           style="position: fixed; top: 20px;z-index: 99; right: 20px; left: auto;">
        <a class="navbar-brand hidden-lg">
          <i [ngClass]="{'fa-times': navbarService.params.isOpen, 'fa-bars': !navbarService.params.isOpen}"
             class="fa"></i>
        </a>
      </div>
    </div>
    <!-- Collect the nav links, forms, and other content for toggling -->
    <div [ngClass]="{'in': navbarService.params.isOpen}" class=" navbar-collapse"
         style="padding-left: 7px; padding-right: 23px;">

      <img class="mobile-menu-logo hidden-lg"
           src="../../../assets/img/logo_dark.png">

      <a (click)="toggleCollapsed()"
         class="navbar-brand mobile-menu-link hidden-lg">
        <i [ngClass]="{'fa-times': navbarService.params.isOpen,  'fa-bars': !navbarService.params.isOpen}"
           class="fa"></i>
      </a>

      <div *ngIf="isDashboard" class="hidden-lg top20 text-center">
        <a class="btn btn-primary userPart" routerLink="dashboard" (click)="closeMenu();" type="button"> Admin Dashboard </a>
      </div>

      <div class="hidden-lg top20 text-center">
        <button (click)="openLoginPopup(); toggleCollapsed()" *ngIf="!logined" class="btn btn-primary"
                type="button">
          <i class="fa fa-sign-in"></i>
          LOGIN
        </button>
        <!-- <button class="btn btn-default" type="button" *ngIf="!logined"
                (click)="openRegisterPopup(); toggleCollapsed()">
          <i class="fa fa-pencil"></i>
          REGISTER
        </button> -->

        <!-- Single button using append-to-body -->
        <div *ngIf="logined" class="btn-group" uib-dropdown >
          <a class="btn btn-primary userPart" routerLink="/account" id="btn-append-to-body"
             style="font-weight: bold; border-radius: 4px;" title="{{userEmail}}"
             type="button" uib-dropdown-toggle (click)="closeMenu()">
            <i class="fa fa-user-md"></i> My Account
          </a>
          <ul aria-labelledby="btn-append-to-body" class="dropdown-menu userDrop" role="menu"
              uib-dropdown-menu>
            <li role="menuitem"><a (click)="toggleCollapsed()"><i
              class="fa fa-user-md"></i> Account
              Profile</a></li>
            <li role="menuitem"><a (click)="toggleCollapsed()" href="orderHistory"><i
              class="fa fa-clock-o"></i>
              Order History</a></li>

            <!-- Check if the user is admin here -->
            <li *ngIf="isDashboard" class="divider" role="separator"></li>
            <li *ngIf="isDashboard" class="dropdown-header">Administration</li>
            <li *ngIf="isDashboard" role="menuitem">
              <a (click)="toggleCollapsed()" href="dashboard.users">
                <i class="fa fa-diamond"></i> Dashboard
              </a>
            </li>
          </ul>
        </div>

        <a (click)="toggleCollapsed();" routerLink="cart" class="btn btn-success">
          <i>Cart</i>
          (<span>{{cartItemsCount}}</span>)
        </a>

        <button (click)="toggleCollapsed(); logout()" *ngIf="logined"
                class="btn btn-danger">
          <i class="fa fa-sign-out"></i>
          <b>LOGOUT</b>
        </button>
      </div>


      <div class="width65">
        <ul *ngFor="let option of sharedService.navigationList | async | navigation : 'navigation'"
            class="nav navbar-nav"
            style="max-height: 75px;">
          <li (click)="closeMenu()" *ngIf="!option.subitems && option.slug !== 'blog'"
              [ngStyle]="{'width': (option.name === 'COVID-19') ? '95px' : '100%'}">
            <a [routerLink]="option.slug ? option.slug : option.name" style="font-weight: bold">
              {{option.name}}
            </a>
          </li>
          <li *ngIf="option.subitems" class="nav-item" ngbDropdown>
            <a (click)="false" class="nav-link" href ngbDropdownToggle
               style="font-weight: bold">{{option.name}}<span class="caret"></span></a>
            <div class="dropdownMenu" ngbDropdownMenu>
              <button (click)="closeMenu()" *ngFor="let subitem of option.subitems" ngbDropdownItem>
                <a *ngIf="!subitem.isLink"
                   [routerLink]="subitem.name"
                   style="font-weight: bold; white-space: initial"
                >
                  {{subitem.name.indexOf('/') === -1 ? subitem.name : subitem.content}}
                </a>
                <a *ngIf="subitem.isLink" [href]="subitem.content" style="font-weight: bold"
                   target="_blank">
                  {{subitem.name}}
                </a>
              </button>
            </div>
          </li>

        </ul>
        <ul class="nav navbar-nav"
            style="max-height: 75px; padding-right: 10px;">
          <li (click)="closeMenu()"
              class="nav-item" >
            <a routerLink="blog" style="font-weight: bold">
              Blog
            </a>
          </li>
        </ul>
        <div class="testCenters">
          <a (click)="testCentersNavigate()" style="font-weight: bold; color: #676767;"><i
            class="fa fa-info-circle"></i> TEST CENTER SEARCH</a>
        </div>
        <div class="adminWrapper" style="display: flex; justify-content: flex-end; max-width: 420px;">
          <app-admin style="max-width: 420px;"></app-admin>
        </div>
      </div>


      <!-- <ul class="nav navbar-nav">
        <li href="main" (click)="closeMenu()">
          <a routerLink="/">
            <i class="fa fa-home"></i>
            Home
          </a>
        </li>
        <li class="dropdown-full" [ngClass]="{'open': isOpen(0)}">
          <a class="dropdown-toggle" (click)="openMenu(0)">
            <i class="fa fa-medkit"></i>
            Tests
            <span class="caret"></span>
          </a>
          <app-tests class="dropdown-menu HingeUpToDown no-padding"></app-tests>
        </li>
        <li class="dropdown-grid" [ngClass]="{'open': isOpen(1)}">
          <a class="dropdown-toggle" (click)="openMenu(1)">
            <i class="fa fa-leaf"></i>
            Panels
            <span class="caret"></span>

          </a>
          <div class="dropdown-grid-wrapper">
            <app-panels class="dropdown-menu HingeUpToDown col-md-4"></app-panels>
          </div>
        </li>
        <li>
          <a routerLink="about" (click)="closeMenu()">
            <i class="fa fa-info-circle"></i>
            About
          </a>
        </li>
        <li>
          <a routerLink="{{blogURL}}/{{ getCookieAuth() }}" target="_blank">
            <i class="fa fa-rss"></i>
            Blog
          </a>
        </li>
        <div style="display: inline-block;
        width: 322px;
        height: 54px;">
        <li>
            <app-admin  ></app-admin>
        </li>
        </div>
      </ul> -->

    </div>

  </nav>
</div>
