<div class="popup forms">

    <div class="cancel" (click)="cancel()">
        <i class="fa fa-times"></i>
    </div>

    <div class="col-md-12">
        <h1 style="padding-top:0; margin-top:0;">Select image</h1>
    </div>

    <div style="overflow-y: auto;
    height: 440px;
    overflow: auto;
    float: left;
    overflow-x: hidden;
    position: relative;
    margin-left: -5px;" class="col-md-12">
        <div class="row">
            <div   *ngFor="let image of images['items']">
                <div style="display: inline-block;" class="clearfix" ></div>
                <div style="float: left;
                width: 33.33%;
                padding: 5px;" class="col-md-3 col-sm-3 col-xs-6">
                    <a (click)="add(image)">
                        <div style="box-sizing: border-box;display:inline-flexbox;"  class="form-group text-center" (click)="add(image)">
                            <img class="img-responsive"
                                 style="margin: 0 auto;"
                                 src="{{ imageURL + image.id }}"
                                 >
                            <p>{{ image.name }}</p>
                        </div>
                    </a>    
                </div>
            </div>
        </div>

        <div class="form-group text-center" *ngIf="images.isLoadMore">
            <button type="submit"
                    class="btn btn-primary"
                    (click)="loadMore()">
                <i class="fa fa-plus"></i> Load more
            </button>
        </div>

        <!--<div class="form-group text-center">-->
            <!--<button type="submit" class="btn btn-primary"><i class="fa fa-plus"></i> Add</button>-->
            <!--<a class="btn btn-warning" ng-click="vm.cancel()"><i class="fa fa-remove"></i> Cancel</a>-->
        <!--</div>-->
    </div>

    <div class="clearfix"></div>
</div>