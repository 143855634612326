import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { CONSTANTS } from 'src/app/const';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProfileService } from 'src/app/services/profile.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { StatesService } from 'src/app/services/states.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: [ './admin-users.component.scss' ]
})
export class AdminUsersComponent implements OnInit {
  _users: any;
  user: any;
  roles = [];
  allRoles;
  tab = false;
  paginatorFunctions = {};
  totalUsers = 0;
  usersFromSearch;
  userForm: UntypedFormGroup;
  model;
  modelAuth;
  page = 1;
  pageSize = 10;
  collectionSize;
  start = true;
  stateList$;
  showOrders = false;
  showEmailChangeHistory = false;
  titles = [ 'None', 'Sr.', 'Jr.', 'I', 'II', 'III' ];
  languagePreference = [ { name: 'English', id: 0 }, { name: 'Spanish', id: 1 } ];
  newDate: NgbDateStruct;
  changeMode = true;
  isAdmin = this.sessionInfoStorage.isAdmin();
  mfaStatus = 'Off';
  nonTransactionalEmailsStatus = 'Off';

  constructor(
    private notifications: NotificationService,
    private accountService: AccountService,
    private modalService: ModalService,
    private sessionInfoStorage: SessionInfoStorageService,
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private statesService: StatesService
  ) {
  }

  ngOnInit() {
    this.stateList$ = this.statesService.states$;

    this.getRoleList();
    this.userForm = this.formBuilder.group({
      searchByName: [ '' ],
      isActive: [ '' ],
      currentRole: this.roles,
      firstName: new UntypedFormControl({
        value: null
      }, Validators.compose([ Validators.required ])),
      middleName: new UntypedFormControl({ value: null }),
      lastName: new UntypedFormControl({
        value: null
      }, Validators.compose([ Validators.required ])),
      title: new UntypedFormControl(null),
      email: new UntypedFormControl({
        value: null,
        disabled: true
      }, Validators.compose([ Validators.email, Validators.required ])),
      homePhone: new UntypedFormControl({ value: null }),
      workPhone: new UntypedFormControl({ value: null }),
      mobilePhone: new UntypedFormControl({ value: null }),
      gender: new UntypedFormControl(null, Validators.compose([ Validators.required ])),
      birthDate: new UntypedFormControl({
        value: null
      }, Validators.compose([ Validators.required ])),
      addressLine: new UntypedFormControl({
        value: null
      }, Validators.compose([ Validators.required ])),
      city: new UntypedFormControl({
        value: null
      }, Validators.compose([ Validators.required ])),
      state: new UntypedFormControl({
        value: null
      }, Validators.compose([ Validators.required ])),
      languagePreference: new UntypedFormControl({
        value: null
      }, Validators.compose([ Validators.required ])),
      zip: new UntypedFormControl({
        value: null
      }, Validators.compose([ Validators.required ])),
    });

    //   this.accountService.getAllUsers({$skip: 0, $top: 10}).subscribe((result) => {
    //
    //           this._users = result;
    //       })

    //   this.accountService.getAllUsers({
    //       "searchByName": this.userForm.get('searchByName'),
    //       "isActive": this.userForm.get('isActive'),
    //   }).subscribe((result) => {
    //       this._users = result;
    //   })
    // this.accountService.getAllUsers({$skip: 0, $top: 10}).subscribe((result) => {this._users = result['items'];});
  }

  // get orders() {
  //     return this._users.map((result, i) => ({id: i + 1, ...result}))
  //       .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

  //     }
  activate();

  activate() {
    if (this.isAdmin) {
      this.getRoleList();
    }
  }

  getRoleList() {
    this.accountService.roleList().subscribe((result) => {
      this.allRoles = result;
      result.map((allRoles) => {
        this.roles.push(allRoles.name);
      });
    });
  }

  getUsers(params, start, currentPage?) {
    // lazy loads all users at the beginning
    this.usersFromSearch = null;
    this.userForm.controls.searchByName.setValue('');
    if ((this.totalUsers > 0) || start) { this.findAllUsers([ params ]); }
  }

  getUsersFromSearch(page) {
    const newItems = this.usersFromSearch.items.slice((page - 1) * 10, page * 10);
    const modifiedResult = { ...this.usersFromSearch, items: newItems };
    this.fillUsers(modifiedResult, true);
  }

  findAllUsers(params) {  // loads all users
    this.accountService.getAllUsers(params).subscribe((result) => {
      this.fillUsers(result, false);
    });
  }

  fillUsers(users, sort?) {
    this.totalUsers = users.count;
    this._users = users.items.map((item, index) => {
      item.isOpen = false;
      // if(index === 1){
      //     item.isOpen = true
      // }
      item.currentRole = this.allRoles.find((val) => {
        return val.id === item.roleId;
      });
      item.isRoleChanged = false;
      return item;
    });
    // this.togglePanel(this._users[0].id,this._users[0].profileId)
  }

  loginAs(user) {
    this.modalService.openLoginAs(user);
  }

  changeRole(user) {
    user.isRoleChanged = true;
    user.roleName = this.userForm.value.currentRole;
    this.allRoles.map((role) => {
      if (role.name === user.roleName) {
        user.roleId = role.id;
      }
    });

    this.accountService.assignRole({
      userId: user.id,
      roleId: user.roleId
    }).subscribe((res) => {
      this.notifications.showSuccess({ message: user.email + ' has been changed to ' + user.roleName });
    });
  }

  togglePanel(user) {
    this.user = user;
    this.tab = false;
    this.changeMode = true;
    this.showOrders = false;
    this.showEmailChangeHistory = true;
    this.mfaStatus = user.isTwoFactor ? 'On' : 'Off';
    this.nonTransactionalEmailsStatus = user.receiveNonTransactionalEmails ? 'Off' : 'On';
    const newOrders = this._users;
    newOrders.forEach((el) => el.isOpen = el.id === user.id ? !el.isOpen : false);
    this._users = newOrders;
    // const roleName = this._users.find((el)=> el.id === id).roleName
    if (!user.profileId){
      this.notifications.showWarning({message: `${user.email} no longer exist.`});
      return;
    }
    this.profileService.getProfile(user.profileId).subscribe((profile) => {
      this.tab = true;
      this.model = profile;
      const birthDate = new Date(this.model.birthDate);
      const year = birthDate.getFullYear();
      const month = birthDate.getMonth();
      const day = birthDate.getDay();
      const date: NgbDateStruct = { year, month, day };
      this.newDate = { year, month, day };
      this.userForm.patchValue({
        // currentRole: roleName,
        email: profile.email,
        firstName: profile.firstName,
        middleName: profile.middleName,
        lastName: profile.lastName,
        gender: profile.gender,
        title: profile.title,
        homePhone: profile.homePhone,
        workPhone: profile.workPhone,
        mobilePhone: profile.mobilePhone,
        ssN: profile.ssn,
        birthDate: profile.birthDate,
        addressLine: profile.homeAddress.addressLine,
        city: profile.homeAddress.city,
        zip: profile.homeAddress.zip,
        state: profile.homeAddress.state,
        languagePreference: user.languagePreference
      });
    });
  }

  preventEvent(e) {
    e.stopPropagation();
  }

  setRoleChanged(user) {
    if (user.roleName !== this.userForm.value.currentRole) {
      user.isRoleChanged = true;
    } else {
      user.isRoleChanged = false;
    }
  }

  toggleAdminStatus(item) {
    let roleId = null;
    if (item.isAdmin) {
      roleId = this.roles.find((role) => {
        return role.name == 'Admin';
      }).id;
    }
    else {
      roleId = this.roles.find((role) => {
        return role.name == 'User';
      }).id;
    }

    this.accountService.assignRole({
      userId: item.id,
      roleId: roleId
    });
  }

  toggleActiveStatus(user) {
    this.accountService.toggleActiveStatus({
      accountId: user.id,
      isActive: !user.isActive
    }).then(r => {
      this._users.find((el) => el.id === user.id).isActive = !user.isActive;
    });
  }

  addUser() {
    this.modalService.openRegistration();
  }

  search() {
    const search = this.userForm.value.searchByName;
    if (search.length <= CONSTANTS.MIN_SEARCH_LENGTH) {
      // $scope.getUsers();
      return;
    }
    this.accountService.search({ search: search }).subscribe((result) => {
      this.usersFromSearch = result;
      const newItems = result.items.slice(0, 10);
      const modifiedResult = { ...result, items: newItems };
      this.fillUsers(modifiedResult, true);
    });
  }

  prepare(user) {
    user.update = true;
    if (!this.modelAuth) {
      this.modalService.openLoginAs(user, model.bind(this));
    } else {
      this.modelAuth.userName = user.email || user.userEmail;
      this.getAccessToken(user);
    }

    function model(model) {
      this.modelAuth = model;
      this.getAccessToken(user);
    }
  }

  getAccessToken(user) {
    this.accountService.loginAs(this.modelAuth).subscribe(result => {
      this.sessionInfoStorage.setFakeAuthInfo({
        access_token: result.access_token,
        asQuasiAdmin: 'true'
      });
      if (user.actionType === 'updateEmail'){
        this.changeEmailAction(user);
      }else if (user.actionType === 'updateUser'){
        this.updateAction(user);
      }else{
        this.showOrders = true;
      }

    });
  }

  changeEmail() {
    const type = 'updateEmail';
    this.modalService.openChangeEmail(type, model.bind(this));

    function model(model) {
      model.email = this.userForm.getRawValue().email;
      this.prepare(model);
    }
  }

  update() {
    if (this.statesService.getState(this.userForm.value.zip) !== this.userForm.value.state){
      this.notifications.block({ message: 'The users zip code does not match their state. Please confirm with the user and update.' }, false);
      return;
    }
    this.changeMode = true;
    const now = moment(this.userForm.getRawValue().birthDate);
    const formData = {
      birthDate: now.format('YYYY-MM-DD') + 'T00:00:00.000Z',
      email: this.userForm.getRawValue().email,
      firstName: this.userForm.getRawValue().firstName,
      gender: this.userForm.getRawValue().gender,
      title: this.userForm.getRawValue().title,
      homePhone: this.userForm.getRawValue().homePhone,
      lastName: this.userForm.getRawValue().lastName,
      middleName: this.userForm.getRawValue().middleName,
      mobilePhone: this.userForm.getRawValue().mobilePhone,
      workPhone: this.userForm.getRawValue().workPhone,
      languagePreference: this.userForm.getRawValue().languagePreference
    };
    const formAddress = {
      state: this.userForm.getRawValue().state,
      addressLine: this.userForm.getRawValue().addressLine,
      city: this.userForm.getRawValue().city,
      zip: this.userForm.getRawValue().zip
    };
    const dataToSend = { ...this.model, ...formData, homeAddress: { ...this.model.homeAddress, ...formAddress } };
    if (!this.userForm.valid) { return; }
    dataToSend.actionType = 'updateUser';
    this.prepare(dataToSend);
  }

  changeEmailAction(dataToSend){
    this.accountService.changeEmail({email: dataToSend.newEmail})
    .subscribe(() => {
      this.notifications.showSuccess({ message: 'Email has been changed' });
      this.sessionInfoStorage.clearToken();
    });
  }

  updateAction(dataToSend) {
    this.profileService.updateProfile(dataToSend).subscribe(() => {
      this.modalService.showTopNotification(true, 'Update success');
      this.sessionInfoStorage.clearToken();
    }, () => {
      this.modalService.showTopNotification(false, 'Update failed');
      this.sessionInfoStorage.clearToken();
    });
  }

  tabChange(event, user) {
    user.actionType = 'orderHistory';
    if (event.index === 1) {
      this.prepare(user);
    } else {
      this.showOrders = false;
    }
  }

  reverify(user) {
    this.accountService.reverifyEmail( user.id )
      .subscribe(() => this.notifications.showSuccess({ message: user.email + ' has been send.' })
      );
  }

  mfaOnOff(){
    this.accountService.mfaControl(this.user.id, !this.user.isTwoFactor).subscribe((res) => {
      this.notifications.showSuccess({ message: `${this.user.firstName} MFA is changed ot ${this.user.isTwoFactor ? 'Off' : 'On'}` });
      this.user.isTwoFactor = !this.user.isTwoFactor;
      this.mfaStatus = this.mfaStatus === 'Off' ? 'On' : 'Off';
    });
  }

  nonTransactionalEmailsStatusChangeStatus(){
    this.accountService.changeNonTransactionalEmails(this.user.id, !this.user.receiveNonTransactionalEmails).subscribe((res) => {
      this.notifications.showSuccess({ message: `${this.user.firstName} Non Transactional Emails is changed ot ${this.user.receiveNonTransactionalEmails ? 'On' : 'Off'}` });
      this.user.receiveNonTransactionalEmails = !this.user.receiveNonTransactionalEmails;
      this.nonTransactionalEmailsStatus = this.nonTransactionalEmailsStatus === 'Off' ? 'On' : 'Off';
    });
  }
}
