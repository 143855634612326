import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ethnicity, race, thirdGroup } from 'src/app/Modals/covid19-saliva/salivaShared/configs';
import { Covid19EmpComponent } from '../covid19-emp/covid19-emp.component';

@Component({
  selector: 'app-covid-emp-without-three-steps',
  templateUrl: './covid-emp-without-three-steps.component.html',
  styleUrls: [ './covid-emp-without-three-steps.component.scss' ]
})
export class CovidEmpWithoutThreeStepsComponent extends Covid19EmpComponent {
  secondGroup = race;

  aCovid = new Covid19EmpComponent(this.dialogRef);
  IsValidFirstGroup = null;
  firstGroup = ethnicity;
  IsValidSecondGroup = null;
  IsValidScreeningQuestion2 = null;
  IsValidScreeningQuestion3 = null;
  screenHouseholdContact = [ {
    id: 'HouseholdContactSubYes',
    Name: 'Yes',
    Selected: false
  }, {
    id: 'HouseholdContactSubNo',
    Name: 'No',
    Selected: false
  } ];
  screeningQuestion2 = [ {
    id: 'Retest',
    Name: 'I am an employee, and it is time for my retest.',
    Selected: false
  }, {
    id: 'Flagged',
    Name: 'I am an employee and have been flagged on daily symptom check.',
    Selected: false
  }, {
    id: 'Quarantine',
    Name: 'I am an employee living alone returning to work after quarantine / isolation.',
    Selected: false
  }, {
    id: 'Household',
    Name: 'I am an employee with household contacts returning to work after quarantine / isolation.',
    Selected: false
  }, {
    id: 'HouseholdContact',
    Name: 'I am a household contact of an employee.',
    Selected: false
  } ];
  screeningQuestion3 = [ {
    id: 'symptomsStarted',
    Name: 'It has been at least 10 days since my symptoms started.',
    Selected: false
  }, {
    id: 'pastHours',
    Name: 'I haven\'t had a fever or used fever-reducing medication in the past 72 hours.',
    Selected: false
  }, {
    id: 'RespiratorySymptoms',
    Name: 'Respiratory symptoms.',
    Selected: false
  } ];
  screenNoFever = [ {
    id: 'screenNoFeverSubYes',
    Name: 'Yes',
    Selected: false
  } ];
  option = '';
  showScreeningQuestion2 = false;
  showScreeningQuestion3 = false;
  HouseholdContact = false;
  RespiratorySymptoms = false;
  HouseholdContactSubNo = false;
  HouseholdContactSubYes = false;
  RespiratorySymptomsSubNo = false;
  RespiratorySymptomsPreSubNo = false;
  screenNoFeverSubYes = false;
  isValidHouseholdContact = true;
  RespiratorySymptomsPreSubYes = false;
  thirdGroup = thirdGroup;
  model = {
    testConsent: null,
    termsAndConditions: null,
    privacyPractices: null
  };
  pastDaysSubYes: boolean;
  RespiratorySymptomsSubYes = false;
  ShowNextScreen: any = false;

  // aCovid = new CovidEmpWithoutThreeStepsComponent();
  constructor(dialogRef: MatDialogRef<CovidEmpWithoutThreeStepsComponent>) {
    super(dialogRef);
  }

  updateSelection(position, entities, household): void {
    if (household === 'HouseholdContactSubYes') {
      this.showScreeningQuestion3 = true;
      this.HouseholdContactSubNo = false;
    }
    if (household === 'HouseholdContactSubNo') {
      this.HouseholdContactSubNo = true;
      this.showScreeningQuestion3 = false;
    }
    entities.forEach(entities, (subscription, index) => {
      if (position !== index) {
        subscription.Selected = false;
      }
    });
    if (!this.HouseholdContact) {
      this.HouseholdContact = entities[position].id === 'HouseholdContact';
    }
    this.RespiratorySymptoms = entities[position].id === 'RespiratorySymptoms';
  }

  nextScreen(): void {
    // this.onCheckBoxSelected();
    this.showScreeningQuestion2 = this.IsValidFirstGroup && this.IsValidSecondGroup;
  }

  submit(): void {
    //   // this.onCheckBoxSelected();
    //
    //   if (!this.form.valid || !this.IsValidFirstGroup || !this.IsValidSecondGroup || !this.IsValidScreeningQuestion2) return;
    //   var demographic = {};
    //   this.firstGroup.forEach((item) => {
    //     demographic[item.id] = item.Selected;
    //   });
    //   var race = {};
    //   this.secondGroup.forEach((item) => {
    //     race[item.id] = item.Selected;
    //   });
    //   var statements = {};
    //   thirdGroup.forEach((item) => {
    //     statements[item.id] = item.Selected;
    //   });
    //
    //   var validationScreeningQuestion2 = {};
    //   this.screeningQuestion2.forEach((item) => {
    //     validationScreeningQuestion2[item.id] = item.Selected;
    //   });
    //   var validationScreeningQuestion3 = {};
    //   this.screeningQuestion3.forEach((item) => {
    //     validationScreeningQuestion3[item.id] = item.Selected;
    //   });
    //
    //   this.ok({
    //     demographic: demographic,
    //     race: race,
    //     statements: statements,
    //     validationScreeningQuestion2: validationScreeningQuestion2,
    //     validationScreeningQuestion3: validationScreeningQuestion3,
    //     terms: this.model,
    //     option: this.option
    //   });
  }

  onCheckBoxSelected(secondGroup): void {
    //   var preferNotToSay = this.secondGroup.filter(function (x) {
    //     return x.id === 'PreferNotToSay';
    //   });
    //   if (secondGroup && preferNotToSay[0] && preferNotToSay[0].Selected === true) {
    //     this.secondGroup.forEach(function (x) {
    //       if (x.Selected === true && x.id !== 'PreferNotToSay')
    //         x.Selected = false;
    //     });
    //   }
    //   this.IsValidFirstGroup = false;
    //   this.IsValidSecondGroup = false;
    //   this.IsValidScreeningQuestion2 = false;
    //   this.IsValidScreeningQuestion3 = false;
    //   for (var i = 0; i < this.firstGroup.length; i++) {
    //     if (this.firstGroup[i].Selected) {
    //       this.IsValidFirstGroup = true;
    //       break;
    //     }
    //   }
    //   this.IsValidSecondGroup = false;
    //   for (var i = 0; i < this.secondGroup.length; i++) {
    //     if (this.secondGroup[i].Selected) {
    //       this.IsValidSecondGroup = true;
    //       break;
    //     }
    //   }
    //   this.screeningQuestion2.forEach(function (x) {
    //       if (x.Selected)
    //         this.IsValidScreeningQuestion2 = true;
    //     }
    //   );
    //   this.screeningQuestion3.forEach(function (x) {
    //       if (x.Selected)
    //         this.this.IsValidScreeningQuestion3 = true;
    //     }
    //   );
  }

  // ok(data) {
  //   // $state.go('main');
  //   this.dialogRef.close(data);
  // };
}
