import { Component, OnInit } from '@angular/core';

export class Counter {
  targetNumber: number;
  currentNumber: number;

  constructor(targetNumber: number, currentNumber: number) {
    this.targetNumber = targetNumber;
    this.currentNumber = currentNumber;
  }
}

@Component({
  selector: 'app-saved-counter',
  templateUrl: './seved-counter.component.html',
  styleUrls: ['./saved-counter.component.scss'],
})

export class SavedCounterComponent implements OnInit {
  customersServedCounter = new Counter(39916, 0);
  labTestsOrderedCounter = new Counter(241422, 0);
  customerSavingsCounter = new Counter(30096051, 0);

  get customersServedCurrentNumber(): string {
    return this.customersServedCounter.currentNumber.toLocaleString('en-GB');
  }

  get labTestsOrderedCurrentNumber(): string {
    return this.labTestsOrderedCounter.currentNumber.toLocaleString('en-GB');
  }

  get customerSavingsCurrentNumber(): string {
    return this.customerSavingsCounter.currentNumber.toLocaleString('en-GB');
  }

  constructor() { }

  ngOnInit(): void {
    this.animateCounter(this.customersServedCounter);
    this.animateCounter(this.labTestsOrderedCounter);
    this.animateCounter(this.customerSavingsCounter);
  }

  animateCounter(counter: Counter) {
    const totalIterations = 3000 / 50;
    const increment = counter.targetNumber / totalIterations;

    const interval = setInterval(() => {
      if (counter.currentNumber < counter.targetNumber) {
        counter.currentNumber += increment;
        counter.currentNumber = Math.floor(counter.currentNumber);
      } else {
        counter.currentNumber = counter.targetNumber;
        clearInterval(interval);
      }
    }, 50);
  }
}
