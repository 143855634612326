import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MomentModule } from 'ngx-moment';
import { CookieService } from 'ngx-cookie-service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { provideNgxWebstorage, withNgxWebstorageConfig, withLocalStorage, withSessionStorage } from 'ngx-webstorage';

import { environment } from '../environments/environment';
import { AdminAboutComponent } from './Admin/admin-about/admin-about.component';
import { AdminCategoriesComponent } from './Admin/admin-categories/admin-categories.component';
import { AdminDashboardComponent } from './Admin/admin-dashboard/admin-dashboard.component';
import { AdminEditComponent } from './Admin/admin-edit/admin-edit.component';
import { AdminInfusionsoftComponent } from './Admin/admin-infusionsoft/admin-infusionsoft.component';
import { AdminProductsComponent } from './Admin/admin-products/admin-products.component';
import { AdminTransactionComponent } from './Admin/admin-transaction/admin-transaction.component';
import { AdminUsersComponent } from './Admin/admin-users/admin-users.component';
import { LoginAsComponent } from './Admin/login-as/login-as.component';
import { PwnOrdersComponent } from './Admin/pwn-orders/pwn-orders.component';
import { PwnTestsComponent } from './Admin/pwn-tests/pwn-tests.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorIntercept } from './interceptors/error.interceptor';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { AutocompleteComponent } from './layout/autocomplete/autocomplete.component';
import { AdminComponent } from './MainPage/admin/admin.component';
import { CartComponent } from './MainPage/cart/cart.component';
import { CheckoutComponent } from './MainPage/checkout/checkout.component';
import { FooterComponent } from './MainPage/footer/footer.component';
import { HeaderComponent } from './MainPage/header/header.component';
import { MainComponent } from './MainPage/main/main.component';
import { DynamicContentComponent } from './MainPage/navigation/dynamic-content/dynamic-content.component';
import { NavigationComponent } from './MainPage/navigation/navigation.component';
import { PanelsComponent } from './MainPage/navigation/panels/panels.component';
import { TestCentersComponent } from './MainPage/navigation/test-centers/test-centers.component';
import { TestsComponent } from './MainPage/navigation/tests/tests.component';
import { ModalModule } from './Modals/modal.module';
import { DynamicRouterPipe } from './pipes/dynamic-router.pipe';
import { NavigationPipe } from './pipes/navigation.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { TestFormatPipe } from './pipes/test-Format';
import { DataService } from './services/data.service';
import { MaterialModule } from './shared/material-module';
import { AllTestsComponent } from './Tests/all-tests/all-tests.component';
import { CategoryComponent } from './Tests/category/category.component';
import { ProductComponent } from './Tests/product/product.component';
import { SearchResultsComponent } from './Tests/search-results/search-results.component';
import { TestListComponent } from './Tests/test-list/test-list.component';
import { NotificationBarModule } from './thirdParty/ngx-notification-bar/notification-bar.module';
import { AccountProfileComponent } from './UserCabinet/account-profile/account-profile.component';
import { DisableControlDirective } from './UserCabinet/account-profile/disable-control.directive';
import { AdminKeapTemplateComponent } from './Admin/admin-keap-template/admin-keap-template.component';
import { AdminEmailComponent } from './Admin/admin-email/admin-email.component';
// import { CardComponent } from './UserCabinet/card/card.component';
import { ChildrenComponent } from './UserCabinet/children/children.component';
import { OrderHistoryComponent } from './UserCabinet/order-history/order-history.component';
import { UserCabinetComponent } from './UserCabinet/user-cabinet/user-cabinet.component';
import { StripeCheckoutComponent } from './MainPage/stripe-checkout/stripe-checkout.component';
import { CountDownComponent } from './UserCabinet/order-history/count-down/count-down.component';
import { AdminPromoCodeComponent } from './Admin/admin-promo-code/admin-promo-code.component';
import { AdminECommerceComponent } from './Admin/admin-e-commerce/admin-e-commerce.component'
import { SavedCounterComponent } from './MainPage/saved-counter/seved-counter.component';
import { GoogleReviewsComponent } from './MainPage/google-reviews/google-reviews.component';
import { AdminUserEmailChangeHistoryComponent } from './Admin/admin-user-email-change-history/admin-user-email-change-history';
import { AdminOrderResultComponent } from './Admin/admin-order-result/admin-order-result.component';
import {BlogPostsComponent} from "./Blog/blog-posts/blog-posts.component";
import {BlogPostComponent} from "./Blog/blog-post/blog-post.component";
import {AdminArticlesComponent} from "./Admin/admin-articles/admin-articles.component";
import {BlogAllPostsComponent} from "./Blog/blog-all-posts/blog-all-posts.component";
import {GoogleMapsModule} from "@angular/google-maps";

@NgModule({ declarations: [
        AppComponent,
        AdminComponent,
        HeaderComponent,
        CartComponent,
        NavigationComponent,
        FooterComponent,
        PanelsComponent,
        TestsComponent,
        TestCentersComponent,
        BlogPostsComponent,
        MainComponent,
        OrderByPipe,
        TestFormatPipe,
        NumbersOnlyDirective,
        AllTestsComponent,
        AdminDashboardComponent,
        AdminTransactionComponent,
        ProductComponent,
        CategoryComponent,
        AdminUsersComponent,
        TestListComponent,
        AdminProductsComponent,
        AdminCategoriesComponent,
        AdminAboutComponent,
        AdminInfusionsoftComponent,
        PwnTestsComponent,
        PwnOrdersComponent,
        AccountProfileComponent,
        ChildrenComponent,
        UserCabinetComponent,
        OrderHistoryComponent,
        SavedCounterComponent,
        GoogleReviewsComponent,
        // CardComponent,
        AutocompleteComponent,
        SearchResultsComponent,
        CheckoutComponent,
        NavigationPipe,
        DynamicRouterPipe,
        DynamicContentComponent,
        AdminEditComponent,
        LoginAsComponent,
        SearchPipe,
        DisableControlDirective,
        StripeCheckoutComponent,
        CountDownComponent,
        AdminPromoCodeComponent,
        AdminECommerceComponent,
        AdminKeapTemplateComponent,
        AdminEmailComponent,
        AdminArticlesComponent,
        AdminUserEmailChangeHistoryComponent,
        AdminOrderResultComponent,
        BlogPostComponent,
        BlogAllPostsComponent
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
    // schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
  imports: [
        AppRoutingModule,
        NgIdleKeepaliveModule.forRoot(),
        MomentModule,
        GoogleMapsModule,
        AutocompleteLibModule,
        ModalModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        MatTableModule,
        FormsModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatInputModule,
        MaterialModule,
        MatFormFieldModule,
        NgbModule,
        NgScrollbarModule,
        NgSelectModule,
        NotificationBarModule], providers: [
    provideNgxWebstorage(
      withNgxWebstorageConfig({ separator: ':', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage()
    ),
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'fill' }
        },
        CookieService,
        DataService,
        SearchPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: ConfigInit,
        //   multi: true,
        //   deps: [Router,HttpClient, SessionInfoStorageService, AccountService]
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorIntercept,
            multi: true
        },
        OrderByPipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor() {
    console.log('environment:', environment);
  }
}
