<h1>Please fill out the following demographic information.</h1>
<form (ngSubmit)="nextScreen(f)" #f="ngForm">
    <mat-card class="result" *ngFor="let it of cartRepeater | keyvalue; let i = index">
      <mat-card-header>
        <mat-card-subtitle>{{ text[i] }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <section [ngModelGroup]="it.key">
          <mat-checkbox
            [color]="'primary'"
            *ngFor="let item of it.value"
            required
            ngModel
            name="{{ item.id }}"
            (change)="onCheckBoxSelected(f, item, it)">
            <div class="text-wrap">
              {{ item.Name }}
            </div>
          </mat-checkbox>
        </section>
      </mat-card-content>
    </mat-card>
<!--  <div class="alignDiv">-->
<!--    <button-->
<!--      color="primary"-->
<!--      mat-raised-button-->
<!--      type="submit"-->
<!--      [disabled]="!validForm">-->
<!--      Next Screen-->
<!--    </button>-->
<!--  </div>-->

</form>
