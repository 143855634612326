import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { AboutService } from '../../../services/about.service';
import { SharedService } from '../../../services/shared.service';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicContentComponent implements OnInit, OnDestroy {
  dynamicHTML: SafeHtml | null = null;
  defaultTitle = 'Grassroots Labs - Blood Tests are Expensive. We fix that.';
  @Input() name: any;

  constructor(
    private readonly aboutService: AboutService,
    public sharedService: SharedService,
    private router: Router,
    private metaService: Meta,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.updateData(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateData(event.url);
      }
    });
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(this.defaultTitle);
    this.metaService.removeTag('name="description"');
    if (environment.production) {
      this.metaService.removeTag('name="robots"');
    } else {
      this.metaService.updateTag({ name: 'robots', content: 'nofollow, noindex' });
    }
  }

  updateData(currentURL: string): void {
    this.sharedService.navigationList.subscribe((data) => {
      const dynamicData = this.getData(data, currentURL);
      if (dynamicData) {
        this.updateMetaTags(dynamicData);
        this.dynamicHTML = dynamicData.content
          ? this.sanitizeContent(dynamicData.content)
          : null;
        this.cdr.markForCheck();
      }
    });
  }

  getData(data: any[], url: string): any {
    if (url.charAt(0) === '/') {
      url = url.slice(1);
    }
    url = decodeURIComponent(url).replace(/%20/g, ' ');
    return data?.find((item) => item.name === url || item.slug === url);
  }

  updateMetaTags(dynamicData: any): void {
    const pageTitle = dynamicData.pageTitle?.trim() || this.defaultTitle;
    this.titleService.setTitle(pageTitle);

    if (dynamicData.metaDescription?.trim()) {
      this.metaService.updateTag({ name: 'description', content: dynamicData.metaDescription });
    } else {
      this.metaService.removeTag('name="description"');
    }

    const robotsContent = dynamicData.metaRobots?.trim() || (environment.production ? '' : 'nofollow, noindex');
    this.metaService.updateTag({ name: 'robots', content: robotsContent });
  }

  sanitizeContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
