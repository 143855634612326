import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { NotificationBarService } from '../thirdParty/ngx-notification-bar/notification-bar.service';
import { NotificationType } from '../thirdParty/ngx-notification-bar/index';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public authenticationState = new ReplaySubject<any | null>(1);
  public cardState = new ReplaySubject<any | null>(1);
  public cartState = new ReplaySubject<any | null>(1);
  public profileState = new ReplaySubject<any | null>(1);

  constructor(private notificationBarService: NotificationBarService) {
  }

  showSuccess({message}, autoHide = true) {
    this.notificationBarService.create({ message, type: NotificationType.Success}, autoHide);
  }

  showWarning({message}) {
    this.notificationBarService.create({ message, type: NotificationType.Warning});
  }

  block({message}, autoHide = true, concat = false){
    this.notificationBarService.create({ message, type: NotificationType.Block},autoHide, concat);
  }

  getAuthenticationState(): Observable<any | null> {
    return this.authenticationState.asObservable();
  }

  getCardState(): Observable<any | null> {
    return this.cardState.asObservable();
  }

  getCartState(): Observable<any | null> {
    return this.cartState.asObservable();
  }

  getProfileState(): Observable<any | null> {
    return this.profileState.asObservable();
  }
}
