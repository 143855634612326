import { SharedService } from 'src/app/services/shared.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Input } from '@angular/core';
import * as LZUTF8 from 'lzutf8';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-page',
  templateUrl: './add-page.component.html',
  styleUrls: ['./add-page.component.scss']
})
export class AddPageComponent implements OnInit {

  newPageForm = this.formBuilder.group({
    name: new UntypedFormControl('', Validators.required),
    parent: new UntypedFormControl('', Validators.required),
    isLink: new UntypedFormControl(true),
    combobox: new UntypedFormControl(''),
    content: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    isActive: new UntypedFormControl(true),
    iconName: new UntypedFormControl(''),
    position: new UntypedFormControl(null),
    metaDescription: new UntypedFormControl(''),
    metaRobots: new UntypedFormControl(''),
    pageTitle: new UntypedFormControl(''),
    slug: new UntypedFormControl('')
  });

  parents = [];
  comboboxes = [];

  constructor(private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<AddPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  cancel() {
    this.dialogRef.close();
  }

  addNewPage() {
    const dataToSend = {...this.newPageForm.value, position: +this.newPageForm.value.position};

    dataToSend.content = LZUTF8.compress(dataToSend.content, {outputEncoding: "StorageBinaryString"})
    dataToSend.content += `${environment.compressedData}`

    this.sharedService.createPage(dataToSend).subscribe((response) => {
      this.cancel();
      this.sharedService.getList();
    });
  }
  updatePage() {
    const dataToSend = {...this.newPageForm.value, position: +this.newPageForm.value.position, id: this.data.id};

    dataToSend.content = LZUTF8.compress(dataToSend.content, {outputEncoding: "StorageBinaryString"})
    dataToSend.content += `${environment.compressedData}`

    this.sharedService.updatePage(dataToSend).subscribe((response) => {
      this.cancel();
      this.sharedService.getList();
    });
  }

  ngOnInit(): void {
    this.sharedService.navigationList.subscribe(list => {
      list.forEach(element => {
        if (!this.parents.includes(element.parent)) {
          this.parents.push(element.parent);
        }
        if (element.combobox && !this.comboboxes.includes(element.combobox)) {
          this.comboboxes.push(element.combobox);
        }
      });
    });

    if (!this.parents.includes('no link')) {
      this.parents.push('no link');
    }

    if (Object.keys(this.data).length !== 0 && this.data.constructor === Object) {
      Object.keys(this.data).forEach(key => {
        if (this.newPageForm.controls[key]) {
          this.newPageForm.controls[key].setValue(this.data[key]);
        }
      });
    }
  }

}
