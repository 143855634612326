import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) {}

  getAllCategories(model):Observable<any> {
    
    if (!model) {
      model = {};
    }
    const {flags} = model;
    delete model.flags;
    model.$orderby = 'Name';  // temporary sorting
    return this.http.get<any>(environment.base + `/api/category/type/${flags}`, {params: model});
  }

  getCategory(model) {
    return this.http.get(environment.base + `/api/category/${model.id}`).toPromise();
    // return this.CategoryResource.getCategory(model).$promise;
  }

  addCategory(model):Observable<any> {
    return this.http.post<any>(environment.base + `/api/category`, model);
    // return this.CategoryResource.addCategory({}, model).$promise;
  }

  updateCategory(model):Observable<any> {
    
    // category?flags=0&id=4bba4266-5c2d-e611-bdd9-2c768adf12b3
    return this.http.put(environment.base + `/api/category?flags=${model.flags}&id=${model.id}`, model);
    // return this.CategoryResource.updateCategory({}, model).$promise;
  }

  removeCategory(id):Observable<any> {
    return this.http.delete<any>(environment.base + `/api/category/${id}`);
    // return this.CategoryResource.removeCategory({categoryId: id}).$promise;
  }

  getCategoryTests(path,id):Observable<any> {
    // if (!params.$orderby) {
    //   params.$orderby = 'Name';
    // }  // default sorting
    return this.http.get<any>(environment.base + `/api/category/browse/${id}?$orderby=Name${path}`);
    // return this.CategoryResource.getProductsForCategory(params).$promise;
  }

  search(model): Observable<any> {
    return this.http.post<any>(environment.base + `/api/category/search/`, model);
    // return this.CategoryResource.search(model).$promise;
  }

  getCategoriesWithProducts(flag, top) {
    return this.http.get(environment.base + `/api/taxonomy/category/list/${flag}/${top}`);
    // return this.TaxonomyResource.getCategoriesWithProducts({'flags': flag}, {'top': top}).$promise;
  }

  getPanelsWithProducts(productType) {
    return this.http.get(environment.base + `/api/taxonomy/product/type/${productType}`);
  }
}
