<div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>
<app-modal-logo></app-modal-logo>
<mat-dialog-content class="mat-typography">
  <app-symptom *ngIf="!ineligible && !eligible" [(eligible)]="eligible"></app-symptom>
  <app-pcr-test
    *ngIf="eligible === 1"
    [(eligible)]="eligible"
    (optionChange)="this.option=$event"
  ></app-pcr-test>

  <app-covid-contact
    *ngIf="eligible === 2"
    [(eligible)]="eligible"
  ></app-covid-contact>

  <app-saliva-fifth-screen
    *ngIf="eligible===3"
    [(eligible)]="eligible"
    (ScreeningQuestion5Change)="this.ScreeningQuestion5 = $event"
    class="row showScreeningQuestion5" style="text-align: center"
  ></app-saliva-fifth-screen>

  <app-saliva-sixth-screen
    *ngIf="eligible===4"
    [(eligible)]="eligible"
    (ScreeningQuestion6Change)="this.ScreeningQuestion6 = $event"
    class="row showScreeningQuestion4" style="text-align: center"
  ></app-saliva-sixth-screen>

  <app-saliva-fourth-screen
    *ngIf="eligible===5"
    [(eligible)]="eligible"
    (ScreeningQuestion4Change)="this.ScreeningQuestion4 = $event"
  ></app-saliva-fourth-screen>

  <app-covid-consent *ngIf="eligible===5" [validFour]="ScreeningQuestion4?.valid" (consentClick)="onConsentClick()"></app-covid-consent>

  <!--  <app-saliva-fourth-screen></app-saliva-fourth-screen>-->
  <!--  <app-demografic-info *ngIf="eligible===5"></app-demografic-info>-->
  <section *ngIf="eligible===999">
    <h1></h1>
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-danger">
          <div class="panel-heading">
            <h3 class="panel-title">
              Ineligible for TESTING
            </h3>
          </div>
          <div class="panel-body text-danger">
            Based on your response, you may still have an active COVID-19 infection and this test
            may not be right for you at this time. COVID-19 Immune Response testing is specifically
            used to check for an immune response to the virus which can take time after an active
            infection.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="ineligible2">
    <h1></h1>
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-danger">
          <div class="panel-heading">
            <h3 class="panel-title">
              Ineligible for TESTING
            </h3>
          </div>
          <div class="panel-body text-danger">
            Based on your response, testing is not recommended for you at this time. Please complete this assessment
            when your symptoms have improved. If your symptoms do not
            improve, please contact your healthcare provider for evaluation
          </div>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>
