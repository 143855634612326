
<div class="popup warningPop">

    <div class="cancel" (click)="no()"><i class="fa fa-times"></i></div>

    <h2><i class="fa fa-exclamation-triangle"></i> {{data.question}}</h2>
    <div class="row text-center">
        <button (click)="no()" class="btn btn-danger" mat-button [mat-dialog-close]="true"><i class="fa fa-times"></i> No</button>
        <button (click)="yes()" class="btn btn-warning" mat-button [mat-dialog-close]="true"><i class="fa fa-check"></i> Yes</button>
    </div>

</div>