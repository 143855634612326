import {Component, OnDestroy, OnInit} from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import {Meta, Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'all-tests',
  templateUrl: './all-tests.component.html',
  styleUrls: ['./all-tests.component.scss']
})
export class AllTestsComponent implements OnInit, OnDestroy {
  tests;
  title = 'Comprehensive Lab Test Catalog | Grassroots Labs Full Listing';
  defaultTitle = 'Grassroots Labs - Blood Tests are Expensive. We fix that.';
  metaDescription = 'Browse our full listing of lab tests to find exactly what you need for your health journey. At Grassroots Labs, we offer a complete range of affordable, transparently-priced diagnostics, supported by healthcare providers.';
  testsCount;
  getTestsFunction = this.getAllProducts.bind(this);
  constructor(
    private readonly productService: ProductService,
    private metaService: Meta,
    private titleService: Title
  ) { }

  ngOnDestroy(): void {
    this.titleService.setTitle(this.defaultTitle);
    this.metaService.removeTag('name=\'description\'');
  }
  ngOnInit() {
    this.metaService.addTag({ name: 'description', content: this.metaDescription});
    this.titleService.setTitle(this.title);
    this.productService.getAllProducts('&$skip=0&$top=10') // gets all types of products OnInit
        .subscribe((result) => {
            this.tests = result['items'];
            this.testsCount = result['count'];
      });
  }

  getAllProducts(params,key?){
    this.productService.getAllProducts(params.path,undefined).subscribe((result) => {
      this.tests = result['items'];
      this.testsCount = result['count'];
}) // gets all types of products
}

getPanelProducts(params){
  this.productService.getPanelProducts(params) // gets all types of products
      .subscribe(function (result) {
          this.tests = result['items'];
          this.testsCount = result['count'];
      });
}
}
