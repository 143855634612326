<div class="saved-counter-wrapper">
  <h1>Thousands Served. Millions ($$$) Saved.</h1>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-4">
        <div>
          <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="i" x1="50%" x2="50%" y2="100%">
                <stop stop-color="#424242" offset="0" />
                <stop stop-color="#3A3A3A" offset="1" />
              </linearGradient>
              <linearGradient id="h" x1="50%" x2="50%" y2="100%">
                <stop stop-color="#FA7764" offset="0" />
                <stop stop-color="#F96151" offset="1" />
              </linearGradient>
              <linearGradient id="g" x1="50%" x2="50%" y2="100%">
                <stop stop-color="#C29B7C" offset="0" />
                <stop stop-color="#8E6246" offset="1" />
              </linearGradient>
              <linearGradient id="f" x1="50%" x2="50%" y2="100%">
                <stop stop-color="#EEB490" offset="0" />
                <stop stop-color="#FFCFA8" offset="1" />
              </linearGradient>
              <linearGradient id="e" x1="50%" x2="50%" y2="100%">
                <stop stop-color="#E7EDFC" offset="0" />
                <stop stop-color="#E1E5F2" offset="1" />
              </linearGradient>
              <linearGradient id="a" x1="50%" x2="50%" y2="100%">
                <stop stop-color="#FDE8DB" offset="0" />
                <stop stop-color="#FACCB4" offset="1" />
              </linearGradient>
              <linearGradient id="d" x1="50%" x2="50%" y2="100%">
                <stop stop-color="#F4BD50" offset="0" />
                <stop stop-color="#EB9F0E" offset="1" />
              </linearGradient>
              <linearGradient id="c" x1="50%" x2="50%" y2="100%">
                <stop stop-color="#FDC39F" offset="0" />
                <stop stop-color="#FFCFA8" offset="1" />
              </linearGradient>
              <linearGradient id="b" x1="50%" x2="50%" y2="100%">
                <stop stop-color="#DBF2FD" offset="0" />
                <stop stop-color="#B4E1FA" offset="1" />
              </linearGradient>
            </defs>
            <path transform="translate(1 6)"
              d="M6.503 4.48C1.38 4.48-.015 6.87 0 9.716c.015 2.845 1.64 4.502 2.207 4.845.568.342 2.127 1.142 4.296 1.142 2.17 0 3.728-.8 4.296-1.142.146-.088 2.189-2.502 2.201-4.845.012-2.344-1.373-5.236-6.497-5.236z"
              fill="url(#i)" />
            <path
              d="M12.409 24.13 9.52 22.927a.647.647 0 0 1-.398-.598v-.782H5.885v.782a.647.647 0 0 1-.399.598L2.598 24.13a.971.971 0 0 0-.598.897v1.295c0 .357.29.647.647.647h9.712c.357 0 .647-.29.647-.647v-1.295a.971.971 0 0 0-.597-.897z"
              fill="#845A40" />
            <path transform="translate(1 6)"
              d="m8.727 16.553.366.549-.036.048 2.352.98a.971.971 0 0 1 .589.768l.008.129v1.295c0 .357-.29.647-.647.647H1.647A.647.647 0 0 1 1 20.322v-1.295c0-.392.236-.746.598-.897l2.35-.98-.035-.048.367-.55a.259.259 0 0 1 .37-.063l1.853 1.39 1.853-1.39a.259.259 0 0 1 .37.064z"
              fill="url(#h)" />
            <path
              d="M13.006 26.322v-1.295a.969.969 0 0 0-.274-.674l-1.304 1.03a.971.971 0 0 0-.364.758v.828h1.295c.357 0 .647-.29.647-.647z"
              fill="#E4EAF6" />
            <path transform="translate(1 6)"
              d="M8.122 9.315c-1.619 1.619-4.795.324-4.795 2.914l.2 1.907a1.3 1.3 0 0 0 .661 1.015l1.843 1.024c.294.163.65.163.944 0l1.843-1.024c.374-.208.622-.588.66-1.015l.233-2.265c.016-.161.02-.323.014-.485-.12-.824-1.28-1.423-1.603-2.07z"
              fill="url(#g)" />
            <path transform="translate(1 6)" d="m28.62 16.625v-1.778h-4.572v1.778l2.286 2.032z" fill="url(#f)" />
            <path transform="translate(1 6)"
              d="M28.655 16.06a.254.254 0 0 1 .382.048l.345.517-.075.162 2.847.67c.421.099.729.451.777.873l.007.116v1.99c0 .28-.228.507-.508.507H20.238a.508.508 0 0 1-.508-.508v-1.989c0-.471.324-.881.783-.989l2.846-.67-.073-.162.345-.517a.254.254 0 0 1 .381-.048l2.322 2.09z"
              fill="url(#e)" />
            <path transform="translate(1 6)"
              d="m29.636 9.006-.117 2.549a.526.526 0 0 1 .609.644l-.287 1.147a.305.305 0 0 1-.228.224l-.068.007a.305.305 0 0 1-.116-.022l-.015.32a1.52 1.52 0 0 1-.608 1.15l-1.05.788a1.524 1.524 0 0 1-.914.304h-1.016c-.33 0-.65-.107-.914-.304l-1.05-.788a1.524 1.524 0 0 1-.609-1.15l-.221-4.87h6.604z"
              fill="url(#a)" />
            <path
              d="m32.073 25.295 1.646-1.479c.138.175.219.395.219.63v1.99c0 .28-.228.507-.508.507h-1.778v-.703c0-.36.153-.704.421-.945z"
              fill="#E2E7F4" />
            <path
              d="M27.969 24.15v.146c0 .157-.06.3-.156.409l.156 2.238h-1.27l.155-2.238a.613.613 0 0 1-.155-.409v-.147h1.27z"
              fill="#5B5D6E" />
            <path
              d="M27.334 12.212c1.382-.024 2.175.488 2.543.97 1.668.25 1.504 3.347 1.504 3.347-.65.715-.508 1.778-.508 1.778l-.415-.643a.501.501 0 0 1-.08-.248c-.02-.433-.146-1.825-.934-2.285-.415.24-1.04.383-1.936.383-.793 0-1.571-.27-2.46.253-.875.516-.787 1.784-.766 2l.004.032-.508.508s0-1.27-.508-1.778c0 0-.318-2.15 1.381-3.405l-.376-.256c.773-.402 2.265-.643 3.059-.656z"
              fill="#785550" />
            <path
              d="m23.54 18.199.287 1.147a.305.305 0 0 0 .6-.036l.146-1.174a.526.526 0 0 0-.522-.59h-.001a.526.526 0 0 0-.51.653z"
              fill="#E6AF78" />
            <path
              d="M17.978 6.031c-8.71 0-7.744 10.593-7.704 18.269a.82.82 0 0 0 .401.7c.965.583 3.616 1.943 7.303 1.943 3.688 0 6.339-1.36 7.304-1.942a.82.82 0 0 0 .401-.701c.04-7.676 1.006-18.269-7.705-18.269z"
              fill="#694B4B" />
            <path transform="translate(1 6)"
              d="M16.978 20.943c-3.687 0-6.338-1.36-7.303-1.942a.82.82 0 0 1-.401-.701C9.234 10.795 8.316.507 16.417.053a7.83 7.83 0 0 1 .561-.022c8.711 0 7.745 10.593 7.705 18.269a.82.82 0 0 1-.401.701c-.965.582-3.616 1.942-7.304 1.942z"
              fill="url(#d)" />
            <path transform="translate(1 6)"
              d="m25.318 19.97-4.91-2.047a1.1 1.1 0 0 1-.678-1.016v-1.33h-5.503v1.33a1.1 1.1 0 0 1-.677 1.016L8.64 19.97a1.651 1.651 0 0 0-1.017 1.524v2.2a1.1 1.1 0 0 0 1.1 1.101h16.51a1.1 1.1 0 0 0 1.1-1.1v-2.201c0-.667-.4-1.268-1.015-1.524z"
              fill="url(#c)" />
            <path transform="translate(1 6)"
              d="M13.198 17.287a.44.44 0 0 1 .63-.108l3.15 2.362 3.15-2.362a.44.44 0 0 1 .63.108l.623.934-.06.083 3.997 1.666a1.65 1.65 0 0 1 1.009 1.371l.007.153v2.2a1.1 1.1 0 0 1-1.1 1.101H8.723a1.1 1.1 0 0 1-1.1-1.1v-2.201c0-.667.4-1.268 1.015-1.524l3.996-1.666-.059-.083z"
              fill="url(#b)" />
            <path
              d="m11.305 28.098-2.208-1.757a1.647 1.647 0 0 0-.474 1.152v2.202a1.1 1.1 0 0 0 1.1 1.1h2.202v-1.407c0-.502-.228-.976-.62-1.29zm16.029 1.597v-2.202c0-.436-.175-.843-.466-1.145l-2.216 1.75c-.392.314-.62.788-.62 1.29v1.407h2.201a1.1 1.1 0 0 0 1.1-1.1zm-9.356 0a.54.54 0 0 1-.55-.53v-3.47h1.1v3.47a.54.54 0 0 1-.55.53z"
              fill="#B4E1FA" />
            <path transform="translate(1 6)"
              d="M19.73 4.984c-2.752 2.752-8.151.55-8.151 4.953l.341 3.243a2.201 2.201 0 0 0 1.123 1.725l3.134 1.74a1.65 1.65 0 0 0 1.603 0l3.134-1.74c.636-.354 1.057-1 1.123-1.725l.394-3.852c.028-.273.034-.549.025-.824-.206-1.4-2.176-2.42-2.726-3.52z"
              fill="url(#a)" />
            <path d="M22.381 29.695H20.18a.55.55 0 0 0-.55.55v.55h3.301v-.55a.55.55 0 0 0-.55-.55z" fill="#B4E1FA" />
          </svg>
          <div>
            <h1>{{ customersServedCurrentNumber }}</h1>
            <p>Customers Served</p>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <linearGradient id="a-11" x1="50%" x2="50%" y1="-36.519%" y2="118.732%">
                <stop offset="0%" stop-color="#FFCA35"></stop>
                <stop offset="100%" stop-color="#FEBB00"></stop>
              </linearGradient>
              <linearGradient id="b-12" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stop-color="#7B939D"></stop>
                <stop offset="100%" stop-color="#455A64"></stop>
              </linearGradient>
              <linearGradient id="c-13" x1="50%" x2="100%" y1="19.133%" y2="101.882%">
                <stop offset="0%" stop-color="#728892"></stop>
                <stop offset="100%" stop-color="#455A64"></stop>
              </linearGradient>
            </defs>
            <path fill="url(#a-11)"
              d="M31.706 4.352a.999.999 0 0 0-.766-.36H6.707c-.31 0-.603.144-.793.39a1.005 1.005 0 0 0-.171.866l3.194 11.937c.117.439.513.74.964.74.016 0 .034 0 .05-.002l19.042-.958a1 1 0 0 0 .933-.816L31.922 5.17a.992.992 0 0 0-.216-.818z"
              transform="translate(1 3)"></path>
            <g transform="translate(1 3)">
              <circle cx="12.975" cy="26.948" r="2.994" fill="url(#b-12)"></circle>
              <circle cx="24.951" cy="26.948" r="2.994" fill="url(#b-12)"></circle>
              <path fill="url(#c-13)"
                d="M26.947 21.957H10.98a.996.996 0 0 1-.965-.74L4.882 1.997H.998A.998.998 0 0 1 .998 0H5.65c.453 0 .848.304.964.74l5.132 19.221h15.202a.998.998 0 0 1 0 1.996z">
              </path>
            </g>
          </svg>
          <div>
            <h1>{{ labTestsOrderedCurrentNumber }}</h1>
            <p>Lab Tests Ordered</p>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" class="injected-svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <linearGradient id="a-14" x1="50%" x2="50%" y1="-197.851%" y2="153.957%">
                <stop offset="0%" stop-color="#FFE7A3"></stop>
                <stop offset="100%" stop-color="#FFC219"></stop>
              </linearGradient>
              <linearGradient id="b-15" x1="50%" x2="50%" y1="157.443%" y2="234.048%">
                <stop offset="0%" stop-color="#FFE7A3"></stop>
                <stop offset="100%" stop-color="#FFC219"></stop>
              </linearGradient>
              <linearGradient id="c-16" x1="46.771%" x2="50.419%" y1="-295.722%" y2="94.929%">
                <stop offset="0%" stop-color="#FFE7A3"></stop>
                <stop offset="100%" stop-color="#FFC219"></stop>
              </linearGradient>
            </defs>
            <g transform="translate(2 2)">
              <circle cx="16.495" cy="15.505" r="15.505" fill="#EFB014"></circle>
              <circle cx="15.505" cy="15.505" r="15.505" fill="url(#a-14)"></circle>
              <circle cx="15.505" cy="15.505" r="11.457" fill="url(#b-15)"></circle>
              <path fill="#EFB014"
                d="M5.038 15.505C5.038 9.37 9.87 4.323 16 4.058h-.495C9.177 4.06 4.05 9.19 4.051 15.518c.002 6.325 5.13 11.452 11.454 11.454H16c-6.137-.265-10.973-5.324-10.962-11.467z">
              </path>
              <path fill="#FFF"
                d="M15.49 28.52c-2.523 0-5.022-.832-7.041-2.343a.33.33 0 0 1 .395-.528 11.16 11.16 0 0 0 6.645 2.21c.006 0 .014.003.016 0a.33.33 0 0 1 .33.33c0 .188-.165.33-.346.33zm10.19-17.963a.33.33 0 0 1-.293-.178 11.24 11.24 0 0 0-5.237-4.99.33.33 0 1 1 .277-.599 11.902 11.902 0 0 1 5.545 5.284.33.33 0 0 1-.292.483zM17.554 4.56a.32.32 0 0 1-.058-.006 11.35 11.35 0 0 0-1.988-.176.32.32 0 0 1-.333-.33.33.33 0 0 1 .33-.33h.003c.703 0 1.411.063 2.104.186a.33.33 0 0 1-.058.655z">
              </path>
              <path fill="url(#c-16)"
                d="M16.32 14.783v-4.338a2.2 2.2 0 0 1 1.389 2.042.815.815 0 0 0 1.63 0 3.837 3.837 0 0 0-3.019-3.741V7.96a.815.815 0 0 0-1.63 0v.786a3.827 3.827 0 0 0 0 7.482v4.338a2.2 2.2 0 0 1-1.389-2.042.815.815 0 0 0-1.63 0 3.837 3.837 0 0 0 3.02 3.74v.786a.815.815 0 0 0 1.629 0v-.785a3.827 3.827 0 0 0 0-7.482zm-3.019-2.31a2.2 2.2 0 0 1 1.39-2.042v4.084a2.2 2.2 0 0 1-1.39-2.028v-.014zm3.019 8.08v-4.058a2.197 2.197 0 0 1 0 4.084v-.026z">
              </path>
            </g>
          </svg>
          <div>
            <h1>${{ customerSavingsCurrentNumber }}</h1>
            <p>Customer Savings</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
