<div class="popup forms">

    <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="logo">
        <img src="../../../assets/img/logo_white.png" alt="">
    </div>
    <h1 class="text-center">Please, enter password for administrator</h1>
    <form [formGroup]="loginAsForm">
        <div class="form-group">
            <label for="password">Password</label>
            <input formControlName="password" [type]="showPassword ? 'text' : 'password'" class="form-control" id="exampleInputPassword1" placeholder="Password">
            <button mat-icon-button (click)="showPassword = !showPassword" type="button" id="visibilityButton">
                <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
              </button>
        </div>

        <button  type="submit" class="btn btn-default" (click)="loginAs()" ><i class="fa fa-sign-in"></i> {{data.info.update? 'Confirm' : 'LOGIN'}} </button>
    </form>
</div>