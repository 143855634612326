import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {BlogArticle} from "../entities/blog/BlogArticle";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient) {}

  getById(id: string): Observable<any>{
  return this.http.get<any>(environment.base + `/api/blog/get?articleId=${id}`);
}

  addArticle(article: BlogArticle) {
    return this.http.post<any>(environment.base + `/api/blog`, article);
  }

  updateArticle(article: BlogArticle) {
    return this.http.put<any>(environment.base + `/api/blog/update`, article);
  }

  removeArticle(articleId: string) :Observable<any> {
    return this.http.delete(environment.base + `/api/blog/delete?articleId=${articleId}`);
  }

  getLastArticle(): Observable<BlogArticle>{
    return this.http.get<BlogArticle>(environment.base + `/api/blog/last`);
  }

  getTopArticleOnMonth(): Observable<BlogArticle[]>{
    return this.http.get<BlogArticle[]>(environment.base + `/api/blog/month/top`);
  }

  getArticleBySlug(slug: string): Observable<BlogArticle>{
    return this.http.get<BlogArticle>(environment.base + `/api/blog/get/slug?slug=${slug}`);
  }

  getTopArticlesOnAllTime(): Observable<BlogArticle[]>{
    return this.http.get<BlogArticle[]>(environment.base + `/api/blog/top`);
  }
  getTopNewest(): Observable<BlogArticle[]>{
    return this.http.get<BlogArticle[]>(environment.base + `/api/blog/top/newest`);
  }

  getAllArticles(searchWord: string= ''): Observable<any>{
    return this.http.get<any>(environment.base + `/api/blog/get/all`);
  }

  getAllArticlesForAdmin(searchWord: string= ''): Observable<any>{
    return this.http.get<any>(environment.base + `/api/blog/get/all/admin`);
  }
  getAllAuthors(): Observable<any>{
    return this.http.get<any>(environment.base + `/api/blog/get/all/authors`);
  }
}


