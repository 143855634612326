<div class="container orderHistory">
  <div class="col-md-12 top10">
    <!--<div class="dashboard">
      <div class="card">
        <div class="card__header">
          <p class="card__headline">Saving Statistics</p>
        </div>

        <div class="card__chart">
          <div class="card__axis">
            <div class="card__row card__row&#45;&#45;top">460</div>
            <div class="card__row card__row&#45;&#45;middle">230</div>
            <div class="card__row card__row&#45;&#45;bottom">0</div>
          </div>

          <div class="card__column">
            <div class="card__bar" style="&#45;&#45;index: 1; color: white; display: flex; justify-content: center; align-items: center;">grl website prices</div>
          </div>
          <div class="card__column">
            <div class="card__bar" style="&#45;&#45;index: 5; color: white; display: flex; justify-content: center; align-items: center;">market prices</div>
          </div>
        </div>
      </div>
    </div>-->
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let order of orders; let i = index"
        [expanded]="i === 0"
        (click)="togglePanel(order.id)"
        [ngStyle]="{
          border: order.isOpen ? '1px solid #E9E9E9' : '',
          'box-shadow': !order.isOpen
            ? 'none'
            : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        }"
      >
        <mat-expansion-panel-header
          [ngStyle]="{
            border: !order.isOpen ? '1px solid #E9E9E9' : '0px',
            'border-radius': !order.isOpen ? '5px' : '0px'
          }"
        >
          <div class="info">
            <small class="panel-header-label">Order ID:</small>
            <span class="panel-header-item">{{ order.number }}</span>

            <small class="panel-header-label space"> Date:</small>
            <span class="panel-header-item">
              {{
                (order.transactions.length > 0
                    ? order.transactions[0].transactionDate
                    : order.createdDate
                ) | date
              }}
            </span>
            <small class="panel-header-label space"> Order for:</small>
            <span class="panel-header-item">{{ order.userFullName }}</span>
            <small class="panel-header-label space"> Status:</small>
            <span class="panel-header-item">{{
                getOrderStatus(order.status)
              }}</span>
            <small class="panel-header-label space"> Order Total:</small>
            <span class="panel-header-item">{{
                order.orderTotal | currency
              }}</span>
          </div>
          <div class="mobileReceipt">
            <a
              class="btn btn-success pull-right"
              placement="left"
              ngbTooltip="Preview and print the receipt of this order."
              type="button"
              (click)="
                $event.stopPropagation();
                printReceipt(
                  order,
                  order.transactions.length > 0
                    ? order.transactions[0].transactionDate
                    : order.createdDate,
                  order.processingFee ||
                    order.physicianOversightFee + order.labDrawFee,
                  order.orderTotal
                )
              "
              style="font-size: 14px; padding: 4px 12px; margin-right: 20px"
            ><i class="fa fa-print"></i> Receipt</a
            >
          </div>
        </mat-expansion-panel-header>
        <div>
          <div *ngIf="order.info">
            <ng-container
              *ngFor="
                let item of order.info.order.items
                  | orderBy : 'product.productType';
                let i = index;
                trackBy: trackByFn;
                let last = last;
                let first = first
              "
            >
              <div class="divBtns">
                <div>
                  <i class="fa fa-caret-right"></i>
                  <a [href]="'product/' + item.product.slug">{{
                      item.product.name
                    }}</a>
                  <ng-container *ngIf="item.children.length">
                    <ul style="margin-top: 10px">
                      <li *ngFor="let child of item.children; let i = index">
                        <a [href]="'product/' + child?.product?.slug">{{
                            child.product.name
                          }}</a>
                      </li>
                    </ul>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="
                  checkuserSentEmailHistories(order) &&
                  i == order.info.order.items.length - 1
                "
                class="sendEmailHistory"
              >
                <strong>Send email history</strong>
                <div
                  class="mailHistory"
                  *ngFor="let emailHistory of order.userSentEmailHistories"
                >
                  <i class="fa fa-caret-right"></i>
                  The
                  <strong>
                    {{
                      emailHistory.fileType.charAt(0).toUpperCase() +
                      emailHistory.fileType.slice(1)
                    }}
                  </strong>
                  for Order <strong>{{ order.number }}</strong> were sent via
                  encrypted email to
                  <strong>{{ emailHistory.contactTo }}</strong> on
                  <strong>{{ formatSendedDate(emailHistory.sendedDate) }} EST.</strong
                  >
                </div>
              </div>
              <div
                class="buttonKeeper my_class"
                *ngIf="i == order.info.order.items.length - 1"
              >
                <div class="mobileBtns">
                  <a
                    class="btn btn-success pull-right"
                    *ngIf="
                      order.status != 4 && !this.checkRequisitionLink(order)
                    "
                    style="margin-left: 5px"
                  >
                    <app-count-down
                      class="btnTextNewLine"
                      [dateCreated]="
                        order.transactions.length > 0
                          ? order.transactions[0].transactionDate
                          : order.createdDate
                      "
                    ></app-count-down>
                  </a>
                  <div
                    style="display: inline-block"
                    [ngbTooltip]="
                      checkResultLink(order)
                        ? 'This order has been completed. Please click the Results button to download your results.'
                        : 'Click to view, download, or share your lab requisition / order.'
                    "
                    placement="left"
                  >
                    <button
                      mat-button
                      [matMenuTriggerFor]="menuRequisition"
                      #t="matMenuTrigger"
                      class="btn btn-success"
                      style="background-color: #5cb85c; color: white"
                      *ngIf="this.checkRequisitionLink(order)"
                      [disabled]="checkResultLink(order)"
                    >
                      <i class="fa fa-file-pdf-o"></i> Requisition
                      <i
                        [ngClass]="{
                          'fa-angle-up': t.menuOpen,
                          'fa-angle-down': !t.menuOpen
                        }"
                        class="fa"
                      ></i>
                    </button>
                    <mat-menu #menuRequisition="matMenu" class="dropdownMenu" >
                      <button
                        class="btn btn-primary "
                        (click)="openModalShare(order, 1)"
                        [disabled]="isAdmin"
                        style="margin-left: 5px"
                      >
                        Share
                      </button>
                      <button
                        class="btn btn-success"
                        style="margin-right: 5px"
                        (click)="iframeDownload(order, 'requisition.pdf')"
                      >
                        View
                      </button>
                    </mat-menu>
                  </div>
                  <div
                    style="display: inline-block"
                    placement="left"
                    ngbTooltip="This button will activate and turn green when your results are ready to download."
                  >
                    <button
                      class="btn btn-secondary disabled"
                      *ngIf="!checkResultLink(order)"
                    >
                      <i class="fa fa-file-pdf-o"></i> Results Pdf
                    </button>
                  </div>
                  <div *ngIf="checkResultLink(order)" class="resultPdf">
                    <button
                      class="btn btn-success"
                      mat-button
                      [matMenuTriggerFor]="menuResults"
                      #tr="matMenuTrigger"
                      style="color:white; background-color: #5cb85c"
                      placement="left"
                      ngbTooltip="Click to view or share your lab results."
                    >
                      <i class="fa fa-file-pdf-o"></i> Results Pdf
                      <i
                        [ngClass]="{
                          'fa-angle-up': tr.menuOpen,
                          'fa-angle-down': !tr.menuOpen
                        }"
                        class="fa"
                      ></i>
                    </button>
                    <mat-menu #menuResults="matMenu" class="dropdownMenu">
                      <button
                        class="btn btn-primary marginBottom"
                        (click)="openModalShare(order, 3)"
                        [disabled]="isAdmin"
                        style="margin-left: 5px"
                      >
                        Share
                      </button>
                      <button
                        class="btn btn-success"
                        (click)="iframeDownload(order, 'result.pdf')"
                        style="margin-right: 5px"
                      >
                        View
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- <div class="text-center  top10">
            <button class="btn btn-warning" style="width: 100%;">
            <i class="fa fa-plus-square-o"></i> Re-Order
            </button>
          </div> -->
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel disabled style="margin-bottom: 10px">
        <mat-expansion-panel-header *ngIf="isEmpty">
          <h3 style="width: 100%; text-align: center; margin: 0px">
            No orders created yet.
          </h3>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
