<h1>
  Please select the statement that best describes your symptoms.
</h1>
<div class="col-md-4 panel-body">
  <button class="btn btn-primary" (click)="screeningQuestion1Click('', 999)">
    <img src="../../../assets/img/tempereture.png"/>
    <ul>
      <li *ngFor="let symptom of ScreeningQuestion1.symptoms1">{{symptom.text}}</li>
    </ul>
  </button>

</div>

<div class="col-md-4 panel-body">
  <button class="btn btn-primary" (click)="screeningQuestion1Click( 'symptoms2',2)">
    <img src="../../../assets/img/worse.png"/>
    <ul>
      <li *ngFor="let symptom of ScreeningQuestion1.symptoms2">{{symptom.text}}</li>
    </ul>
  </button>
</div>

<div class="col-md-4 panel-body">
  <button class="btn btn-primary" (click)="screeningQuestion1Click('symptoms3',2)">
    <img src="../../../assets/img/happy.png">
    <ul>
      <li>{{ScreeningQuestion1.symptoms2[0].text}}</li>
    </ul>
  </button>
</div>
