import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/services/modal.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-print-receipt',
  templateUrl: './print-receipt.component.html',
  styleUrls: [ './print-receipt.component.scss' ]
})
export class PrintReceiptComponent implements OnInit {
  orderInfo;
  testsInfo;
  firstName;
  lastName;
  birthDate;
  physiciansName;
  physiciansNPI;
  lab;
  total;
  processingFee = environment.processingFee;
  physicianFee = environment.physicianFee;
  labDrawFee = environment.labDrawFee;
  orderDate;
  orderNumber;
  requistion: any;
  cardType: any;
  lastDigits: any;
  panelTest: any;
  duplicateChilds = [];
  discount = 0;
  promoCodeName = '';
  promoCodeDiscount = 0;
  subTotal = 0;
  constructor(private modalService: ModalService, @Inject(MAT_DIALOG_DATA) public data, private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.activate(this.data.data.order);
  }

  cancel() {
    this.modalService.dialog.closeAll();
  }

  print() {
    const myWindow = window.open('', 'PRINT', 'height=400,width=600');

    myWindow.document.write('<html><head><title>' + document.title + '</title>');
    myWindow.document.write('</head><body >');
    myWindow.document.write('<h1>' + document.title + '</h1>');
    myWindow.document.write(document.getElementById('print-target').innerHTML);
    myWindow.document.write('</body></html>');

    myWindow.document.close(); // necessary for IE >= 10
    myWindow.focus(); // necessary for IE >= 10*/
    setTimeout(() => {
      myWindow.print();
      myWindow.close();
    }, 250);
  }

  activate(orderInfo) {
    this.orderInfo = orderInfo.info.result;
    this.testsInfo = orderInfo.info.order.items;
    this.promoCodeName =  orderInfo.info.order.promoCode
    this.subTotal = orderInfo.info.order.subTotal
    // console.log('orderInfo.info.order',orderInfo.info.order)
    this.promoCodeDiscount = (Number(orderInfo.info.order.orderTotalWithoutPromo) - Number(orderInfo.info.order.subTotal));
    // console.log('orderTotalWithoutPromo: ',orderInfo.info.order.orderTotalWithoutPromo,'subTotal: ',orderInfo.info.order.subTotal , 'orderTotal',orderInfo.info.order.orderTotal)
    // console.log('orderInfo.info.order', orderInfo.info.order, 'promoCodeDiscount', this.promoCodeDiscount)
    orderInfo.info.order.items.map(x => {
      // this.subTotal += x.orderItemInitialPrice;
      if(x.promoCode != ''){
        this.promoCodeName = x.promoCode;
        // this.promoCodeDiscount = Number(x.orderItemInitialPrice) - Number(x.orderItemPrice)
      }
    });
    this.duplicateChilds = this.orderInfo.items.reduce((a, e) => {
      if (a[e.product.id]){
        a[e.product.id].push(e);
      }else{
        a[e.product.id] = [];
      }
      return a;
    }, []);
    this.duplicateChilds = Object.values(this.duplicateChilds).filter(e => e.length);
    this.discount = 0;
    this.duplicateChilds.forEach((current) => {
        this.discount += (( current[0].orderItemInitialPrice || current[0].masterPrice) * current.length);       
      });
    this.panelTest = this.orderInfo.items.find(x => x.product.slug === 'Panel_Test');
    // this.cardType = orderInfo.x.or.creditCardInfo ? orderInfo.x.or.creditCardInfo.split(' ') : '';
    // this.lastDigits = this.cardType ? this.cardType[1].split('***') : '';

    this.cardType = orderInfo.info.order.creditCardInfo || '';
    this.orderNumber = this.orderInfo.number;
    this.orderDate = this.data.data.order.submittedDate;
    this.processingFee = this.data.data.order.processingFee;
    this.total = orderInfo.orderTotal;
    this.physicianFee = orderInfo.info.order.physicianOversightFee;
    this.labDrawFee = orderInfo.info.order.labDrawFee;
    const orderCount = this.orderInfo.items.length;
    if (orderCount > 0) {
      const profile = this.orderInfo.items[orderCount - 1].profile;
      this.firstName = profile.firstName;
      this.lastName = profile.lastName;
      this.birthDate = profile.birthDate;

      if (this.orderInfo.results.length > 0) {
        this.requistion = this.orderInfo.results[0].apiResultId;
        this.physiciansName = this.orderInfo.results[0].physiciansName;
        this.physiciansNPI = this.orderInfo.results[0].physiciansNPI;
        // this.lab = this.orderInfo.results[0].reconciledResults[0] ? this.orderInfo.results[0].reconciledResults[0].labFacility : '';
      }
    }
  }

}
