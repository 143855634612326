import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-logo',
  templateUrl: './modal-logo.component.html',
  styleUrls: ['./modal-logo.component.scss']
})
export class ModalLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
