import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromoCodeService } from 'src/app/services/promo-code.service';

@Component({
  selector: 'app-promoCode-confirm',
  templateUrl: './promoCode-confirm.component.html',
  styleUrls: ['./promoCode-confirm.component.scss']
})
export class PromoCodeConfirmComponent implements OnInit {
  id: string;
  name: string;
  blured: boolean | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PromoCodeConfirmComponent>
  ) {
    this.id = data.id;
    this.blured = data.isBlured;
    this.name = data.name;
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}
