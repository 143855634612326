import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {AdminPages} from '../../entities/admin/AdminPages';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminDashboardComponent implements OnInit {
  tab = this.route.snapshot.queryParams.code? 6 : 0;
  selectedTab: AdminPages = AdminPages.Transactions;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
  }

  onTabChange(event: any) {
    this.selectedTab = event.index;
  }

  isSelected(page: AdminPages): boolean {
    return this.selectedTab === page;
  }

  public AdminPages = AdminPages;
}

