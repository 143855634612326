import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { CookiesStorageService } from './cookies-storage.service';
import { NotificationService } from './notification.service';
import { SessionInfoStorageService } from './session-info-storage.service';


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(public http: HttpClient,
              public notificationService: NotificationService,
              public sessionInfoStorageService: SessionInfoStorageService, public cookiesStorageService: CookiesStorageService) {
  }

  register(model) {

    model.birthDate = formatDate(model.date, 'MM-dd-yyyy', 'en-US');

    // Object.keys(model).map(key => {
    //   if (!model[key]) {
    //     delete model[key];
    //   }
    // });
    return this.http.post(environment.base + '/api/account/register', model);
    // return this.AccountResource.register(model).$promise;
  }

  confirm(model): Observable<any> {
    return this.http.post<any>(environment.base + '/api/account/register/confirm', model, { observe: 'response' });
    // return this.AccountResource.confirm(model).$promise;

  }
  disableNonTransactionalEmails(id): Observable<any>{
    return this.http.get<any>(environment.base + `/api/keap/disableNonTransactionalEmails?userId=${id}`);
  }
  getUserEmailChangeHistoryByUserId(id): Observable<any>{
    return this.http.get<any>(environment.base + `/api/account/emailHistory?userId=${id}`);
  }
changeNonTransactionalEmails(id, isActive): Observable<any>{
    return this.http.get<any>(environment.base + `/api/account/email/transactional/${id}/${isActive}`);
  }

  forgotPassword(model): Observable<any> {
    return this.http.post<any>(environment.base + '/api/account/pasword/reset', { email: model });
    // return this.AccountResource.forgotPassword(model).$promise;
  }

  confirmForgotPassword(model) {
    return this.http.post(environment.base + '/api/account/password/reset/confirm', model);

    // return this.AccountResource.confirmForgotPassword(model).$promise;
  }

  checkEmail(model) {
    return this.http.post(environment.base + '/api/account/checkEmail', model);
  }

  changeEmail(model) {
    return this.http.post(environment.base + '/api/account/changeUserName', model);
    // return this.AccountResource.changeUserName(model).$promise;
  }

  changePassword(model): Observable<any> {
    return this.http.post(environment.base + '/api/account/changePassword', model);
    // return this.AccountResource.changePassword(model).$promise;
  }

  checkPassword(model): Observable<any> {
    return this.http.post(environment.base + '/api/account/checkPassword', model);
  }

  assignRole(model): Observable<any> {
    return this.http.post<any>(environment.base + '/api/account/role/assign', model);
    // return this.AccountResource.assignRole(model).$promise;
  }

  reverifyEmail(accountId: string): Observable<any> {
    return this.http.post<any>(environment.base + '/api/account/sendConfirmation?accountId=' + accountId.replace(/-/g, ''), null);
  }

  getAllUsers(model): Observable<any> {
    // model
    if (!model) model = {};
    if (!model.$orderby) {
      model.$orderby = 'LastName';
    }
    // default sorting
    return this.http.get<any>(environment.base + `/api/account?$orderby=${ model.$orderby }&$skip=${ model[0].$skip }&$top=${ model[0].$top }`);

    // return this.AccountResource.getAllUsers(model).$promise;
  }

  roleList(): Observable<any> {
    return this.http.get<any>(environment.base + '/api/account/role');
    // return this.AccountResource.roleList().$promise;
  }

  logout(): Observable<any> {
    const logoutPromise = this.http.post<any>(environment.base + '/api/account/logout', null);
    // var logoutPromise = this.AccountResource.logout().$promise;

    logoutPromise.subscribe(() => {
      this.cookiesStorageService.clearAuth();
      this.sessionInfoStorageService.clearToken();
      this.sessionInfoStorageService.cleanAuthInfo();
      this.sessionInfoStorageService.cleanOrderInfo();

      this.notificationService.authenticationState.next('authentication:logout');
      // this.$rootScope.$broadcast('authentication:logout'); !!!nottify
    });
    return logoutPromise;
  }

  mfaControl(id, status){
    return this.http.get<any>(environment.base + `/api/account/twofactor/${id}/${status}`);
  }

  authorize(model: any) {

    model.orderId = this.sessionInfoStorageService.getOrderInfo().orderId;
// https://www.grassrootslabs.io/confirm/65486269-90c4-46a5-945a-a9aa00aa1049/2118764b-2f96-4bb0-8963-583d16840d67      vfh7rqji@payspun.com
//                 console.warn(SessionInfoStorageService.getOrderInfo());
//                 console.warn("LocalStorage orderID =  " + SessionInfoStorageService.getOrderInfo().orderId);
    const body = new URLSearchParams();
    Object.keys(model).map(key => {
      body.set(key, model[key]);
    });
    return this.http.post(environment.base + '/Token', body.toString(),
      {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      }).pipe(
      tap((result: any) => {
        if (result.access_token) {
          this.sessionInfoStorageService.setOrderInfo({
            orderId: result.orderId,
            profileId: result.profileId,
            orderNumber: result.orderNumber
          });
          this.sessionInfoStorageService.setAuthInfo(result);
          if (model.rememberMe) {
            this.cookiesStorageService.saveAuth(result);
          }
          this.notificationService.authenticationState.next('authentication:login');
        }
      }), shareReplay()
    );
  }

  loginAs(model): Observable<any> {
    model.grant_type = 'password';
    const body = new URLSearchParams();
    Object.keys(model).map(key => {
      body.set(key, model[key]);
    });
    return this.http.post<any>(environment.base + '/Token', body.toString(),
      {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      });
    // return this.TokenResource.getToken(model).$promise;
  }

  isAuthorized() {
    return this.sessionInfoStorageService.getToken();
  }

  toggleActiveStatus(model) {
    const accountId = model.accountId;
    const isActive = model.isActive.toString();
    return this.http.post(environment.base + `/api/account/${ accountId }/${ isActive }`, model).toPromise();
    // return this.AccountResource.toggleActiveStatus(model);
  }

  getUserInfo(): Observable<any> {
    return this.http.get<any>(environment.base + '/api/account/userInfo');

    // return this.AccountResource.getUserInfo().$promise;
  }

  search(model): Observable<any> {
    return this.http.post<any>(environment.base + '/api/account/search', model);
    // return this.AccountResource.search(model).$promise;
  }
}

