import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-results-requisition',
  templateUrl: './results-requisition.component.html',
  styleUrls: ['./results-requisition.component.scss']
})
export class ResultsRequisitionComponent implements OnInit {
  shareResultForm: UntypedFormGroup;
  showContent = 1;
  userInfo;
  constructor(
    private modalService: ModalService,
    private orderService: OrderService,
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<ResultsRequisitionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
   this.profileService.getProfile(this.data.info.order.items[0].profile.id).subscribe((_userInfo)=>{
    this.userInfo = _userInfo;
    });

  }

  private buildForm() {
    this.shareResultForm = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required, Validators.email,]),
      order: new UntypedFormControl('')
    })
  }
  showConfirmation(next = true) {
    if(next){
      this.showContent++;
    }else{
      this.showContent--;
    }
  }

  shareModalSend() {
    // this.orderService.testGet(this.data.info.order.id).subscribe((testInfo: any) => {
      // this.shareResultForm.controls['order'].setValue(this.data.info.order.id)
      this.orderService.shareModalSendEmail(this.data.info.order.id, this.data.info.result.results[0].apiResultId, this.data.type, this.shareResultForm.value.email).subscribe((res) => {
        this.notificationService.showSuccess({ message: `Your ${this.data.type === 1? "requisition" : "results"} for Order ${this.data.number} was sent successfully to ${this.shareResultForm.controls.email.value} on ${new Date().toLocaleDateString()}.` },false)
        this.dialogRef.close();
      })
    // })
  }

  cancel() {
    this.modalService.dialog.closeAll();
  }
}
