import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-admin-user-email-change-history',
  templateUrl: './admin-user-email-change-history.html',
  styleUrls: [ './admin-user-email-change-history.scss' ]
})
export class AdminUserEmailChangeHistoryComponent implements OnInit {

  emailHistory: any = [];
  @Input() userId;
  constructor( private accountService: AccountService){

  }
  ngOnInit(): void {
     this.accountService.getUserEmailChangeHistoryByUserId(this.userId).subscribe((result) => {
      this.emailHistory = result;
     });
  }
}
