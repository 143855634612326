import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: UntypedFormGroup;

  
  constructor(
    private accountService:AccountService,
    public modalService:ModalService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.forgotForm = new UntypedFormGroup({
      email: new UntypedFormControl('')
    });
  }       

submit () {
  if(this.forgotForm.controls.email.valid) {
    this.accountService.forgotPassword(this.forgotForm.value.email).subscribe(() => {
        this.modalService.showTopNotification(true, 'We just sent you an email with instructions on how to reset your password.')
    }, function (error) {
        // ModalService.showTopNotification(false, 'Invalid email address.')
    });

    this.ok();
  }
};

ok() {
this.dialogRef.close();
};

cancel() {
  this.dialogRef.close('cancel');
};
}

