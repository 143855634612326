<div class="container">

    <div class="row breadcrumbs">
        <div class="col-md-12">
            <div class="btn-group btn-breadcrumb">
                <a href="main" title="Grassroot Labs Home" class="btn btn-default"><i class="glyphicon glyphicon-home"></i></a>
                <a href="all" title="Tests" class="btn btn-default">Tests</a>
                <a href="search" title="Seach" class="btn btn-default">Search</a>
            </div>
        </div>
    </div>

    <!-- <div class="innerSearch">
        <form class="form-inline searchResult">
            <div class="col-lg-12 text-center">
                <h2>Find Your Tests by Searching Below</h2>
                <div class="container-fluid">
                    <div class="col-md-10 col-xs-8 no-padding">
                        <input  type="text" [(ngModel)]="searchMessage" (keyup)="search(searchMessage)" [ngModelOptions]="{standalone: true}" class="form-control" style="width: 100%"
                               placeholder="Search by Test Name, Panel Name, or CPT Code">
                        <div class="searchResults" *ngIf="minError">
                            <br/>
                        </div>
                    </div>
                    <div class="col-md-2 col-xs-4">
                        <button (click)="this.search(searchMessage)" class="btn btn-lg btn-primary"><i class="fa fa-search"></i>  Search</button>
                    </div>

                </div>

            </div>
        </form>
    </div> -->
    <test-list [testsCount]="testCount"  [tests]="_displayedProducts"></test-list>

    <div class="searchResults standartInfo">  <!-- ng-show="noFound"   -->
        <div class="row top20">
            <div class="col-md-3"><img src="../../../assets/img/m.png" alt="no tests found" class="img-responsive"/></div>
            <div class="col-md-8 top20">
                <h2 class="text-center">Need Help Finding Your Tests?</h2>
                <p class="top20">We are always adding new tests to Grassroots Labs. If you do not see the test or tests that you are looking for, reach out to our support team and we will work to see if we can add it for you.

                    <br><br>Start a live chat session with us by clicking the purple chat button in the bottom left corner of this web page or, if it is after hours, click the same button and leave us a message detailing what tests you are looking for and we will get back with you ASAP!
                </p>
            </div>
        </div>
    </div>



</div>
