<h1>In the past 10 days, have you tested positive for COVID-19 by a molecular (PCR) test?</h1>
<h1>Molecular (PCR) test collects a sample through the nose or back of the throat with a swab. This can also be
  done by a saliva test. This does not include serology testing.</h1>
<div class="row">
  <div class="col-md-4 panel-body">
    <button class="btn btn-info covid" (click)="eligibleClick(999)">
      <span>I have tested positive for COVID-19 in the past 10 days.</span>
    </button>
  </div>
  <div class="col-md-4 panel-body">
    <button class="btn btn-info covid"
            (click)="testAnswer('I have tested positive for COVID-19 more than 10 days ago.')">
      <span>I have tested positive for COVID-19 more than 10 days ago.</span>
    </button>
  </div>
  <div class="col-md-4 panel-body">
    <button class="btn btn-info covid"
            (click)="testAnswer( 'I have never been tested for COVID-19.')">
      <span>I have never been tested for COVID-19.</span>
    </button>
  </div>
</div>
