import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class DataService {

  private searchSource = new BehaviorSubject('');
  currentMessage = this.searchSource.asObservable();
  constructor() { }

  changeMessage(message: string) {
    this.searchSource.next(message)
  }

 
}