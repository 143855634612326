import { I } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IPromoType } from 'src/app/interfaces/promo-type.interface';
import { ModalService } from 'src/app/services/modal.service';
import { PromoCodeService } from 'src/app/services/promo-code.service';
import { environment } from '../../../environments/environment';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-add-promo-code',
  templateUrl: './add-promo-code.component.html',
  styleUrls: ['./add-promo-code.component.scss']
})
export class AddPromoCodeComponent implements OnInit {
  data = [];
  campaignOne = new UntypedFormGroup({
    start: new UntypedFormControl(new Date(year, month, 13)),
    end: new UntypedFormControl(new Date(year, month, 16)),
  });
  campaignTwo = new UntypedFormGroup({
    start: new UntypedFormControl(new Date(year, month, 15)),
    end: new UntypedFormControl(new Date(year, month, 19)),
  });
  editMode = false;
  model;
  submitted = false;
  promotionTypeList: any[] = [
    { name: '$', value: 0 },
    { name: '%', value: 1 }
  ];

  date: { year: number, month: number };
  promoCodeForm: UntypedFormGroup;
  selectedWpof: any[] = [
    { name: 'no ', value: false },
    { name: 'waive physician oversight fee', value: true }
  ];
  selectedWldf: any[] = [
    { name: 'no ', value: false },
    { name: 'waive lab draw fee', value: true }
  ];

  selectedChildren = [];
  promoUrl = '';
  promoProductUrl = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddPromoCodeComponent>,
    private promoCodeService: PromoCodeService,
    public modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public inputdata: any
  ) {
      this.data = this.inputdata.items;
  }
  get f() {
    return this.promoCodeForm.controls;
  }
  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.promoCodeForm = this.formBuilder.group({
      discountType: new UntypedFormControl('', Validators.compose([ Validators.required ])),
      promoType: new UntypedFormControl('', Validators.compose([ Validators.required ])),
      code: new UntypedFormControl('', Validators.compose([ Validators.required ])),
      description: new UntypedFormControl('',  Validators.compose([ Validators.required ])),
      dateStart: new UntypedFormControl(new Date(year, month, 13)),
      dateFinish: new UntypedFormControl(new Date(year, month, 15)),
      isActive: new UntypedFormControl(true, Validators.compose([ Validators.required ])),
      isBlured: new UntypedFormControl(false, Validators.compose([ Validators.required ])),
      discount: new UntypedFormControl('', Validators.compose([ Validators.required ])),
      totalUseLimit: new UntypedFormControl('', Validators.compose([ Validators.required ])),
      customerUseLimit: new UntypedFormControl('', Validators.compose([ Validators.required ])),
      isPhysicianOversightFeeDiscount: new UntypedFormControl(false),
      isLabDrawFeeDiscount: new UntypedFormControl(false),
      products: new UntypedFormControl(null),
      promoProductUrl: new UntypedFormControl(null),
    });
    if (this.inputdata.isEdit) {
      this.editMode = true;
      this.promoCodeForm.patchValue(this.inputdata.promo);
      this.selectedChildren = this.inputdata.promo.products;
      this.promoUrl =  environment.baseUrl + `/promocode/${this.inputdata.promo.id}`;
      // if(this.inputdata.promo.products.length){
      //   this.promoProductUrl = environment.baseUrl + `/product/${this.inputdata.promo.products[0].slug}/promocode/${this.inputdata.promo.code}`;
      // }

      // this.promoCodeForm.controls['promoType'].setValue(this.inputdata.promo.products.length? 2 : 1)
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  };
  submit() {
    this.submitted = true;
    if (this.promoCodeForm.invalid || (this.promoCodeForm.value.promoType === 1 && this.selectedChildren.length === 0)) {
      return;
    }
    if (this.editMode) {
      this.promoCodeForm.value.id = this.inputdata.promo.id;
      this.promoCodeForm.value.products = this.selectedChildren;
      this.promoCodeService.updatePromoCode(this.promoCodeForm.value).subscribe((res)=>{
        this.modalService.showTopNotification(true, 'PROMO CODE \'' + this.inputdata.promo['description'] + '\' has been updated.');
      });
    } else {
      this.promoCodeForm.controls['products'].setValue(this.promoCodeForm.value.promoType === 0? null : this.selectedChildren);
      this.promoCodeForm.controls['isPhysicianOversightFeeDiscount'].setValue( this.promoCodeForm.value.isPhysicianOversightFeeDiscount);
      this.promoCodeForm.controls['isLabDrawFeeDiscount'].setValue( this.promoCodeForm.value.isLabDrawFeeDiscount);
      this.promoCodeService.addPromoCode(this.promoCodeForm.value)
      .subscribe((pr) => {
        this.modalService.showTopNotification(true, 'PROMO CODE \'' + this.promoCodeForm.get('description').value + '\' has been created.');
      })
    }
  }

  onProductChange(product) {
    this.selectedChildren = product.map((el)=> el.id);
  }

  generateUrl(product){
    this.promoProductUrl = product[0]? `${environment.baseUrl}/product/${product[0].slug}/promocode/${this.inputdata.promo.id}` : '';
  }

  coppy(url = undefined){
    if(url){
      navigator.clipboard.writeText(url);
    }
  }
}
