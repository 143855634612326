<div class="popup forms">
  <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>
  <div class="col-md-12">
    <h1 style="padding-top:0; margin-top:0;">{{editMode ? 'Edit' : 'Add'}} Template</h1>
  </div>
  <div class="row">
    <form class="form-horizontal" [formGroup]="keapTemplateForm" (ngSubmit)="submit()">
      <div class="col-md-12">
        <div mat-dialog-content>
          <div class="col-md-12">
            <div class="form-group">
              <label class="col-sm-4 control-label">Template Name*</label>
              <div class="col-sm-8">
                <input type="text" formControlName="name" class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">Body*</label>
              <div class="col-sm-8">
                  <textarea
                    class="form-control"
                    type="text"
                    rows="10"
                    maxlength="100000"
                    formControlName="body"
                    class="form-control"
                    id="instructions"></textarea>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">Template Type *</label>
              <div class="col-sm-8">

                <mat-radio-group formControlName="type">
                  <mat-radio-button *ngFor="let templateType of templatesType" [value]="templateType" class="type-wrapper">
                    {{ templateType == 0 ? 'Transactional ' : 'Non-Transactional'}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right" mat-dialog-actions align="end">
          <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Save</button>
          <a class="btn btn-warning" (click)="cancel()"><i class="fa fa-remove"></i> Cancel</a>
        </div>
      </div>
    </form>
  </div>
</div>
