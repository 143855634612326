import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AccountService} from "../../services/account.service";
import {Router} from "@angular/router";
import {ModalService} from "../../services/modal.service";

@Component({
  selector: 'app-unsubscribe-email',
  templateUrl: './unsubscribe-email.component.html',
  styleUrls: [ './unsubscribe-email.component.scss' ]
})
export class UnsubscribeEmailComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UnsubscribeEmailComponent>,
    public accountService: AccountService,
    public router: Router,
    private modalService: ModalService
  ) {
  }
  no() {
    this.router.navigate([ '/' ]);
    this.dialogRef.close('cancel');
  }
  yes(){
    this.accountService.disableNonTransactionalEmails(this.data.disableNonTransactionalEmails).subscribe((res) => {
      this.modalService.showTopNotification(true, 'You have successfully unsubscribe Non-Transactional Emails.');
      this.router.navigate([ '/' ]);
      this.dialogRef.close('ok');
    });
  }
}
