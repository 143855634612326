import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CONSTANTS } from 'src/app/const';
import { CardService } from 'src/app/services/card.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {
  imageURL = environment.baseIconUrl;
  processingFee = environment.processingFee;
  physicianFee = environment.physicianFee;
  labDrawFee = environment.labDrawFee;
  payment = {
    paymentType: 0
  };
  savePaymentType = false;
  reflexesPresence = false;
  noActiveCards = false;
  selectCard = {};
  card = {};
  cardsT = CONSTANTS.cardsTypes;
  years = CONSTANTS.expireYears;
  months = CONSTANTS.monthsNumbers;
  paymentTypeInfo = {
    cardNumber: "",
    cvc: "",
    cardType: this.cardsT[0],
    expirationMonth: 0,
    expirationYear: 0
  };
  currentYear = CONSTANTS.currentYear;
  currentMonth = CONSTANTS.currentMonth;
  chform: UntypedFormGroup;
  allcards;

  constructor(
    private cardService: CardService,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<AddCardComponent>,
    public notifications: NotificationService,
  ) {

  }


  ngOnInit(): void {
 
    // this.cardService.getAllCards().then((res)=>{
    //   this.allcards = res || []
    // })

    this.chform = new UntypedFormGroup({
      // cardType: new FormControl('', Validators.required),
      cardNumber: new UntypedFormControl(this.paymentTypeInfo.cardNumber, Validators.required),
      year: new UntypedFormControl(this.years),
      month: new UntypedFormControl(this.months),
      // savePaymentType: new FormControl(false),
      // payment: new FormControl(0),
      // selectCard: new FormControl(this.selectCard),
      // savePayment: new FormControl(this.savePaymentType),
      cvc: new UntypedFormControl(this.paymentTypeInfo.cvc, Validators.required)
    });

  }

  cancel(){
    this.dialogRef.close({
      refresh: false
    });
  }

  getPaymentCard() {
    return this.chform.value.cardNumber.substring(0, 4) + "***" + this.chform.value.cardNumber.slice(this.chform.value.cardNumber.length - 4);
  };

  submit(){
    let currentCard = this.allcards.filter((el) =>
      el.cardNumberLastDigits === this.getPaymentCard() &&
      el.expirationMonth === this.chform.value.month &&
      el.expirationYear === this.chform.value.year)
      // return

    if (currentCard.length > 0) {
      this.notifications.block({message: 'The card already exist.'},false);
    } else {
      // this.cardService.addCard({
      //   cardNumber: this.chform.value.cardNumber,
      //   cardType: this.findTypeOfCard(this.chform.value.cardNumber),
      //   cvc: this.chform.value.cvc,
      //   expirationMonth: this.chform.value.month,
      //   expirationYear: this.chform.value.year
      // }).subscribe(res => {
      //   this.dialogRef.close({
      //     refresh: true
      //   });
      // })
    }
  }

  findTypeOfCard(card) {

    // visa
    var re = new RegExp("^4");
    if (card.match(re) != null)
        return 1;

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
     if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(card)) 
        return 2;

    // AMEX
    re = new RegExp("^3[47]");
    if (card.match(re) != null)
        return 3;

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (card.match(re) != null)
        return 5;

    // Diners
    re = new RegExp("^36");
    if (card.match(re) != null)
        return 6;

    // Diners - Carte Blanche
    // re = new RegExp("^30[0-5]");
    // if (card.match(re) != null)
    //     return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (card.match(re) != null)
        return 4;

    // Visa Electron
    // re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    // if (card.match(re) != null)
    //     return "Visa Electron";

    return 255;
  };

}
