<div *ngIf="allArticles && allArticles.length > 0">
  <div style="background-color:white; padding-top: 40px; padding-bottom: 40px; ">
    <div class="container">
      <div class="all-article-input-wrapper">
        <div class="most-popular-post-text">
          All articles
        </div>
        <div>
          <label>
            <div class="input-group">
              <input class="form-control"
                     type="text"
                     (input)="onSearch($event.target.value)"
                     placeholder="Search articles"
                     id="search">
              <span class="input-group-addon">
                    <i class="glyphicon glyphicon-search"></i>
                </span>
            </div>
          </label>
        </div>
      </div>

      <div class="most-popular-container">
        <div class="most-popular-post" *ngFor="let post of filteredArticles">
          <div class="most-popular-image-wrapper">
            <img (click)="navigatePost('blog/' + post.slug)" [src]="imageURL + post.imageId" alt="Post image" class="most-popular-image">
          </div>

          <div class="most-popular-post-content">
            <h3 (click)="navigatePost('blog/' + post.slug)" class="most-popular-title">{{ post.title }}</h3>
            <p class="most-popular-description">{{ post.shortDescription }}</p>
            <div class="most-popular-meta">
              <span class="most-popular-date">{{ post.createDate | date:'MMMM d, yyyy' }}</span>
              <span class="most-popular-read-time">{{ post.estimateLength }} min</span>
              <span class="most-popular-author">{{ post.author }}</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ngb-pagination
          style="display: flex; justify-content: space-evenly;"
          [collectionSize]="count"
          [(page)]="page"
          [maxSize]="5"
          [pageSize]="9"
          (pageChange)="getAllArticle()"
          [rotate]="true"
          [boundaryLinks]="true">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
