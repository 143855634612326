<div class="cabinetTabs">
    <div class="container-fluid">

    <div class="text-center top10">
        <span [hidden]="model.authorizationCode">
            <p> By pressing the button below you will be redirected to <i>infusionsoft.com</i></p> <br>
            <a href="{{redirect_uri}}" class="fa fa-sign-in btn btn-lg btn-default bot10"> Sign-in to infusionsoft.com</a>
        </span>
        <span *ngIf="errorData"> Code received but not stored on the server.</span>
        <span *ngIf="model.authorizationCode && !errorData"> Your code <i>{{model.authorizationCode}}</i>  just posted to the server.</span></div>
    </div>
</div>