<div class="popup forms printable" >
  <p *ngIf="blured === false">
      Welcome to Grassroots Labs. The "{{ name }}" discount has been added to your cart automatically. You can now shop for the tests you need and see your discount at checkout. Please do not clear your browser cache, as this will remove your discount.
  </p>
  <p *ngIf="blured === true">
      Welcome to Grassroots Labs. Your discount has been added to your cart automatically. You can now shop for the tests you need and see your discount at checkout. Please do not clear your browser cache, as this will remove your discount.
  </p>
  <div class="text-center top10">
      <button class="btn btn-default" (click)="cancel()">Continue</button>
  </div>
</div>
