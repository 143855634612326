<div class="container checkout">
  <h2 class="hidden-xs" style="font-weight: bold !important; margin: 16px 0px; font-size: 25px; flex: 0 0 100%;">Checkout for Order #{{orderInfo.orderNumber}}</h2>
  <form  [formGroup]="chform"  class="form-horizontal" novalidate>
    <div  class="col col-lg-6">
          <div class="col col-lg-12">
              <div class="panel panel-main">
                  <div class="panel-heading panel-deals">
                    <h2 class="panel-title">1. Account Verification</h2>
                  </div>
                  <div  class="panel-body-container">
                    <div>
                      <label>First and Last Name:</label>
                      <span>{{ user?.fullName }}</span>
                    </div>
                    <div>
                      <label>Date of Birth:</label>
                      <span>{{ user?.birth | date }}</span>
                    </div>
                    <div>
                      <label>Gender:</label>
                      <span *ngIf="user?.gender == 2"> Female </span>
                      <span *ngIf="user?.gender == 1"> Male </span>
                    </div>
                    <div class="checkbox" [ngClass]="{'account-ver': !showStepTwo.checked}">
                      <input type="checkbox"
                      #showStepTwo
                      formControlName="agree"
                      name="agree"
                      >
                      <label class="wider">
                          By checking here, I verify that this lab order is for myself alone, the person identified above. I am not ordering for any other person.
                      </label>
                      <p class="panel-title font13">Grassroots Labs users can only order lab tests for themselves, and no one else. If testing is needed for someone else, a spouse, other family member, etc., then they must make their own account and make an order under their own account.</p>
                    </div>
                  </div>
                </div>
          </div>
          <!-- <div class="col col-lg-12">
              <div class="panel panel-main">
                  <div class="panel-heading panel-deals payment">
                    <h2 class="panel-title">2. Select a payment method</h2>
                    <span style="cursor: pointer;" *ngIf="showStepThree && showStepTwo.checked" (click)="showStepThree = !showStepThree" >Edit</span>
                  </div>
                  <ng-container  *ngIf="showStepTwo.checked" >
                    <div  class="panel-body-container" *ngIf="!showStepThree">
                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1" checked> <i class="fa fa-credit-card"></i> Credit or Debit Card</mat-radio-button>
                      </mat-radio-group>
                      <hr>
                      <div >
                        <div class="duplicate-childs">
                          <mat-radio-group aria-label="Select an option" formControlName="selectedCard">
                            <mat-radio-button   value="{{card.id}}" *ngFor="let card of allcards">
                              <i class="fa fa-credit-card"></i>
                              <label >Ending in:</label>
                              <span >{{ card.cardNumberLastDigits }}</span><br>
                              <label >Exp:</label>
                              <span >{{ card.expirationMonth }}/{{ card.expirationYear }}</span><br>
                              <label>Billing Zip:</label>
                              <span>{{ zip }}</span>
                            </mat-radio-button>

                          </mat-radio-group>
                        </div>
                      </div>
                      <p style="cursor: pointer;" (click)="addCard()">Add new credit card</p>
                      <button class="btn btn-warning" (click)="check();showStepThree = !showStepThree" *ngIf="chform.value.selectedCard">Continue ot Order Review</button>
                    </div>
                    <div *ngIf="showStepThree" class="panel-body-container">
                      <div>
                        <label >Ending in: {{ selectCard.cardNumberLastDigits}}</label>
                      </div>
                      <div>
                        <p>
                          <small>Exp: {{ selectCard.expirationMonth }}/{{ selectCard.expirationYear }}   Billing Zip: {{ zip }}</small>
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </div>
          </div> -->
          <div class="col col-lg-12">
              <div class="panel panel-main">
                  <div class="panel-heading panel-deals">
                    <h2 class="panel-title">2. Order Review</h2>
                  </div>
                  <div  class="panel-body-container" *ngIf="showStepTwo.checked && isVisiblePromo" >
                    <p>
                      Please review your account verification and summary before placing your order.
                    </p>
                    <h3>Promo Code</h3>
                    <button *ngIf="!isPromoCode" class="promoCode" (click)="isPromoCode = true">+ Apply a promo code</button>
                    <ng-container  *ngIf="isPromoCode">
                      <input class="inputPromoCode" type="text" #promo [placeholder]="!!isBlured ? 'PROMO CODE APPLIED' : 'ENTER CODE'" (change)="promoInput(promo.value)" [disabled]="!!isBlured">
                      <button *ngIf="!isBlured" class="btn btn-primary" (click)="addPRomoCode(promo.value)" [type]="!promo.value? 'button' : 'submit'"> {{addPromoCode? 'Change' : 'Apply' }}</button>
                      <button  *ngIf="addPromoCode" class="btn btn-warning" (click)="removePromoCode()" type="button">remove promo code</button>
                    </ng-container>
                    <h3 *ngIf="addPromoCode && !isBlured">Promo Code applied: {{promoCodeName}}</h3>
                    <hr>
                    <h3 style="cursor: pointer;" (click)="needHelp()">Need Help?</h3>
                  </div>
                </div>
          </div>
          <div class="col col-lg-12">
            <div class="panel panel-main">
                <div class="panel-heading panel-deals">
                  <h2 class="panel-title">3. Consent for Service</h2>
                </div>
                <div class="panel-body-container" *ngIf="showStepTwo.checked">

                <div class="checkbox">
                  <input type="checkbox"
                  #showStepTwo
                  formControlName="agreeSection3_1"
                  name="agreeSection3_1"
                  >
                  <label class="wider">
                    By checking this box, I confirm I have read and agree to the Grassroots Lab's
                     <a href="https://zachgrassrootslabs-my.sharepoint.com/:w:/g/personal/zach_grassrootslabs_com/ETbg2OiFnIdaM4D1U93MhWUBbJ0fTMYbrWsUy0gwRP5WjQ?rtime=vWsDhVhH3Eg" target="_blank">Terms and Conditions</a>
                      and
                      <a href="https://zachgrassrootslabs-my.sharepoint.com/:w:/g/personal/zach_grassrootslabs_com/EYaUQbv42i9fVngyyCL1FAoBSrBvt1dCJZPXiIBC8SjauA?e=eYKKQr" target="_blank">Privacy Policy</a>
                      .
                </label>
                </div>
                <div class="checkbox">
                  <input type="checkbox"
                  #showStepTwo
                  formControlName="agreeSection3_2"
                  name="agreeSection3_3"
                  >
                  <label class="wider">
                    By checking this box, I confirm I have read and agree to Grassroots Labs's independent Physician Oversight

                       <a href="https://zachgrassrootslabs-my.sharepoint.com/:w:/g/personal/zach_grassrootslabs_com/EV40_Xk1dm1GsgN9zCdMaBgBOyJXsihAKavaSZ0SHRKncQ?e=90HtCS" target="_blank">
                        Everly Health's Terms and Conditions
                      </a>
                      .
                  </label>
                </div>
                <p style="padding: 6px 0;">
                Physician Oversight:
                <a href="https://help.grassrootslabs.com/article/177-who-approves-and-reviews-lab-orders-purchased-with-grassroots-labs" target="_blank">
                  Who is Everly Health?
                </a>
              </p>
              </div>
              </div>
            </div>
    </div>
    <div  class="col col-lg-6">
      <div class="col col-lg-12">
        <div class="panel panel-main">
          <div class="panel-heading panel-deals">
            <h2 class="panel-title">Summary</h2>
          </div>
          <div  class="panel-container">
            <div class="visible-xs"> <!-- mobile view -->
              <table class="table">
                <div *ngFor="let item of cartData.items; let i = index;" class="mobile-table">
                  <tr class="itemsFromLoop gitCells" style="border: 0px;">
                    <td>
                      <img [src]="imageURL+item.product.imageId" alt="Product image" width="50">
                    </td>
                    <td style="cursor: pointer; padding-top: 6px;" >
                      <h3 (click)="navigateProduct('product/' + item.product.slug)" ><b>{{item.product.name}}</b></h3>
                      <div>
                        <h4 style="display: inline-block; font-size: 14px;">Market Price:</h4><span  class="decoration"> {{ item.product.marketPrice | currency}}</span>
                      </div>
                      <div>
                              <h2 style="display: inline-block;">Price: <span [class.decoration]="item.product.masterPrice !== item.orderItemPrice && item.isUsePromoCode" >{{item.product.masterPrice | currency}}</span> <span *ngIf="item.product.masterPrice !== item.orderItemPrice && item.isUsePromoCode">{{item.orderItemPrice | currency}}</span></h2>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="item.children?.length > 0" class="gitCells">
                    <td ></td>
                    <td>
                      <h3>Panel Includes:</h3>
                      <ng-container>
                        <ul class="testsUl">
                          <li *ngFor="let child of item.children">
                            {{child.product.name}}
                          </li>
                        </ul>

                      </ng-container>
                    </td>
                  </tr>
                </div>
                <ng-container *ngIf="discount > 0" >
                  <tr>
                    <td colspan="5">
                      <div class="panel panel-success" style="margin: 15px;">
                        <div class="panel-heading cart-header-container">
                          <h3 class="panel-title">Discounts</h3>
                        </div>
                        <div class="panel-body-discount cart-container">
                          <h3 class="panel-title cart-width100" >Duplicate Tests Ordered:</h3>
                        </div>
                        <div class="panel-body-discount cart-container duplicate-childs" *ngFor="let duplicateChild of duplicateChilds">
                          <span class="cart-width100">{{duplicateChild[0].product.name}}</span>
                          <span class="cart-widthPrice" >- {{ duplicateChild[0].product.masterPrice | currency }} {{ duplicateChild.length > 1? ' ( * ' + duplicateChild.length + ')': '' }}</span>
                        </div>
                        <div  class="panel-body-discount cart-container">
                          <h3 class="panel-title cart-width100" >Total Order Discounts</h3>
                          <span class="cart-widthPrice">- {{ discount | currency}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                <tr *ngIf="!addPromoCode && !discount" class="price">
                  <th >Subtotal</th>
                  <td><span [class.decoration]="addPromoCode || discount > 0">{{ subTotal | currency }}</span> </td>
                  <td><span *ngIf="addPromoCode || discount > 0">{{ subTotalPromoCode | currency }}</span></td>
                </tr>
                <tr *ngIf="discount" class="price">
                  <td >Subtotal w/Duplicate Discounts</td>
                  <td ><span [class.decoration]="addPromoCode || discount > 0">{{ subTotal | currency }}</span> </td>
                  <td><span *ngIf="addPromoCode || discount > 0">{{ subTotal -  discount| currency }}</span></td>
                </tr>
                <tr *ngIf="addPromoCode" class="price">
                  <td >Subtotal with promo Discount</td>
                  <td ><span [class.decoration]="addPromoCode || discount > 0">{{ subTotal - discount | currency }}</span> </td>
                  <td><span *ngIf="addPromoCode || discount > 0">{{ subTotalPromoCode | currency }}</span></td>
                </tr>
                <tr *ngIf="cartData.items.length > 0" class="price">
                    <td style="padding-top: 10px;"><a class="cart-width40"
                      tooltip-append-to-body="true"
                      placement="bottom"
                      ngbTooltip="Click on the link to see Order Fees description"
                      (click)="showProcessingFee()">Physician Oversight Fee:
                      <i class="fa fa-question-circle"></i>
                    </a></td>
                    <td><span class="decoration">$219</span></td>
                    <td><span class="cart-widthPrice">${{ physicianFee }}</span></td>
                </tr>
                <tr *ngIf="cartData.items.length > 0" class="price">
                  <td style="padding-bottom: 10px;"><a class="cart-width40"
                    tooltip-append-to-body="true"
                    placement="bottom"
                    ngbTooltip="Click on the link to see Order Fees description"
                    (click)="showProcessingFee()">Lab Draw Fee:
                    <i class="fa fa-question-circle"></i>
                  </a></td>
                  <td><span class="decoration">$13</span></td>
                  <td><span class="cart-widthPrice">${{ labDrawFee }}</span></td>
                </tr>
                <tfoot>
                  <tr *ngIf="cartData.items.length > 0">
                    <th>Cart Total</th>
                    <th colspan="2">
                      <span>{{cartTotal | currency}}</span>
                      <span *ngIf="isFree" style="color: green">
                                        Free of charge
                      </span>
                    </th>
                  </tr>
                </tfoot>
              </table>
              <div #squareWrapper>
                <h3 class="panel-decoration panel-green visible-xs"><b>Average Order Savings: <span >{{ 219 + 13 + getTotalMarketPrice() - (physicianFee + labDrawFee + getTotalPrice()  - discount) | currency}}</span></b></h3>
              </div>

            </div>
            <div class="table-responsive hidden-xs">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="2">Product</th>
                    <th>Market Price</th>
                    <th>Price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of cartData.items; let i = index;">
                    <tr>
                        <td colspan="2" (click)="navigateProduct('product/' + item.product.slug)">
                          <img [src]="imageURL+item.product.imageId" alt="Product image" width="50">
                          <span >{{item.product.name}}</span>
                        </td>
                      <td>
                        <span class="decoration">{{ item.product.marketPrice | currency}}</span>
                      </td>
                      <td colspan="2"><span [class.decoration]="item.product.masterPrice !== item.orderItemPrice && item.isUsePromoCode" >{{item.product.masterPrice | currency}}</span> <span *ngIf="item.product.masterPrice !== item.orderItemPrice && item.isUsePromoCode">{{item.orderItemPrice | currency}}</span></td>
                    </tr>
                    <tr class="tests" *ngIf="item.children?.length > 0">
                      <td colspan="5" style="border-top: 0px;">
                        <h3>Panel Includes the Following Tests:</h3>
                        <ng-container>
                          <ul class="testsUl">
                            <li *ngFor="let child of item.children">
                              {{child.product.name}}
                            </li>
                          </ul>

                        </ng-container>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="discount > 0" >
                    <tr>
                      <td colspan="5">
                        <div class="panel panel-success">
                          <div class="panel-heading cart-header-container">
                            <h3 class="panel-title">Discounts</h3>
                          </div>
                          <div class="panel-body-discount cart-container">
                            <h3 class="panel-title cart-width100" >Duplicate Tests Ordered:</h3>
                          </div>
                          <div class="panel-body-discount cart-container duplicate-childs" *ngFor="let duplicateChild of duplicateChilds">
                            <span class="cart-width100">{{duplicateChild[0].product.name}}</span>
                            <span class="cart-widthPrice" >- {{ duplicateChild[0].product.masterPrice | currency }} {{ duplicateChild.length > 1? ' ( * ' + duplicateChild.length + ')': '' }}</span>
                          </div>
                          <div  class="panel-body-discount cart-container">
                            <h3 class="panel-title cart-width100" >Total Order Discounts</h3>
                            <span class="cart-widthPrice">- {{ discount | currency}}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <tr *ngIf="!addPromoCode && !discount">
                    <td colspan="2">Subtotal</td>
                    <td><span [class.decoration]="addPromoCode || discount > 0">{{ subTotal | currency }}</span> </td>
                    <td colspan="2"><span *ngIf="addPromoCode || discount > 0">{{ subTotalPromoCode | currency }}</span></td>
                  </tr>
                  <tr *ngIf="discount">
                    <td colspan="2">Subtotal w/Duplicate Discounts</td>
                    <td><span [class.decoration]="addPromoCode || discount > 0">{{ subTotal | currency }}</span> </td>
                    <td colspan="2"><span *ngIf="addPromoCode || discount > 0">{{ subTotal -  discount| currency }}</span></td>
                  </tr>
                  <tr *ngIf="addPromoCode">
                    <td colspan="2">Subtotal with promo Discount</td>
                    <td><span [class.decoration]="addPromoCode || discount > 0">{{ subTotal - discount | currency }}</span> </td>
                    <td colspan="2"><span *ngIf="addPromoCode || discount > 0">{{ subTotalPromoCode | currency }}</span></td>
                  </tr>
                  <tr *ngIf="cartData.items.length > 0">
                    <td  colspan="2">
                      <a
                        tooltip-append-to-body="true"
                        placement="bottom"
                        ngbTooltip="Click on the link to see Order Fees description"
                        (click)="showProcessingFee()">Physician Oversight Fee:
                        <i class="fa fa-question-circle"></i></a>
                    </td>
                    <td><span class="decoration">$219</span></td>
                    <td  colspan="2"><span [class.decoration]="defaultPhysicianFee != physicianFee && isPromoCode">${{defaultPhysicianFee}}</span> <span *ngIf="defaultPhysicianFee != physicianFee && isPromoCode">${{physicianFee}}</span></td>
                  </tr>

                  <tr *ngIf="cartData.items.length > 0">
                    <td td  colspan="2">
                      <a
                        tooltip-append-to-body="true"
                        placement="bottom"
                        ngbTooltip="Click on the link to see Order Fees description"
                        (click)="showProcessingFee()">Lab Draw Fee:
                        <i class="fa fa-question-circle"></i></a>
                    </td>
                    <td><span class="decoration">$13</span></td>
                    <td colspan="2" ><span [class.decoration]="defaultLabDrawFee != labDrawFee && isPromoCode">${{defaultLabDrawFee}}</span> <span *ngIf="defaultLabDrawFee != labDrawFee && isPromoCode">${{labDrawFee}}</span></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr *ngIf="cartData.items.length > 0">
                    <th colspan="3">Cart Total</th>
                    <th colspan="2">
                      <span >{{cartTotal | currency}}</span>
                      <span *ngIf="isFree" style="color: green">
                                        Free of charge
                      </span>
                    </th>
                  </tr>
                </tfoot>
              </table>
              <h3 class="panel-decoration panel-green" *ngIf="cartData.items.length"><b>Average Order Savings: {{ 219 + 13 + getTotalMarketPrice() - (physicianFee + labDrawFee + getTotalPrice()  - discount) | currency}}</b></h3>
            </div>

            <div>

              <div class="order-secure-info">
                <img class="stripe-img" src="../../assets/img/stripe-badge-transparent.png"
                      alt="WP Full Stripe - Trust badge with transparent background"
                      title="WP Full Stripe - Trust badge with transparent background">
                <div style="position: relative;">
                  <div *ngIf="!chform.value.agree ||!chform.value.agreeSection3_1 || !chform.value.agreeSection3_2" class="divTooltip" (click)="toggleWithGreeting(t1)" #t1="matTooltip" matTooltipTouchGestures="on" matTooltipClass="checkoutTooltipClass"
                   [matTooltip]="chform.value.agree ? 'Please consent to service by checking the boxes in Consent for Service, section 3 of checkout.' : 'Please confirm your information above in the Account Verification section to check out.'"></div>
                  <span class="order-secure-span"
                  [matTooltip]="chform.value.agree ? 'Please consent to service by checking the boxes in Consent for Service, section 3 of checkout.' : 'Please confirm your information above in the Account Verification section to check out.'"
                  matTooltipClass="checkoutTooltipClass"
                  matTooltipTouchGestures="on"
                  [matTooltipDisabled]="!!chform.value.agree &&!!chform.value.agreeSection3_1 && !!chform.value.agreeSection3_2">
                    <button class="btn btn-success checkout-btn font16"
                      [type]="promoCodeValue? 'button' : 'submit'"
                      [disabled]="!(!!chform.value.agree && !!chform.value.agreeSection3_1 && !!chform.value.agreeSection3_2)"
                      (click)="pay()">
                      <i class="fa fa-thumbs-up"></i>
                      Place Order by Secure Checkout
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-lg-12">
        <div class="panel panel-main">
          <div class="cart-container cart-header-container">
            <h3 class="panel-title panel-decoration">Buy with confidence</h3>
          </div>
          <div class="cart-container">
            <i class='fa fa-calendar-check-o fa-lg icon-margin'></i>
            <div class="panel-body">
              <span><b>Convenient Testing on Your Schedule.</b> </span>
              <p>
                Have your tests performed locally at one of over 2100+ Quest Diagnostics Testing centers across the US. Note: Testing not available in AZ.
              </p>
              <br>
              <p>
                <a href="https://questhealth.pxf.io/Gm1NL9" target="_blank">
                  Click here for testing in NJ, NY, and RI.
                </a>
              </p>
              <br>
              <p>
                Please Note: A small minority of testing centers require appointments to have labs drawn. Please contact the testing center to confirm.
              </p>
              <br>
              <p>
                Need an appointment?
                <a href="https://help.grassrootslabs.com/article/146-scheduling-an-appointment-for-lab-testing-with-quest-diagnostics" target="_blank">
                  Click here.
                </a>
              </p>
            </div>
          </div>
          <div class="cart-container">
            <i class='fa fa-support fa-lg icon-margin'></i>
            <div class="panel-body">
              <span><b>World Class Support.</b></span>
              <p>Our team is unashamedly for our family of customers and works hard to make them happy.<br>
                Available:</p>
              <ul>
                <li>
                  Live Chat: Mon-Fri, 9am-5pm EST
                </li>
                <li>
                  Phone: Mon-Fri, 7am-12am EST / 4am-9pm PST
                </li>
                <li>
                  Email: 9am-5pm EST
                </li>
              </ul>
            </div>
          </div>
          <div class="cart-container ">
            <i class="fa fa-usd fa-lg icon-margin"></i>
            <div class="panel-body">
              <span><b>Affordability = Better Healthcare.</b> </span>
              <p>Save on average between 30-70% off market rates for lab tests.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
