<div class="popup forms">

    <div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>

    <div class="col-md-12">
        <h1 style="padding-top:0; margin-top:0;">Change Email</h1>
    </div>

    <form class="form-horizontal" [formGroup]="emailForm">

        <!-- Current Email -->
        <div class="form-group">
            <label for="currentEmail" class="col-sm-3 control-label">Current Email *</label>
            <div class="col-sm-9">
                <input name="oldEmail" required formControlName="oldEmail" ng-disabled="true" type="email" class="form-control" id="currentEmail">
            </div>
        </div>
        <!--<div class="form-group col-md-12 pull-right" ng-cloak ng-show="form.$submitted || form.password.$touched">-->
            <!--<div class="alert alert-danger" role="alert" ng-show="form.password.$error.required"><b>Error:</b> Please fill password field</div>-->
        <!--</div>-->

        <!-- New Login Email -->
        <div class="form-group">
            <label for="newEmail" class="col-sm-3 control-label">New Email *</label>
            <div class="col-sm-9">
                <input name="email" required formControlName="newEmail" type="email" class="form-control" id="newEmail" placeholder="New Email">
            </div>
        </div>
        <!-- <div class="form-group col-md-12 pull-right" ng-cloak ng-show="form.$submitted || form.email.$touched">
            <div class="alert alert-danger" role="alert" ng-show="form.email.$error.required"><b>Error:</b> Please add an email address.</div>
            <div class="alert alert-danger" role="alert" ng-show="form.email.$error.email"><b>Error:</b> Email is invalid.</div>
        </div> -->

        <!-- Confirm Email -->
        <div class="form-group">
            <label for="confirmEmail" class="col-sm-3 control-label">Confirm Email *</label>
            <div class="col-sm-9">
                <input compare-to="model.email" name="confirm" formControlName="confirmEmail" type="email" class="form-control" id="confirmEmail" placeholder="Confirm Email">
            </div>
        </div>
        <!-- <div class="form-group col-sm-12 pull-right" *ngIf="form.$submitted || form.confirm.$touched">
            <div class="alert alert-danger" role="alert" *ngIf="form.confirm.$error.compareTo"><b>Error:</b> New Email and confirmed Email do not match.</div>
        </div> -->

        <!-- Buttons-->
        <div class="form-group">
            <div class="col-sm-12 text-right">
                <div class="col-sm-12 text-right">
                    <button (click)="change()" type="submit" class="btn btn-primary">Change Email</button>
                    <a (click)="cancel()" class="btn btn-default">CANCEL</a>
                </div>
            </div>
        </div>
    </form>
</div>
