import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, of } from 'rxjs';
import { mergeMap, map, toArray } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/const';
import { UserSentEmailHistory } from 'src/app/entities/user/UserSentEmailHistory';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';
import { OrderService } from 'src/app/services/order.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderHistoryComponent implements OnInit {
  isAdmin = false;
  isEmpty = false;
  filteredOrders = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'orderId',
    'date',
    'status',
    'price',
    'actions',
  ];
  processingFee = environment.processingFee;
  physicianFee = environment.physicianFee;
  labDrawFee = environment.labDrawFee;
  @Input() clearToken;

  constructor(
    private orderService: OrderService,
    private cartService: CartService,
    private http: HttpClient,
    private sessionInfoStorage: SessionInfoStorageService,
    public modalService: ModalService,
    public sharedService: SharedService,
    public datepipe: DatePipe
  ) {
    // this.isAdmin = sessionInfoStorage.isAdmin();
  }

  _orders = [];

  get orders() {
    return this._orders;
  }

  set orders(newOrders) {
    newOrders.forEach((el, i) => {
      if (el.isOpen && !el.info) {
        this.moreInfo(el).subscribe((x) => {
          const baseDownloadLink = x[0].result.results.length
            ? environment.base +
            '/api/order/results/download/' +
            el.id +
            '/' +
            x[0].result.results[0].apiResultId
            : null;
          if (x[1]) {
            x[1].requisitionLink = x[1].hasRequisitionPdf
              ? baseDownloadLink + '/1'
              : null;
            //     item.reconciledResultsLink = item.result.hasReconciledResultsPdf ? baseDownloadLink + '/2' : null;
            x[1].resultLink = x[1].hasResultsPdf
              ? baseDownloadLink + '/3'
              : null;
            //     item.resultHl7Link = item.result.hasResultsHl7 ? baseDownloadLink + '/4' : null;
          }
          x[0].order.items = x[0].order.items.sort((a, b) => {
            return b.product.productType !== 8 ? -1 : 1;
          });
          x[0].links = x[1] || null;
          this._orders[i].info = x[0];
          this._orders[i].userSentEmailHistories = x[0].userSendHistory;
        });
      }
      this._orders = newOrders;
    });
  }

  formatSendedDate(sendedDate: string): string {
    return moment.utc(sendedDate).local().format('MMM DD, YYYY h:mm A');
  }

  moreInfo(el) {
    const getInfo = forkJoin({
      result: this.orderService.getOrderResults(el.id),
      order: this.orderService.getOrder(el.id, true),
      userSendHistory: this.orderService.getUserSendHistory(el.id),
    }).pipe(
      mergeMap((group) =>
        group.result.results[0]
          ? this.http
            .get<any>(
              environment.base +
              `/api/order/results/get/${group.result.results[0].apiResultId}/${el.id}`
            )
            .pipe(map((links) => [group, links]))
          : of([{ ...group }])
      )
    );

    return getInfo;
  }

  getOrderStatus(statusCode) {
    return CONSTANTS.orderStatuses[statusCode];
  }

  openResults(results) {
    if (results && results.length) {
      this.modalService.openTestResultsPop(results);
    }
  }

  processProfilesForInput(orders) {
    return Object.assign(orders).map((order) => {
      let date = new Date(order.createdDate);
      this.filteredOrders.push({
        orderId: order.number,
        date: date.toDateString().slice(4),
        status: this.getOrderStatus(order.status),
        price: `$${this.processingFee + order.subTotal}`,
        isExpanded: false,
      });
      this.dataSource = new MatTableDataSource(this.filteredOrders);
    });
  }

  openModalShare(order, type) {
    order.type = type;
    this.modalService.openModalShare(order).subscribe((el) => {
      this.orders.forEach((el, i) => {
        if (el.id === order.id) {
          this.moreInfo(el).subscribe((x) => {
            this._orders[i].userSentEmailHistories = x[0].userSendHistory;
          });
        }
      });
    });
  }

  printReceipt(order, orderDate, processingFee, orderTotal) {
    this.moreInfo(order).subscribe((x) => {
      x[0].order.items = x[0].order.items.sort((a, b) => {
        return b.product.productType !== 8 ? -1 : 1;
      });

      // x[0].links = x[1];
      // order.info = x[0];
      this.modalService.openPopup({
        data: { order, orderDate, processingFee, orderSubTotal: orderTotal },
      });
    });
  }

  reOrder(productItems) {
    productItems.forEach((item) => {
      if (!item.isChild) this.cartService.addToCart(item.product);
    });
  }

  iframeDownload(urlPath, name) {
    if (name === 'requisition.pdf') {
      urlPath = urlPath.info.links?.requisitionLink;
    } else {
      urlPath = urlPath.info.links?.resultLink;
    }
    this.http
      .get(urlPath, { responseType: 'blob' })
      .subscribe((result: any) => {
        // $http.get(url).then(function(result)
        const type =
          name.indexOf('hl7') > -1
            ? 'multipart/x-hl7-cdalevel-one'
            : 'application/pdf';
        const file = new Blob([result], { type });
        const url = window.URL.createObjectURL(file);
        // window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        link.click();
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
        }, 100);
      });
  }

  togglePanel(id) {
    const newOrders = this.orders;
    newOrders.forEach((el) => (el.isOpen = el.id === id ? !el.isOpen : false));
    this.orders = newOrders;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.orderService.getOrderStatuses().subscribe((result: any) => {
      // 62 = Submitted(2) + InProgress(4) + Completed(8) + Rejected(16) + PaymentFailed(32)
      const mappedResult = result
        .sort(
          (a, b) =>
            (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any)
        )
        .map((el, index) => ({
          ...el,
          isOpen: index === 0,
        }));
      this.orders = mappedResult;
      this.isEmpty = mappedResult.length === 0 ? true : false;
      if (this.clearToken) {
        this.isAdmin = true;
        this.sessionInfoStorage.clearToken();
      }
      // console.log(' this.orders',  this.orders)
    });
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  checkuserSentEmailHistories(order) {
    return order.userSentEmailHistories.length > 0;
  }

  checkResultLink(order) {
    return !!order.info.links?.resultLink;
  }

  checkRequisitionLink(order: any) {
    return !!order.info.links?.requisitionLink;
  }

  panelChildren(items: any) {
    return !items?.some((te) => te?.isChild);
  }
}
