import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CategoryService } from '../../services/category.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AddCategoryComponent } from 'src/app/Modals/add-category/add-category.component';


@Component({
  selector: 'app-admin-categories',
  templateUrl: './admin-categories.component.html',
  styleUrls: ['./admin-categories.component.scss']
})
export class AdminCategoriesComponent implements OnInit {
  page = 1;
  parameters;
  categories = [];
  paginatorFunctions = {};
  searchModel = {
    search: ""
  };
  categoriesForm:UntypedFormGroup;
  count: any;


  constructor(
    private modalService: ModalService,
    private categoryService: CategoryService,
    private builder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.categoriesForm = new UntypedFormGroup({
      search: new UntypedFormControl('')
      })
      this.getAllCategories({$skip: 0, $top:10})
  }

  deleteCategory(category, categoryIndex) {
    this.modalService.openConfirmModal("Delete category?", deleteCategory.bind(this, category.id, categoryIndex, this.categories));
    function deleteCategory(id, categoryIndex,categories) {
        this.categoryService.removeCategory(id).subscribe(() => {
              categories.splice(categoryIndex, 1);
              // this.modalService.showTopNotification(true, "Category '" + category.name + "' was successfully removed");
            }
    )
    }
};

  openCategoryPopup (id) {  // create or edit
    this.modalService.openCategoryPop(id).subscribe((resp) => {
      if (resp === 'ok') {
        this.getAllCategories(this.parameters);
      }
    });
    // dialog.closed.then(function(){
    //     this.paginatorFunctions.update();
    // });
};

  getAllCategories (params) {
    params.flags = 255;
    this.parameters = params;
    this.categoryService.getAllCategories(params).subscribe((resp) => {
        this.categories = resp.items;
        this.count = resp.count; // || resp.length;
    })
  };

  search () {
    // if (search.length < 2) {
    //   this.getAllCategories({});
    //   return;
    // }

    this.categoryService.search({"search": this.categoriesForm.value.search}).subscribe((resp) => {
        // this.categories = $filter("orderBy")(resp, 'name');
        this.categories = resp;
        this.count = resp['length'];
    });
  };

}