import { Component, OnInit } from '@angular/core';
import { UploadService, UploadResult } from 'src/app/services/upload.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
	images;
	public uploads: any[];

	private uploadService: UploadService;

	// I initialize the app component.
	constructor( 
		uploadService: UploadService,
		private imageService: ImageService ) {

		this.uploadService = uploadService;
		this.uploads = [];

	}

	// ---
	// PUBLIC METHODS.
	// ---

	// I upload the given files to the remote API.
	public async uploadFiles( files: File[] ) : Promise<void> {

		// The given files collection is actually a "live collection", which means that
		// it will be cleared once the Input is cleared. As such, we need to create a
		// local copy of it so that it doesn't get cleared during the asynchronous file
		// processing within the for-of loop.
		for ( var file of Array.from( files ) ) {
			// let formData:FormData = new FormData();
			// formData.append('uploadFile', file, file.name);
				this.uploads.push(
					await this.uploadService.uploadFile( file )
				);
			
		}

	}

	
}
