<h1>In the past 14 days, have you had close contact or been directly exposed to someone diagnosed with COVID-19?<br/>
  Close contact includes being within 6 feet of the person for more than a few minutes.<br>
  Direct exposure includes being coughed or sneezed on.</h1>
<div class="row">
  <div class="col-md-4 panel-body" align="center">
    <button class="btn btn-info covid" (click)="eligibleClick(999)">
      <span>Yes, I have recently been exposed and have not had any prior exposure.</span>
    </button>
  </div>
  <div class="col-md-4 panel-body" align="center">
    <button class="btn btn-info covid" (click)="fourteen($event)">
      <span>Yes, I have recently been recently exposed and may have had ongoing or multiple exposures because of my job or where i live.</span>
    </button>
  </div>
  <div class="col-md-4 panel-body" align="center">
    <button class="btn btn-info covid" (click)="fourteen($event)">
      <span>NO</span>
    </button>
  </div>
</div>
