import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { ConfirmForgotPasswordComponent } from 'src/app/Modals/confirm-forgot-password/confirm-forgot-password.component';
import { NotificationService } from 'src/app/services/notification.service';
import { OrderService } from 'src/app/services/order.service';
import { ProductService } from 'src/app/services/product.service';
import { StripeCheckoutComponent } from '../MainPage/stripe-checkout/stripe-checkout.component';
import { AddCardComponent } from '../Modals/add-card/add-card.component';
import { AddCategoryComponent } from '../Modals/add-category/add-category.component';
import { AddPageComponent } from '../Modals/add-page/add-page.component';
import { AddPromoCodeComponent } from '../Modals/add-promo-code/add-promo-code.component';
import { AddTestsComponent } from '../Modals/add-tests/add-tests.component';
import { BrowseImageComponent } from '../Modals/browse-image/browse-image.component';
import { ChangeEmailComponent } from '../Modals/change-email/change-email.component';
import { ChangePasswordComponent } from '../Modals/change-password/change-password.component';
import { CheckoutSuccessComponent } from '../Modals/checkout-success/checkout-success.component';
import { ConfirmComponent } from '../Modals/confirm/confirm.component';
import { CovidEmpWithoutThreeStepsComponent } from '../Modals/covid-emp-without-three-steps/covid-emp-without-three-steps.component';
import { Covid19EmpComponent } from '../Modals/covid19-emp/covid19-emp.component';
import { Covid19SalivaComponent } from '../Modals/covid19-saliva/covid19-saliva.component';
import { Covid19Component } from '../Modals/covid19/covid19.component';
import { ForgotPasswordComponent } from '../Modals/forgot-password/forgot-password.component';
import { IdleComponent } from '../Modals/idle/idle.component';
import { ImportantNoticeComponent } from '../Modals/important-notice/important-notice.component';
import { InProgressComponent } from '../Modals/in-progress/in-progress.component';
import { LoginAsModalComponent } from '../Modals/login-as/login-as-modal.component';
import { LoginComponent } from '../Modals/login/login.component';
import { PrintReceiptComponent } from '../Modals/print-receipt/print-receipt.component';
import { ProcessingFeeComponent } from '../Modals/processing-fee/processing-fee.component';
import { PromoCodeConfirmComponent } from '../Modals/promoCode-confirm/promoCode-confirm.component';
import { RegistrationSuccessComponent } from '../Modals/registration-success/registration-success.component';
import { RegistrationComponent } from '../Modals/registration/registration.component';
import { CatalogService } from './catalog.service';
import { PromoCodeService } from './promo-code.service';
import { ResultsRequisitionComponent } from '../Modals/results-requisition/results-requisition.component';
import {AddKeapTemplateComponent} from '../Modals/add-keap-template/add-keap-template.component';
import {KeapMailTemplateService} from './keap-mail-template.service';
import {switchMap} from 'rxjs/operators';
import {UnsubscribeEmailComponent} from "../Modals/unsubscribe-email/unsubscribe-email.component";
import {AddArticleComponent} from "../Modals/add-article/add-article.component";
import {BlogService} from "./blog.service";

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: any[] = [];

  constructor(
    public productService: ProductService,
    private notifications: NotificationService,
    public dialog: MatDialog,
    private orderService: OrderService,
    public router: Router,
    public promoCodeService: PromoCodeService,
    private catalogService: CatalogService,
    public keapMailTemplateService: KeapMailTemplateService,
    private blogService: BlogService,
  ) {}

  dismissAll() {
    // let dialogRef: MatDialogRef<IdleComponent>;
    this.dialog.closeAll();
  }

  openIdle() {
    const dialogRef = this.dialog.open(IdleComponent, {
      width: '600px',
      panelClass: ['modal-lg'],
    });
    return dialogRef;
  }

  openLogin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '348px',
      panelClass: ['app-full-bleed-dialog', 'modal-sm', 'loginmodal'],
      disableClose: true,
      // data: {name: this.name, animal: this.animal}
    } as any);

    /*dialogRef.afterClosed().subscribe(result => {
      this.animal = result;
    });*/
  }

  openRegistrationSuccess() {
    const dialogRef = this.dialog.open(RegistrationSuccessComponent, {
      width: '348px',
      panelClass: ['app-full-bleed-dialog', 'modal-sm', 'loginmodal'],
      disableClose: true,
      // data: {name: this.name, animal: this.animal}
    } as any);
  }

  openForgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: '550px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
    });
  }

  openChangeEmail(actionType?, action?) {
    const dialogRef = this.dialog.open(ChangeEmailComponent, {
      data: {
        actionType,
        action,
      },
      width: '898px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
      disableClose: true,
    });
  }

  openCheckoutSuccess() {
    const dialogRef = this.dialog.open(CheckoutSuccessComponent,{
      width: '550px',
    });
    dialogRef.afterClosed().subscribe(() => {
      window.scrollTo(0, 0);
    });
  }

  openCovid(callback) {
    const dialogRef = this.dialog.open(Covid19Component, {
      width: '550px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
    });

    // dialogRef.afterClosed().subscribe((data) => {
    //   callback(data)
    // })
  }

  openCovidEmp(callback) {
    const dialogRef = this.dialog.open(Covid19EmpComponent, {
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
      width: '600px',
    } as any);

    dialogRef.afterClosed().subscribe((data) => {
      callback(data);
    });
  }

  openCovid19Saliva(callback) {
    const dialogRef = this.dialog.open(Covid19SalivaComponent, {
      panelClass: ['app-full-bleed-dialog'],
      width: '700px',
      // borderRadius: '4px',
      maxHeight: '90vh',
      background: '#102a41',
    } as any);

    // dialogRef.afterClosed().subscribe((data) => {
    //   callback(data)
    // })
  }

  openCovidWithoutThreeSteps(callback) {
    const dialogRef = this.dialog.open(CovidEmpWithoutThreeStepsComponent, {
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((data) => {
      callback(data);
    });
  }

  openLoginAs(info, action?) {
    const dialogRef = this.dialog.open(LoginAsModalComponent, {
      data: {
        info,
        action,
      },
      panelClass: ['app-full-bleed-dialog'],
      width: '348px',
    } as any);
  }

  openConfirmForgotPassword(data) {
    this.dialog.open(ConfirmForgotPasswordComponent, {
      data,
      panelClass: ['app-full-bleed-dialog'],
      width: '500px',
    });
  }

  openUnsubscribeEmail(data) {
    this.dialog.open(UnsubscribeEmailComponent, {
      data,
      panelClass: ['app-full-bleed-dialog'],
      width: '500px',
    });
  }

  openTestPopup() {
    this.productService.getAllProducts().subscribe((res) => {
      this.dialog.open(AddTestsComponent, {
        panelClass: ['app-full-bleed-dialog'],
        width: '800px',
        data: { isEdit: false, items: res.items },
      });
    });
    // this.dialog.open(AddTestsComponent, {
    //   panelClass: [ 'app-full-bleed-dialog' ],
    //   width: '800px'
    // } as any);
  }

  openAddArticlePopup(): Observable<any> {
    return  this.dialog.open(AddArticleComponent, {
        panelClass: ['app-full-bleed-dialog'],
        width: '800px',
        data: {  },
      }).afterClosed();
  }

  openUpdateArticlePopup(id): Observable<any> {
    return this.blogService.getById(id).pipe(
      switchMap((result) =>
        this.dialog.open(AddArticleComponent, {
          panelClass: ['app-full-bleed-dialog'],
          width: '800px',
          data: { article: result, isEdit: true },
        }).afterClosed()
      )
    );
  }

  openKeapTemplatePopup(): Observable<any> {
    return this.dialog.open(AddKeapTemplateComponent, {
      panelClass: ['app-full-bleed-dialog'],
      width: '500px',
      data: { isEdit: false },
    }).afterClosed();
  }

  openConfirmPromoCode(data) {
    this.dialog.open(PromoCodeConfirmComponent, {
      data,
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
      width: '500px',
    });
  }

  openConfirmModal(message, result) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        question: message,
        result: result,
      },
      width: '300px',
      panelClass: ['app-full-bleed-dialog', 'modal-sm'],
      disableClose: true,
    });
  }

  showTopNotification(isSuccess, message) {
    if (isSuccess) {
      this.notifications.showSuccess({ message });
    } else {
      this.notifications.showWarning({ message });
    }
  }

  openCategoryPop(id): Observable<any> {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      width: '898px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
      disableClose: true,
      data: id,
    });
    return dialogRef.afterClosed();
  }

  openRegistration() {
    const dialogRef = this.dialog.open(RegistrationComponent, {
      width: '1140px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg', 'registrationmodal'],
      disableClose: true,
      // data: {name: this.name, animal: this.animal}
    } as any);
    // openPopup('lg', '/partials/Modals/register.html', 'RegistrationController', undefined, 'appModalWindow');
  }

  openTestResultsPop(...args) {}

  openChangePassword(userId) {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '898px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
      disableClose: true,
      data: userId,
    });
  }

  openAddCardComponent(callback) {
    const dialogRef = this.dialog.open(AddCardComponent, {
      width: '898px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => {
      callback(data);
    });
  }

  openStripeComponent(callback, totalAmount) {
    const dialogRef = this.dialog.open(StripeCheckoutComponent, {
      data: { totalAmount },
    });
    dialogRef.afterClosed().subscribe((data) => {
      callback(data);
    });
  }

  openPopup(order?) {
    this.dialog.open(PrintReceiptComponent, {
      width: '898px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
      disableClose: true,
      data: order,
    });
  }
  openModalShare(order) {
    const dialogRef = this.dialog.open(ResultsRequisitionComponent, {
      width: '898px',
      panelClass: ['app-full-bleed-dialog'],
      disableClose: true,
      data: order,
    });

    return dialogRef.afterClosed();
  }
  openPromoCodPopup() {
    this.productService.getAllProductsPromo().subscribe((res) => {
      this.dialog.open(AddPromoCodeComponent, {
        panelClass: ['app-full-bleed-dialog'],
        width: '800px',
        data: { isEdit: false, items: res.items },
      });
    });
  }

  updateTestPopup(id) {
    this.productService.getAllProducts().subscribe((prod) => {
      this.catalogService.getProduct(id).subscribe((res) => {
        this.dialog.open(AddTestsComponent, {
          width: '898px',
          panelClass: ['app-full-bleed-dialog', 'modal-lg'],
          data: { isEdit: true, ...res, items: prod.items },
        });
      });
    });
  }
  updatePromoCode(id, isEdit) {
    forkJoin({
      products: this.productService.getAllProductsPromo(),
      promo: this.promoCodeService.getPromoListById(id),
    }).subscribe((results) => {
      results.promo['id'] = id;
      this.dialog.open(AddPromoCodeComponent, {
        width: '898px',
        panelClass: ['app-full-bleed-dialog', 'modal-lg'],
        data: { promo: results.promo, isEdit, items: results.products.items },
      });
    });
  }

  updateKeapTemplate(id, isEdit): Observable<any> {
    return this.keapMailTemplateService.getTemplateById(id).pipe(
      switchMap(template => {
        return this.dialog.open(AddKeapTemplateComponent, {
          width: '500px',
          panelClass: ['app-full-bleed-dialog', 'modal-lg'],
          data: { template, isEdit },
        }).afterClosed();
      })
    );
  }

  openBrowseImage(...args) {
    return this.dialog.open(BrowseImageComponent, {
      width: '898px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg'],
      disableClose: true,
    });
  }

  openInProgress() {
    const dialogRef = this.dialog.open(InProgressComponent);
  }

  openEdit(data = {}) {
    this.dialog.open(AddPageComponent, {
      width: '946px',
      panelClass: ['app-full-bleed-dialog', 'modal-lg', 'registrationmodal'],
      disableClose: true,
      data,
    } as any);
  }

  openProcessingFee() {
    const dialogRef = this.dialog.open(ProcessingFeeComponent);
  }

  importantNotice(state, register?) {
    this.dialog.open(ImportantNoticeComponent, {
      autoFocus: false,
      data: {
        state: state || false,
        register: register || false,
      },
    });
  }
}
