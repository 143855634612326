import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CONSTANTS } from 'src/app/const';
// import { CardService } from 'src/app/services/card.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';
import { OrderService } from 'src/app/services/order.service';
import { ProfileService } from 'src/app/services/profile.service';
import { PwnHealthService } from 'src/app/services/pwn-health.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { CatalogService } from 'src/app/services/catalog.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PromoCodeService } from 'src/app/services/promo-code.service';
import { StatesService } from 'src/app/services/states.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  imageURL = environment.baseIconUrl;
  orderInfo = this.sessionInfoStorage.getOrderInfo();
  promoCodeSession = this.sessionInfoStorage.getPromoCode();
  isFree = null;
  processingFee = environment.processingFee;
  defaultPhysicianFee = environment.physicianFee;
  defaultLabDrawFee = environment.labDrawFee;
  physicianFee;
  labDrawFee;
  payment = {
    paymentType: 0
  };
  savePaymentType = false;
  reflexesPresence = false;
  noActiveCards = false;
  cartData;
  selectCard = {};
  isVisiblePromo = false;
  paymentSuccess = false;
  paymentError = false;
  submitSuccess = false;
  submitError = false;
  logicError = false;
  agree = false;
  agreeSection3_1= false;
  agreeSection3_2= false
  alreadyPaid = false;
  toggleCards = false;
  addPromoCode = false;
  tab = 0;
  step = 1;
  card = {};
  cardsT = CONSTANTS.cardsTypes;
  years = CONSTANTS.expireYears;
  months = CONSTANTS.monthsNumbers;
  paymentTypeInfo = {
    cardNumber: "",
    cvc: "",
    cardType: this.cardsT[0],
    expirationMonth: 0,
    expirationYear: 0
  };
  cardList;
  showNote;
  cartTotal = 0;
  cartTotalNoPromoOrder = 0;
  currentYear = CONSTANTS.currentYear;
  currentMonth = CONSTANTS.currentMonth;
  disableAll: boolean;
  chform: UntypedFormGroup;
  isShown: boolean = false;
  currentId = '';
  allcards;
  promoCode;
  isPromoCode = false;
  isBlured = false;
  duplicateChilds = []
  discount = 0
  zip;
  user = {
    fullName: '',
    birth: '',
    gender: ''

  };
  subTotal = 0;
  subTotalPromoCode = 0;
  promoCodeName = '';
  pwnItems = [];
  pwnNotApproved = [];
  private types: [];
  @ViewChild('statNumber') statNumber: any;
  @ViewChild('squareWrapper') squareWrapper: any;

  constructor(
    private sessionInfoStorage: SessionInfoStorageService,
    private modalService: ModalService,
    private cartService: CartService,
    // private cardService: CardService,
    private orderService: OrderService,
    public sharedService: SharedService,
    private pwnHealthService: PwnHealthService,
    private profileService: ProfileService,
    public dialog: MatDialog,
    private router: Router,
    private catalogService: CatalogService,
    private notificationService: NotificationService,
    private promoCodeService: PromoCodeService,
    private statesService: StatesService

  ) {

  }


  load(){
    let products = []
    this.duplicateChilds = []
    this.subTotal = 0;
    this.cartData = this.cartService.sendCartInfo()
    this.cartService.refreshCart().then(res => {
      this.labDrawFee = res[0].labDrawFee;
      this.physicianFee = res[0].physicianOversightFee;
      this.isPromoCode = res[0].isUsePromoCode;
      this.addPromoCode = this.isPromoCode;
      this.subTotalPromoCode = res[0].subTotal;
      this.promoCodeName = res[0].promoCode;
      if (res[0].isUsePromoCode){
      this.promoCodeService.getPromoListById(res[0].promoCodeId).subscribe(response => {

        this.isBlured = response.isBlured;
        this.isVisiblePromo = true;
      });
    }
    else{
      this.isVisiblePromo = true;
    }

      this.catalogService.getPwn().subscribe((pwn)=>{
        this.pwnItems = pwn;
        res[0].items.forEach((el, i) => {
          if(el.children.length && el.product.productType != 16){
            el.children.forEach((child) => {
              if(!this.pwnItems.find((item)=>item.id === child.product.id)){
                  this.pwnNotApproved.push(child.product.name + ';')
                }
            })
          }else{
            if(!this.pwnItems.find((item)=>item.id === el.product.id) && el.product.productType != 16){
              this.pwnNotApproved.push(el.product.name + ';')
            }
          }
          this.subTotal += el.product.masterPrice;
            if(el.promoCode){
              this.promoCodeName = el.promoCode;
            }

            if(el.children.length > 0){
              products = products.concat(el.children)
            }else{
              products.push(el)
            }
            this.duplicateChilds = products.reduce((a, e) => {
              if(a[e.product.id]){
                a[e.product.id].push(e)
              }else{
                a[e.product.id] = []
              }
              return a;
            }, []);
            this.duplicateChilds = Object.values(this.duplicateChilds).filter(e => e.length)
            this.discount = 0
            this.duplicateChilds.forEach((current) => {
                this.discount += (current[0].product.masterPrice * current.length);
              });
        })
        if(this.promoCodeName){
          this.addPRomoCode(this.promoCodeName,false, false)
        }
        this.cartTotalNoPromoOrder = this.subTotalPromoCode + this.defaultLabDrawFee + this.defaultPhysicianFee;
        this.cartTotal = res[0].orderTotal;
       })
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)
    // this.pwnHealthService.getTypes('?$orderby=Name')
    //   .subscribe((response) => {
    //     this.types = response;
    //   });

    this.load();
    this.updateNote(this.cartData);
    this.chform = new UntypedFormGroup({
      agreeSection3_1: new UntypedFormControl(''),
      agreeSection3_2: new UntypedFormControl(''),
      agree: new UntypedFormControl(''),
      selectedCard: new UntypedFormControl(''),
    });
    const id = this.sessionInfoStorage.getProfileId();
    this.profileService.getProfile(id).subscribe((profile) => {
      if(this.statesService.getState(profile.homeAddress.zip) !== profile.homeAddress.state){
        this.notificationService.block({ message: 'Your zip code does not match your state. Please contact GRL support to have your address updated to proceed with ordering tests.' },false)
      }
      this.zip = profile.govAddress.zip;
      this.user.fullName = `${profile.firstName} ${profile.lastName}`;
      this.user.birth = moment(profile.birthDate).format('YYYY-MM-DD');
      this.user.gender = profile.gender;
    })

  }

  check() {
    this.selectCard = this.allcards.find(el => el.id === this.chform.value.selectedCard)
  }

  toggleWithGreeting(tooltip) {
    if (tooltip._tooltipInstance?._closeOnInteraction) {
      tooltip.hide();
    } else {
      tooltip.show();
    }
  }

  updateNote(cartData, event?) {
    this.showNote = cartData.items.some((item) => {
      return environment.covidIdList.indexOf(item.product.id) > -1 || environment.covidIdListEmp.indexOf(item.product.id) > -1
    }) ? 'Please note that every order with a COVID-19 IgG test includes a $10 fee, collected on behalf of PWNHealth for Physician Oversight Services.' : '';
  }

  deleteItem = (id, productName) => {
    this.modalService.openConfirmModal('Are you sure to remove' + '\n' + productName + ' ?', () => {
      this.cartService.deleteFromCart(id);
    });
  };

  currentStep(ind) {
    this.step = ind;
  }

  getTotalPrice() {
    if (this.cartData) {
      if (this.cartData.items.length == 1) {
        return this.cartData.items[0].orderItemPrice;
      } else {
        return this.cartData.items.reduce((prev, current) => {
          return prev + current.orderItemPrice;
        }, 0);
      }
    }
  };

  getTotalMarketPrice() {
    if (this.cartData.items && this.cartData.items.length) {
      return this.cartData.items.reduce((prev, current) => {

        return prev + current.product.marketPrice;
      }, 0);
    }
  }


  getCardType(key) {
    if (!key) return;

    let needle = CONSTANTS.cardsTypes.find(
      (type) => {
        return type.value == key
      }
    );

    return needle ? needle.name : "";
  };

  getPaymentCard() {
    return this.chform.value.cardNumber.substring(0, 4) + "***" + this.chform.value.cardNumber.slice(this.chform.value.cardNumber.length - 4);
  };

  activateTab(key) {
    this.tab = key;
  };

  // updateCardList() {
  //   if (this.isFree)
  //     return;
  //   this.cardService.getAllCards().then((list: any) => {
  //     this.allcards = list;
  //     this.cardList = list.filter((el) => el.isActive === true);
  //     if (!this.cardList || this.cardList.length) {
  //       this.noActiveCards = true;
  //       this.savePaymentType = true;
  //     }

  //   });
  // }

  isCurrentYear() {
    return (item) => {
      return (this.card['expirationYear'] == this.currentYear) ? item > this.currentMonth - 1 : item;
    }
  };

  yearSelect() {
    if (this.card['expirationYear'] == this.currentYear && this.card['expirationMonth'] < this.currentMonth) {
      this.card['expirationMonth'] = this.currentMonth
    }
  };

  pay() {

    this.cartService.refreshCart().then(ress => {

      let allAreActive = true;
      let inActiveName = '';
      let removeProductId = '';
      ress[0].items.map((el, itemIndex)=>{
        el.children.push(el)
        el.children.forEach((child, index)=>{
          this.catalogService.getProduct(child.product.id).subscribe((res)=>{
            if (!res.isActive) {
              allAreActive = false;
              inActiveName = child.product.name;
              removeProductId = el.id;
            }
            if(el.children && index === el.children.length - 1 && itemIndex === ress[0].items.length -1){
              if(!allAreActive){
                this.cartService.deleteFromCart(removeProductId).then((ress)=> {
                  this.load();
                  this.notificationService.block({message: `We are sorry, but a test or panel that you had in your cart is no longer approved for ordering. Please contact customer support for help. <br><br>Non-approved test: ${inActiveName}`},false);
                })
              }else if(this.pwnNotApproved.length){
                this.notificationService.block({message: `We are sorry. Something didn't work. </br></br>Please contact GRL customer support and let them know that there was the following error: </br>Error: 101 </br>Test(s) affected: </br>${this.pwnNotApproved.join('</br>')}`},false);

              }else{
                // const totalAmount = this.isPromoCode? this.cartTotal : this.physicianFee + this.labDrawFee + this.getTotalPrice()  - this.discount
                const totalAmount = this.cartTotal;
                if(totalAmount == 0 ){
                  this.orderService.stripeFreeConfirm(this.orderInfo.orderId).subscribe((data)=>{
                    this.modalService.openInProgress()
                      this.modalService.openCheckoutSuccess()
                      this.cartService.clearSubmittedCart();
                      this.sessionInfoStorage.cleanPromoCode()
                      this.promoCodeSession = this.sessionInfoStorage.getPromoCode();
                      this.router.navigateByUrl('cabinet');
                  })
                }else{
                  this.modalService.openStripeComponent((data) => {
                    if(data.status == "succeeded") {
                      // this.notificationService.showSuccess({message: 'Payment succeeded!'});
                      this.modalService.openCheckoutSuccess()
                      this.cartService.clearSubmittedCart();
                      // this.router.navigate([`account`]);
                      this.sessionInfoStorage.cleanPromoCode()
                      this.promoCodeSession = this.sessionInfoStorage.getPromoCode();
                      this.router.navigateByUrl('cabinet');
                    }
                    // else{
                    //   this.modalService.showTopNotification(false,'Something went wrong.');
                    // }
                    this.cartService.refreshCart();
                  }, totalAmount);
                }
              }
            }
          })
        });
      })
    })

    return;

    this.alreadyPaid = true;
    let modalInProgress = this.modalService.openInProgress();
    let paymentData = {
      orderId: this.orderInfo.orderId,
      paymentTypeId: this.chform.value.selectedCard,
      savePaymentType: true,
      repeatOrderCount: 0,
      repeatOrderSettings: 0
    };
    // this.submitData(paymentData)

  };

  submitData(paymentData){
    this.orderService.submitCart(paymentData).subscribe(() => { //Todo: http://grl-api-dev.softprise.net/swagger/ui/index#!/Order/Order_ProceedToCheckout
          this.alreadyPaid = true;
          this.submitSuccess = true;
          this.paymentSuccess = true;
          this.paymentError = false;
          this.cartService.clearSubmittedCart();
          // if (this.savePaymentType) this.cardService.getAllCards();
          if (!this.paymentError) {
            // this.activateTab(5);
            this.disableAll = true;
            if (this.submitSuccess && this.paymentSuccess) this.modalService.openCheckoutSuccess();
          }
        }, (errorData) => {
          this.agree = false;
          this.agreeSection3_1 = false;
          this.agreeSection3_2 = false;
          this.alreadyPaid = false;
          if (errorData.data) {
            if (errorData.data.errorType == 1) this.logicError = true;
            if (errorData.data.errorType == 5) {
              this.paymentError = true;
              // this.activateTab(2);
            }
          } else this.submitError = true;

        });
  };

  updateItems(cartData, event?) {
    let token = this.sessionInfoStorage.getToken();
    this.cartData = cartData.items.map((item) => {
      if (!item.profile && token)
        item.profile = this.cartData['profileList'[0]];
      return item;
    });
  }

  showProcessingFee() {
    if (this.cartData.items.length > 0)
      this.modalService.openProcessingFee();
  };

  toggleShow(item) {
    this.currentId = item.id;
    if (!this.isShown) {
      this.sharedService.getTest(item.product.slug)
    }
    this.isShown = !this.isShown;
  }

  showStepThree = false;

  needHelp(){
    window["Beacon"]('open', '5e6e10bd-1d24-4f4f-bb92-5a4f48036d49')
  }
  promoCodeValue = '';
  addPRomoCode(promoCode, notification = true, load = true){

    // let reg = /^([a-z]|[A-Z]|[0-9]|[-]){3,}$/g;

    // if(!promoCode.match(reg)){
    //   this.notificationService.showWarning({message: `Unallowed character!`});
    //   return
    // }

    this.promoCodeService.checkPromoCode(this.orderInfo.orderId,promoCode).subscribe((res)=>{
      // this.isPromoCode = true;
      this.promoCodeValue = promoCode;

      this.cartTotal = res.orderTotal;
      this.subTotalPromoCode = res.subTotal;
      this.sessionInfoStorage.setPromoCode(promoCode);
      this.promoCodeSession = this.sessionInfoStorage.getPromoCode();
      if(notification){

        this.notificationService.showSuccess({message: `The promo code is applied successfully`});
      }
      if(load){
        this.load()
      }
    },()=>{
      if(!notification){
        this.removePromoCode(false, true)
        this.notificationService.block({message: `Please contact Grassroots Labs Support for help.`},false, true);
      }
    });
  }

  removePromoCode(notification = true, load = true){
    this.promoCodeService.removePromoCode(this.orderInfo.orderId).subscribe((res)=>{
      // this.isPromoCode = false;
      this.promoCodeValue = '';
      this.cartTotal = res.orderTotal;
      this.subTotalPromoCode = res.subTotal;
      this.sessionInfoStorage.cleanPromoCode()
      this.promoCodeSession = this.sessionInfoStorage.getPromoCode();
      if(notification){
        this.notificationService.showSuccess({message: `The promo code is removed successfully`});
      }
      if(load){
        this.load()
      }
    });
  }

  promoInput(text){
    this.promoCodeValue = text
  }
}
