import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AboutService } from 'src/app/services/about.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-admin-infusionsoft',
  templateUrl: './admin-infusionsoft.component.html',
  styleUrls: ['./admin-infusionsoft.component.scss']
})
export class AdminInfusionsoftComponent implements OnInit {
  model = {
    authorizationCode: this.route.snapshot.queryParams.code || null
  }
  errorData = false;
  redirect_uri;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private aboutService:AboutService,
    private notifications: NotificationService) { }

  ngOnInit() {
    if (!this.model.authorizationCode) {
      this.aboutService.getAuthClient().subscribe(
          (result) => {
              const client = result['clientId'];
              const redirect = `${window.location.origin}/dashboard/infusionsoft`;
              this.redirect_uri = "https://accounts.infusionsoft.com/app/oauth/authorize?response_type=code&realm=realm&client_id=" + client + "&redirect_uri=" + redirect;

          }
      );
  } else {
      this.aboutService.saveAuthCode(this.model).then(  // {model: {authorizationCode: $stateParams.code}}
          () => {(this.notifications.showSuccess({message:"InfusionSoft code has been sent to the server"}))
          }, (errorData) => {
              console.error(errorData);
              this.errorData = errorData;
          }
      )
  }
     // $scope.infusionCode = $state.params.code;
// console.info(" auth code = " + $scope.model.authorizationCode + " clientId = " + $scope.clientId);

;
}

test_activate() {
    // alert("you'll be redirected in 2 seconds");
    setTimeout(() => {
        console.warn(" 2 II");
    }, 2000 );
}
}
