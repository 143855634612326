<div class="container cabinet">
    <div class="col-md-12 top10 hidden-md hidden-lg">
      <!-- <h2 >Account Profile for {{ profile?.firstName + " " + profile?.lastName }}</h2> -->

      <!-- <hr> -->
      <mat-tab-group mat-align-tabs="start" animationDuration="0ms" [selectedIndex]="tab">
        <mat-tab>
          <ng-template mat-tab-label>
            <i class="fa fa-user"></i>
            Order History
          </ng-template>
          <app-order-history></app-order-history>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i class="fa fa-user"></i>
            Account Profile
          </ng-template>
          <app-account-profile *ngIf="!innerWidth"></app-account-profile>
        </mat-tab>
        <mat-tab *ngIf="isAdmin">
          <ng-template mat-tab-label>
            <i class="fa fa-users"></i>
            Children/Minors
          </ng-template>
          <app-children></app-children>
        </mat-tab>
        <!-- <mat-tab>
          <ng-template mat-tab-label>
            <i class="fa fa-credit-card"></i>
            Credit Card
          </ng-template>
          <app-card></app-card>
        </mat-tab> -->
        <mat-tab *ngFor="let option of sharedService.navigationList | async | navigation : 'myadmin'" [label]="option.name" style="flex: none !important;"><app-dynamic-content [name]="option.name"></app-dynamic-content></mat-tab>
      </mat-tab-group>
    </div>
    <div class="col-md-12 top10 hidden-xs hidden-sm desktopTabs">
      <!-- <h2 >Account Profile for {{ profile?.firstName + " " + profile?.lastName }}</h2> -->

      <!-- <hr> -->
      <mat-tab-group mat-align-tabs="start" animationDuration="0ms" [selectedIndex]="tab">
        <mat-tab>
          <ng-template mat-tab-label>
            <i class="fa fa-user"></i>
            Order History
          </ng-template>
          <app-order-history></app-order-history>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i class="fa fa-user"></i>
            Account Profile
          </ng-template>
          <app-account-profile *ngIf="innerWidth"></app-account-profile>
        </mat-tab>
        <mat-tab *ngIf="isAdmin">
          <ng-template mat-tab-label>
            <i class="fa fa-users"></i>
            Children/Minors
          </ng-template>
          <app-children></app-children>
        </mat-tab>
        <!-- <mat-tab>
          <ng-template mat-tab-label>
            <i class="fa fa-credit-card"></i>
            Credit Card
          </ng-template>
          <app-card></app-card>
        </mat-tab> -->
        <mat-tab *ngFor="let option of sharedService.navigationList | async | navigation : 'myadmin'" [label]="option.name" style="flex: none !important;"><app-dynamic-content [name]="option.name"></app-dynamic-content></mat-tab>
        <mat-tab disabled>
          <ng-template mat-tab-label></ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
