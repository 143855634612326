<!-- <header> -->
  <!-- Top -->
  <!-- <div class="row top">
    <div class="col-md-4 col-lg-4 hidden-xs info"> -->
      <!--            <i class="fa fa-phone fa-flip-horizontal"></i>-->
      <!--            <p>-->
      <!--                +1 (888) 315-3265<br>-->
      <!--                support@grassrootslabs.io-->
      <!--            </p>-->
    <!-- </div>
    <div class="col-md-3 col-lg-4 col-xs-12 text-center" href="main">
      <a href="">
        <img class="hidden-xs img-responsive height51" src="../../../assets/img/loggo.png">
        <img class="visible-xs" src="../../../assets/img/logo_dark.png">
      </a>
      <div style="float: left;" class="ng-autocomplete">
        <ng-autocomplete [data]="data" [searchKeyword]="keyword" (selected)='selectEvent($event)'
            (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>


  </div> -->
  <!-- Top -->

<!-- </header> -->

<header>
  <!-- Top -->
  <div class="row top">
<!--        <div class="col-md-4 col-lg-4 hidden-xs info">-->
<!--            <i class="fa fa-phone fa-flip-horizontal"></i>-->
<!--            <p>-->
<!--                +1 (888) 315-3265<br>-->
<!--                support@grassrootslabs.com-->
<!--            </p>-->
<!--        </div>-->
      <div class="col-md-3 col-sm-3 col-lg-2 col-xs-12 mt-4 headerImg"  ui-sref="main">
          <a routerLink="/">
              <img href="" class="hidden-xs img-responsive height51" src="../../../assets/img/loggo.png" style="width: 125px; height: 51px;">
              <img href="" class="visible-xs" src="../../../assets/img/logo_dark.png" style="margin-top: 3px; width: 170px; height: 70px;">
          </a>
        </div>

      <app-autocomplete ></app-autocomplete>

  </div>
<!--    <div class="visible-xs text-center">-->
<!--        <a ui-sref="testCenters">-->
<!--            <i class="fa fa-hospital-o"></i>-->
<!--            Test Centers-->
<!--        </a>-->
<!--    </div>-->
  <!-- <div class="hide-gt-xs col-md-6 col-lg-2 col-sm-9 col-xs-12 info searchMobileSteps" style="align:center">
      <div
          >
      </div>
  </div> -->
  <!-- Top -->
</header>

<!--<div class="container cart"-->
<!--     ng-show="showCart"-->
<!--     ng-controller="CartController"-->
<!--     ng-include="'/partials/MainPage/cart.html'"></div>-->
