<h1>Please select the option that most closely describes you.</h1>
<div class="panel panel-primary btn btn-primary"
     *ngFor="let item of ScreeningQuestion2; let i = index"
     style="width: 90%;margin-left: 25px; margin-bottom: 20px"
     (click)="screeningQuestion2Click(i,item)">
  <div class="panel-heading" style="white-space: pre-line; font-size: 15px">{{item.title}}</div>
  <div class="panel-body" style="white-space: initial; font-size: 15px; text-align: left;">{{item.body}}</div>
</div>

<div class="alert alert-danger" style="margin-top: 20px; font-size: 15px" role="alert">The results of this test
  should not be used to determine if you are eligible to return to work. If you have
  questions about your ability to return to work, please contact your employer or the department of health for
  guidance on appropriate testing and work safety.
</div>
