import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewResourcesService {
  list

  constructor(private http: HttpClient) {
    this.list = this.http.get('/api/content/list').pipe(shareReplay());
  }

  getNewResources() {
    return this.http.get(environment.base + '/api/taxonomy/newResources');
  }

  updateNewResources(model) {
    return this.http.put(environment.base + '/api/taxonomy/newResources', model).toPromise();
  }

  saveAuthCode(model) {
    return this.http.put(environment.base + '/api/taxonomy/authcode', model).toPromise();
  }

  getAuthClient() {
    return this.http.get(environment.base + '/api/taxonomy/clientid').toPromise();
  }
}
