<div class="cabinetTabs">
  <div class="container-fluid">
    <div class="actionLine">
      <button (click)="openModal()" class="btn btn-default" >
        <i class="fa fa-plus-circle" ></i>
        Add New Template
      </button>
    </div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th>Name</th>
        <th class="col-md-4">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let template of templates; let i = index">
        <td>
            {{template.name}}
        </td>
        <td>
          <button class="btn btn-success" (click)="copyLink(template.id)">
            <i class="fa fa-pencil-square"></i>
            Copy Link
          </button>
          <button class="btn btn-success" (click)="openModalToUpdate(template.id)">
            <i class="fa fa-pencil-square"></i>
            Edit
          </button>
          <button class="btn btn-danger" (click)="removeTemplate(template,i)">
            <i class="fa fa-trash"></i>
            Delete
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
