<div *ngIf="notifications.length !== 0" class="notifications-container ">
  <div class="forms notification">
    <div *ngFor="let notification of notifications; let i = index;"
        class="notifications-div {{notification.typeValue}}"
        (mouseover)="hideOnHover(notification)" >
      <div class="cancel" (click)="hideNotification(notification)"><i class="fa fa-times"></i></div>
      <span *ngIf="notification.isHtml" class="message" [innerHTML]="notification.message"></span>
      <span *ngIf="!notification.isHtml" class="message" >{{notification.message}}</span>
    </div>
    <button *ngIf="notifications[0].contact" (click)="contact()" class="btn btn-success">Contact Support</button>
  </div>
  <!-- <div *ngFor="let notification of notifications; let i = index;"
        class="notification {{notification.typeValue}}"
        (mouseover)="hideOnHover(notification)"
        >
    <span *ngIf="notification.isHtml" class="message" [innerHTML]="notification.message"></span>
    <span *ngIf="!notification.isHtml" class="message">{{notification.message}}</span>
    <span class="close-click fa fa-times" *ngIf="notification.allowClose" (click)="hideNotification(notification)"></span>
  </div> -->
    
</div>
