import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { SessionInfoStorageService } from '../services/session-info-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService, private sessionStorage: SessionStorageService, private sessionInfoStorageService: SessionInfoStorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*if (!request || !request.url || (request.url.startsWith('http'))) {
      return next.handle(request);
    }*/
    const token = this.sessionInfoStorageService.getToken();
    // config.headers["Content-Type"] = "application/json";
    /*if (token) {
      config.headers['Authorization'] = 'Bearer ' + SessionInfoStorageService.getToken();

      delete config.headers['Origin'];
    }*/
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    return next.handle(request);
  }
}
