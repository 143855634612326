import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs'
import { IPromocode } from '../interfaces/promo-code.interface';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {
  list =[];
  constructor(private http: HttpClient) { }

  addPromoCode(model):Observable<any>{
    // console.log('addPromoCode', environment.base + `/api/promo/create`, model)
    return this.http.post<any>(environment.base + `/api/promo/create`, model)
  }
  getPromoList():Observable<any>{
    // console.log('get promo', this.list)
    return this.http.get<any>(environment.base +  `/api/promo/list`)
    // .pipe(
    //   tap((list) => {
    //     this.list = list
    //     console.log('list', list);
    //   })
    // );
  }

  getPromoListById(promoId: any):Observable<IPromocode>{
    // console.log('get promo', this.list)
    return this.http.get<IPromocode>(environment.base +  `/api/promo/get/${promoId}`)
  }

  updatePromoCode(model): Observable<any>{
    return this.http.put(environment.base + `/api/promo/update`, model)
  }

  checkPromoCode(order,promoCode){
    return this.http.post<any>(environment.base +  `/api/order/promo/add`,{code: promoCode, orderId: order});
  }

  checkBluredPromoCode(code){
    return this.http.get<any>(environment.base +  `/api/promo/check?code=${code}`)
  }

  removePromoCode(order){
    return this.http.get<any>(environment.base +  `/api/order/promo/remove/${order}`);
  }
}
