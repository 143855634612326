import { Component, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { AccountService } from '../../services/account.service';
import { CartService } from '../../services/cart.service';
import { ModalService } from '../../services/modal.service';
import { NotificationService } from '../../services/notification.service';
import { ProfileService } from '../../services/profile.service';
import { SessionInfoStorageService } from '../../services/session-info-storage.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: [ './admin.component.scss' ]
})

export class AdminComponent implements OnInit {
  showCart = false;
  userName = '';
  userEmail = '';
  cartItemsCount = 0;
  isDashboard;
  logined;

  constructor(private accountService: AccountService,
              private $sessionStorage: SessionStorageService,
              public sessionInfoStorageService: SessionInfoStorageService,
              public notificationService: NotificationService,
              public modalService: ModalService,
              public cartService: CartService,
              public profileService: ProfileService,
              public router: Router) {
  }

  updateUserName(profiles) {
    // console.log(profiles);
    if (!profiles || !profiles.length) {
      return;
    }
    const profile = profiles[0];
    this.userName = profile.firstName + ' ' + profile.lastName;
    this.userEmail = profile.email;
  }

  updateItemCount(cartData) {
    this.cartItemsCount = cartData.itemsCount;
  }


  closeCart() {
    this.showCart = false;
    // this.notificationService.cartState.next({event: 'cart:close'})
    // $rootScope.$broadcast('cart:close', {});
  }

  openCart() {
    this.showCart = true;
  }

  toggleCart() {
    this.showCart = !this.showCart;
    if (this.showCart) {
      this.notificationService.cartState.next({ event: 'cart:onOpen', data: {} });
      // $rootScope.$broadcast('cart:onOpen', {});
    } else {
      this.notificationService.cartState.next({ event: 'cart:close', data: {} });
      // $rootScope.$broadcast('cart:close', {});
    }
  }

  setLogined() {

    const url = this.router.url;
    const tree: UrlTree = this.router.parseUrl(url);
    if (Object.keys(tree.queryParams).length && tree.queryParams['access_token']) {
      this.sessionInfoStorageService.setFakeAuthInfo(tree.queryParams);
    }
    if (this.$sessionStorage.retrieve('auth')) {
      this.logined = true;
      this.isDashboard = this.sessionInfoStorageService.isDashboardAllowed();
    } else {
      this.logined = false;
      this.isDashboard = false;
    }
  }


  openLoginPopup() {
    this.modalService.openLogin();
  };

  openRegisterPopup() {
    this.modalService.openRegistration();
  };

  logout() {
    this.router.navigateByUrl('');
    this.accountService.logout();
  };


  ngOnInit(): void {
    setTimeout(() => {
      this.setLogined();
    }, 1);
    this.cartService.refreshCart();
    this.$sessionStorage.observe('auth').subscribe(data => {
      this.setLogined();
    });
    this.profileService.getProfiles((profiles) => {
      this.updateUserName(profiles);
    });
    this.setLogined();

    this.notificationService.getProfileState().subscribe(data => {
      if (data && data.event === 'profiles:update') {
        this.updateUserName(data.data);
      }
    });
    const orderInfo = this.sessionInfoStorageService.getOrderInfo();
    if(orderInfo.orderId) {
      this.cartService.checkoutOrder(orderInfo.orderId).subscribe((x: any) => {
        this.updateItemCount({ itemsCount: x.items.length });
      });
    }

    this.notificationService.getCartState().subscribe(data => {
      if (data.event === 'cart:update') {
        this.updateItemCount(data.data);
      }
      if (data.event === 'cart:close') {
        this.closeCart();
      }
      if (data.event === 'cart:open') {
        this.openCart();
      }
    });

  }

}
