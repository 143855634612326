import { Component, OnInit, Inject } from '@angular/core';
import { FormsModule, Validators } from '@angular/forms';
import { ImageService } from 'src/app/services/image.service';
import { CategoryService } from 'src/app/services/category.service';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
  imageURL = environment.baseIconUrl;
  model;
  params = {
    $top: 10,
    $skip: 0
};
  editMode = !!this.data;
  categoryForm = new UntypedFormGroup({
    name: new UntypedFormControl('',Validators.required),
    description: new UntypedFormControl(''),
    uploadFile: new UntypedFormControl(''),
    uploadFile2: new UntypedFormControl(''),
    type: new UntypedFormControl(''),
  });
  images: any;
  constructor(
    private readonly imageService: ImageService,
    private readonly categoryService: CategoryService,
    public dialogRef: MatDialogRef<AddCategoryComponent>,
    private formBuilder: UntypedFormBuilder,
    private modalService:ModalService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){}

  ngOnInit(): void {
    this.getCategory()
  }

  getImages(){
		this.imageService.getImages(this.params).subscribe((result) => {
			this.images = result;
		})
  }
  
  save () {
    this.model = {
      name:this.categoryForm.get('name').value,
      description:this.categoryForm.get('description').value,
      uploadFile:this.categoryForm.get('uploadFile').value,
      uploadFile2:this.categoryForm.get('uploadFile2').value,
      type:this.categoryForm.get('type').value,
      id:this.data
    }

      if (this.editMode) {
          this.categoryService.updateCategory(this.model).subscribe(() => this.ok("Category '" + this.model.name + "' has been updated"));

      } else {
          this.categoryService.addCategory(this.model).subscribe(() => this.ok("Category '" + this.model.name + "' has been created"));
      }
  };

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.categoryForm.value);
  }

  cancel() {
      this.dialogRef.close('cancel');
  };

  openGallery(){
    this.modalService.openBrowseImage();
  }
  ok(message) {
      // this. data.onSuccess();
      // this.modalService.showTopNotification(message);
      this.dialogRef.close('ok');
  }

  getCategory() {
      this.categoryService.getCategory({id: this.data}).then((res) => {
          this.model = res;
          setTimeout(() => {
              this.categoryForm.patchValue({
                name:this.model['name'],
                description:this.model['description'],
                uploadFile:this.model['image1Id'],
                uploadFile2:this.model['image2Id'],
                type:this.model['flags']

              })
              this.model.type = this.model.flags;
              //console.log($scope.model.type);
          })
      })
  }

  uploadFileImage (fieldName, uploadFile) {
    // this.imageService.uploadImage(uploadFile).then(
    //     function (response) {
    //         this.modalService.showTopNotification(true, "Upload successful");
    //         this.model[fieldName] = response.id;
    //     }, function () {
    //         this.modalService.showTopNotification(false, "Upload failed");
    //     });
};
}

