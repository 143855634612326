import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookiesStorageService {

  $cookies;

  constructor(private cookieService: CookieService) {
    this.$cookies = cookieService;
  }

  saveAuth(info) {
    const date = new Date();
    const expireDate = new Date(date.getTime() + info.expires_in * 1000);
    this.$cookies.set('grassroots-auth', info, {expires: expireDate});
  }

  getAuth() {
    return this.$cookies.get('grassroots-auth');
  }

  clearAuth() {
    this.$cookies.delete('grassroots-auth');
  }
}
