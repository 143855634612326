import { Component, EventEmitter, Output } from '@angular/core';
import { EligibleIneligibleDirective } from 'src/app/Modals/partialCovid/eligible-ineligible.directive';
import { thirdGroup } from '../configs';

@Component({
  selector: 'app-saliva-third-screen',
  templateUrl: './saliva-third-screen.component.html',
  styleUrls: [ './saliva-third-screen.component.scss' ]
})
export class SalivaThirdScreenComponent extends EligibleIneligibleDirective {
  readonly thirdGroup = thirdGroup;
  ScreeningQuestion3 = {
    option1: { option1: false },
    option2: { option2: false }
  };
  @Output() ScreeningQuestion3Change = new EventEmitter<any>();

  screeningQuestion3Click(item) {
    this.ScreeningQuestion3[item].selected = true;
    this.ScreeningQuestion3Change.emit(this.ScreeningQuestion3[item]);
    this.eligibleClick(4);
  }
}
