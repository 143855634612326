import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, Inject, InjectionToken, OnDestroy, OnInit, Optional } from '@angular/core';

import { NotificationBarService } from './notification-bar.service';
import { MessagesConfig } from './message-config';
import { Subscription } from 'rxjs/index';
import { Notification, NotificationType  } from './index';
import { Router } from '@angular/router';


export const MESSAGES_CONFIG = new InjectionToken('notification-bar.messages.config');

@Component({
  selector: 'app-notification-bar',
  styleUrls: ['./notification-bar.scss'],
  templateUrl: './notification-bar.html',
  /*animations: [
    trigger('flyDown', [
      state('in', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-100%)'
        }),
        animate('600ms ease-in')
      ]),
      transition('* => void', [
        animate('200ms ease-out', style({
          opacity: 0,
          transform: 'translateY(-100%)'
        }))
      ])
    ])
  ]*/
})
export class NotificationBarComponent implements OnInit, OnDestroy {

  notifications: Notification[] = [];

  defaults = {
    message: '',
    type: NotificationType.Info,
    autoHide: true,
    contact: false,
    hideDelay: 3000,
    isHtml: true,
    allowClose: true,
    hideOnHover: false
  };

  subscription: Subscription;

  constructor(private notificationBarService: NotificationBarService,
              private router : Router,
              @Inject(MESSAGES_CONFIG) @Optional() private config?: MessagesConfig) {
    this.subscription = this.notificationBarService.onCreate.subscribe(this.addNotification.bind(this));
  }

  ngOnInit() {
  }

  addNotification(notification: Notification) {
    const newNotification = Object.assign({}, this.defaults, notification);
    if(this.router.url.indexOf('/dashboard') != -1){
      newNotification.autoHide = true;
    }
    newNotification.typeValue = NotificationType[newNotification.type].toLowerCase();
    if (this.config && this.config.messages) {
      newNotification.message = this.config.messages[notification.message] || notification.message;
    }

    this.notifications.push(newNotification);

    if (newNotification.autoHide) {
      window.setTimeout(() => {
        this.hideNotification(newNotification);
      }, newNotification.hideDelay);
    }
  }

  contact(){
    window["Beacon"]('open', '5e6e10bd-1d24-4f4f-bb92-5a4f48036d49')
  }

  hideNotification(notification: Notification) {
    const index = this.notifications.indexOf(notification);

    this.notifications.splice(index, 1);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  hideOnHover(notification: Notification) {
    if (notification.hideOnHover) {
      this.hideNotification(notification);
    }
  }
}
