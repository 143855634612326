const dontKnowWhatIsThis = [
  'Severe trouble breathing (such as being unable to talk without gasping for air)',
  'Continuous pain or pressure in the chest',
  'Feeling confused or having difficulty waking up',
  'Blue-colored lips or face',
  'Any other emergency signs or symptoms'
];

export type fieldSetType = { aHref: string; name: string; model: string; text: string; id: string; value: string; labelFor: string }

const fieldSet: Array<fieldSetType> = [ {
  text: ' PWN Notice of Privacy Practices',
  model: 'privacyPractices',
  value: '1',
  id: 'privacyPractices',
  name: 'privacyPractices',
  labelFor: 'privacyPractices',
  aHref: 'https://www.pwnhealth.com/privacy-policy-covid-19/'
}, {
  text: 'PWN Terms and Conditions',
  model: 'termsAndConditions',
  value: '2',
  id: 'termsAndConditions',
  name: 'termsAndConditions',
  labelFor: 'termsAndConditions',
  aHref: 'http://www.pwnhealth.com/self-pay-terms-of-use/'
}, {
  text: 'PWN COVID-19 Test Consent',
  model: 'testConsent',
  value: '3',
  id: 'testConsent',
  name: 'testConsent',
  labelFor: 'testConsent',
  aHref: 'https://www.pwnhealth.com/covid-19/'
} ];
const h3Element = [
  ` PWNHealth provides physician oversight, ordering, and follow-up consults for COVID-19
      testing. Please review and consent to each item below.`,
  `By checking, I consent to the PWNHealth Notice of Privacy Practices, Terms and
      Conditions, and Consent.` ];
export const consentConfig = {
  dontKnowWhatIsThis,
  fieldSet,
  h3Element
};
