import { Pipe, PipeTransform } from '@angular/core';
import { forEachChild } from 'typescript';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {

    let filterItems = [];
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
      searchText = searchText.toLocaleLowerCase();
      items.filter(it => {
        if(it.userFullName){
          if(it.userFullName.toLocaleLowerCase().includes(searchText)){
            filterItems.push(it);
          }
          if(it.userEmail){
            if(it.userEmail.toLocaleLowerCase().includes(searchText)){
              filterItems.push(it);
            }
          }
            if(it.number.toLocaleLowerCase().includes(searchText)){
            filterItems.push(it)

          }
        }




      });
      return filterItems
    }
  }
