import { Injectable } from '@angular/core';
import { CategoryService } from './category.service';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriesStorageService {

  categories = null;
  zeroCategories = null;

  constructor(public categoryService: CategoryService) {
  }

  getAll() {
    return this.categoryService.getAllCategories({flags: 1});
  }

  getAllZero() {
    return this.categoryService.getAllCategories({flags: 0});
  }

  getAllZeroWithProducts() {
    return this.categoryService.getCategoriesWithProducts(0, 5);
  }

  getName(id) {
    return this.getAll().pipe(filter((v: any) => {
      if (v.id === id) {
        return v.name;
      }
    }));
  }

  getNameZero(id) {
    if (this.zeroCategories) {
      return this.getAll().pipe(filter((v: any) => {
          if (v.id === id) {
            return v.name;
          }
        })
      );
    }
  }
}
