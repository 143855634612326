import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { CartService } from 'src/app/services/cart.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: [ './autocomplete.component.scss' ],
})
export class AutocompleteComponent implements OnInit {
  showCart = false;
  cartItemsCount = 0;
  message = '';
  searchFormControl = new UntypedFormControl('');
  options: string[] = [ 'One', 'Two', 'Three' ];
  filteredOptions: Observable<string[]>;
  search;
  innerWidth;
  isActive = true;
  data = [];
  selectedItem = null;
  searchText = null;
  noCache = true;
  keyword = 'name';
  minError: boolean;
  searchOpen: boolean;

  constructor(

    public sessionInfoStorageService: SessionInfoStorageService,

    public cartService: CartService,
    public profileService: ProfileService,
    private router: Router,
    private dataService: DataService,
    public notificationService: NotificationService
  ) {
  }

  updateItemCount(cartData) {
    this.cartItemsCount = cartData.itemsCount;
  }
  closeCart() {
    this.showCart = false;
  }

  openCart() {
    this.showCart = true;
  }

  toggleCart() {
    this.showCart = !this.showCart;
    if (this.showCart) {
      this.notificationService.cartState.next({event: 'cart:onOpen', data: {}});
      // $rootScope.$broadcast('cart:onOpen', {});
    } else {
      this.notificationService.cartState.next({event: 'cart:close', data: {}});
      // $rootScope.$broadcast('cart:close', {});
    }
  }


  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.dataService.currentMessage.subscribe(message => this.message = message);
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe(val => this.querySearch(val));

    this.notificationService.getCartState().subscribe(data => {
      if (data.event === 'cart:update') {
        this.updateItemCount(data.data);
      }
      if (data.event === 'cart:close') {
        this.closeCart();
      }
      if (data.event === 'cart:open') {
        this.openCart();
      }
    });
  }

  searchF() {
    this.querySearch(this.searchFormControl.value);
  }

  testCentersRedirect() {
    this.router.navigateByUrl('testcenters');
  }


  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  newMessage(message: string) {
    this.dataService.changeMessage(message);
  }

  querySearch(query) {
    this.newMessage(query);
    // console.log($scope.search);
    // if($scope.searchValue.length < CONSTANTS.MIN_SEARCH_LENGTH) {
    //     $scope.minError = true;
    //     return;
    // }
    this.search = query;
    this.minError = false;
    this.searchOpen = false;
    // $scope.search()
    this.router.navigateByUrl(`search/${ query }`);
    // $uibModalInstance.dismiss('cancel');

    // if(query && query.length > 1) {
    //    return this.productService.searchProducts(escape(query))
    //         .subscribe((result) => {
    //             console.log(result);
    //             this.data = result.items;
    //             // return [];
    //             return result.items;
    //         });
    // } else {
    //     this.data = [];
    // }
  };

  selectedItemChange(item) {
    this.selectedItem = null;
    this.searchText = null;
    setTimeout(() => {
      // this.router.navigate('product', {id: item.slug});
    });
  }
}
