import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from 'src/app/const';
import { DataService } from 'src/app/services/data.service';
import { ProductService } from 'src/app/services/product.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: [ './search-results.component.scss' ]
})
export class SearchResultsComponent implements OnInit, OnChanges, OnDestroy  {
  title = ' Find Your Lab Test | Grassroots Labs Search';
  defaultTitle = 'Grassroots Labs - Blood Tests are Expensive. We fix that.';
  metaDescription = 'Easily search for your required lab test from a comprehensive list at Grassroots Labs. Affordable, transparent, and physician-backed testing is just a click away. Start your search now.';
  searchMessage = '';
  products = [];
  // searchForm = new FormControl('');
  path;
  page = 1;
  pageSize: number = 10;
  noFound: boolean;
  minError: boolean;
  testCount: any;
  skip;
  top;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private metaService: Meta,
    private titleService: Title
  ) {
  }

  _displayedProducts = [];

  get displayedProducts() {
    return this._displayedProducts
      .map((products, i) => ({ id: i + 1, ...products }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  ngOnInit(): void {
    this.metaService.addTag({ name: 'description', content: this.metaDescription});
    this.titleService.setTitle(this.title);
    this.productService.getAllProducts(this.path).subscribe(
      (result) => {
        this.products = result.items;
        this._displayedProducts
          .map((products, i) => ({ id: i + 1, ...products }))
          .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
        this.dataService.currentMessage.subscribe(message => {
          if (message !== this.searchMessage) {
            this.searchMessage = message;
            this.search(this.searchMessage);
          }
        });
      }
    );
    this.path = {
      skip: 0,
      top: 10
    };


  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onPageChange(currentPage) {
    this.skip = (10 * currentPage) - 10;
    const top = 10;
  }

  doSearch(message: string) {
    this._displayedProducts = this.products.filter((item) => {
      return (item.name && item.name.toLowerCase().indexOf(message.toLowerCase()) != -1) ||
        (item.cpt && item.cpt.toLowerCase().indexOf(message.toLowerCase()) != -1) ||
         (item.questCode && item.questCode.indexOf(message) != -1) ||
          (item.tags && item.tags.indexOf(message) != -1);
    });

    this.testCount = this._displayedProducts.length;
    this.noFound = !this.displayedProducts.length;
  }

  search(message: string) {
    this.path;
    if (message.length < CONSTANTS.MIN_SEARCH_LENGTH) {
      this.minError = true;
      this._displayedProducts = [];
      return;
    }
    this.doSearch(message);
  };

  ngOnDestroy(): void {
    this.titleService.setTitle(this.defaultTitle);
    this.metaService.removeTag('name=\'description\'');
  }
}


