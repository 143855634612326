<div  class="container" >

    <div  class="row marketing">    
        <h1 style="text-align: center;">Add new card</h1>
        <form  [formGroup]="chform" (ngSubmit)="submit()"  class="form-horizontal" novalidate>
            <div  class="col-lg-12">
                <div class="container cabinet">
                    <div class="col-md-12 top10">
                        <fieldset class="form-horizontal" >
                            <div>
                                <!-- <div class="form-group">
                                    <label class="col-xs-4 col-sm-4 control-label font16">Card Type *</label>
                                    <div class="col-xs-8 col-sm-6">
                                        <ng-select   formControlName="cardType" bindLabel="cardT"  style="width: 100%" theme="bootstrap" class="cardType"
                                        [clearable]="false">
                                        <ng-option   *ngFor="let card of cardsT" [value]="card">
                                                {{card.name}}
                                        </ng-option>
                                        </ng-select>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <label class="col-xs-4 col-sm-4 control-label font16">Card Number *</label>
                                    <div class="col-xs-8 col-sm-6">
                    
                                        <input required type="text"
                                                formControlName="cardNumber"
                                                class="form-control"
                                                name="cardNumber"
                                                id="card-number"
                                                placeholder="Debit/Credit Card Number"
                                                minlength="14"
                                                maxlength="16">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-4 col-sm-4 control-label font16">Expiration Date *</label>
                                    <div class="col-xs-8 col-sm-6">
                                        <div class="row dateSelect">
                                            <div class=" mobileFlex form-field-group" style="margin-right: 15px;">
                                                <mat-form-field  appearance="outline">
                                                    <mat-select formControlName="month" bindLabel="Month" style="text-align: center;" placeholder="Month">
                                                        <mat-option  *ngFor="let month of months" [value]="month">
                                                            {{month}}
                                                        </mat-option>
                                                    </mat-select>
                                                    </mat-form-field>
                                            </div>
                                            <div class=" mobileFlex form-field-group">
                                                <mat-form-field appearance="outline">
                                                    <mat-select formControlName="year" bindLabel="Year" style="text-align: center" placeholder="Year">
                                                        <mat-option style="height: 2em !important;" *ngFor="let year of years" [value]="year">
                                                            {{year}}
                                                        </mat-option>
                                                    </mat-select>
                                                    </mat-form-field>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-xs-4 col-sm-4 control-label font16">Card CVV *</label>
                                    <div class="col-xs-8 col-sm-6">
                    
                                        <input type="text"
                                                formControlName="cvc"
                                                class="form-control"
                                                name="cvc"
                                                required="true"
                                                id="cvc"
                                                placeholder="Security Code"
                                                minlength="3"
                                                maxlength="4">
                                    </div>
                                </div>
                    
                            </div>
                    
                        </fieldset>
                        <div style="float: right;">
                            <button class="btn btn-warning font16" type="button" (click)="cancel()">Cancel</button>
                            <button class="btn btn-success font16" type="submit" ><i class="fa fa-thumbs-up"></i>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
