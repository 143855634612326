import { Component, EventEmitter, Output } from '@angular/core';

import { EligibleIneligibleDirective } from 'src/app/Modals/partialCovid/eligible-ineligible.directive';

@Component({
  selector: 'app-saliva-symptoms',
  templateUrl: './saliva-symptoms.component.html',
  styleUrls: ['./saliva-symptoms.component.scss']
})
export class SalivaSymptomsComponent extends EligibleIneligibleDirective {
  statementApplied = {symptoms1: false, symptoms2: false, symptoms3: false};
  ScreeningQuestion1 = {
    symptoms1: [{
      id: 'highFever',
      text: 'I have a high fever of greater than 102 degrees Fahrenheit, OR I have a high fever that has lasted longer than 48 hours.'
    }, {
      id: 'speakFull',
      text: 'I can\'t speak full sentences or do simple activities without feeling short of breath.'
    }, {
      id: 'severeCoughing',
      text: 'I am having severe coughing spells, or I am coughing up blood.'
    }, {
      id: 'faceBlue.',
      text: 'My lips or face are blue.'
    }, {
      id: 'veryTired',
      text: 'I feel very tired or lethargic.'
    }, {
      id: 'veryTired',
      text: 'I feel very tired or lethargic.'
    }, {
      id: 'dizzy',
      text: 'I feel dizzy, lightheaded, or too weak to stand.'
    }, {
      id: 'slurredSpeech',
      text: 'I am having slurred speech or seizures.'
    }, {
      id: 'seriouslyIll',
      text: 'I do not feel like I can stay at home because I feel seriously ill.'
    }],
    symptoms2: [{
      text: 'I have a fever between 100.4 degrees Fahrenheit and 100.2 degree Fahrenheit, am feeling feverish, or feel warm to the touch.'
    }, {
      text: 'I have a new or worsening cough.'
    }, {
      text: 'I have a new or worsening sore throat.'
    }, {
      text: 'I am having flu-like symptoms (chills, runny or stuffy nose, whole body aches, headache, and/or feeling tired).'
    }, {
      text: 'I am having shortness of breath that is not limiting my ability to speak.'
    }, {
      text: 'I have new loss of taste or smell.'
    }],
    symptoms3: [{text: 'I am not having any symptoms, or I am having symptoms, but they are getting better.'}]
  };
  @Output() ScreeningQuestion1Change = new EventEmitter<any>();

  screeningQuestion1Click(statement, screen) {
    this.statementApplied[statement] = true;
    this.ScreeningQuestion1Change.emit(this.statementApplied);
    this.eligibleClick(screen);
  }

}
