import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModalService } from './modal.service';
import { NotificationService } from './notification.service';
import { OrderService } from './order.service';
import { ProfileService } from './profile.service';
import { SessionInfoStorageService } from './session-info-storage.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  reflexesPresence = false;
  cartData = {
    items: [],
    itemsCount: 0,
    processingFee: environment.processingFee
  };
  public isExist = false;
  private deletePromise: Promise<Object>;

  constructor(
    private orderService: OrderService,
    private sessionInfoStorageService: SessionInfoStorageService,
    private notifications: NotificationService,
    private profileService: ProfileService,
    private sharedService: SharedService,
    private modalService: ModalService) {
    notifications.getAuthenticationState().subscribe(data => {
      this.refreshCart();
    });
  }

  sendCartInfo(){

    return this.cartData;
  }

  processToCart(product) {
    if (product.productType === 2) {
      this.notifications.showWarning({
        message: product.name + ' has been added to your cart.' +
        ' Be advised, this is a reflex test which has a possible extra fee of <b>$' + product.extraTestsPrice +
        '</b> if the test returns a positive result. See the test\'s description for more information.'
      });
    } else {
      this.notifications.showSuccess({message: product.name + ' has been added to your cart.'});
    }
    const orderId = this.sessionInfoStorageService.getOrderInfo().orderId;
    const params = {
      orderId,
      productId: product.id,
      profileId: '',
      eligibility: product.eligibility
    };

    const addCartPromise = this.orderService.addCategoryToCart(params);
    addCartPromise.then((res: any) => {
      if (params.orderId == null && res.id || params.orderId !== res.id) {
        this.profileService.getProfiles((profiles) => {

          if (profiles && profiles.length) {

            this.sessionInfoStorageService.setOrderInfo({
              orderId: res.id,
              profileId: profiles[0].id,
              orderNumber: res.number
            });
          } else {
            this.sessionInfoStorageService.setOrderInfo({
              orderId: res.id,
              profileId: null,
              orderNumber: res.number
            });
          }
        });
      }
      // this.cartData.processingFee = res.processingFee;

      const item = {
        id: res.newItemId,
        product
      };
      this.cartData.items.push(item);
      this.updateCartData(this.cartData.items);
    });
    return addCartPromise;
  }


  addToCart(product) {

    let products = this.sendCartInfo();
    let hasBlock = false;
    products.items.forEach((el)=>{
        if(el.product.id === product.id){
          this.isExist = true
          this.notifications.block({message: 'This test is already in your cart and can only be added once.'},false);
        }else if(product.children && product.children.length > 0){
          if(product.children.findIndex(x => x.id === el.product.id) != -1){
            this.deleteFromCart(el.id)
            this.notifications.block({message: el.product.name + ' was removed from your cart, because it is included in the panel which was just added.'},false);
          }
        }else if(el.product.children && el.product.children.length > 0){
          el.product.children.forEach((child)=>{
            if(child.id === product.id && !hasBlock){
              this.isExist = true
              hasBlock = true
              this.notifications.block({message: 'This test is already included in a panel in your cart and can not be added individually.'},false);
            }
          })
        }
      })
      if(!this.isExist){

        if (product && environment.questCodesSaliva.indexOf(product.questCode) > -1) {
          this.modalService.openCovid19Saliva((data) => {
              Object.assign(product, {eligibility: data});
              this.processToCart(product);
          })
        } else if (product && environment.covidIdListEmp.indexOf(product.id) > -1) {
          this.modalService.openCovidEmp((data) => {
              Object.assign(product, {eligibility: data});
              this.processToCart(product);
          })
        } else if (product && environment.covidIdList.indexOf(product.id) > -1) {
          this.modalService.openCovidEmp((data) => {
              Object.assign(product, {eligibility: data});
              if(data){
                this.processToCart(product);
              }
          })
        } else if (product) {
          const covidExist = environment.questCodesEmp.indexOf(product.questCode) > -1;

          const covidWithoutThreeStepsExist = environment.questCodesEmpWithoutThreeSteps.indexOf(product.questCode) > -1;

          if (covidWithoutThreeStepsExist) {
              this.modalService.openCovidWithoutThreeSteps((data) => {
                  Object.assign(product, {eligibility: data});
                  this.processToCart(product);
              })
          }
          if (covidExist) {
              this.modalService.openCovidEmp( (data) => {
                  Object.assign(product, {eligibility: data});
                  this.processToCart(product);
              })
          }
          if (!covidWithoutThreeStepsExist && !covidExist) {
            if (this.deletePromise) {
              return this.deletePromise.then(() => {

                this.deletePromise = null;
                this.processToCart(product);
              });
            } else {
              return this.processToCart(product);
            }

          }
        } else {
          return this.processToCart(product);
        }
      }
      this.isExist = false

}

  updateOrder(items) {
    if (!items) {
      items = this.cartData.items;
    }
    const orderId = this.sessionInfoStorageService.getOrderInfo().orderId;
    const params = {
      orderId,
      action: 1,
      items
    };
    return this.orderService.updateCart(params);
  }

  refreshCart() {
    const orderInfo = this.sessionInfoStorageService.getOrderInfo();
    return new Promise((resolve, reject) => {


      if (!orderInfo.orderId) {
        this.updateCartData();
        resolve([]);
      } else {
        this.profileService.getProfiles((profiles) => {

          let isOwnProfile = profiles.find((profile) => {

            return profile.id === orderInfo.profileId;
          });
          isOwnProfile = isOwnProfile || !profiles.length;
          if (isOwnProfile) {
            this.checkoutOrder(orderInfo.orderId, true).subscribe(function() {
              resolve(arguments);
            });
          } else {
            this.sessionInfoStorageService.cleanOrderInfo();
            this.updateCartData();
            resolve([]);
          }
        });
      }
    });


  }

  checkoutOrder(orderId, refresh?) {
    const orderList = this.orderService.getOrder(orderId, refresh)
    orderList.subscribe((res: any) => {

      const processedItems = res.items.map((item) => {
        item.product.quantity = 1;
        return item;
      });

      this.updateCartData(processedItems);
    }, () => {
      this.sessionInfoStorageService.cleanOrderInfo();
      this.updateCartData();
    });

    return orderList;

  }

  updateCartData(items?) {
    if (!items) {
      items = [];
    }
    this.cartData.items = items;
    this.cartData.itemsCount = items.length;
    this.reflexesPresence = false;

    this.cartData.items.forEach((item) => {
      if (item.product.productType === 2) {
        this.reflexesPresence = true;
      }
      if(!item.product.hasOwnProperty('children')){
        this.sharedService.getTest(item.product.slug).subscribe((res)=>{
          item.product.children = res.children
        })
      }
    });
    this.notifications.cartState.next({event: 'cart:update', data: this.cartData});
    // this.$rootScope.$broadcast('cart:update', this.cartData);
  }


  getReflexesPresence() {
    return this.reflexesPresence;
  }

  deleteFromCart(id) {
    const orderId = this.sessionInfoStorageService.getOrderInfo().orderId;
    const params = {
      orderId,
      itemId: id
    };

    const deleteFromCart = this.orderService.deleteItemInCart(params);
    deleteFromCart.then(() => {
      this.cartData.items.forEach((item, index) => {
        if (item.id === id) {
          this.cartData.items.splice(index, 1);
          this.updateCartData(this.cartData.items);
        }
      });
    }, () => {
      this.sessionInfoStorageService.cleanOrderInfo();
      this.updateCartData();
    });
    return deleteFromCart;
  }

  clearCart() {
    const orderId = this.sessionInfoStorageService.getOrderInfo().orderId;
    const params = {
      orderId
    };
    const deleteFromCart = this.orderService.clearCart(params); // deleteAllItems
    deleteFromCart.then(() => {
      this.updateCartData();
    }, () => {
      this.clearSubmittedCart();
    });
    return deleteFromCart;
  }

  clearSubmittedCart() {
    this.sessionInfoStorageService.cleanOrderInfo();
    this.updateCartData();
  }

  submitCart() {
    const orderId = this.sessionInfoStorageService.getOrderInfo().orderId;
    return this.orderService.submitCart(orderId);
  }

  closeCart() {
    this.notifications.cartState.next({event: 'cart:close'});
  }

  openCart() {
    this.notifications.cartState.next({event: 'cart:open'});
  }
}
