<div class="cancel" (click)="cancel()"><i class="fa fa-times"></i></div>
<app-modal-logo></app-modal-logo>
<mat-dialog-content class="mat-typography" style=" padding:20px">
  <app-symptom *ngIf="!ineligible && !eligible" [(eligible)]="eligible"></app-symptom>
  <app-pcr-test *ngIf="eligible === 1" [(eligible)]="eligible" (optionChange)="this.option=$event"></app-pcr-test>

  <app-covid-contact *ngIf="eligible === 2" [(eligible)]="eligible" ></app-covid-contact>

  <section *ngIf="ShowNextScreen === 1">

  </section>
  <section *ngIf="eligible === 3 && !ineligible2">
    <h1>Please fill out the following demographic information.</h1>
    <div class="row">
      <div class="col-md-12" style="text-align:center">
        <form name="form" class="form-horizontal" novalidate>
          <section *ngIf="!showScreeningQuestion2">
            <div class="panel panel-primary">

              <div class="panel-heading">
                <h3 class="panel-title">
                  What is your ethnicity?
                </h3>
              </div>

              <div class="panel-body text-info">

                <div class="form-group" *ngFor="let item of firstGroup">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">

                        <input type="checkbox"
                               style="margin-top: 7px"
                               (change)="onCheckBoxSelected(item.id)"
                               value="{{item.id}}"
                               id="firstGroup{{item.id}}"
                               [(ngModel)]="item.Selected"
                               name="first"
                               class="checkbox-inline col-xs-2 col-sm-2"/>
                        <label for="firstGroup{{item.id}}" class="col-xs-10 col-sm-10 control-label"
                               style="text-align: left">{{item.Name}}</label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div [hidden] class="form-group col-sm-12 pull-right"
                     *ngIf="IsValidFirstGroup === null || IsValidFirstGroup">
                  <div class="alert alert-danger" role="alert">
                    Select at least one checkbox!
                  </div>
                </div>
              </div>
            </div>


            <div class="panel panel-info">
              <div class="panel-heading">
                <h3 class="panel-title">
                  What is your race?
                </h3>
              </div>

              <div class="panel-body text-info">

                <div class="form-group" *ngFor="let item of secondGroup">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">
                        <input type="checkbox"
                               (change)="onCheckBoxSelected(item)"
                               style="margin-top: 7px"
                               value="{{item.id}}"
                               id="secondGroup{{item.id}}"
                               [(ngModel)]="item.Selected"
                               class="checkbox-inline col-xs-2 col-sm-2"/>
                        <label for="secondGroup{{item.id}}"
                               class="col-xs-10 col-sm-10 control-label"
                               style="text-align: left">{{item.Name}}</label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div [hidden] class="form-group col-sm-12 pull-right"
                     *ngIf="IsValidSecondGroup === null || IsValidSecondGroup">
                  <div class="alert alert-danger" role="alert">
                    Select at least one checkbox!
                  </div>
                </div>
              </div>
            </div>


            <div class="panel panel-warning">
              <div class="panel-heading">
                <h3 class="panel-title">
                  Do any of the following statements apply to you?
                </h3>
              </div>

              <div class="panel-body text-info">

                <div class="form-group" *ngFor="let item of thirdGroup">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">
                        <input type="checkbox"
                               (change)="onCheckBoxSelected(item.id)"
                               style="margin-top: 7px"
                               value="{{item.id}}"
                               id="thirdGroup{{item.id}}"
                               [(ngModel)]="item.Selected"
                               class="checkbox-inline col-xs-2 col-sm-2"/>
                        <label for="thirdGroup{{item.id}}"
                               class="col-xs-10 col-sm-10 control-label"
                               style="text-align: left">{{item.Name}}</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <button (click)="nextScreen() " type="button" class="btn btn-default"><i
                  class="fa fa-sign-in"></i>NEXT SCREEN
                </button>

              </div>

            </div>

          </section>

          <section *ngIf="showScreeningQuestion2 && !showScreeningQuestion3">
            <div class="panel panel-primary">

              <div class="panel-heading">
                <h3 class="panel-title">
                  Please select the statement that applies to you: (single-select, required)
                </h3>
              </div>

              <div class="panel-body text-info">

                <div class="form-group" *ngFor="let item of screeningQuestion2;let $index = i">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">
                        <input type="checkbox"
                               style="margin-top: 7px"
                               (change)="onCheckBoxSelected(true)"
                               (click)="updateSelection($index, screeningQuestion2, item.id)"
                               value="{{item.id}}"
                               id="screeningQuestion2{{item.id}}"
                               [(ngModel)]="item.Selected"
                               class="checkbox-inline col-xs-2 col-sm-2"/>
                        <label for="screeningQuestion2{{item.id}}"
                               class="col-xs-10 col-sm-10 control-label"
                               style="text-align: left">
                          {{item.Name}}
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section *ngIf="HouseholdContact && !showScreeningQuestion3">
            <div class="panel panel-primary">

              <div class="panel-heading">
                <h3 class="panel-title">
                  Are you currently experiencing symptoms of COVID-19 (fever, cough, shortness of
                  breath)*?
                </h3>
              </div>

              <div class="panel-body text-info">

                <div class="form-group" *ngFor="let item of screenHouseholdContact; let $index = i">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">
                        <input type="checkbox"
                               style="margin-top: 7px"
                               (change)="onCheckBoxSelected(true)"
                               (click)="updateSelection($index, screenHouseholdContact, item.id)"
                               value="{{item.id}}"
                               id="screenHouseholdContact{{item.id}}"
                               [(ngModel)]="item.Selected"
                               class="checkbox-inline col-xs-2 col-sm-2"/>
                        <label for="screeningQuestion2{{item.id}}"
                               class="col-xs-10 col-sm-10 control-label"
                               style="text-align: left">
                          {{item.Name}}
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section *ngIf="HouseholdContactSubYes || HouseholdContactSubNo && !showScreeningQuestion3">
            <div class="panel panel-primary">

              <div class="panel-heading">
                <h3 class="panel-title">
                  In the past 10 days, have you had a positive
                  COVID-19 PCR test result?
                </h3>
              </div>

              <div class="panel-body text-info">

                <div class="form-group" *ngFor="let item of screenNoFever;let $index = i">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">
                        <input type="checkbox"
                               style="margin-top: 7px"
                               (change)="onCheckBoxSelected(true)"
                               (click)="updateSelection($index, screenNoFever, item.id)"
                               value="{{item.id}}"
                               id="screenNoFever{{item.id}}"
                               [(ngModel)]="item.Selected"
                               class="checkbox-inline col-xs-2 col-sm-2"/>
                        <label for="screenNoFever{{item.id}}"
                               class="col-xs-10 col-sm-10 control-label"
                               style="text-align: left">
                          {{item.Name}}
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section *ngIf="showScreeningQuestion3 && !ineligible2">
            <div class="panel panel-danger">

              <div class="panel-heading">
                <h3 class="panel-title">
                  Do ALL of the following statements apply to you?
                </h3>
              </div>

              <div class="panel-body text-danger">

                <div class="form-group" *ngFor="let item of screeningQuestion3">
                  <div class="col-sm-12">
                    <fieldset>
                      <div style="display: block;text-align: left;">
                        <span class="col-xs-2 col-sm-2"></span>
                        <label
                          class="col-xs-10 col-sm-10 control-label"
                          style="text-align: left">
                          {{item.Name}}
                        </label>
                      </div>

                    </fieldset>
                  </div>
                </div>
                <div style="display: flex; justify-content: flex-start">
                  <span class="col-xs-2 col-sm-2"></span>
                  <input type="radio"
                         name="RespiratorySymptomsPreSub"
                         style="margin-top: 7px"
                         value="Yes"
                         id="RespiratorySymptomsPreSubYes"
                         (click)="RespiratorySymptomsPreSubYes=true; RespiratorySymptomsPreSubNo=false; onCheckBoxSelected(true)"
                         class="checkbox-inline col-xs-2 col-sm-2"/>
                  <label for="RespiratorySymptomsPreSubYes">Yes</label><br>
                  <input type="radio"
                         name="RespiratorySymptomsPreSub"
                         style="margin-top: 7px"
                         value="No"
                         id="RespiratorySymptomsPreSubNo"
                         (click)="RespiratorySymptomsPreSubNo=true; RespiratorySymptomsPreSubYes=false; ineligible2=true; onCheckBoxSelected(true)"
                         class="checkbox-inline col-xs-2 col-sm-2"/>
                  <label for="RespiratorySymptomsPreSubNo">No</label>
                </div>
                <section *ngIf="RespiratorySymptomsPreSubNo">
                                        <span>
                                            Based on your response, testing is not recommended for you at this time. Please complete this assessment when your symptoms have improved. If your symptoms do not
                                            improve, please contact your healthcare provider for evaluation.
                                        </span>
                </section>
                <section [hidden]="RespiratorySymptoms">
                  <div class="form-group">
                    <div class="col-sm-12">
                      <fieldset>
                        <div style="display: block;text-align: left; display: flex; flex-direction: column">
                          <div>
                            <span class="col-xs-2 col-sm-2"></span>
                            <label class="col-xs-10 col-sm-10 control-label"
                                   style="text-align: left">
                              Are your respiratory symptoms (such as cough or shortness of
                              breath) have improved, or I have not had respiratory
                              symptoms?
                            </label>
                          </div>
                          <div style="display: flex; justify-content: flex-start">
                            <span class="col-xs-2 col-sm-2"></span>
                            <input type="radio"
                                   name="RespiratorySymptomsSub"
                                   style="margin-top: 7px"
                                   value="Yes"
                                   id="RespiratorySymptomsSubYes"
                                   (click)="RespiratorySymptomsSubYes=true; RespiratorySymptomsSubNo=false"
                                   class="checkbox-inline col-xs-2 col-sm-2"/>
                            <label for="RespiratorySymptomsSubYes">Yes</label><br>
                            <input type="radio"
                                   name="RespiratorySymptomsSub"
                                   style="margin-top: 7px"
                                   value="No"
                                   id="RespiratorySymptomsSubNo"
                                   (click)="RespiratorySymptomsSubNo=true; RespiratorySymptomsSubYes=false"
                                   class="checkbox-inline col-xs-2 col-sm-2"/>
                            <label for="RespiratorySymptomsSubNo">No</label>
                          </div>
                        </div>
                        <section *ngIf="RespiratorySymptomsSubNo">
                                                    <span>Based on your response, testing is not recommended for you at this time. Please complete this assessment when your symptoms have improved. If your symptoms do not
                                                        improve, please contact your healthcare provider for evaluation.</span>
                        </section>
                      </fieldset>
                    </div>
                  </div>
                </section>

              </div>
            </div>
          </section>
          <div class="covid2 panel" [hidden]="(showScreeningQuestion2 || showScreeningQuestion3) && !ineligible2">
            <div *ngIf="HouseholdContact"
                 style="text-align: initial;padding-left: 20px; background-color: #ffc107; color: black">
                            <span>
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                If you are currently experiencing any of the following
                                symptoms, seek medical attention right away.
                            </span>
              <ul style="list-style-type:disc">
                <li>Severe trouble breathing (such as being unable to talk without gasping for air)</li>
                <li>Continuous pain or pressure in the chest</li>
                <li>Feeling confused or having difficulty waking up</li>
                <li>Blue-colored lips or face</li>
                <li>Any other emergency signs or symptoms</li>
              </ul>
            </div>
            <div class="panel-heading">
              <h3 class="panel-title" style="text-align: center;color: white;">
                PWNHealth provides physician oversight, ordering, and follow-up consults for COVID-19
                testing. Please review and consent to each item below.
              </h3>
              <h3 class="panel-title" style="text-align: center;color: white;">
                By checking, I consent to the PWNHealth Notice of Privacy Practices, Terms and
                Conditions, and Consent.
              </h3>
            </div>
            <div class="panel-body" style="padding: 0 30px;">
              <div class="col-sm-12">
                <fieldset class="form-group">
                  <div class="row" style="display: block;text-align: left;">
                    <input required ng-model="model.privacyPractices" type="checkbox"
                           class="checkbox-inline col-xs-2 col-sm-2" name="privacyPractices"
                           value="1"
                           id="privacyPractices">
                    <label for="privacyPractices"
                           style="text-align: left"
                           class="col-xs-10 col-sm-10 control-label"> <a
                      style="font-size:16px;text-align: left;margin: 0"
                      href="https://www.pwnhealth.com/privacy-policy-covid-19/"
                      target="_blank">PWN Notice of Privacy Practices </a> </label>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row" style="display: block;text-align: left;">
                    <input required [(ngModel)]="model.termsAndConditions" type="checkbox"
                           class="checkbox-inline col-xs-2 col-sm-2" name="termsAndConditions"
                           value="2"
                           id="termsAndConditions">
                    <label for="termsAndConditions"
                           style="text-align: left"
                           class="col-xs-10 col-sm-10 control-label"> <a
                      style="font-size:16px;text-align: left;margin: 0"
                      href="http://www.pwnhealth.com/self-pay-terms-of-use/" target="_blank">PWN
                      Terms and Conditions </a> </label>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row" style="display: block;text-align: left;">
                    <input required [(ngModel)]="model.testConsent" type="checkbox"
                           class="checkbox-inline col-xs-2 col-sm-2" name="testConsent" value="3"
                           id="testConsent">
                    <label for="testConsent"
                           style="text-align: left"
                           class="col-xs-10 col-sm-10 control-label"> <a
                      style="font-size:16px;text-align: left;margin: 0"
                      href="https://www.pwnhealth.com/covid-19/" target="_blank">PWN COVID-19
                      Test Consent</a> </label>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div ng-cloak class="form-group col-sm-12 pull-right"
               [hidden]="(showScreeningQuestion2 || showScreeningQuestion3) && !ineligible2">
            <div class="alert alert-danger" role="alert"
                 *ngIf="!form.valid">
              Please check to agree to the
              {{!model.privacyPractices ? 'PWN Notice of Privacy Practices' :
              ''}}{{!model.privacyPractices && (!model.termsAndConditions || !model.testConsent) ? ' and
              ': ''}}
              {{!model.termsAndConditions ? 'PWN Terms and Conditions' : ''}}{{!model.termsAndConditions
            && !model.testConsent ? ' and ' : ''}}
              {{!model.testConsent ? 'Privacy Policy and COVID-19 Test Consent' : ''}}
            </div>
          </div>

          <div class="row" ng-show="(showScreeningQuestion2 || showScreeningQuestion3) && !ineligible2">
            <div class="col-md-12" style="text-align: center;">
              <button class="btn btn-info" type="submit" (click)="submit()" [disabled]="!isValidHouseholdContact">
                <span>Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </section>
  <section *ngIf="ineligible">
    <h1></h1>
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-danger">
          <div class="panel-heading">
            <h3 class="panel-title">
              Ineligible for TESTING
            </h3>
          </div>
          <div class="panel-body text-danger">
            Based on your response, you may still have an active COVID-19 infection and this test
            may not be right for you at this time. COVID-19 Immune Response testing is specifically
            used to check for an immune response to the virus which can take time after an active
            infection.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="ineligible2">
    <h1></h1>
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-danger">
          <div class="panel-heading">
            <h3 class="panel-title">
              Ineligible for TESTING
            </h3>
          </div>
          <div class="panel-body text-danger">
            Based on your response, testing is not recommended for you at this time. Please complete this assessment
            when your symptoms have improved. If your symptoms do not
            improve, please contact your healthcare provider for evaluation
          </div>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>
