import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { CONSTANTS } from 'src/app/const';
import { SearchPipe } from 'src/app/pipes/search.pipe';
import { AccountService } from 'src/app/services/account.service';
import { CartService } from 'src/app/services/cart.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { ModalService } from 'src/app/services/modal.service';
import { OrderService } from 'src/app/services/order.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ProductService } from 'src/app/services/product.service';
import { ProfileService } from 'src/app/services/profile.service';
import { SessionInfoStorageService } from 'src/app/services/session-info-storage.service';
import { StatesService } from 'src/app/services/states.service';
import { environment } from 'src/environments/environment';

export interface UserData {
  userFullName: string;
  number: string;
  subTotal: string;
  isPaymentSkipped: string;
  submittedDate: string;
}

@Component({
  selector: 'app-admin-transaction',
  templateUrl: './admin-transaction.component.html',
  styleUrls: ['./admin-transaction.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminTransactionComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  processingFee = environment.processingFee;
  date = {
    dateStart: '',
    dateEnd: ''
  };
  search;
  orderStatuses = CONSTANTS.orderStatuses;
  columnsToDisplay = ['userFullName', 'number', 'subTotal', 'isPaymentSkipped', 'promoCode', 'submittedDate', 'Status', 'Actions'];
  expandedElement;
  pager: any = {};
  pagedItems: any[];
  error = '';
  page = 1;
  paginator = {};
  isAll = true;
  isPaymentSkipped = false;
  statusList: Array<any> = [
    {
      id: 255,
      label: 'All',
      checked: true
    },
    {
      id: 1,
      label: CONSTANTS.orderStatuses[1],
      checked: false
    },
    {
      id: 2,
      label: CONSTANTS.orderStatuses[2],
      checked: false
    },
    {
      id: 4,
      label: CONSTANTS.orderStatuses[4],
      checked: false
    },
    {
      id: 8,
      label: CONSTANTS.orderStatuses[8],
      checked: false
    },
    {
      id: 16,
      label: CONSTANTS.orderStatuses[16],
      checked: false
    },
    {
      id: 32,
      label: CONSTANTS.orderStatuses[32],
      checked: false
    },
    {
      id: 64,
      label: CONSTANTS.orderStatuses[64],
      checked: false
    }
  ];
  isPromo = false;
  searchControl = new UntypedFormControl('')
  selectControl = new UntypedFormControl('')
  // _filteredOrders = this._orders;
  pageSize = 10;
  collectionSize;
  transactionForm: UntypedFormGroup;
  selectedStatus = [];
  path;
  currentStatusId = 255;
  filteredOrders: any = [];
  filteredTransactions: string;
  items = [];
  newItems = [];
  count;
  dataSource: MatTableDataSource<UserData>;
  inputdata = [];
  options = [];
  modelAuth;
  filteredOptions = [];

  constructor(
    private profileService: ProfileService,
    private modalService: ModalService,
    private orderService: OrderService,
    private statesService: StatesService,
    private paginationService: PaginationService,
    private searchPipe: SearchPipe,
    private productService: ProductService,
    private cartService: CartService,
    private accountService: AccountService,
    private sessionInfoStorage: SessionInfoStorageService,
    private catalogService: CatalogService
  ) {

  }

  private _orders = [];

  get orders() {
    if (this.filteredOrders.length) {
      return this.filteredOrders.map((result, i) => ({ id: i + 1, ...result }))
        .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    } else {
      this.collectionSize = this.count;
      return this._orders;
    }
  }

  dateChange() {
    if (this.transactionForm.value.searchModel !== '') {
      this.filterTransaction(this.transactionForm.value.searchModel);
      return;
    }
    const startDate = this.transactionForm.value.createdDateFrom && moment(this.transactionForm.value.createdDateFrom)
      .format('YYYY-MM-DD') + 'T00:00:00.000Z';
    const endDate = this.transactionForm.value.createdDateTo && moment(this.transactionForm.value.createdDateTo).format('YYYY-MM-DD') + 'T23:59:00.000Z';
    const startDatePath = startDate ? `&createdDateFrom=${startDate}` : '';
    const endDatePath = endDate ? `&createdDateTo=${endDate}` : '';
    const path = `$orderby=CreatedDate+desc&$skip=0&$top=10${startDatePath}${endDatePath}&status=${this.currentStatusId === 0 ? 255 : this.currentStatusId}`;
    this.orderService.getOrderTaxonomy(path).subscribe(result => {
      this._orders = result['items'];
      this.filteredOrders = result['items'];
      this.collectionSize = result['count'];
      this.page = 1;
      this.dataSource = new MatTableDataSource(this._orders);
      this.dataSource.sort = this.sort;
    });
  }
  changeCart(el) {
    this.newItems = el;
  }
  saveCart(order) {
    this.orderService.getOrder(order.id, true).subscribe((oldItems) => {
      // let oldChildrenId = {};
      let forRemove = {}
      oldItems.items.map((el) => {
        forRemove[el.id] = el.product.id

        el.product.productId = el.id
      })
      let newChildrenId = this.newItems.map((el) => el.id)

      newChildrenId.map((el) => {
        if (Object.values(forRemove).indexOf(el) != -1) {
          delete forRemove[Object.keys(forRemove)[Object.values(forRemove).indexOf(el)]]
        }
      })

      let forAdd = newChildrenId
      oldItems.items.map((el) => {

        if (forAdd.indexOf(el.product.id) != -1) {
          forAdd.splice(forAdd.indexOf(el.product.id), 1)
        }
      })
      if (Object.keys(forRemove).length || forAdd.length) {
        order.forRemove = forRemove;
        order.forAdd = forAdd;
        order.actionType = 'order'
        this.prepare(order)
      }
    })
  }

  prepare(item) {
    item['update'] = true
    if (!this.modelAuth) {
      this.modalService.openLoginAs(item, model.bind(this));
    } else {
      this.modelAuth.userName = item.email || item.userEmail
      this.getAccessToken(item)
    }

    function model(model) {
      this.modelAuth = model
      this.getAccessToken(item)
    }
  }

  getAccessToken(item) {
    this.accountService.loginAs(this.modelAuth).subscribe(result => {
      this.sessionInfoStorage.setFakeAuthInfo({
        access_token: result.access_token,
        asQuasiAdmin: "true"
      })
      this.updateAction(item)
    })
  }
  updateAction(item) {
    if (item.actionType === 'cart') {
      this.orderService.addCategoryToCart({
        productId: item.itemForCart
      }).then((res) => {
        this.orderService.deleteOrder(res['id']).subscribe(() => {
          this.modalService.showTopNotification(true, 'Successfully added');
        })
        this.sessionInfoStorage.clearToken()
      })
    } else {
      if (Object.keys(item.forRemove).length) {
        Object.keys(item.forRemove).map(x => {
          const params = {
            orderId: item.id,
            itemId: x
          };
          this.orderService.deleteItemInCart(params)
        });
      }
      if (item.forAdd) {
        item.forAdd.map(x => {
          const params = {
            orderId: item.id,
            productId: x
          }
          this.orderService.addCategoryToCart(params)
        });
      }
      this.modalService.showTopNotification(true, 'Updated.');
      this.sessionInfoStorage.clearToken()
    }
  }

  searchUser() {
    const search = this.searchControl.value;
    if (search.length <= CONSTANTS.MIN_SEARCH_LENGTH) {
      this.modalService.showTopNotification(false, `Required length: ${CONSTANTS.MIN_SEARCH_LENGTH}`);
      return;
    }
    this.accountService.search({ "search": search }).subscribe((result) => {
      this.options = result.items
      this.filteredOptions = result.items
    });
  };
  addCart() {
    let user = this.options.filter((re) => re.email === this.selectControl.value)[0]
    if (!user) {
      this.modalService.showTopNotification(false, 'No such user');
    } else {
      user.actionType = 'cart';
      user.itemForCart = this.inputdata[0].id
      this.prepare(user)
    }
  }

  onChange(value) {
    if (typeof value !== 'string') {
      this.filteredOptions = this.options
      return
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.options.filter(option => option.email.toLowerCase().includes(filterValue));
  }
  ngOnInit(): void {
    const path = `$orderby=CreatedDate+desc&$skip=0&$top=10&status=${this.currentStatusId === 0 ? 255 : this.currentStatusId}`;

    this.orderService.getOrderTaxonomy(path).subscribe(result => {
      this._orders = result['items'];
      this.dataSource = new MatTableDataSource(this._orders);
      this.dataSource.sort = this.sort;
      this.count = result['count'];
      this.collectionSize = result['count'];
      this.filteredOrders = result['items'].map((result, i) => ({ id: i + 1, ...result }))
        .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

      // this._orders = result['items'].map((result, i) => ({id: i + 1, ...result}))
      // .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    });
    this.transactionForm = new UntypedFormGroup({
      searchModel: new UntypedFormControl(''),
      status: new UntypedFormControl(''),
      onlyFree: new UntypedFormControl(''),
      dateStart: new UntypedFormControl(''),
      dateEnd: new UntypedFormControl(''),
      createdDateFrom: new UntypedFormControl(''),
      createdDateTo: new UntypedFormControl(''),
    });

    this.catalogService.search({}).subscribe((res) => {
      this.inputdata = res['items'].filter((el) => el.isActive)
    })
    // this.productService.getAllProducts().subscribe(res => {
    //   this.inputdata = res.items
    // })
  }

  expandRow(element) {
    if (this.expandedElement && this.expandedElement.id === element.id) {
      this.expandedElement = null;
      return;
    }
    this.orderService.getOrder(element.id, true).subscribe((res: any) => {
      this.items = res.items.map((el) => el.product);
      this.expandedElement = element;
    });
  }

  filterTransaction(search) {
    let splitedSearch = search.split('-');
    if (search === '') {
      this.collectionSize = this.count;
      this.dateChange();
      return;
    } else if (isNaN(splitedSearch[0])) {
      search = `searchUser=${search}`;
    } else if (splitedSearch) {
      search = `number=${search}`;
    }

    const startDate = this.transactionForm.value.createdDateFrom && moment(this.transactionForm.value.createdDateFrom).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    const endDate = this.transactionForm.value.createdDateTo && moment(this.transactionForm.value.createdDateTo).format('YYYY-MM-DD') + 'T23:59:00.000Z';
    const startDatePath = startDate ? `&createdDateFrom=${startDate}` : '';
    const endDatePath = endDate ? `&createdDateTo=${endDate}` : '';
    search += `${startDatePath}${endDatePath}&status=${this.currentStatusId === 0 ? 255 : this.currentStatusId}&isPromo=${this.isPromo}`;

    this.filteredOrders = this.orderService.browseOrders(search).subscribe((res) => {
      this.collectionSize = res.length;
      this.filteredOrders = res;
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.sort = this.sort;
    });

  }

  getOrderTaxonomy(currentPage, event, status?) {
    if (status !== undefined) {
      this.currentStatusId = this.statusList.filter((status) => status.checked).reduce((acc, cur) => acc + cur.id, 0);

    }
    if (this.transactionForm.value.searchModel !== '') {
      this.filterTransaction(this.transactionForm.value.searchModel);
      return;
    }
    const skip = (currentPage ? (currentPage - 1) : (event.target.innerHTML.split(' ')[1] - 1)) * 10;
    const startDate = this.transactionForm.value.createdDateFrom && moment(this.transactionForm.value.createdDateFrom).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    const endDate = this.transactionForm.value.createdDateTo && moment(this.transactionForm.value.createdDateTo).format('YYYY-MM-DD') + 'T23:59:00.000Z';
    const startDatePath = startDate ? `&createdDateFrom=${startDate}` : '';
    const endDatePath = endDate ? `&createdDateTo=${endDate}` : '';
    // if(this.currentStatusId !== undefined){
    this.path = `$orderby=CreatedDate+desc&$skip=${skip}&$top=${this.pageSize}${startDatePath}${endDatePath}&status=${this.currentStatusId === 0 ? 255 : this.currentStatusId}`;
    // }
    // else{
    //   this.path = `$orderby=CreatedDate+desc&$skip=${skip}&$top=${this.pageSize}${startDatePath}${endDatePath}&status=${255}`
    // }
    this.filteredOrders = this.orderService.getOrderTaxonomy(this.path).subscribe((result: any) => {
      // this.filteredOrders = result['items'];
      this._orders = result['items'];
      this.dataSource = new MatTableDataSource(this._orders);
      this.dataSource.sort = this.sort;
      this.count = result['count'];
      this.filteredOrders = result;
      this.page = currentPage;
      this.transactionForm.patchValue({ searchModel: '' });
    });
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  toggleSelectionPromo() {
    this.isPromo = !this.isPromo;
  }

  toggleSelection(event, status) {
    status.checked = event.target.checked;
    if (status.id === 255 && status.checked) {
      this.statusList.forEach(function (item) {
        if (item.id !== status.id) {
          item.checked = false;
        }
      });
    } else {
      this.statusList.filter(status => status.id == 255)[0].checked = false;
    }
    ;
  }

  cancelOrder(order, key) {
    this.modalService.openConfirmModal('Cancel order?', cancelOrder.bind(this, order, key, this._orders));

    function cancelOrder(order, key, orders) {
      this.orderService.cancelOrder(order.id).subscribe(
        () => {
          order.status = 64;
          // this.modalService.showTopNotification(true, 'Order was successfully canceled');
        }
      );
    }
  };

  removeOrder(order, key) {
    this.modalService.openConfirmModal('Delete order?', removeOrder.bind(this, order, key, this._orders));

    function removeOrder(order, key, orders) {
      this.orderService.deleteOrder(order.id).subscribe(
        () => {
          orders.splice(key, 1);
          // this.modalService.showTopNotification(true, 'Order was successfully removed');
        }
      );
    }
  };

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  // orderBy: 'name';trackBy: trackByFn
}
