<div class="cabinetTabs">
  <div class="container-fluid">
    <div class="actionLine">
      <div class="promo-input-wrapper">
      <button class="btn btn-default" (click)="openModal()"><i class="fa fa-plus-circle"></i>
        Add Promo code
      </button>
      <div class="row">
        <div class="col-md-12">
          <div class="input-group">
            <input class="form-control" type="text" (keyup)="applyFilter($event)" #search (keyup)="filterPromoCode(search.value)" >
            <span class="input-group-addon">
            <i class="glyphicon glyphicon-search"></i>
          </span>
          </div>
        </div>
      </div>
    </div>
    </div>


    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 ">
      <ng-container matColumnDef="code" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Promo code</th>
        <td mat-cell *matCellDef="let promoCode" matTooltip="{{ promoCode.code }}">{{ promoCode.code }}</td>
      </ng-container>
      <ng-container matColumnDef="totalLimit" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header="totalUsedLimit">Used/Allotted</th>
        <td mat-cell *matCellDef="let promoCode" matTooltip="{{promoCode.totalUsedLimit}}/{{promoCode.totalUseLimit}}">{{promoCode.totalUsedLimit}}/{{promoCode.totalUseLimit}}</td>
      </ng-container>
      <ng-container matColumnDef="isActive" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
        <td mat-cell *matCellDef="let promoCode" matTooltip="{{promoCode.isActive? 'Active' : 'Inactive'}}">{{promoCode.isActive? 'Active' : 'Inactive'}}</td>
      </ng-container>
      <ng-container matColumnDef="dateFinish" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expires</th>
        <td mat-cell *matCellDef="let promoCode" matTooltip="{{promoCode.dateFinish | date}}">{{promoCode.dateFinish | date}}</td>
      </ng-container>
      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let promoCode">
          <button class="btn btn-success" (click)="openPromoCode(promoCode.id)">
            <i class="fa fa-pencil-square"></i>
            Edit
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="['code', 'totalLimit', 'isActive', 'dateFinish', 'action']"></tr>
      <tr mat-row *matRowDef="let promoCode; columns: ['code', 'totalLimit', 'isActive', 'dateFinish', 'action']"></tr>
    </table>
    <ngb-pagination [collectionSize]="totalPromo" [(page)]="page" [maxSize]="10"
                    (pageChange)="getUsersFromSearch(page)"
                    [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
  </div>
</div>
